import React, { useEffect, useState, useCallback } from 'react';
import { Row, Col, Typography } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { LoadingSpin } from 'common/components/LoadingSpin';
import { LanguageForm } from 'entities/Language/components/LanguagesForm';
import { communicationLanguage, ILanguageConnectedProps } from 'entities/Language/Language.communication';
import { ILanguageModel } from 'entities/Language/Language.models';
import { LanguageModal } from 'entities/Language/components/LanguageModal';

interface IProps {}

type AllProps = IProps & ILanguageConnectedProps;

const LanguagePageComponent: React.FC<AllProps> = props => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalData, setModalData] = useState<ILanguageModel | null>(null);
  const { getLanguageCollection, clearLanguageCollection, languageCollection } = props;
  const { data, loading } = languageCollection;
  const languages = data?.data;

  useEffect(() => {
    getLanguageCollection({ withPrivate: true });
    return clearLanguageCollection;
  }, [getLanguageCollection, clearLanguageCollection]);

  const onCloseAddModal = () => {
    setModalVisible(false);
    setModalData(null);
  };

  const openModal = () => {
    setModalVisible(true);
  };

  const onEdit = useCallback((language: ILanguageModel) => {
    setModalData(language);
    openModal();
  }, []);

  return (
    <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10 pb-10">
      <Row gutter={[16, 16]} align="middle">
        {loading && <LoadingSpin />}
        {languages?.map(language => (
          <Col key={language.locale} span={4}>
            <LanguageForm onEdit={onEdit} language={language} />
          </Col>
        ))}
        <Col span={4} className="p-6 cursor-pointer t-align-c" onClick={openModal}>
          <Typography.Title level={5} className="mb-0">
            <PlusCircleOutlined /> Add new
          </Typography.Title>
        </Col>
      </Row>
      <LanguageModal modalData={modalData} visible={modalVisible} onCancel={onCloseAddModal} />
    </CommonLayout>
  );
};

export const LanguagePage = communicationLanguage.injector(LanguagePageComponent);
