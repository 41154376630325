import React, { useEffect, useMemo } from 'react';
import { Form, Button, Popconfirm, InputNumber, Input, Row, Col } from 'antd';
import { useFormMapper } from '@axmit/antd4-helpers';
import { Link } from 'react-router-dom';
import { nameBuilder } from 'common/helpers/name.helper';
import { ERoutesPrivate } from 'common/models/routesModel';
import { formRules } from 'common/const/common.const';
import { communicationWallet, IWalletConnectedProps } from 'entities/Wallet/Wallet.communication';
import { WalletTransactionTypeSelector } from 'entities/Wallet/components/Selector/WalletTransactionTypeSelector';
import { EWalletTransactionType, IWalletTransactionParamsModel } from 'entities/Wallet/Wallet.models';
import { IPlayerModel } from 'entities/Player/Player.models';

interface IComponentProps {
  player?: IPlayerModel;
  redirectUrl?: string;
}
type AllProps = IWalletConnectedProps & IComponentProps;

const WalletDirectTransactionFormComponent: React.FC<AllProps> = props => {
  const { addWalletDirectTransaction, walletDirectTransaction, clearWalletDirectTransaction, player, redirectUrl } = props;
  const { wallet, firstName, lastName, id: playerId } = player || {};
  const { coinsBalance, id } = wallet || {};
  const name = nameBuilder(firstName, lastName);
  const { loading, params, errors } = walletDirectTransaction;
  const [form] = Form.useForm();
  const memoizedValue = useMemo(
    () => ({
      transactionType: EWalletTransactionType.Replenished
    }),
    []
  );
  const formParams: any = useMemo(
    () => (params?.coinsAmount ? { ...params, coinsAmount: Math.abs(params?.coinsAmount) } : params),
    [params]
  );

  const { fields } = useFormMapper(['description', 'coinsAmount', 'transactionType'], memoizedValue, formParams, errors);

  const submit = (values: any) => {
    if (id) {
      const { transactionType, coinsAmount, description } = values;
      const coinsAmountValue = transactionType === EWalletTransactionType.ChargeOff ? -coinsAmount : coinsAmount;
      const paramsData: IWalletTransactionParamsModel = {
        id,
        coinsAmount: coinsAmountValue,
        description
      };
      if (redirectUrl) {
        paramsData.redirectAfterSuccess = redirectUrl;
      }
      addWalletDirectTransaction(paramsData);
    }
  };
  useEffect(() => {
    return () => clearWalletDirectTransaction();
  }, []); // eslint-disable-line

  return (
    <Col span={24} className="mt-5">
      <Form form={form} className="mb-5" onFinish={submit} fields={fields}>
        <Row gutter={[16, 0]} className="mb-5 width-full" justify="space-between" align="middle">
          <Col span={12}>
            <span className="mr-3" title="Coins balance">
              Player:
            </span>
            <Link className="color-blue" to={`${ERoutesPrivate.Players}/${playerId}`}>
              {name}
            </Link>
          </Col>
          <Col span={12}>
            <span className="basic__text_label mr-3" title="Coins balance">
              Coins balance: {coinsBalance}
            </span>
          </Col>
          <Col span={12}>
            <Form.Item name="coinsAmount" label="Coins amount" rules={[formRules.required]}>
              <InputNumber disabled={loading} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="transactionType" label="Transaction type">
              <WalletTransactionTypeSelector />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Transaction description" name="description" rules={[formRules.required]}>
              <Input.TextArea maxLength={1024} rows={2} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Popconfirm title="Are you sure?" onConfirm={() => form.submit()} okText="Yes" cancelText="No">
              <Button htmlType="submit" type="primary" disabled={loading} loading={loading} title="Save">
                Make transaction
              </Button>
            </Popconfirm>
          </Col>
        </Row>
      </Form>
    </Col>
  );
};

export const WalletDirectTransactionForm = communicationWallet.injector(WalletDirectTransactionFormComponent);
