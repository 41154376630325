import { EActionsTypes, APIProvider, BaseStrategy, Branch, buildCommunication, StoreBranch } from '@axmit/redux-communications';
import { message } from 'antd';
import { put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { ERoutesPrivate } from 'common/models/routesModel';
import { skinTransport } from 'entities/Skin/Skin.transport';
import {
  ESkinSuccessMessage,
  ISkinModel,
  ISkinUpdateParams,
  ISkinCollectionFilter,
  ISkinCollection,
  ISkinCreateParams
} from 'entities/Skin/Skin.models';

const namespace = 'skin';

export interface ISkinConnectedProps {
  skinModel: StoreBranch<ISkinModel>;
  skinCollection: StoreBranch<ISkinCollection, ISkinCollectionFilter>;

  getSkinModel(id: string): void;
  addSkinModel(params: ISkinCreateParams): void;
  updateSkinModel(params: ISkinUpdateParams): void;
  deleteSkinModel(id: string): void;
  getSkinCollection(filter?: ISkinCollectionFilter): void;

  clearSkinModel(): void;
  clearSkinCollection(): void;
}

const SkinModelAPIProviders = [
  new APIProvider(EActionsTypes.get, skinTransport.get),
  new APIProvider(EActionsTypes.update, skinTransport.update, {
    onSuccess: function*() {
      message.success(ESkinSuccessMessage.Edit);
      yield put(push(ERoutesPrivate.Skins));
    }
  }),
  new APIProvider(EActionsTypes.add, skinTransport.add, {
    onSuccess: function*() {
      message.success(ESkinSuccessMessage.Add);
      yield put(push(ERoutesPrivate.Skins));
    }
  })
];

const SkinCollectionAPIProviders = [new APIProvider(EActionsTypes.get, skinTransport.getCollection)];

const branches = [new Branch('model', SkinModelAPIProviders), new Branch('collection', SkinCollectionAPIProviders)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationSkin = buildCommunication<ISkinConnectedProps>(strategy);
