import { ColumnsType } from 'antd/lib/table';
import { RouteComponentProps, withRouter } from 'react-router';
import { ISorter, TableCommon } from 'common/components/TableCommon';
import { IBaseFilterModel } from 'common/models/requestModels';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { dateFormatter } from 'common/helpers/date.helper';
import { simpleDateFormatWithTime } from 'common/models/dateModels';
import { ERoutesPrivate } from 'common/models/routesModel';
import { IFeedCollection, IFeedCollectionFilter, IFeedModel } from 'entities/Feed/Feed.models';
import { communicationFeed, IFeedConnectedProps } from 'entities/Feed/Feed.communication';
import { EEntityType } from 'entities/User/User.models';

interface IComponentProps {
  config: ColumnsType<any>;
}

type AllProps = IComponentProps & IFeedConnectedProps & RouteComponentProps;

class FeedTableComponent extends TableCommon<IFeedCollection, IFeedModel, ColumnsType<any>, AllProps, IFeedCollectionFilter> {
  loadCollection = (params: IBaseFilterModel, sorter?: ISorter) => {
    const { getFeedCollection, filter } = this.props;
    const filterParams: IFeedCollectionFilter = { ...params };
    if (filter?.title) {
      filterParams.title = filter?.title;
    }
    if (filter?.status) {
      filterParams.status = filter?.status;
    }
    if (filter?.type) {
      filterParams.type = filter?.type;
    }
    if (filter?.from) {
      filterParams.from = filter.from;
    }
    if (filter?.to) {
      filterParams.to = filter.to;
    }
    if (filter?.authorValue) {
      if (filter?.authorEntity === EEntityType.Player) {
        filterParams.authorPlayerId = filter.authorValue?.id;
      }
      if (filter?.authorEntity === EEntityType.User) {
        filterParams.authorUserId = filter.authorValue?.id;
      }
    }
    if (filter?.to) {
      filterParams.to = filter.to;
    }
    if (sorter?.field === 'createdAt' || sorter?.field === 'status' || sorter?.field === 'type' || sorter?.field === 'likes') {
      if (sorter?.order) {
        const orderDirection = sorter.order === 'ascend' ? 'ASC' : 'DESC';
        filterParams.orderField = sorter?.field;
        filterParams.orderDirection = orderDirection;
      }
    } else {
      if (filter?.orderField && filter?.orderDirection) {
        filterParams.orderField = filter?.orderField;
        filterParams.orderDirection = filter?.orderDirection;
      }
    }
    getFeedCollection(filterParams);
  };

  getCollection = () => {
    const { feedCollection } = this.props;
    if (feedCollection?.data?.data) {
      const data: IFeedModel[] = feedCollection.data.data.map(item => {
        const { createdAt } = item || {};
        return {
          ...item,
          createdAt: dateFormatter(createdAt, simpleDateFormatWithTime),
          edit: item?.id
        };
      });
      return {
        ...feedCollection,
        data: {
          ...feedCollection.data,
          data
        }
      };
    } else {
      return feedCollection;
    }
  };

  addQuery = (pageIndex: number, sorterValue?: ISorter) => {
    const searchPath = queryToObject<IFeedCollectionFilter>({
      pageIndex: 1,
      title: undefined,
      status: undefined,
      from: undefined,
      to: undefined,
      type: undefined,
      authorEntity: undefined,
      authorValue: undefined,
      orderField: undefined,
      orderDirection: undefined
    });
    let search;

    if (
      sorterValue?.field === 'createdAt' ||
      sorterValue?.field === 'status' ||
      sorterValue?.field === 'type' ||
      sorterValue?.field === 'likes'
    ) {
      if (sorterValue?.order) {
        const orderDirection = sorterValue.order === 'ascend' ? 'ASC' : 'DESC';
        search = objectToQuery({ ...searchPath, pageIndex, orderField: sorterValue?.field, orderDirection });
      } else {
        search = objectToQuery({ ...searchPath, pageIndex, orderField: undefined, orderDirection: undefined });
      }
    } else {
      search = objectToQuery({ orderField: undefined, orderDirection: undefined, ...searchPath, pageIndex });
    }

    return this.props.history.replace({ search });
  };

  onRowClick = (item: IFeedModel) => {
    const { history } = this.props;
    history.push(`${ERoutesPrivate.Feed}/${item.id}`);
  };

  clearCollection = () => {
    return this.props.clearFeedCollection();
  };
}

export const FeedTable = communicationFeed.injector(withRouter(FeedTableComponent));
