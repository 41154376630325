import React from 'react';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { Link } from 'react-router-dom';
import { StoreBranch } from '@axmit/redux-communications';
import { useFormMapper } from '@axmit/antd4-helpers';
import { ERoutesPrivate } from 'common/models/routesModel';
import { LanguageSelect } from 'entities/Language/components/Selectors/LanguageSelect';
import { INotificationTemplateCreateParams } from 'entities/NotificationTemplate/NotificationTemplate.models';

interface IComponentProps {
  model: StoreBranch<void>;
  onSubmit: (data: INotificationTemplateCreateParams) => void;
}

const NotificationEmailSendFormComponent: React.FC<IComponentProps> = props => {
  const { model, onSubmit } = props;
  const { errors, loading } = model;

  const { fields } = useFormMapper(['emails', 'title', 'text', 'lang'], null, null, errors);

  const handleSubmit = (results: INotificationTemplateCreateParams) => {
    onSubmit({
      ...results
    });
  };

  return (
    <Form className="mt-5" onFinish={handleSubmit} fields={fields}>
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Must be at least 1 email'
              }
            ]}
            name="emails"
            label="Emails"
          >
            <Select tokenSeparators={[' ', ',', ', ']} mode="tags" placeholder="Emails" style={{ width: '100%' }} />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Title text is required'
              },
              {
                min: 2,
                message: 'Title must be longer than or equal 2 characters'
              },
              {
                max: 128,
                message: 'Title must be shorter than or equal 128 characters'
              }
            ]}
            name="title"
            label="Title"
          >
            <Input placeholder="Title" disabled={loading} />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Message text is required'
              },
              {
                min: 2,
                message: 'Message text must be longer than or equal 2 characters'
              },
              {
                max: 1024,
                message: 'Message text must be shorter than or equal 1024 characters'
              }
            ]}
            name="text"
            label="Message text"
          >
            <Input.TextArea rows={3} placeholder="Message text" disabled={loading} />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Language is required'
              }
            ]}
            label="Email language"
            name="lang"
          >
            <LanguageSelect />
          </Form.Item>
        </Col>
      </Row>

      <Row justify="space-between" align="middle">
        <Col span={16}>
          <Form.Item className="mb-0">
            <Button className="mr-8" htmlType="submit" type="primary" disabled={loading} loading={loading} title="Save">
              Send
            </Button>
            <Link to={ERoutesPrivate.NotificationTemplates}>
              <Button disabled={loading} loading={loading} title="Cancel">
                Cancel
              </Button>
            </Link>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export const NotificationEmailSendForm = NotificationEmailSendFormComponent;
