import axios, { AxiosStatic } from 'axios';
import { BaseHttpTransport } from '@axmit/transport';
import {
  IAuthModel,
  IAuthParams,
  IPasswordForgotParams,
  IPasswordRestoreParams,
  IPasswordRestoreTokenCheckParams
} from 'entities/Auth/Auth.models';

const basePath = '/auth';
const passwordRestore = '/restore-password';

class AuthTransport extends BaseHttpTransport<AxiosStatic, IAuthModel, void, IAuthParams, void, void> {
  public logout = (): Promise<void> => axios.delete(basePath);
}

class PasswordRestoreTransport extends BaseHttpTransport<
  AxiosStatic,
  void,
  IPasswordForgotParams,
  IPasswordRestoreParams,
  void,
  void
> {
  public passwordRestore = (params: IPasswordRestoreParams): Promise<void> => axios.put(passwordRestore, params);
  public checkRestoreToken = (params: IPasswordRestoreTokenCheckParams): Promise<void> =>
    axios.post(`${passwordRestore}/check-token`, params);
}

export const passwordRestoreTransport = new PasswordRestoreTransport(passwordRestore, axios);

export const authTransport = new AuthTransport(basePath, axios);
