import { Moment } from 'moment';
import { Button, message } from 'antd';
import React, { useCallback } from 'react';
import { HOST } from 'common/const/common.const';
import { copyToBuffer } from 'common/helpers/buffer.helper';
import { IBasePathParams } from 'common/models/requestModels';
import { ISelectMapItem } from 'common/models/selectModels';
import { nameBuilder } from 'common/helpers/name.helper';
import { ILocationModel } from 'common/helpers/location.helper';
import { IUserModel } from 'entities/User/User.models';
import { IAcademyModel } from 'entities/Academy/Academy.models';
import { playerTransport } from 'entities/Player/Player.transport';
import { EMentorRequestStatus, IMentorRequestModel } from 'entities/MentorRequest/MentorRequest.models';

export interface IPlayerCollection extends Components.Schemas.PlayerCollectionDto {}
export interface IPlayerCollectionFilter extends Paths.PlayerControllerList.QueryParameters {
  pageIndex?: number;
  academyFilter?: Partial<IAcademyModel> & { referralCode?: string };
  referralCode?: string;
  mentorFilter?: Partial<IUserModel>;
  trainerFilter?: Partial<IUserModel>;
  excludeTeamId?: string;
  excludeTrainerId?: string;
  academyId?: string;
  location?: ILocationModel;
}
export interface IPlayerChangeAcademyParams extends Components.Schemas.MovePlayerDto, IBasePathParams {}
export interface IPlayerChangeStatusParams extends Components.Schemas.UpdatePlayerStatusDto, IBasePathParams {}
export interface IPlayerChangeMentorParams extends Components.Schemas.PlayerSetMentorDto, IBasePathParams {}
export interface IPlayerModel extends Components.Schemas.PlayerDto {}
export interface IPlayerCreateParams extends Components.Schemas.CreatePlayerDto {}
export interface IPlayerUpdateParams
  extends Omit<Components.Schemas.UpdatePlayerByAdminDto, 'birthday' | 'phone'>,
    IBasePathParams {
  birthday: Moment;
  phone?: string | null;
}
export interface IPlayerParamsModel extends IPlayerUpdateParams, IBasePathParams {}

export interface IPlayerVideoModel extends Components.Schemas.PlayerVideoDto {}
export interface IPlayerVideoCollection extends Components.Schemas.PlayerVideoCollectionDto {}
export interface IPlayerVideoCollectionFilter
  extends Paths.PlayerVideoControllerList.PathParameters,
    Paths.PlayerVideoControllerList.QueryParameters {}

export interface IPlayerTestStatisticModel extends Components.Schemas.PlayerTestCountStatisticDto {}
export interface IPlayerTestStatisticFilter
  extends Paths.PlayerControllerGetTestStatistic.PathParameters,
    Paths.PlayerControllerGetTestStatistic.QueryParameters {}
export interface IPlayerCSVCollectionFilter extends Paths.TrainerControllerGetPlayersCsv.QueryParameters {}

export const tablePlayerConfig = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    sorter: true
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
    ellipsis: true
  },
  {
    title: 'Age Group',
    dataIndex: 'ageGroupLabel',
    key: 'ageGroupLabel',
    ellipsis: true,
    width: 100,
    sorter: true
  },
  {
    title: 'Address',
    dataIndex: 'fullAddress',
    key: 'fullAddress',
    ellipsis: true
  },
  {
    title: 'Rating',
    dataIndex: 'rating',
    key: 'rating',
    ellipsis: true,
    width: 100,
    sorter: true
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    ellipsis: true
  },
  {
    title: 'Referral academy',
    dataIndex: ['referralCode', 'academy', 'name'],
    key: 'referralCode',
    ellipsis: true
  },
  {
    title: 'Registration date',
    dataIndex: 'registrationDate',
    key: 'registrationDate',
    ellipsis: true,
    sorter: true
  }
];

export const tableAcademyPlayerConfig = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true
  },
  {
    title: 'Age Group',
    dataIndex: 'ageGroupLabel',
    key: 'ageGroupLabel',
    ellipsis: true,
    width: 100,
    sorter: true
  },
  {
    title: 'Address',
    dataIndex: 'fullAddress',
    key: 'fullAddress',
    ellipsis: true
  },
  {
    title: 'Rating',
    dataIndex: 'rating',
    key: 'rating',
    ellipsis: true,
    width: 100,
    sorter: true
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    ellipsis: true
  },
  {
    title: 'Mentor',
    dataIndex: 'mentorRequest',
    key: 'mentorRequest',
    ellipsis: true,
    render: (mentorRequest: IMentorRequestModel) => {
      if (mentorRequest && mentorRequest.status === EMentorRequestStatus.Accepted) {
        return nameBuilder(mentorRequest.mentor.firstName, mentorRequest.mentor.lastName, mentorRequest.mentor.email);
      }

      return '-';
    }
  },
  {
    title: 'Share',
    dataIndex: 'share',
    key: 'share',
    width: 110,
    render: (data: IPlayerModel) => {
      const { id, juniCoachModule } = data || {};
      const onCopy = useCallback(
        async (e?: any) => {
          e?.stopPropagation();
          if (id) {
            let link = `${HOST}/player/${id}`;
            if (data?.mentorRequest?.status !== 'accepted' && juniCoachModule) {
              const { token } = await playerTransport.createShareToken(id);
              link = `${HOST}/player/${id}?token=${token}`;
            }
            try {
              await copyToBuffer(link);
              message.success('Link has been copied');
            } catch (e) {
              console.log(e);
            }
          }
        },
        [id, data]
      );
      return (
        !!id && (
          <Button className="ml-4" type="primary" title="Share" onClick={onCopy}>
            Share
          </Button>
        )
      );
    }
  }
];

export enum EPlayerStatuses {
  Active = 'active',
  Banned = 'banned'
}
export enum EPlayerStatusText {
  Active = 'Active',
  Banned = 'Banned'
}

export enum EPlayerAgeGroups {
  U7 = 'U7',
  U8 = 'U8',
  U9 = 'U9',
  U10 = 'U10',
  U11 = 'U11',
  U12 = 'U12',
  U13 = 'U13',
  U14 = 'U14',
  U15 = 'U15',
  U16 = 'U16',
  U17 = 'U17',
  U17p = 'U17+'
}

export enum EPlayerAgeGroupToText {
  'U7' = 'U7',
  'U8' = 'U8',
  'U9' = 'U9',
  'U10' = 'U10',
  'U11' = 'U11',
  'U12' = 'U12',
  'U13' = 'U13',
  'U14' = 'U14',
  'U15' = 'U15',
  'U16' = 'U16',
  'U17' = 'U17',
  'U17+' = '18+'
}

export const PlayerAgeGroupMap: ISelectMapItem<EPlayerAgeGroupToText, EPlayerAgeGroups>[] = [
  {
    title: EPlayerAgeGroupToText.U7,
    value: EPlayerAgeGroups.U7
  },
  {
    title: EPlayerAgeGroupToText.U8,
    value: EPlayerAgeGroups.U8
  },
  {
    title: EPlayerAgeGroupToText.U9,
    value: EPlayerAgeGroups.U9
  },
  {
    title: EPlayerAgeGroupToText.U10,
    value: EPlayerAgeGroups.U10
  },
  {
    title: EPlayerAgeGroupToText.U11,
    value: EPlayerAgeGroups.U11
  },
  {
    title: EPlayerAgeGroupToText.U12,
    value: EPlayerAgeGroups.U12
  },
  {
    title: EPlayerAgeGroupToText.U13,
    value: EPlayerAgeGroups.U13
  },
  {
    title: EPlayerAgeGroupToText.U14,
    value: EPlayerAgeGroups.U14
  },
  {
    title: EPlayerAgeGroupToText.U15,
    value: EPlayerAgeGroups.U15
  },
  {
    title: EPlayerAgeGroupToText.U16,
    value: EPlayerAgeGroups.U16
  },
  {
    title: EPlayerAgeGroupToText.U17,
    value: EPlayerAgeGroups.U17
  },
  {
    title: EPlayerAgeGroupToText['U17+'],
    value: EPlayerAgeGroups.U17p
  }
];

export enum EPlayerTabKeys {
  Academy = 'academy',
  Training = 'training',
  Progress = 'progress',
  Tests = 'tests',
  Video = 'video',
  Setting = 'setting'
}

export const PLAYER_TEST_LABELS: { [key: string]: string | undefined } = {
  Speed: 'Quickness',
  'Jumping ability': 'Jumping',
  Power: 'Strength',
  Stop: '1st touch',
  Control: 'Ball control',
  Maintaining: 'Dribbling',
  'Hit precision': 'Shot accuracy',
  'Impact force': 'Shot power'
};
