import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';

type AllProps = SelectProps<any>;

enum ETestFormTypes {
  None = 'none',
  Solo = 'solo',
  GroupKeyboard = 'groupKeyboard',
  GroupTeamCard = 'groupTeamCard'
}

export class TestFormTypeSelector extends React.PureComponent<AllProps> {
  render() {
    const { ...props } = this.props;

    return (
      <Select className="width-full" defaultActiveFirstOption={false} showArrow {...props}>
        <Select.Option key={ETestFormTypes.None} value="" label={ETestFormTypes.None}>
          {ETestFormTypes.None}
        </Select.Option>
        <Select.Option key={ETestFormTypes.Solo} value={ETestFormTypes.Solo} label={ETestFormTypes.Solo}>
          {ETestFormTypes.Solo}
        </Select.Option>
        <Select.Option
          key={ETestFormTypes.GroupTeamCard}
          value={ETestFormTypes.GroupTeamCard}
          label={ETestFormTypes.GroupTeamCard}
        >
          {ETestFormTypes.GroupTeamCard}
        </Select.Option>
        <Select.Option
          key={ETestFormTypes.GroupKeyboard}
          value={ETestFormTypes.GroupKeyboard}
          label={ETestFormTypes.GroupKeyboard}
        >
          {ETestFormTypes.GroupKeyboard}
        </Select.Option>
      </Select>
    );
  }
}
