import React, { useEffect } from 'react';
import { FormInstance } from 'antd/lib/form/hooks/useForm';

interface IProps {
  form: FormInstance;
  errors: any;
}

export const useCustomFormValidation: React.FC<IProps> = props => {
  const { form, errors } = props;

  useEffect(() => {
    if (errors) {
      const { validation = {} } = errors ?? ({} as any);
      const errorList = Object.keys(validation).map(errorKey => ({
        name: errorKey.split('.').map((key: string) => (isNaN(Number(key)) ? key : Number(key))),
        errors: validation[errorKey]
      }));
      form.setFields(errorList);
    }
  }, [errors, form]);

  return null;
};
