import axios, { AxiosStatic } from 'axios';
import { BaseHttpTransport } from '@axmit/transport';
import {
  IProductCollection,
  IProductCollectionFilter,
  IProductCreateData,
  IProductModel,
  IProductUpdateData
} from 'entities/Product/Product.models';

const basePath = '/products';

class ProductTransport extends BaseHttpTransport<
  AxiosStatic,
  IProductModel,
  IProductCollection,
  IProductCreateData,
  IProductUpdateData,
  IProductCollectionFilter
> {}

export const productTransport = new ProductTransport(basePath, axios);
