import axios, { AxiosStatic } from 'axios';
import { BaseHttpTransport } from '@axmit/transport';
import {
  INotificationTemplateCollection,
  INotificationTemplateCollectionFilter,
  INotificationTemplateModel,
  INotificationTemplateUpdateParams
} from 'entities/NotificationTemplate/NotificationTemplate.models';

const basePath = '/notification-templates';

class NotificationTemplateTransport extends BaseHttpTransport<
  AxiosStatic,
  INotificationTemplateModel,
  INotificationTemplateCollection,
  null,
  INotificationTemplateUpdateParams,
  INotificationTemplateCollectionFilter
> {}

export const notificationTemplateTransport = new NotificationTemplateTransport(basePath, axios);
