import React from 'react';
import { Col, Row } from 'antd';

interface IComponentProps {
  id?: string;
}

export const LayoutBasic: React.FC<IComponentProps> = props => {
  const { id, children } = props;

  return (
    <Row id={id} className="basic__layout">
      <Col span={24}>{children}</Col>
    </Row>
  );
};
