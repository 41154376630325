import React, { useEffect } from 'react';
import { Breadcrumb, Col, Skeleton } from 'antd';
import { useParams } from 'react-router';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { communicationGiftcard, IGiftcardConnectedProps } from 'entities/Giftcard/Giftcard.communication';
import { GiftcardForm } from 'entities/Giftcard/components/Form/GiftcardForm';

type AllProps = IGiftcardConnectedProps;

const GiftcardPageComponent: React.FC<AllProps> = props => {
  const { giftcardModel, addGiftcardModel, getGiftcardModel, clearGiftcardModel, updateStatusGiftcardModel } = props;
  const { data, loading } = giftcardModel;
  const { id } = useParams();
  const isEdit = id;

  useEffect(() => {
    if (id) {
      getGiftcardModel(id);
    }
    return () => {
      clearGiftcardModel();
    };
  }, []); // eslint-disable-line

  return (
    <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10 pb-10">
      <Col span={12}>
        <Breadcrumb className="fs-sm font-weight-5 font-family__default mb-8">
          <Breadcrumb.Item href={ERoutesPrivate.Giftcards}>Giftcards</Breadcrumb.Item>
          <Breadcrumb.Item>
            {isEdit ? (
              !loading ? (
                `№ ${data?.number}`
              ) : (
                <Skeleton.Input active style={{ width: '200px' }} size="small" />
              )
            ) : (
              'Add giftcard'
            )}
          </Breadcrumb.Item>
        </Breadcrumb>
        {!loading || !isEdit ? (
          <GiftcardForm
            giftcardModel={giftcardModel}
            giftcardId={id}
            addGiftcardModel={addGiftcardModel}
            updateStatusGiftcardModel={updateStatusGiftcardModel}
          />
        ) : null}
      </Col>
    </CommonLayout>
  );
};

export const GiftcardPage = communicationGiftcard.injector(GiftcardPageComponent);
