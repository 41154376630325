import React, { ChangeEvent } from 'react';
import { Checkbox, Col, Input, Row } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import debounce from 'lodash.debounce';
import { RouteComponentProps, withRouter } from 'react-router';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { PlayerTable } from 'entities/Player/components/PlayerTable';
import { EPlayerStatuses, IPlayerCollectionFilter, tableAcademyPlayerConfig } from 'entities/Player/Player.models';
import { PlayerStatusSelector } from 'entities/Player/components/Selector/PlayerStatusSelector';

interface IComponentState {
  filter: IPlayerCollectionFilter;
}

interface IComponentProps {
  academyId?: string;
}

type AllProps = RouteComponentProps & IComponentProps;

class AcademyPlayersTableComponent extends React.PureComponent<AllProps, IComponentState> {
  public debounceSearch: any;
  state: IComponentState = {
    filter: {
      pageIndex: 1,
      name: undefined,
      statuses: undefined,
      hideArchived: false
    }
  };
  constructor(props: AllProps) {
    super(props);
    this.state.filter = { ...this.state.filter, ...queryToObject<IPlayerCollectionFilter>(this.state.filter) };
    this.debounceSearch = debounce(this.autocompleteSearch, 300);
  }

  render() {
    const { academyId } = this.props;
    const { filter } = this.state;
    const { statuses, name, hideArchived } = filter;

    return (
      <>
        <Row className="mb-10 width-full" justify="space-between" align="middle">
          <Col span={24}>
            <Row justify="space-between" align="middle" gutter={16}>
              <Col span={8}>
                <Input.Search
                  placeholder="Search by name"
                  defaultValue={name}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => this.debounceSearch(e.target.value)}
                />
              </Col>
              <Col span={8}>
                <PlayerStatusSelector onChange={this.onChangeStatus} value={statuses} placeholder="Search by status" />
              </Col>
              <Col span={8}>
                <Checkbox checked={hideArchived} onChange={this.onChangeHideArchive}>
                  Hide archived
                </Checkbox>
              </Col>
            </Row>
          </Col>
        </Row>
        <PlayerTable
          config={tableAcademyPlayerConfig}
          filter={{
            ...filter,
            hideArchived,
            academy: academyId
          }}
        />
      </>
    );
  }

  setFilter = (partialFilter: Partial<IPlayerCollectionFilter>) => {
    const oldFilter = this.state.filter;
    const filter = { ...oldFilter, ...partialFilter };

    this.props.history.replace({ search: objectToQuery(filter) });
    this.setState({ filter });
  };

  onChangeStatus = (statuses?: EPlayerStatuses[]) => {
    this.setFilter({ statuses });
  };

  onChangeHideArchive = (e: CheckboxChangeEvent) => {
    this.setFilter({ hideArchived: e.target.checked });
  };

  autocompleteSearch = (text: string) => {
    const name = text === '' ? undefined : text;
    this.setFilter({ name });
  };
}

export const AcademyPlayersTable = withRouter(AcademyPlayersTableComponent);
