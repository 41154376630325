import React from 'react';
import { Avatar, Row } from 'antd';
import { CameraOutlined } from '@ant-design/icons';
import { IBasePathParams } from 'common/models/requestModels';
import { IVideoModel } from 'common/components/Image/Image.models';
import { VideoPrivate } from 'common/components/Video/VideoPrivate';
import { getImageUrl, IImageModel } from 'common/helpers/image.helper';

export interface IStoryModel extends Components.Schemas.StoryDto {}
export interface IStoryCollection extends Components.Schemas.StoryCollectionDto {}

export interface IStoryCollectionFilter extends Paths.StoryControllerList.QueryParameters {
  pageIndex?: number;
}
export interface IStoryCreateData extends Components.Schemas.CreateStoryDto {}

export interface IStoryUpdateData extends Components.Schemas.UpdateStoryDto, IStoryUpdateParams {}
export interface IStoryUpdateParams extends Paths.StoryControllerUpdate.PathParameters {}

export type TStoryParamsModel = IStoryCreateData | IStoryUpdateData | IBasePathParams;

export enum EStorySuccessMessage {
  Delete = 'Story has been successfully deleted',
  Add = 'Story has been successfully created',
  Edit = 'Story has been successfully edited'
}
export enum EStoryStatus {
  Active = 'active',
  Cancel = 'cancel'
}

const stopPropagation = (event: React.MouseEvent) => {
  event.stopPropagation();
};

export const tableStoryConfig = [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    ellipsis: true
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    ellipsis: true
  },
  {
    title: 'langs',
    dataIndex: 'langs',
    key: 'langs',
    ellipsis: true,
    render: (val: string[]) => (
      <Row align="middle" className="flex-noWrap">
        {val?.map((item, index) => (
          <div key={item}>
            {item}
            {index < val.length - 1 ? ', ' : ''}
          </div>
        ))}
      </Row>
    )
  },
  {
    title: 'ageGroups',
    dataIndex: 'ageGroups',
    key: 'ageGroups',
    ellipsis: true,
    render: (val: string[]) => (
      <Row className="flex-noWrap" align="middle">
        {val?.map((item, index) => (
          <div key={index}>
            {item}
            {index < val.length - 1 ? ', ' : ''}
          </div>
        ))}
      </Row>
    )
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    ellipsis: true
  },
  {
    title: 'Video',
    dataIndex: 'video',
    key: 'video',
    ellipsis: true,
    width: 150,
    render: (video: IVideoModel) => {
      const { id, image } = video || {};
      return (
        <Row justify="center" onClick={stopPropagation}>
          {id ? <VideoPrivate height={50} width={100} videoId={id} preview={image?.id} /> : 'No video'}
        </Row>
      );
    }
  },
  {
    title: 'Image',
    dataIndex: 'image',
    key: 'image',
    ellipsis: true,
    width: 150,
    render: (image: IImageModel) => {
      const { id } = image || {};
      return (
        <Row justify="center" onClick={stopPropagation}>
          {id ? <Avatar shape="square" size={100} icon={<CameraOutlined />} src={getImageUrl(id)} /> : 'No image'}
        </Row>
      );
    }
  },
  {
    title: 'Order',
    dataIndex: 'order',
    key: 'order',
    ellipsis: true,
    render: (val?: number | null) => {
      return <Row justify="center">{val ? val : 'random'}</Row>;
    }
  }
];
