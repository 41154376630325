import React from 'react';
import { Avatar, Row } from 'antd';
import { CameraOutlined } from '@ant-design/icons';
import { IVideoModel } from 'common/components/Image/Image.models';
import { VideoPrivate } from 'common/components/Video/VideoPrivate';
import { IImageModel, getImageUrl } from 'common/helpers/image.helper';

export interface ISkinModel extends Components.Schemas.SkinDto {}
export interface ISkinCollection extends Components.Schemas.SkinCollectionDto {}
export interface ISkinCreateParams extends Components.Schemas.CreateSkinDto {}
export interface ISkinUpdateParams extends Components.Schemas.UpdateSkinDto {}
export interface ISkinCollectionFilter extends Paths.AdminSkinControllerList.QueryParameters {
  pageIndex?: number;
}

export enum ESkinSuccessMessage {
  Add = 'Skins has been successfully created',
  Edit = 'Skins has been successfully edited'
}
const stopPropagation = (event: React.MouseEvent) => {
  event.stopPropagation();
};

export const tableSkinConfig = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    width: 150
  },
  {
    title: 'Cost',
    dataIndex: 'cost',
    key: 'cost',
    ellipsis: true,
    width: 150
  },
  {
    title: 'Enabled',
    dataIndex: 'isEnable',
    key: 'isEnable',
    render: (isEnable: boolean) => <span>{isEnable ? 'Yes' : 'No'}</span>,
    width: 100
  },
  {
    title: 'Video',
    dataIndex: 'video',
    key: 'video',
    ellipsis: true,
    width: 150,
    render: (video: IVideoModel) => {
      const { id, image } = video || {};
      return (
        <Row justify="center" onClick={stopPropagation}>
          {id ? <VideoPrivate height={50} width={100} videoId={id} preview={image?.id} /> : 'No video'}
        </Row>
      );
    }
  },
  {
    title: 'Image',
    dataIndex: 'image',
    key: 'image',
    ellipsis: true,
    width: 150,
    render: (image: IImageModel) => {
      const { id } = image || {};
      return (
        <Row justify="center" onClick={stopPropagation}>
          {id ? <Avatar shape="square" size={100} icon={<CameraOutlined />} src={getImageUrl(id)} /> : 'No image'}
        </Row>
      );
    }
  }
];
