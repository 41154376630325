import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { EGiftcardTypes, EGiftcardTypesText } from 'entities/Giftcard/Giftcard.models';

interface IComponentProps {
  onChange?: (value?: EGiftcardTypes) => void;
  disabled?: boolean;
}

type AllProps = SelectProps<any> & IComponentProps;

export class GiftcardTypeSelector extends React.PureComponent<AllProps> {
  render() {
    const { value, onChange, disabled = false } = this.props;

    return (
      <Select
        allowClear
        className="width-full"
        placeholder="Select type"
        value={value}
        defaultActiveFirstOption={false}
        showArrow
        onChange={(value: EGiftcardTypes) => onChange && onChange(value)}
        disabled={disabled}
      >
        <Select.Option key={EGiftcardTypes.Standard} value={EGiftcardTypes.Standard} label={EGiftcardTypesText.Standard}>
          {EGiftcardTypesText.Standard}
        </Select.Option>
        <Select.Option key={EGiftcardTypes.Mentor} value={EGiftcardTypes.Mentor} label={EGiftcardTypesText.Mentor}>
          {EGiftcardTypesText.Mentor}
        </Select.Option>
        <Select.Option key={EGiftcardTypes.Player} value={EGiftcardTypes.Player} label={EGiftcardTypesText.Player}>
          {EGiftcardTypesText.Player}
        </Select.Option>
      </Select>
    );
  }
}
