import { IBasePathParams } from 'common/models/requestModels';
import { IAcademyModel } from 'entities/Academy/Academy.models';

export interface IAdminModel extends Components.Schemas.UserDto {}
export interface IAdminModelForm extends IAdminModel {
  academy?: IAcademyModel;
}
export interface IAdminCollection extends Components.Schemas.UserCollectionDto {}

export interface IAdminCollectionFilter extends Paths.AdminControllerList.QueryParameters {
  pageIndex?: number;
  excludeTeamId?: string;
  academyId?: string;
}
export interface IAdminCreateData extends Components.Schemas.CreateAdminDto {
  lang?: string; // todo remove after JS-4281
}

export interface IAdminUpdateData extends Components.Schemas.UpdateUserByAdminDto, IAdminUpdateParams {
  academy?: string;
}
export interface IAdminUpdateParams extends Paths.AdminControllerUpdate.PathParameters {}

export type TAdminParamsModel = IAdminCreateData | IAdminUpdateData | IBasePathParams;

export enum EAdminSuccessMessage {
  Delete = 'User has been successfully deleted',
  Add = 'User has been successfully created',
  AddTrainer = 'Coach has been successfully created',
  Edit = 'User has been successfully edited'
}

export enum EAdminRole {
  Admin = 'admin',
  Tagger = 'dataTagger',
  Trainer = 'trainer',
  AcademyWorker = 'academyWorker'
}

export enum EAdminRoleText {
  Admin = 'Admin',
  Tagger = 'Tagger',
  Trainer = 'Coach',
  AcademyWorker = 'Academy worker'
}

export const tableAdminConfig = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    ellipsis: true
  },
  {
    title: 'Role',
    dataIndex: 'role',
    key: 'role',
    ellipsis: true,
    render: (role: EAdminRole) => (role === EAdminRole.Trainer ? 'coach' : role)
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    ellipsis: true
  }
];

export enum EAdminStatus {
  Active = 'active',
  Banned = 'banned'
}
export enum EAdminStatusText {
  Active = 'Active',
  Banned = 'Banned'
}
