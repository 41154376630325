import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { EAcademyStatus, EAcademyStatusText } from 'entities/Academy/Academy.models';

interface IComponentProps {
  onChange?: (value?: EAcademyStatus) => void;
  disabled?: boolean;
}

type AllProps = SelectProps<any> & IComponentProps;

export class AcademyStatusSelector extends React.PureComponent<AllProps> {
  render() {
    const { value, onChange, disabled = false } = this.props;

    return (
      <Select
        allowClear
        className="width-full"
        placeholder="Select status"
        value={value}
        defaultActiveFirstOption={false}
        showArrow
        onChange={(value: EAcademyStatus) => onChange && onChange(value)}
        disabled={disabled}
      >
        <Select.Option key={EAcademyStatus.Active} value={EAcademyStatus.Active} label={EAcademyStatusText.Active}>
          {EAcademyStatusText.Active}
        </Select.Option>
        <Select.Option key={EAcademyStatus.Moderation} value={EAcademyStatus.Moderation} label={EAcademyStatusText.Moderation}>
          {EAcademyStatusText.Moderation}
        </Select.Option>
        <Select.Option key={EAcademyStatus.Banned} value={EAcademyStatus.Banned} label={EAcademyStatusText.Banned}>
          {EAcademyStatusText.Banned}
        </Select.Option>
      </Select>
    );
  }
}
