import React, { useEffect } from 'react';
import { Col, Row, Spin, Statistic } from 'antd';
import { normalize } from 'common/helpers/normalize.helper';
import { communicationNotification, INotificationConnectedProps } from 'entities/Notification/Notification.communication';

type AllProps = INotificationConnectedProps;

const NotificationQueueTaskStatisticComponent = ({ notificationQueueCount, getNotificationQueueCount }: AllProps) => {
  const { data, loading } = notificationQueueCount;
  const { count = 0 } = data ?? {};

  useEffect(() => {
    getNotificationQueueCount();
  }, [getNotificationQueueCount]);

  return (
    <Row gutter={[32, 16]}>
      <Col>
        <Spin spinning={loading}>
          <Statistic title="Queue task count" value={normalize(count)} />
        </Spin>
      </Col>
    </Row>
  );
};

export const NotificationQueueTaskStatistic = communicationNotification.injector(NotificationQueueTaskStatisticComponent);
