import { ColumnsType } from 'antd/lib/table';
import { RouteComponentProps, withRouter } from 'react-router';
import { TableCommon } from 'common/components/TableCommon';
import { IBaseFilterModel } from 'common/models/requestModels';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { ERoutesPrivate } from 'common/models/routesModel';
import { IAdminCollection, IAdminCollectionFilter, IAdminModel } from 'entities/Admin/Admin.models';
import { communicationAdmin, IAdminConnectedProps } from 'entities/Admin/Admin.communication';

interface IComponentProps {
  config: ColumnsType<any>;
}

type AllProps = IComponentProps & IAdminConnectedProps & RouteComponentProps;

class AdminTableComponent extends TableCommon<IAdminCollection, IAdminModel, ColumnsType<any>, AllProps, IAdminCollectionFilter> {
  loadCollection = (params: IBaseFilterModel) => {
    const { getAdminCollection, filter } = this.props;
    const filterParams: IAdminCollectionFilter = { ...params };

    if (filter?.search) {
      filterParams.search = filter?.search;
    }
    if (filter?.academy) {
      filterParams.academy = filter?.academy;
    }
    if (filter?.roles) {
      filterParams.roles = filter?.roles;
    }
    if (filter?.status) {
      filterParams.status = filter?.status;
    }
    if (filter?.regComplete !== undefined) {
      filterParams.regComplete = filter.regComplete;
    }
    getAdminCollection(filterParams);
  };

  getCollection = () => {
    const { adminCollection } = this.props;
    if (adminCollection?.data?.data) {
      const data: IAdminModel[] = adminCollection.data.data.map(item => {
        return { ...item, name: `${item.firstName || 'Unknown'} ${item.lastName || 'name'}` };
      });
      return {
        ...adminCollection,
        data: {
          ...adminCollection.data,
          data
        }
      };
    } else {
      return adminCollection;
    }
  };

  addQuery = (pageIndex: number) => {
    const searchPath = queryToObject<IAdminCollectionFilter>({
      pageIndex: 1,
      academy: undefined,
      search: undefined,
      roles: undefined,
      status: undefined,
      regComplete: undefined
    });
    const search = objectToQuery({ ...searchPath, pageIndex });
    return this.props.history.replace({ search });
  };

  onRowClick = (item: IAdminModel) => {
    const { history } = this.props;
    history.push(`${ERoutesPrivate.UserList}/${item.id}`);
  };

  clearCollection = () => {
    return this.props.clearAdminCollection();
  };
}

export const AdminTable = communicationAdmin.injector(withRouter(AdminTableComponent));
