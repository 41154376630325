import {
  EActionsTypes,
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  StoreBranch,
  buildCollectionResponseFormatter,
  buildCollectionPreRequestDataMapper
} from '@axmit/redux-communications';
import { message } from 'antd';
import { put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { ERoutesPrivate } from 'common/models/routesModel';
import { adminTransport } from 'entities/Admin/Admin.transport';
import {
  EAdminSuccessMessage,
  IAdminCreateData,
  IAdminModel,
  IAdminUpdateData,
  IAdminCollectionFilter,
  IAdminCollection,
  TAdminParamsModel
} from 'entities/Admin/Admin.models';

const namespace = 'admin';

export interface IAdminConnectedProps {
  adminModel: StoreBranch<IAdminModel, TAdminParamsModel>;
  adminCollection: StoreBranch<IAdminCollection, IAdminCollectionFilter>;

  getAdminModel(id: string): void;
  addAdminModel(params: IAdminCreateData): void;
  updateAdminModel(params: IAdminUpdateData): void;
  deleteAdminModel(id: string): void;
  getAdminCollection(filter?: IAdminCollectionFilter): void;
  getSearchAdminCollection(filter?: IAdminCollectionFilter): void;

  clearAdminModel(): void;
  clearAdminCollection(): void;
}

const AdminModelAPIProviders = [
  new APIProvider(EActionsTypes.get, adminTransport.get),
  new APIProvider(EActionsTypes.update, adminTransport.update, {
    onSuccess: function*() {
      message.success(EAdminSuccessMessage.Edit);
      yield put(push(ERoutesPrivate.UserList));
    },
    onFail: response => {
      const errMessage = response?.data?.message || 'An error occurred during updating user';
      message.error(errMessage);
    }
  }),
  new APIProvider(EActionsTypes.delete, adminTransport.delete, {
    onSuccess: function*() {
      message.success(EAdminSuccessMessage.Delete);
      yield put(push(ERoutesPrivate.UserList));
    }
  }),
  new APIProvider(EActionsTypes.add, adminTransport.add, {
    onSuccess: function*(response: IAdminModel) {
      message.success(EAdminSuccessMessage.Add);
      yield put(push(`${ERoutesPrivate.UserList}/${response?.id}`));
    }
  })
];

const AdminCollectionAPIProviders = [
  new APIProvider(EActionsTypes.get, adminTransport.getCollection),
  new APIProvider(
    'getSearch',
    adminTransport.getAdminSearch,
    {
      mapSuccess: buildCollectionResponseFormatter<any, any>(),
      preRequestDataMapper: buildCollectionPreRequestDataMapper<IAdminCollection, IAdminCollectionFilter>()
    },
    takeLatest
  )
];

const branches = [new Branch('model', AdminModelAPIProviders), new Branch('collection', AdminCollectionAPIProviders)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationAdmin = buildCommunication<IAdminConnectedProps>(strategy);
