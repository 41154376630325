import React, { ChangeEvent } from 'react';
import { Breadcrumb, Button, Col, DatePicker, Input, Row } from 'antd';
import moment, { Moment } from 'moment';
import { RouteComponentProps, withRouter } from 'react-router';
import debounce from 'lodash.debounce';
import { SortOrder } from 'antd/lib/table/interface';
import { ColumnsType } from 'antd/es/table';
import { simpleDateFormatWithTime, simpleTimeFormat } from 'common/models/dateModels';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import {
  EWalletStatus,
  EWalletType,
  IWalletTransactionCollectionFilter,
  tableWalletTransactionConfig
} from 'entities/Wallet/Wallet.models';
import { WalletTransactionTable } from 'entities/Wallet/components/WalletTransactionTable';
import { WalletTypeSelector } from 'entities/Wallet/components/Selector/WalletTypeSelector';
import { WalletStatusSelector } from 'entities/Wallet/components/Selector/WalletStatusSelector';
import { AcademySelector } from 'entities/Academy/components/Selector/AcademySelector';
import { PlayerSelector } from 'entities/Player/components/PlayerSelector';
import { UserByRoleSelector } from 'entities/User/components/UserByRoleSelector';
import { EntityTypeSelector } from 'entities/User/components/EntityTypeSelector';
import { EEntityType, TEntityValue } from 'entities/User/User.models';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';

type AllProps = RouteComponentProps<{ id: string }> & IUIConnectedProps;

const { RangePicker } = DatePicker;

interface IComponentState {
  filter: IWalletTransactionCollectionFilter;
  configTableValue: ColumnsType<any>;
}

class WalletTransactionTablePageComponent extends React.PureComponent<AllProps, IComponentState> {
  public debounceSearch: any;
  state: IComponentState = {
    configTableValue: tableWalletTransactionConfig,
    filter: {
      pageIndex: 1,
      description: undefined,
      externalId: undefined,
      product: undefined,
      statuses: undefined,
      types: undefined,
      from: undefined,
      to: undefined,
      orderField: undefined,
      orderDirection: undefined,
      direction: undefined,
      senderValue: undefined,
      senderEntity: undefined,
      targetValue: undefined,
      targetEntity: undefined
    }
  };
  constructor(props: AllProps) {
    super(props);
    this.state.filter = { ...this.state.filter, ...queryToObject<IWalletTransactionCollectionFilter>(this.state.filter) };
    const orderField = this.state?.filter?.orderField;
    const orderDirection = this.state?.filter?.orderDirection;
    if (orderField === 'createdAt' || orderField === 'status') {
      const defaultSortOrder = (orderDirection === 'ASC' ? 'ascend' : 'descend') as SortOrder;
      this.state.configTableValue = tableWalletTransactionConfig?.map(item => {
        if (item?.dataIndex === orderField) {
          return { ...item, defaultSortOrder };
        }
        return item;
      });
    }
    this.debounceSearch = debounce(this.autocompleteSearch, 300);
  }

  render() {
    const { filter, configTableValue } = this.state;
    const { match, openUIWalletModal, history } = this.props;
    const { params } = match;
    const { id } = params;
    const redirectUrl = `${history?.location?.pathname}${history?.location?.search}`;
    const { from, to, description, types, statuses, targetValue, targetEntity, senderValue, senderEntity } = filter;
    const fromValue = from ? moment(from) : null;
    const toValue = to ? moment(to) : null;
    const renderSelector = (entityTypeValue?: EEntityType, onChange?: () => void, value?: TEntityValue) => {
      switch (entityTypeValue) {
        case EEntityType.Academy: {
          return <AcademySelector onChange={onChange} value={value} placeholder="Select academy" />;
        }
        case EEntityType.Player: {
          return <PlayerSelector onChange={onChange} value={value} placeholder="Select player" />;
        }
        case EEntityType.User: {
          return <UserByRoleSelector roles={[]} onChange={onChange} value={value} placeholder="Select user" />;
        }
        default: {
          return null;
        }
      }
    };

    return (
      <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10">
        <Breadcrumb className="fs-sm font-weight-5 font-family__default mb-8">
          <Breadcrumb.Item>Transaction history</Breadcrumb.Item>
        </Breadcrumb>
        <Row gutter={[24, 16]} className="mb-10 width-full" justify="start">
          <Col span={5}>
            <Row gutter={[16, 8]}>
              <Input.Search
                placeholder="Search by description"
                defaultValue={description}
                onChange={(e: ChangeEvent<HTMLInputElement>) => this.debounceSearch(e.target.value)}
              />
              <RangePicker
                allowEmpty={[true, true]}
                showNow={false}
                showTime={{ format: simpleTimeFormat }}
                value={[fromValue, toValue]}
                format={simpleDateFormatWithTime}
                onChange={this.onChangeTimeDate}
              />
            </Row>
          </Col>
          <Col span={5}>
            <Row gutter={[16, 8]}>
              <WalletTypeSelector onChange={this.onChangeType} value={types} placeholder="Search by type" />
              <WalletStatusSelector onChange={this.onChangeStatus} value={statuses} placeholder="Search by status" />
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={[16, 8]}>
              <Col span={12}>
                <EntityTypeSelector
                  onChange={this.onChangeTargetEntityType}
                  value={targetEntity}
                  placeholder="Select target entity"
                />
              </Col>
              <Col span={12}>{renderSelector(targetEntity, this.onChangeTarget, targetValue)}</Col>

              <Col span={12}>
                <EntityTypeSelector
                  onChange={this.onChangeSenderEntityType}
                  value={senderEntity}
                  placeholder="Select sender entity"
                />
              </Col>
              <Col span={12}>{renderSelector(senderEntity, this.onChangeSender, senderValue)}</Col>
            </Row>
          </Col>
          <Col span={4}>
            <Row justify="end">
              <Button block type="primary" title="Create transaction" onClick={() => openUIWalletModal({ redirectUrl })}>
                Create transaction
              </Button>
            </Row>
          </Col>
        </Row>
        <WalletTransactionTable config={configTableValue} filter={{ ...filter, id }} />
      </CommonLayout>
    );
  }

  onChangeTimeDate = (dates: [Moment | null, Moment | null] | null) => {
    const fromDate = dates?.[0];
    const toDate = dates?.[1];
    const from = fromDate ? fromDate.toISOString() : undefined;
    const to = toDate ? toDate.toISOString() : undefined;

    this.setFilter({ from, to });
  };
  setFilter = (partialFilter: Partial<IWalletTransactionCollectionFilter>) => {
    const oldFilter = this.state.filter;
    const filter = { ...oldFilter, ...partialFilter };

    this.props.history.replace({ search: objectToQuery(filter) });
    this.setState({ filter });
  };
  autocompleteSearch = (text: string) => {
    const description = text === '' ? undefined : text;
    this.setFilter({ description });
  };

  onChangeType = (types?: EWalletType) => {
    this.setFilter({ types: types ? [types] : undefined });
  };

  onChangeStatus = (status?: EWalletStatus) => {
    this.setFilter({ statuses: status ? [status] : undefined });
  };
  onChangeSenderEntityType = (senderEntityValue?: EEntityType) => {
    const senderValue = this.state.filter || {};

    this.setFilter(
      !senderEntityValue || senderEntityValue !== senderValue
        ? { senderEntity: senderEntityValue, senderValue: undefined }
        : { senderEntity: senderEntityValue }
    );
  };
  onChangeTargetEntityType = (targetEntityValue?: EEntityType) => {
    const targetValue = this.state.filter || {};

    this.setFilter(
      !targetEntityValue || targetEntityValue !== targetValue
        ? { targetEntity: targetEntityValue, targetValue: undefined }
        : { targetEntity: targetEntityValue }
    );
  };
  onChangeSender = (senderValue?: TEntityValue) => {
    this.setFilter({ senderValue });
  };
  onChangeTarget = (targetValue?: TEntityValue) => {
    this.setFilter({ targetValue });
  };
}

export const WalletTransactionTablePage = communicationUI.injector(withRouter(WalletTransactionTablePageComponent));
