import axios, { AxiosStatic } from 'axios';
import { BaseHttpTransport } from '@axmit/transport';
import { objectToQuery } from 'common/helpers/filters.helper';
import {
  ITestCollection,
  ITestCollectionFilter,
  ITestModel,
  ITestUpdateData,
  ITestStatisticCollectionFilter,
  ITestStatisticCollection,
  ITestLeaderboardCollectionFilter
} from 'entities/Test/Test.models';

const basePath = '/tests';

class TestTransport extends BaseHttpTransport<
  AxiosStatic,
  ITestModel,
  ITestCollection,
  void,
  ITestUpdateData,
  ITestCollectionFilter
> {
  public getTestSearchCollection = (filters: ITestCollectionFilter): Promise<ITestCollection> =>
    axios.get(`${basePath}/search${objectToQuery(filters)}`);
  public getTestStatisticCollection = (filters: ITestStatisticCollectionFilter): Promise<ITestStatisticCollection> =>
    axios.get(`${basePath}/statistic${objectToQuery(filters)}`);
  public getLeaderboardCollection = ({ id, ...params }: ITestLeaderboardCollectionFilter) =>
    axios.get(`${basePath}/${id}/leaderboard${objectToQuery(params)}`);
}

export const testTransport = new TestTransport(basePath, axios);
