import React from 'react';
import { Col, Row, DatePicker } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router';
import InputNumber from 'antd/es/input-number';
import moment, { Moment } from 'moment';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { simpleDateFormat } from 'common/models/dateModels';
import { getUTCEndOfDay, getUTCStartOfDay } from 'common/helpers/date.helper';
import { TestResultStatisticTable } from 'entities/Test/components/TestResultStatisticTable';
import { ITestStatisticCollectionFilter, tableTestResultStatisticConfig } from 'entities/Test/Test.models';

type AllProps = RouteComponentProps;

interface IComponentState {
  filter: ITestStatisticCollectionFilter;
}

const { RangePicker } = DatePicker;

class TestResultStatisticPageComponent extends React.PureComponent<AllProps, IComponentState> {
  public debounceSearch: any;
  state: IComponentState = {
    filter: {
      humanCost: undefined,
      aiCost: undefined,
      dateFrom: moment()
        .subtract(2, 'week')
        .toISOString(),
      dateTo: moment().toISOString()
    }
  };
  constructor(props: AllProps) {
    super(props);
    this.state.filter = { ...this.state.filter, ...queryToObject<ITestStatisticCollectionFilter>(this.state.filter) };
  }

  render() {
    const { filter } = this.state;
    const { aiCost, humanCost, dateFrom, dateTo } = filter;
    const fromValue = dateFrom ? moment(dateFrom) : moment().subtract(2, 'week');
    const toValue = dateTo ? moment(dateTo).utc() : moment();

    return (
      <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10">
        <Row className="mb-10 width-full" justify="space-between" align="middle">
          <Col span={18}>
            <Row justify="start" gutter={[16, 0]} align="middle">
              <Col span={4}>
                <span className="basic__text_label mr-5" title="Human cost(per sec)">
                  Human cost(per sec)
                </span>
                <InputNumber
                  min={0}
                  placeholder="Human cost(per sec)"
                  defaultValue={humanCost ?? 0}
                  onChange={this.humanCostChanged}
                />
              </Col>
              <Col span={4}>
                <span className="basic__text_label mr-5" title="AI cost(per sec)">
                  AI cost(per sec)
                </span>
                <InputNumber min={0} placeholder="AI cost(per sec)" defaultValue={aiCost ?? 0} onChange={this.aiCostChanged} />
              </Col>
              <Col span={5}>
                <span className="basic__text_label mr-5" title="Date range">
                  Date range
                </span>
                <RangePicker
                  allowEmpty={[false, false]}
                  clearIcon={false}
                  showNow={false}
                  showTime={false}
                  value={[fromValue, toValue]}
                  format={simpleDateFormat}
                  onChange={this.onChangeTimeDate}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <TestResultStatisticTable config={tableTestResultStatisticConfig} filter={filter} />
      </CommonLayout>
    );
  }

  setFilter = (partialFilter: Partial<ITestStatisticCollectionFilter>) => {
    const oldFilter = this.state.filter;
    const filter = { ...oldFilter, ...partialFilter };

    this.props.history.replace({ search: objectToQuery(filter) });
    this.setState({ filter });
  };

  aiCostChanged = (value: number | null) => {
    const aiCost = value ?? undefined;
    this.setFilter({ aiCost });
  };

  humanCostChanged = (value: number | null) => {
    const humanCost = value ?? undefined;
    this.setFilter({ humanCost });
  };

  onChangeTimeDate = (dates: [Moment | null, Moment | null] | null) => {
    const fromDate = dates?.[0];
    const toDate = dates?.[1];
    const dateFrom = fromDate ? getUTCStartOfDay(fromDate) : undefined;
    const dateTo = toDate ? getUTCEndOfDay(toDate) : undefined;

    this.setFilter({ dateFrom, dateTo });
  };
}

export const TestResultStatisticPage = withRouter(TestResultStatisticPageComponent);
