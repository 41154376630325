import { EActionsTypes, APIProvider, BaseStrategy, Branch, buildCommunication, StoreBranch } from '@axmit/redux-communications';
import { message } from 'antd';
import { franchiseTransport } from 'entities/Franchise/Franchise.transport';
import {
  IFranchiseModel,
  IFranchiseCreate,
  IFranchiseRequestModel,
  IFranchiseRequestParamsModel,
  IFranchiseRequestModelUpdateStatusParams,
  EFranchiseErrorCode,
  EFranchiseErrorMessages
} from 'entities/Franchise/Franchise.models';
import { updateAcademyModel, namespace as academyNamespace } from 'entities/Academy/Academy.communication';

const namespace = 'franchise';

export interface IFranchiseConnectedProps {
  franchiseModel: StoreBranch<IFranchiseModel>;
  franchiseRequestModel: StoreBranch<IFranchiseRequestModel, IFranchiseRequestParamsModel>;

  addFranchiseModel(params: IFranchiseCreate): void;
  deleteFranchiseModel(id: string): void;
  updateStatusFranchiseRequestModel(params: IFranchiseRequestModelUpdateStatusParams): void;

  clearFranchiseModel(): void;
  clearFranchiseRequestModel(): void;
}

const FranchiseModelAPIProviders = [
  new APIProvider(EActionsTypes.add, franchiseTransport.add, {
    onSuccess: function*(response, originalParams) {
      yield updateAcademyModel(originalParams?.academy);
    }
  }),
  new APIProvider(EActionsTypes.delete, franchiseTransport.delete, {
    onSuccess: function*(response, originalParams, branchState, fullState: any) {
      const academyModel = fullState[academyNamespace]?.model?.data;
      yield updateAcademyModel(academyModel?.id);
    },
    onFail: response => {
      if (response?.data?.code === EFranchiseErrorCode.FranchiseNotEmpty) {
        message.error(EFranchiseErrorMessages.FranchiseNotEmpty);
      }
    }
  })
];
const franchiseRequestModelApiProvider = [
  new APIProvider(EActionsTypes.get, franchiseTransport.getFranchiseRequest),
  new APIProvider('updateStatus', franchiseTransport.updateStatusFranchiseRequest)
];

const branches = [new Branch('model', FranchiseModelAPIProviders), new Branch('requestModel', franchiseRequestModelApiProvider)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationFranchise = buildCommunication<IFranchiseConnectedProps>(strategy);
