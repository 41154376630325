import React from 'react';
import { Button, Col, Form, Input, InputNumber, Row, Switch, TimePicker } from 'antd';
import { Link } from 'react-router-dom';
import { StoreBranch } from '@axmit/redux-communications';
import moment, { Moment } from 'moment';
import { ERoutesPrivate } from 'common/models/routesModel';
import { ImageUpload } from 'common/components/ImageUpload/ImageUpload';
import { useCustomFormValidation } from 'common/helpers/useCustomFormValidation';
import { timeFormat } from 'common/models/dateModels';
import {
  INotificationTemplateModel,
  INotificationTemplateModelFilter,
  INotificationTemplateUpdateParams,
  NOTIFICATION_TEMPLATE_LANGS
} from 'entities/NotificationTemplate/NotificationTemplate.models';

interface IComponentProps {
  model: StoreBranch<INotificationTemplateModel, INotificationTemplateModelFilter>;
  id: string;
  onSubmit: (data: INotificationTemplateUpdateParams) => void;
}

interface IFormData extends INotificationTemplateUpdateParams {
  deliveryTimeRange: Moment[];
}

const SEC_TO_YEAR = 31536000;

const NotificationTemplateFormComponent: React.FC<IComponentProps> = props => {
  const { model, onSubmit, id } = props;
  const { errors, loading, data } = model;
  const { type, receiverType, event, vars = [] } = data ?? {};

  const [form] = Form.useForm();
  const values = React.useMemo(() => {
    if (data) {
      const deliveryTimeFrom = data?.deliveryTimeFrom ? moment(data.deliveryTimeFrom, timeFormat) : null;
      const deliveryTimeTo = data?.deliveryTimeTo ? moment(data.deliveryTimeTo, timeFormat) : null;

      return {
        ...data,
        image: data?.image?.id,
        deliveryTimeRange: [deliveryTimeFrom, deliveryTimeTo]
      } as INotificationTemplateUpdateParams;
    }
    return undefined;
  }, [data]);

  const handleSubmit = (results: IFormData) => {
    const { deliveryTimeRange, ...rest } = results;
    const body = { ...rest, id };
    const [deliveryTimeFrom, deliveryTimeTo] = deliveryTimeRange;

    if (deliveryTimeFrom) {
      body.deliveryTimeFrom = deliveryTimeFrom.format(timeFormat);
    }
    if (deliveryTimeTo) {
      body.deliveryTimeTo = deliveryTimeTo.format(timeFormat);
    }

    onSubmit(body);
  };

  useCustomFormValidation({ form, errors });

  return (
    <Form initialValues={values ?? undefined} className="mt-5" onFinish={handleSubmit} form={form}>
      <Row gutter={[8, 8]}>
        <Col md={6} xs={12}>
          <Form.Item label="Type">
            <Input value={type} disabled />
          </Form.Item>
        </Col>
        <Col md={6} xs={12}>
          <Form.Item label="Receiver type">
            <Input value={receiverType} disabled />
          </Form.Item>
        </Col>
        <Col md={6} xs={12}>
          <Form.Item label="Event">
            <Input value={event} disabled />
          </Form.Item>
        </Col>
        <Col md={6} xs={12}>
          <Form.Item label="Vars">
            <Input value={vars.join(', ')} disabled />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item name="deliveryTimeRange" label="Time to send notifications">
            <TimePicker.RangePicker allowEmpty={[false, true]} showNow={false} format={timeFormat} />
          </Form.Item>
          <Form.Item name="image" label="Image">
            <ImageUpload />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Time to send is required'
              }
            ]}
            name="timeToSend"
            label="Time to send (sec)"
          >
            <InputNumber max={SEC_TO_YEAR} min={0} step={1} disabled={loading} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Lifetime is required'
              }
            ]}
            name="lifetime"
            label="Lifetime (ms)"
          >
            <InputNumber max={SEC_TO_YEAR * 1000} min={0} step={1} disabled={loading} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item valuePropName="checked" name="isTemplateImageFirst" label="Is template image first">
            <Switch checkedChildren="Yes" unCheckedChildren="No" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item valuePropName="checked" name="isEnable" label="Is notification enabled">
            <Switch checkedChildren="Yes" unCheckedChildren="No" />
          </Form.Item>
        </Col>
        {NOTIFICATION_TEMPLATE_LANGS.map(lang => {
          const itemIndex = values?.data.findIndex(element => element.lang === lang) ?? values?.data.length ?? 0;
          return (
            <Col span={24} key={lang}>
              <Row className="mb-3" align="middle">
                <span className="basic__text_title" title={lang}>
                  {lang}
                </span>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item name={['data', itemIndex, 'lang']} hidden />
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Title is required'
                      },
                      {
                        min: 2,
                        message: 'Title must be longer than or equal 2 characters'
                      },
                      {
                        max: 1024,
                        message: 'Title must be shorter than or equal 1024 characters'
                      }
                    ]}
                    name={['data', itemIndex, 'title']}
                    label="Title"
                  >
                    <Input type="text" placeholder="Title" disabled={loading} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Description is required'
                      },
                      {
                        min: 2,
                        message: 'Description must be longer than or equal 2 characters'
                      },
                      {
                        max: 1024,
                        message: 'Description must be shorter than or equal 1024 characters'
                      }
                    ]}
                    name={['data', itemIndex, 'description']}
                    label="Description"
                  >
                    <Input.TextArea placeholder="Description" disabled={loading} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          );
        })}
      </Row>

      <Row justify="space-between" align="middle">
        <Col span={16}>
          <Form.Item className="mb-0">
            <Button className="mr-8" htmlType="submit" type="primary" disabled={loading} loading={loading} title="Save">
              Save
            </Button>
            <Link to={ERoutesPrivate.UserList}>
              <Button disabled={loading} loading={loading} title="Cancel">
                Cancel
              </Button>
            </Link>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export const NotificationTemplateForm = NotificationTemplateFormComponent;
