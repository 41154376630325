import React, { ChangeEvent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Col, Row, Input } from 'antd';
import debounce from 'lodash.debounce';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { ELocationType, ILocationModel } from 'common/helpers/location.helper';
import { LocationInput } from 'common/components/Input/LocationInput';
import { AcademyTable } from 'entities/Academy/components/AcademyTable';
import { EAcademyStatus, IAcademyCollectionFilter, tableAcademyConfig } from 'entities/Academy/Academy.models';
import { AcademyStatusSelector } from 'entities/Academy/components/Selector/AcademyStatusSelector';
import { AcademiesStatistic } from 'entities/Academy/components/AcademiesStatistic';
import { AcademyFranchiseSelector } from 'entities/Academy/components/Selector/AcademyFranchiseSelector';
import { EFranchiseTypes } from 'entities/Franchise/Franchise.models';

type AllProps = RouteComponentProps;

interface IComponentState {
  filter: IAcademyCollectionFilter;
}

class AcademyTablePageComponent extends React.PureComponent<AllProps, IComponentState> {
  public debounceSearch: any;
  state: IComponentState = {
    filter: {
      pageIndex: 1,
      status: undefined,
      name: undefined,
      location: undefined,
      franchiseRequestType: undefined
    }
  };
  constructor(props: AllProps) {
    super(props);
    this.state.filter = { ...this.state.filter, ...queryToObject<IAcademyCollectionFilter>(this.state.filter) };
    this.debounceSearch = debounce(this.autocompleteSearch, 300);
  }

  render() {
    const { filter } = this.state;
    const { location, status, name, franchiseRequestType } = filter;

    return (
      <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10">
        <Row className="mb-10 width-full" justify="space-between" align="middle">
          <Col span={14}>
            <Row justify="space-between" align="middle">
              <Col span={7}>
                <Input.Search
                  placeholder="Search by name"
                  defaultValue={name}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => this.debounceSearch(e.target.value)}
                />
              </Col>
              <Col span={5}>
                <AcademyStatusSelector onChange={this.onChangeStatus} value={status} placeholder="Search by status" />
              </Col>

              <Col span={6}>
                <LocationInput
                  placeholder="Search by location"
                  onChange={this.onChangeLocation}
                  value={location}
                  types={[ELocationType.Regions]}
                />
              </Col>

              <Col span={5}>
                <AcademyFranchiseSelector
                  onChange={this.onChangeFranchiseType}
                  value={franchiseRequestType}
                  placeholder="Search by franchise type"
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <AcademiesStatistic />
        <AcademyTable config={tableAcademyConfig} filter={filter} />
      </CommonLayout>
    );
  }
  setFilter = (partialFilter: Partial<IAcademyCollectionFilter>) => {
    const oldFilter = this.state.filter;
    const filter = { ...oldFilter, ...partialFilter };

    this.props.history.replace({ search: objectToQuery(filter) });
    this.setState({ filter });
  };

  onChangeLocation = (location?: ILocationModel) => {
    this.setFilter({ location });
  };

  onChangeStatus = (status?: EAcademyStatus) => {
    this.setFilter({ status });
  };

  onChangeFranchiseType = (franchiseRequestType?: EFranchiseTypes) => {
    this.setFilter({ franchiseRequestType });
  };

  autocompleteSearch = (text: string) => {
    const name = text === '' ? undefined : text;
    this.setFilter({ name });
  };
}

export const AcademyTablePage = withRouter(AcademyTablePageComponent);
