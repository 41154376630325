import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { EAvailabilityTypes, EAvailabilityTypesText } from 'entities/Test/Test.models';

type AllProps = SelectProps<any>;

const items = [
  { value: EAvailabilityTypes.Ready, title: EAvailabilityTypesText.Ready },
  { value: EAvailabilityTypes.Test, title: EAvailabilityTypesText.Test },
  { value: EAvailabilityTypes.None, title: EAvailabilityTypesText.None }
];

export class AvailabilitySelector extends React.PureComponent<AllProps> {
  render() {
    const { ...props } = this.props;

    return (
      <Select className="width-full" defaultActiveFirstOption={false} showArrow {...props}>
        {items.map(item => (
          <Select.Option key={item.value} value={item.value} label={item.title}>
            {item.title}
          </Select.Option>
        ))}
      </Select>
    );
  }
}
