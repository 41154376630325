import message from 'antd/es/message';

export const copyToClipboard = (text?: string) => {
  if (navigator.clipboard?.writeText && text) {
    navigator.clipboard.writeText(text).then(
      () => {
        message.success('Copied!');
      },
      err => {
        message.error('Error while copying!');
        console.error('Async: Could not copy: ', err);
      }
    );
  }
};
