import { ColumnsType } from 'antd/lib/table';
import { RouteComponentProps, withRouter } from 'react-router';
import { TableCommon } from 'common/components/TableCommon';
import { IBaseFilterModel } from 'common/models/requestModels';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { ERoutesPrivate } from 'common/models/routesModel';
import { ISkinCollection, ISkinCollectionFilter, ISkinModel } from 'entities/Skin/Skin.models';
import { communicationSkin, ISkinConnectedProps } from 'entities/Skin/Skin.communication';

interface IComponentProps {
  config: ColumnsType<any>;
}

type AllProps = IComponentProps & ISkinConnectedProps & RouteComponentProps;

class SkinTableComponent extends TableCommon<ISkinCollection, ISkinModel, ColumnsType<any>, AllProps, ISkinCollectionFilter> {
  loadCollection = (params: IBaseFilterModel) => {
    const { getSkinCollection, filter } = this.props;
    const filterParams: ISkinCollectionFilter = { ...params };
    if (filter?.isEnable) {
      filterParams.isEnable = filter?.isEnable;
    }
    getSkinCollection(filterParams);
  };

  getCollection = () => {
    const { skinCollection } = this.props;
    if (skinCollection?.data?.data) {
      const data: ISkinModel[] = skinCollection.data.data.map(item => {
        return {
          ...item
        };
      });
      return {
        ...skinCollection,
        data: {
          ...skinCollection.data,
          data
        }
      };
    } else {
      return skinCollection;
    }
  };

  addQuery = (pageIndex: number) => {
    const searchPath = queryToObject<ISkinCollectionFilter>({
      pageIndex: 1,
      isEnable: undefined
    });
    const search = objectToQuery({ ...searchPath, pageIndex });
    return this.props.history.replace({ search });
  };

  onRowClick = (item: ISkinModel) => {
    const { history } = this.props;
    history.push(`${ERoutesPrivate.Skins}/${item.id}`);
  };

  clearCollection = () => {
    return this.props.clearSkinCollection();
  };
}

export const SkinTable = communicationSkin.injector(withRouter(SkinTableComponent));
