import { EActionsTypes, APIProvider, BaseStrategy, Branch, buildCommunication, StoreBranch } from '@axmit/redux-communications';
import { subscriptionTransport } from 'entities/Subscription/Subscription.transport';
import {
  ISubscriptionModel,
  ISubscriptionCollection,
  ISubscriptionCreate,
  ISubscriptionUpdateStatus,
  TSubscriptionParamsModel,
  ISubscriptionCollectionFilter,
  IPaymentRequestParams,
  ISubscriptionActivityModel,
  ISubscriptionActivityCollection,
  ISubscriptionActivityCollectionFilter,
  ISubscriptionActivityApplyGiftcard
} from 'entities/Subscription/Subscription.models';

const namespace = 'subscription';

export interface ISubscriptionConnectedProps {
  subscriptionModel: StoreBranch<ISubscriptionModel, TSubscriptionParamsModel>;
  subscriptionCollection: StoreBranch<ISubscriptionCollection, ISubscriptionCollectionFilter>;
  subscriptionActivityModel: StoreBranch<ISubscriptionActivityModel, ISubscriptionCollectionFilter>;
  subscriptionActivityCollection: StoreBranch<ISubscriptionActivityCollection, ISubscriptionActivityCollectionFilter>;

  addSubscriptionModel(params: ISubscriptionCreate): void;
  getSubscriptionModel(id: string): void;
  updateStatusSubscriptionModel(params: ISubscriptionUpdateStatus): void;

  getSubscriptionCollection(filter?: ISubscriptionCollectionFilter): void;

  addSubscriptionActivityModel(params: IPaymentRequestParams): void;
  deleteSubscriptionActivityModel(subscriptionId: string): void;
  applyGiftcardSubscriptionActivityModel(params: ISubscriptionActivityApplyGiftcard): void;
  getSubscriptionActivityCollection(params: ISubscriptionActivityCollectionFilter): void;

  clearSubscriptionModel(): void;
  clearSubscriptionCollection(): void;
  clearSubscriptionActivityCollection(): void;
}

const SubscriptionModelAPIProviders = [
  new APIProvider(EActionsTypes.get, subscriptionTransport.get),
  new APIProvider(EActionsTypes.add, subscriptionTransport.add),
  new APIProvider('updateStatus', subscriptionTransport.updateStatus)
];

const SubscriptionActivityModelAPIProviders = [
  new APIProvider(EActionsTypes.add, subscriptionTransport.createSubscriptionActivity),
  new APIProvider('applyGiftcard', subscriptionTransport.applyGiftcardSubscriptionActivity),
  new APIProvider(EActionsTypes.delete, subscriptionTransport.deleteSubscriptionActivity)
];

const SubscriptionCollectionAPIProviders = [new APIProvider(EActionsTypes.get, subscriptionTransport.getCollection)];

const SubscriptionActivityCollectionAPIProviders = [
  new APIProvider(EActionsTypes.get, subscriptionTransport.getSubscriptionActivityCollection)
];

const branches = [
  new Branch('model', SubscriptionModelAPIProviders),
  new Branch('collection', SubscriptionCollectionAPIProviders),
  new Branch('activityModel', SubscriptionActivityModelAPIProviders),
  new Branch('activityCollection', SubscriptionActivityCollectionAPIProviders)
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationSubscription = buildCommunication<ISubscriptionConnectedProps>(strategy);
