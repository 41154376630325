import { getBaseUrl } from 'common/helpers/axios.helper';
import { IMAGE_SIZE_LIST, TImageSizes } from 'entities/Image/Image.models';

export interface IImageModel extends Components.Schemas.ImageDto {}

export const getVideosUrl = (id?: string, isPrivate?: boolean) => {
  if (id) {
    return isPrivate ? `${getBaseUrl()}/videos/${id}` : `${getBaseUrl()}/videos/${id}/public`;
  }
  return undefined;
};

export const getImageUrl = (id?: string | null, isPrivate?: boolean) => {
  if (id) {
    return isPrivate ? `${getBaseUrl()}/images/${id}` : `${getBaseUrl()}/images/${id}/public`;
  }
  return undefined;
};

export const getImageSize = (sizeFirst?: number | null, sizeSecond?: number | null) => {
  let size = sizeFirst;
  if (!sizeFirst || (sizeSecond && sizeFirst < sizeSecond)) {
    size = sizeSecond;
  }

  if (!size) {
    return null;
  }

  const sizeList = Object.keys(IMAGE_SIZE_LIST).sort((a, b) => Number(a) - Number(b));
  const key = sizeList.find(item => {
    if (Number(size) <= Number(item)) {
      return item;
    }
    return false;
  });

  if (key) {
    return IMAGE_SIZE_LIST[key as TImageSizes];
  }

  return null;
};
