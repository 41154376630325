// Axios initialization
import { IAsyncStorage, WebStorageDecorator } from 'universal-storage';
import axios from 'axios';
import { configureAxiosJWTInterseptors, ICreds } from 'axios-patch-jwt';
import { errorMapper } from 'fe-error-helper';
import { EActionsTypes, getSuccessType } from '@axmit/redux-communications';
import { store } from 'index';

const storage: IAsyncStorage = new WebStorageDecorator(localStorage);

export const axiosConfig = {
  storage,
  axios,
  refreshTokenEndpoint: '/auth',
  onSaveCreds: (creds: ICreds) => {
    store.dispatch({ type: getSuccessType('auth', 'model', EActionsTypes.add), payload: creds });
  }
};

configureAxiosJWTInterseptors(axiosConfig);

export const initAxios = () => {
  axios.defaults.baseURL = `/api`;
  axios.interceptors.request.use(config => config);
  axios.interceptors.response.use(
    response => {
      if (!response?.config) {
        return response;
      }
      return response?.data;
    },
    error => errorMapper(error)
  );
};

export function getHost() {
  return window.location.origin;
}

export const getBaseUrl = () => {
  return axios.defaults.baseURL;
};
