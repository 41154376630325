import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { EWalletTransactionType } from 'entities/Wallet/Wallet.models';

interface IComponentProps {
  onChange?: (value: EWalletTransactionType) => void;
  placeholder?: string;
}

type AllProps = SelectProps<any> & IComponentProps;

export class WalletTransactionTypeSelector extends React.PureComponent<AllProps> {
  handleChange = (value: EWalletTransactionType) => {
    const { onChange } = this.props;
    onChange && onChange(value);
  };

  render() {
    const { value, defaultValue, placeholder = 'Select' } = this.props;

    return (
      <Select
        allowClear
        className="width-full"
        value={value}
        placeholder={placeholder}
        defaultActiveFirstOption={false}
        defaultValue={defaultValue}
        showArrow
        onChange={(value: EWalletTransactionType) => this.handleChange(value)}
      >
        <Select.Option
          key={EWalletTransactionType.ChargeOff}
          value={EWalletTransactionType.ChargeOff}
          label={EWalletTransactionType.ChargeOff}
        >
          {EWalletTransactionType.ChargeOff}
        </Select.Option>
        <Select.Option
          key={EWalletTransactionType.Replenished}
          value={EWalletTransactionType.Replenished}
          label={EWalletTransactionType.Replenished}
        >
          {EWalletTransactionType.Replenished}
        </Select.Option>
      </Select>
    );
  }
}
