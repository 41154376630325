import React from 'react';
import { Link } from 'react-router-dom';
import { IBasePathParams } from 'common/models/requestModels';
import { ERoutesPrivate } from 'common/models/routesModel';
import { nameBuilder } from 'common/helpers/name.helper';
import { EEntityType, TEntityValue } from 'entities/User/User.models';

export interface IWalletModel extends Components.Schemas.WalletDto {}
export interface IWalletTransactionModel extends Components.Schemas.WalletTransactionDto {}
export interface IWalletTransactionParamsModel extends Components.Schemas.CreateDirectTransactionDto, IBasePathParams {
  redirectAfterSuccess?: string;
}

export interface IWalletTransactionCollection extends Components.Schemas.WalletTransactionCollectionDto {}
export interface IWalletTransactionCollectionFilter extends Paths.WalletTransactionControllerList.QueryParameters {
  id?: string;
  pageIndex?: number;
  senderValue?: TEntityValue;
  targetValue?: TEntityValue;
  senderEntity?: EEntityType;
  targetEntity?: EEntityType;
}
export enum EWalletTransactionType {
  Replenished = 'replenished',
  ChargeOff = 'charge-off'
}

export enum EWalletStatus {
  new = 'new',
  confirmed = 'confirmed',
  canceled = 'canceled'
}

export enum EWalletType {
  Direct = 'direct',
  CoinsPurchase = 'coinsPurchase',
  RewardForCompleteRegistration = 'rewardForCompleteRegistration',
  RewardForSuccessTestPass = 'rewardForSuccessTestPass',
  BuyTestPassTry = 'buyTestPassTry',
  TransferDebit = 'transferDebit',
  TransferCredit = 'transferCredit'
}

const renderEntity = (data: { type: EEntityType; value: TEntityValue }) => {
  const { type, value } = data || {};

  if (type && value) {
    const { id, firstName, lastName, name } = value || {};

    switch (type) {
      case EEntityType.Player: {
        return <Link to={`${ERoutesPrivate.Players}/${id}`}>{nameBuilder(firstName, lastName)}</Link>;
      }
      case EEntityType.Academy: {
        return <Link to={`${ERoutesPrivate.Academy}/${id}`}>{name}</Link>;
      }
      case EEntityType.User: {
        return <Link to={`${ERoutesPrivate.UserList}/${id}`}>{nameBuilder(firstName, lastName)}</Link>;
      }
      default: {
        return type;
      }
    }
  } else {
    return '-';
  }
};
export const tableWalletTransactionConfig = [
  {
    title: 'Target',
    dataIndex: 'targetData',
    key: 'targetData',
    ellipsis: true,
    render: renderEntity
  },
  {
    title: 'Sender',
    dataIndex: 'senderData',
    key: 'senderData',
    ellipsis: true,
    render: renderEntity
  },
  {
    title: 'Amount',
    dataIndex: 'coinsAmount',
    key: 'coinsAmount',
    ellipsis: true
  },
  {
    title: 'Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    ellipsis: true,
    sorter: true
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    ellipsis: true
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    ellipsis: true
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    ellipsis: true,
    sorter: true
  }
];
