import { ISelectMapItem } from 'common/models/selectModels';

export interface INotificationTemplateModel extends Components.Schemas.NotificationTemplateDto {}
export interface INotificationTemplateCollection extends Components.Schemas.NotificationTemplateCollectionDto {}

export interface INotificationTemplateCollectionFilter extends Paths.NotificationTemplateControllerList.QueryParameters {
  pageIndex?: number;
}
export interface INotificationTemplateModelFilter extends Paths.NotificationTemplateControllerGet.PathParameters {}

export interface INotificationTemplateUpdateParams
  extends Components.Schemas.UpdateNotificationTemplateDto,
    Paths.PlayerToTestControllerUpdate.PathParameters {}

export interface INotificationTemplateCreateParams extends Components.Schemas.CreateNotificationDto {}

export const tableNotificationTemplateConfig = [
  {
    title: 'Title',
    dataIndex: ['data', '0', 'title'],
    key: 'title',
    ellipsis: true
  },
  {
    title: 'Description',
    dataIndex: ['data', '0', 'description'],
    key: 'description',
    ellipsis: true
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    ellipsis: true
  },
  {
    title: 'Receiver type',
    dataIndex: 'receiverType',
    key: 'receiverType',
    ellipsis: true
  },
  {
    title: 'Event',
    dataIndex: 'event',
    key: 'event',
    ellipsis: true
  }
];

export enum ENotificationTemplateTypes {
  MentorRequest = 'mentorRequest',
  AcademyRequest = 'academyRequest',
  Test = 'test',
  PlayerToTest = 'playerToTest',
  Info = 'info',
  Giftcard = 'giftcard',
  Academy = 'academy',
  Player = 'player'
}
export enum ENotificationTemplateTypesText {
  MentorRequest = 'MentorRequest',
  AcademyRequest = 'AcademyRequest',
  PlayerToTest = 'PlayerToTest',
  Test = 'Test',
  Info = 'Info',
  Giftcard = 'Giftcard',
  Academy = 'Academy',
  Player = 'Player'
}

export const NotificationTemplateTypesMap: ISelectMapItem<ENotificationTemplateTypesText, ENotificationTemplateTypes>[] = [
  {
    title: ENotificationTemplateTypesText.MentorRequest,
    value: ENotificationTemplateTypes.MentorRequest
  },
  {
    title: ENotificationTemplateTypesText.AcademyRequest,
    value: ENotificationTemplateTypes.AcademyRequest
  },
  {
    title: ENotificationTemplateTypesText.Test,
    value: ENotificationTemplateTypes.Test
  },
  {
    title: ENotificationTemplateTypesText.PlayerToTest,
    value: ENotificationTemplateTypes.PlayerToTest
  },
  {
    title: ENotificationTemplateTypesText.Info,
    value: ENotificationTemplateTypes.Info
  },
  {
    title: ENotificationTemplateTypesText.Giftcard,
    value: ENotificationTemplateTypes.Giftcard
  },
  {
    title: ENotificationTemplateTypesText.Academy,
    value: ENotificationTemplateTypes.Academy
  },
  {
    title: ENotificationTemplateTypesText.Player,
    value: ENotificationTemplateTypes.Player
  }
];

export enum ENotificationTemplateEvent {
  AcademyRequestPlayer = 'academyRequestPlayer',
  PlayerRequestAcademy = 'playerRequestAcademy',
  MentorRequestNew = 'mentorRequestNew',
  MentorRequestAccept = 'mentorRequestAccept',
  MentorRequestDecline = 'mentorRequestDecline',
  MentorRequestAbort = 'mentorRequestAbort',
  PlayerToTestAccept = 'playerToTestAccept',
  PlayerToTestReject = 'playerToTestReject',
  FirstTest = 'firstTest',
  RegistrationComplete = 'registrationComplete',
  After24h = 'after24h',
  After48hWithoutTest = 'after48hWithoutTest',
  After72hWithoutTest = 'after72hWithoutTest',
  AfterFirstTest24hWithoutMentor = 'afterFirstTest24hWithoutMentor',
  LongTimeWithoutTestFirst = 'longTimeWithoutTestFirst',
  LongTimeWithoutTestSecond = 'longTimeWithoutTestSecond',
  LongTimeWithoutTestThird = 'longTimeWithoutTestThird',
  GiftcardNew = 'giftcardNew',
  GiftcardNew1mForRegistrationComplete = 'giftcardNew1mForRegistrationComplete',
  GiftcardUsed = 'giftcardUsed',
  GiftcardExpired = 'giftcardExpired',
  GiftcardBanned = 'giftcardBanned',
  NewAcademy = 'newAcademy',
  NewPlayer = 'newPlayer',
  HalfSkillsEvaluated = 'halfSkillsEvaluated',
  AllSkillsEvaluated = 'allSkillsEvaluated',
  After24hAfterAllSkillsEvaluated = 'after24hAfterAllSkillsEvaluated'
}

export enum ENotificationTemplateEventText {
  AcademyRequestPlayer = 'AcademyRequestPlayer',
  PlayerRequestAcademy = 'PlayerRequestAcademy',
  MentorRequestNew = 'MentorRequestNew',
  MentorRequestAccept = 'MentorRequestAccept',
  MentorRequestDecline = 'MentorRequestDecline',
  MentorRequestAbort = 'MentorRequestAbort',
  PlayerToTestAccept = 'PlayerToTestAccept',
  PlayerToTestReject = 'PlayerToTestReject',
  FirstTest = 'FirstTest',
  RegistrationComplete = 'RegistrationComplete',
  After24h = 'After24h',
  After48hWithoutTest = 'After48hWithoutTest',
  After72hWithoutTest = 'After72hWithoutTest',
  AfterFirstTest24hWithoutMentor = 'AfterFirstTest24hWithoutMentor',
  LongTimeWithoutTestFirst = 'LongTimeWithoutTestFirst',
  LongTimeWithoutTestSecond = 'LongTimeWithoutTestSecond',
  LongTimeWithoutTestThird = 'LongTimeWithoutTestThird',
  GiftcardNew = 'GiftcardNew',
  GiftcardNew1mForRegistrationComplete = 'GiftcardNew1mForRegistrationComplete',
  GiftcardUsed = 'GiftcardUsed',
  GiftcardExpired = 'GiftcardExpired',
  GiftcardBanned = 'GiftcardBanned',
  NewAcademy = 'NewAcademy',
  NewPlayer = 'NewPlayer',
  HalfSkillsEvaluated = 'HalfSkillsEvaluated',
  AllSkillsEvaluated = 'AllSkillsEvaluated',
  After24hAfterAllSkillsEvaluated = 'After24hAfterAllSkillsEvaluated'
}

export const NotificationTemplateEventMap: ISelectMapItem<ENotificationTemplateEventText, ENotificationTemplateEvent>[] = [
  {
    title: ENotificationTemplateEventText.AcademyRequestPlayer,
    value: ENotificationTemplateEvent.AcademyRequestPlayer
  },
  {
    title: ENotificationTemplateEventText.PlayerRequestAcademy,
    value: ENotificationTemplateEvent.PlayerRequestAcademy
  },
  {
    title: ENotificationTemplateEventText.MentorRequestNew,
    value: ENotificationTemplateEvent.MentorRequestNew
  },
  {
    title: ENotificationTemplateEventText.MentorRequestAccept,
    value: ENotificationTemplateEvent.MentorRequestAccept
  },
  {
    title: ENotificationTemplateEventText.MentorRequestDecline,
    value: ENotificationTemplateEvent.MentorRequestDecline
  },
  {
    title: ENotificationTemplateEventText.MentorRequestAbort,
    value: ENotificationTemplateEvent.MentorRequestAbort
  },
  {
    title: ENotificationTemplateEventText.PlayerToTestAccept,
    value: ENotificationTemplateEvent.PlayerToTestAccept
  },
  {
    title: ENotificationTemplateEventText.PlayerToTestReject,
    value: ENotificationTemplateEvent.PlayerToTestReject
  },
  {
    title: ENotificationTemplateEventText.FirstTest,
    value: ENotificationTemplateEvent.FirstTest
  },
  {
    title: ENotificationTemplateEventText.RegistrationComplete,
    value: ENotificationTemplateEvent.RegistrationComplete
  },
  {
    title: ENotificationTemplateEventText.After24h,
    value: ENotificationTemplateEvent.After24h
  },
  {
    title: ENotificationTemplateEventText.After48hWithoutTest,
    value: ENotificationTemplateEvent.After48hWithoutTest
  },
  {
    title: ENotificationTemplateEventText.After72hWithoutTest,
    value: ENotificationTemplateEvent.After72hWithoutTest
  },
  {
    title: ENotificationTemplateEventText.AfterFirstTest24hWithoutMentor,
    value: ENotificationTemplateEvent.AfterFirstTest24hWithoutMentor
  },
  {
    title: ENotificationTemplateEventText.LongTimeWithoutTestFirst,
    value: ENotificationTemplateEvent.LongTimeWithoutTestFirst
  },
  {
    title: ENotificationTemplateEventText.LongTimeWithoutTestSecond,
    value: ENotificationTemplateEvent.LongTimeWithoutTestSecond
  },
  {
    title: ENotificationTemplateEventText.LongTimeWithoutTestThird,
    value: ENotificationTemplateEvent.LongTimeWithoutTestThird
  },
  {
    title: ENotificationTemplateEventText.GiftcardNew,
    value: ENotificationTemplateEvent.GiftcardNew
  },
  {
    title: ENotificationTemplateEventText.GiftcardNew1mForRegistrationComplete,
    value: ENotificationTemplateEvent.GiftcardNew1mForRegistrationComplete
  },
  {
    title: ENotificationTemplateEventText.GiftcardUsed,
    value: ENotificationTemplateEvent.GiftcardUsed
  },
  {
    title: ENotificationTemplateEventText.GiftcardExpired,
    value: ENotificationTemplateEvent.GiftcardExpired
  },
  {
    title: ENotificationTemplateEventText.GiftcardBanned,
    value: ENotificationTemplateEvent.GiftcardBanned
  },
  {
    title: ENotificationTemplateEventText.NewAcademy,
    value: ENotificationTemplateEvent.NewAcademy
  },
  {
    title: ENotificationTemplateEventText.NewPlayer,
    value: ENotificationTemplateEvent.NewPlayer
  },
  {
    title: ENotificationTemplateEventText.HalfSkillsEvaluated,
    value: ENotificationTemplateEvent.HalfSkillsEvaluated
  },
  {
    title: ENotificationTemplateEventText.AllSkillsEvaluated,
    value: ENotificationTemplateEvent.AllSkillsEvaluated
  },
  {
    title: ENotificationTemplateEventText.After24hAfterAllSkillsEvaluated,
    value: ENotificationTemplateEvent.After24hAfterAllSkillsEvaluated
  }
];

export enum ENotificationTemplateLang {
  Ru = 'ru',
  Eng = 'eng'
}

export const NOTIFICATION_TEMPLATE_LANGS = [ENotificationTemplateLang.Ru, ENotificationTemplateLang.Eng];
