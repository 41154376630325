import React, { Dispatch, SetStateAction } from 'react';
import ReactCrop, { Crop } from 'react-image-crop';

interface IProps {
  src: string | ArrayBuffer | null | undefined;
  crop: Crop;
  circularCrop?: boolean;
  setCrop: Dispatch<SetStateAction<Crop>>;
  setImage: Dispatch<SetStateAction<HTMLImageElement | undefined>>;
}

const cropOptions = {
  minWidth: 100,
  minHeight: 100
};

const imageStyle = { maxHeight: 600 };

export const ImageUploadBody: React.FC<IProps> = ({ src, circularCrop, crop, setCrop, setImage }) => {
  return (
    <ReactCrop
      src={src as any}
      circularCrop={circularCrop === undefined ? true : circularCrop}
      minWidth={cropOptions.minWidth}
      minHeight={cropOptions.minHeight}
      onImageLoaded={setImage}
      crop={crop}
      imageStyle={imageStyle}
      onChange={setCrop}
    />
  );
};
