import moment from 'moment';
import { simpleDateFormat } from 'common/models/dateModels';
import { EGiftcardStatuses } from 'entities/Giftcard/Giftcard.models';

const DEFAULT_VALUE = '-';

export const normalize = (value?: number | null) => {
  if (value !== undefined && value !== null) {
    return Number(value).toLocaleString(undefined, { maximumFractionDigits: 2 });
  }
  return DEFAULT_VALUE;
};

export const normalizeNumber = (value?: number | null) => {
  if (value !== undefined && value !== null && !isNaN(value)) {
    return Math.round((value + Number.EPSILON) * 100) / 100;
  }
  return 0;
};

export const copyObject = (object: object) => JSON.parse(JSON.stringify(object));

export const normalizeMoney = (value?: number | null, currency?: string) => {
  if (value !== undefined && value !== null) {
    return Number(value).toLocaleString(undefined, { style: 'currency', currency });
  }
  return DEFAULT_VALUE;
};

export const normalizeStatus = (status?: string, subscriptionExpiredDate?: string) => {
  if (status === EGiftcardStatuses.Expired && !subscriptionExpiredDate) {
    return EGiftcardStatuses.New;
  }
  return status;
};

export const normalizeDate = (date: string) => (date ? moment(date).format(simpleDateFormat) : '-');

export const normalizeRequestBody: any = (object: any) => {
  const keys = Object.keys(object);
  return keys.reduce((reducer, key) => {
    const item = object[key];
    switch (typeof item) {
      case 'string': {
        if (item.length === 0) {
          return { ...reducer, [key]: null };
        }
        return { ...reducer, [key]: item };
      }
      case 'number':
      default: {
        return { ...reducer, [key]: item };
      }
    }
  }, {});
};
