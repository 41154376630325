import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Form, Input } from 'antd';
import { useFormMapper } from '@axmit/antd4-helpers';
import { ILanguageModel } from 'entities/Language/Language.models';
import { communicationLanguage, ILanguageConnectedProps } from 'entities/Language/Language.communication';

interface IProps {
  onCancel: () => void;
  modalData: ILanguageModel | null;
}

type AllProps = IProps & ILanguageConnectedProps;

const LanguageModalBodyComponent: React.FC<AllProps> = props => {
  const [dataIsSend, setDataIsSend] = useState(false);
  const { addLanguageModel, updateLanguageModel, languageModel, onCancel, modalData } = props;
  const { loading, params, errors } = languageModel;
  const isEdit = !!modalData;

  const { fields } = useFormMapper(['locale', 'name', 'isPrivate'], modalData, params, errors);

  const onFinish = (values: { locale: string; name: string; isPrivate?: boolean }) => {
    if (isEdit) {
      updateLanguageModel({ id: values.locale, name: values.name, isPrivate: values?.isPrivate });
    } else {
      addLanguageModel(values);
    }
    setDataIsSend(true);
  };

  useEffect(() => {
    if (dataIsSend && !loading && !errors) {
      if (errors) {
        setDataIsSend(false);
      } else {
        onCancel();
      }
    }
  }, [loading, errors, onCancel, dataIsSend]);

  return (
    <Form onFinish={onFinish} fields={fields}>
      <Form.Item
        rules={[
          {
            required: true,
            message: 'Locale is required'
          }
        ]}
        name="locale"
        label="Locale"
      >
        <Input disabled={isEdit || loading} placeholder="en" />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: 'Locale name is required'
          }
        ]}
        name="name"
        label="Locale name"
      >
        <Input disabled={loading} placeholder="English" />
      </Form.Item>
      <Form.Item name="isPrivate" valuePropName="checked">
        <Checkbox>Is private</Checkbox>
      </Form.Item>
      <Button htmlType="submit" type="primary" disabled={loading} loading={loading} title="Submit">
        Submit
      </Button>
    </Form>
  );
};

export const LanguageModalBody = communicationLanguage.injector(LanguageModalBodyComponent);
