import axios, { AxiosStatic } from 'axios';
import { BaseHttpTransport } from '@axmit/transport';
import { objectToQuery } from 'common/helpers/filters.helper';
import {
  IInfluencerModel,
  IInfluencerParams,
  IInfluencerCollectionFilter,
  IInfluencerCollection
} from 'entities/Influencer/Influencer.models';

const basePath = '/common/influencers/statistics';

class InfluencerTransport extends BaseHttpTransport<
  AxiosStatic,
  IInfluencerModel,
  IInfluencerCollection,
  null,
  void,
  IInfluencerCollectionFilter
> {
  public getInfluencerModel = ({ id, ...params }: IInfluencerParams): Promise<IInfluencerModel> =>
    axios.get(`${basePath}/${id}${objectToQuery(params)}`);
}

export const influencerTransport = new InfluencerTransport(basePath, axios);
