import { useState } from 'react';

export const useFilesLoading = (ids: string[]): [boolean, (type: string, isLoading: boolean) => void] => {
  const [loadingStatus, setLoadingStatus] = useState(ids.reduce((acc, id) => ({ ...acc, [id]: false }), {}));

  const onChangeFileLoading = (type: string, isLoading: boolean) => {
    setLoadingStatus({ ...loadingStatus, [type]: isLoading });
  };

  const filesLoading = Object.values(loadingStatus).includes(true);

  return [filesLoading, onChangeFileLoading];
};
