import { EActionsTypes, APIProvider, BaseStrategy, Branch, buildCommunication, StoreBranch } from '@axmit/redux-communications';
import { message } from 'antd';
import { put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { ERoutesPrivate } from 'common/models/routesModel';
import { storyTransport } from 'entities/Story/Story.transport';
import {
  EStorySuccessMessage,
  IStoryCreateData,
  IStoryModel,
  IStoryUpdateData,
  IStoryCollectionFilter,
  IStoryCollection,
  TStoryParamsModel
} from 'entities/Story/Story.models';

const namespace = 'story';

export interface IStoryConnectedProps {
  storyModel: StoreBranch<IStoryModel, TStoryParamsModel>;
  storyCollection: StoreBranch<IStoryCollection, IStoryCollectionFilter>;

  getStoryModel(id: string): void;
  addStoryModel(params: IStoryCreateData): void;
  updateStoryModel(params: IStoryUpdateData): void;
  deleteStoryModel(id: string): void;
  getStoryCollection(filter?: IStoryCollectionFilter): void;

  clearStoryModel(): void;
  clearStoryCollection(): void;
}

const StoryModelAPIProviders = [
  new APIProvider(EActionsTypes.get, storyTransport.get),
  new APIProvider(EActionsTypes.update, storyTransport.update, {
    onSuccess: function*() {
      message.success(EStorySuccessMessage.Edit);
      yield put(push(ERoutesPrivate.Story));
    }
  }),
  new APIProvider(EActionsTypes.delete, storyTransport.delete, {
    onSuccess: function*() {
      message.success(EStorySuccessMessage.Delete);
      yield put(push(ERoutesPrivate.Story));
    }
  }),
  new APIProvider(EActionsTypes.add, storyTransport.add, {
    onSuccess: function*() {
      message.success(EStorySuccessMessage.Add);
      yield put(push(ERoutesPrivate.Story));
    }
  })
];

const StoryCollectionAPIProviders = [new APIProvider(EActionsTypes.get, storyTransport.getCollection)];

const branches = [new Branch('model', StoryModelAPIProviders), new Branch('collection', StoryCollectionAPIProviders)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationStory = buildCommunication<IStoryConnectedProps>(strategy);
