import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { EGiftcardStatuses, EGiftcardStatusText } from 'entities/Giftcard/Giftcard.models';

interface IComponentProps {
  onChange?: (value?: EGiftcardStatuses[]) => void;
  disabled?: boolean;
}

type AllProps = SelectProps<any> & IComponentProps;

export class GiftcardStatusSelector extends React.PureComponent<AllProps> {
  render() {
    const { value, onChange, disabled = false } = this.props;

    return (
      <Select
        allowClear
        mode="multiple"
        className="width-full"
        placeholder="Select statuses"
        value={value}
        defaultActiveFirstOption={false}
        showArrow
        onChange={(value: EGiftcardStatuses[]) => onChange && onChange(value)}
        disabled={disabled}
      >
        <Select.Option key={EGiftcardStatuses.Active} value={EGiftcardStatuses.Active} label={EGiftcardStatusText.Active}>
          {EGiftcardStatusText.Active}
        </Select.Option>
        <Select.Option key={EGiftcardStatuses.Banned} value={EGiftcardStatuses.Banned} label={EGiftcardStatusText.Banned}>
          {EGiftcardStatusText.Banned}
        </Select.Option>
        <Select.Option key={EGiftcardStatuses.New} value={EGiftcardStatuses.New} label={EGiftcardStatusText.New}>
          {EGiftcardStatusText.New}
        </Select.Option>
        <Select.Option key={EGiftcardStatuses.Used} value={EGiftcardStatuses.Used} label={EGiftcardStatusText.Used}>
          {EGiftcardStatusText.Used}
        </Select.Option>
        <Select.Option key={EGiftcardStatuses.Expired} value={EGiftcardStatuses.Expired} label={EGiftcardStatusText.Expired}>
          {EGiftcardStatusText.Expired}
        </Select.Option>
      </Select>
    );
  }
}
