import React, { useCallback, useMemo, useState } from 'react';
import { Button, Col, Form, Input, InputNumber, message, Popconfirm, Row, Switch } from 'antd';
import { Link } from 'react-router-dom';
import { StoreBranch } from '@axmit/redux-communications';
import { useHistory } from 'react-router';
import { useFormMapper } from '@axmit/antd4-helpers';
import moment from 'moment';
import { CopyOutlined } from '@ant-design/icons';
import { ERoutesPrivate } from 'common/models/routesModel';
import { ImageUpload } from 'common/components/ImageUpload/ImageUpload';
import { simpleDateFormat } from 'common/models/dateModels';
import { copyToClipboard } from 'common/helpers/copy.helper';
import {
  EAdminRole,
  IAdminCreateData,
  IAdminModel,
  IAdminModelForm,
  IAdminUpdateData,
  TAdminParamsModel
} from 'entities/Admin/Admin.models';
import { AdminStatusSelector } from 'entities/Admin/components/Selector/AdminStatusSelector';
import { AdminRoleSelector } from 'entities/Admin/components/Selector/AdminRoleSelector';
import { EUserRoles } from 'entities/User/User.models';
import { AcademySelector } from 'entities/Academy/components/Selector/AcademySelector';
import { LanguageSelect } from 'entities/Language/components/Selectors/LanguageSelect';
import { adminTransport } from 'entities/Admin/Admin.transport';

interface IComponentProps {
  createAdmin?: (data: IAdminCreateData) => void;
  updateAdmin?: (data: IAdminUpdateData) => void;
  deleteAdmin?: (id: string) => void;
  adminModel: StoreBranch<IAdminModel, TAdminParamsModel>;
  adminId?: string;
}

const AdminFormComponent: React.FC<IComponentProps> = props => {
  const { adminModel, createAdmin, updateAdmin, adminId, deleteAdmin } = props;
  const { push } = useHistory();
  const { errors, loading, data, params } = adminModel;
  const isUpdateForm = !!adminId;
  const submitButtonText = isUpdateForm ? 'Save' : ' Add';
  const role = data?.role as EUserRoles & EAdminRole;
  const [roleValue, setRoleValue] = useState(role || EUserRoles.DataTagger);
  const [form] = Form.useForm();
  const { academyWorker, trainerRequest } = data ?? {};

  const isTrainer = roleValue === EUserRoles.Trainer;
  const isAcademyWorker = roleValue === EAdminRole.AcademyWorker;
  const isAdmin = roleValue === EAdminRole.Admin;
  const isTagger = roleValue === EAdminRole.Tagger;
  const isAcademyWorkerOrTrainer = isAcademyWorker || isTrainer;
  const registrationDate = moment(data?.createdAt).format(simpleDateFormat);

  const parsedData = useMemo(() => {
    const formData: IAdminModelForm | { academy?: string; image?: string; lang?: string } = { ...data };

    if (isAcademyWorkerOrTrainer) {
      if (isAcademyWorker) {
        formData.academy = academyWorker?.academy;
      } else {
        formData.academy = trainerRequest?.academy;
      }
    }

    formData.image = data?.image?.id;
    formData.lang = data?.lang || 'en';

    return formData;
    // eslint-disable-next-line
  }, [data]);

  const { fields } = useFormMapper(
    [
      'firstName',
      'lastName',
      'email',
      'lang',
      'externalId',
      'image',
      'status',
      'password',
      'isReceiveAcademyMails',
      'isReceiveTestModerationMails',
      'isReceivePlayerMails',
      'testBalance',
      'academy',
      'phone'
    ],
    parsedData,
    params,
    errors
  );

  const addAdmin = (values: any) => {
    const {
      firstName,
      lastName,
      email,
      password,
      academy,
      lang,
      isReceiveAcademyMails,
      isReceivePlayerMails,
      isReceiveTestModerationMails
    } = values;

    const newData: IAdminCreateData = {
      firstName,
      lastName,
      email,
      role: roleValue,
      password,
      lang,
      isReceiveAcademyMails: !!isReceiveAcademyMails,
      isReceivePlayerMails: !!isReceivePlayerMails,
      isReceiveTestModerationMails: isAdmin || isTagger ? !!isReceiveTestModerationMails : undefined
    };

    if (isAcademyWorkerOrTrainer && academy?.id) {
      newData.academy = academy?.id;
    }

    createAdmin && createAdmin(newData);
  };

  const editAdmin = (values: any) => {
    const { academy, isReceiveAcademyMails, isReceivePlayerMails, isReceiveTestModerationMails, externalId, ...body } = values;

    if (adminId && updateAdmin) {
      const newData: IAdminUpdateData = {
        id: adminId,
        role: roleValue,
        externalId: externalId,
        ...body,
        isReceiveAcademyMails: !!isReceiveAcademyMails,
        isReceivePlayerMails: !!isReceivePlayerMails,
        isReceiveTestModerationMails: isAdmin || isTagger ? !!isReceiveTestModerationMails : undefined
      };

      if (isAcademyWorkerOrTrainer && academy?.id) {
        newData.academy = academy?.id;
      }

      updateAdmin(newData);
    }
  };

  const onCopyEmail = () => {
    copyToClipboard(data?.email);
  };

  const onCopyPhone = () => {
    copyToClipboard(data?.phone);
  };

  const sendLoginLink = useCallback(
    async (e?: any) => {
      e?.stopPropagation();
      if (adminId) {
        try {
          await adminTransport.sendLoginLink(adminId);
          message.success('Link has been sended');
        } catch (err) {
          console.log(err);
        }
      }
    },
    [adminId]
  );

  return (
    <Row gutter={16}>
      <Col span={12}>
        <Form form={form} className="mt-5" onFinish={isUpdateForm ? editAdmin : addAdmin} fields={fields}>
          <>
            <>
              <Row className="mb-5" align="middle">
                <span className="basic__text_title" title="Personal info">
                  Personal info
                </span>
              </Row>
              <Row gutter={[28, 10]}>
                {isTrainer && (
                  <Col span={24}>
                    <Form.Item name="image" label="Avatar">
                      <ImageUpload />
                    </Form.Item>
                  </Col>
                )}
                <Col span={24}>
                  <span className="basic__text_label mr-5" title="Registration date">
                    Registration date:
                  </span>
                  <span className="basic__text_default" title={registrationDate}>
                    {registrationDate}
                  </span>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'First name is required'
                      },
                      {
                        min: 2,
                        message: 'First name must be longer than or equal 2 characters'
                      },
                      {
                        max: 32,
                        message: 'First name must be shorter than or equal 32 characters'
                      }
                    ]}
                    name="firstName"
                    label="First name"
                  >
                    <Input name="firstName" type="text" placeholder="First name" disabled={loading} />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Last name is required'
                      },
                      {
                        min: 2,
                        message: 'Last name must be longer than or equal 2 characters'
                      },
                      {
                        max: 32,
                        message: 'Last name must be shorter than or equal 32 characters'
                      }
                    ]}
                    name="lastName"
                    label="Last name"
                  >
                    <Input name="lastName" type="text" placeholder="Last name" disabled={loading} />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Row align="middle" className="width-full">
                    <Col flex={1}>
                      <Form.Item
                        rules={[
                          {
                            required: !isUpdateForm,
                            message: 'Email is required'
                          },
                          { type: 'email', message: 'Please enter correct email' }
                        ]}
                        name="email"
                        label="Email"
                      >
                        <Input
                          type="email"
                          autoComplete="off"
                          name="email"
                          placeholder="Email"
                          disabled={loading || isUpdateForm}
                        />
                      </Form.Item>
                    </Col>
                    <CopyOutlined onClick={onCopyEmail} className="cursor-pointer ml-3" />
                  </Row>
                </Col>
                {isUpdateForm && (
                  <>
                    <Col xs={24}>
                      <Row align="middle" className="width-full">
                        <Col flex={1}>
                          <Form.Item
                            rules={[
                              {
                                min: 2,
                                message: 'External Id must be longer than or equal 2 characters'
                              },
                              {
                                max: 128,
                                message: 'External Id must be shorter than or equal 128 characters'
                              }
                            ]}
                            name="externalId"
                            label="External Id"
                          >
                            <Input name="externalId" type="text" placeholder="External Id" disabled={loading} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={24}>
                      <Row align="middle" className="width-full">
                        <Col flex={1}>
                          <Form.Item name="phone" label="Phone">
                            <Input autoComplete="off" name="phone" placeholder="Phone" disabled />
                          </Form.Item>
                        </Col>
                        <CopyOutlined onClick={onCopyPhone} className="cursor-pointer ml-3" />
                      </Row>
                    </Col>
                  </>
                )}
                <Col xs={24}>
                  <Form.Item
                    rules={[
                      {
                        required: !isUpdateForm,
                        message: 'Password is required'
                      },
                      {
                        min: 8,
                        message: 'Password must be longer than or equal 8 characters'
                      },
                      {
                        max: 16,
                        message: 'Password must be shorter than or equal 16 characters'
                      }
                    ]}
                    name="password"
                    label="Password"
                  >
                    <Input.Password
                      type="password"
                      autoComplete="new-password"
                      placeholder="Password"
                      disabled={loading || isUpdateForm}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item name="lang" label="Lang">
                    <LanguageSelect />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="mb-5" align="middle">
                <span className="basic__text_title" title="Personal info">
                  System info
                </span>
              </Row>
              <Row gutter={[28, 10]}>
                <Col xs={24}>
                  <AdminRoleSelector
                    allowClear={false}
                    value={roleValue}
                    disabled={loading || isUpdateForm}
                    onChange={(value: any) => setRoleValue(value)}
                  />
                </Col>
                {isAcademyWorkerOrTrainer && (
                  <Col xs={24}>
                    <Form.Item
                      rules={[
                        {
                          required: isAcademyWorker,
                          message: 'Academy is required'
                        }
                      ]}
                      name="academy"
                      label="Academy"
                    >
                      <AcademySelector placeholder="Select by academy" />
                    </Form.Item>
                  </Col>
                )}
                <Col xs={24}>
                  <Form.Item
                    rules={[
                      {
                        required: !!isUpdateForm,
                        message: 'Status is required'
                      }
                    ]}
                    name="status"
                    label="Status"
                  >
                    <AdminStatusSelector disabled={loading || !isUpdateForm} />
                  </Form.Item>
                </Col>
                {isAdmin && (
                  <Col xs={24}>
                    <Form.Item valuePropName="checked" name="isReceiveAcademyMails" label="Receive academy mails">
                      <Switch />
                    </Form.Item>
                  </Col>
                )}
                {isTrainer && isUpdateForm && (
                  <Col xs={24}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Test balance is required'
                        },
                        {
                          type: 'number'
                        }
                      ]}
                      name="testBalance"
                      label="Test balance"
                    >
                      <InputNumber min={0} placeholder="Test balance" disabled={loading} />
                    </Form.Item>
                  </Col>
                )}
                {(isAdmin || isTagger) && (
                  <Col xs={24}>
                    <Form.Item valuePropName="checked" name="isReceiveTestModerationMails" label="Receive test moderation mails">
                      <Switch />
                    </Form.Item>
                  </Col>
                )}
                {isAdmin && (
                  <Col xs={24}>
                    <Form.Item valuePropName="checked" name="isReceivePlayerMails" label="Receive player mails">
                      <Switch />
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </>

            <Row justify="space-between" align="middle">
              <Col>
                <Form.Item className="mb-0">
                  <Popconfirm title="Are you sure?" onConfirm={() => form.submit()} okText="Yes" cancelText="No">
                    <Button className="mr-8" type="primary" disabled={loading} loading={loading} title={submitButtonText}>
                      {submitButtonText}
                    </Button>
                  </Popconfirm>
                  <Link to={ERoutesPrivate.UserList}>
                    <Button disabled={loading} loading={loading} title="Cancel">
                      Cancel
                    </Button>
                  </Link>
                </Form.Item>
              </Col>

              <Button className="ml-4" type="primary" title="Send login link" onClick={sendLoginLink}>
                {'Send login link'}
              </Button>
              {isUpdateForm && isTrainer && (
                <Popconfirm
                  title="Are you sure?"
                  onConfirm={() => deleteAdmin && adminId && deleteAdmin(adminId)}
                  okText="Yes"
                  cancelText="No"
                  disabled={loading}
                >
                  <Button danger disabled={loading} loading={loading} title="Delete" className="mt-3">
                    Delete
                  </Button>
                </Popconfirm>
              )}
            </Row>
          </>
        </Form>
      </Col>
      {academyWorker ? (
        <Col span={12}>
          <Row className="mb-5 mt-5" align="middle" gutter={[8, 16]}>
            <Col span={24} className="mb-5">
              <span className="basic__text_title" title="Personal info">
                Academy
              </span>
            </Col>
            <Col span={24}>
              <span className="basic__text_label mr-5" title="Academy name">
                Academy name:
              </span>
              <span className="basic__text_default" title={academyWorker?.academy.name}>
                {academyWorker?.academy.name}
              </span>
            </Col>
            <Col span={24}>
              <span className="basic__text_label mr-5" title="Role">
                Role:
              </span>
              <span className="basic__text_default" title={data?.status}>
                {academyWorker?.role}
              </span>
            </Col>
            <Col span={24}>
              <Button onClick={() => push(`${ERoutesPrivate.Academy}/${academyWorker?.academy.id}`)}>To academy</Button>
            </Col>
          </Row>
        </Col>
      ) : null}
    </Row>
  );
};

export const AdminForm = AdminFormComponent;
