import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { ETestAnchorDetectionTypes, ETestAnchorDetectionTypesText } from 'entities/Test/Test.models';

type AllProps = SelectProps<any>;

export class AnchorTypeSelector extends React.PureComponent<AllProps> {
  render() {
    const { ...props } = this.props;

    return (
      <Select className="width-full" defaultActiveFirstOption={false} showArrow {...props}>
        <Select.Option
          key={ETestAnchorDetectionTypes.Horizontal}
          value={ETestAnchorDetectionTypes.Horizontal}
          label={ETestAnchorDetectionTypes.Horizontal}
        >
          {ETestAnchorDetectionTypesText.Horizontal}
        </Select.Option>
        <Select.Option
          key={ETestAnchorDetectionTypes.None}
          value={ETestAnchorDetectionTypes.None}
          label={ETestAnchorDetectionTypes.None}
        >
          {ETestAnchorDetectionTypesText.None}
        </Select.Option>
      </Select>
    );
  }
}
