import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { EAdminStatus, EAdminStatusText } from 'entities/Admin/Admin.models';

interface IComponentProps {
  onChange?: (value?: EAdminStatus) => void;
  disabled?: boolean;
}

type AllProps = SelectProps<any> & IComponentProps;

export class AdminStatusSelector extends React.PureComponent<AllProps> {
  render() {
    const { value, onChange, disabled = false } = this.props;

    return (
      <Select
        allowClear
        className="width-full"
        placeholder="Select status"
        value={value}
        defaultActiveFirstOption={false}
        showArrow
        onChange={(value: EAdminStatus) => onChange && onChange(value)}
        disabled={disabled}
      >
        <Select.Option key={EAdminStatus.Active} value={EAdminStatus.Active} label={EAdminStatusText.Active}>
          {EAdminStatusText.Active}
        </Select.Option>
        <Select.Option key={EAdminStatus.Banned} value={EAdminStatus.Banned} label={EAdminStatusText.Banned}>
          {EAdminStatusText.Banned}
        </Select.Option>
      </Select>
    );
  }
}
