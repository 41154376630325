import axios from 'axios';
import { IImageModel } from 'entities/Image/Image.models';

const basePath = '/images';

export const uploadImage = (image: Blob, fileName: string): Promise<IImageModel> => {
  const form = new FormData();
  form.append('image', image, fileName);

  return axios.post(basePath, form);
};
