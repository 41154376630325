import { EActionsTypes, APIProvider, BaseStrategy, Branch, buildCommunication, StoreBranch } from '@axmit/redux-communications';
import {
  IStatisticCollection,
  IStatisticCollectionFilter,
  IStatisticTestCollection,
  IStatisticTestCollectionFilter
} from 'entities/Statistic/Statistic.models';
import { statisticTransport } from 'entities/Statistic/Statistic.transport';

const namespace = 'statistic';

export interface IStatisticConnectedProps {
  statisticCollection: StoreBranch<IStatisticCollection, IStatisticCollectionFilter>;
  statisticTestCollection: StoreBranch<IStatisticTestCollection, IStatisticTestCollectionFilter>;

  getStatisticCollection(filter?: IStatisticCollectionFilter): void;
  getStatisticTestCollection(filter?: IStatisticTestCollectionFilter): void;

  clearStatisticCollection(): void;
  clearStatisticTestCollection(): void;
}

const StatisticCollectionAPIProviders = [new APIProvider(EActionsTypes.get, statisticTransport.getCollection)];
const StatisticTestCollectionAPIProviders = [new APIProvider(EActionsTypes.get, statisticTransport.getTestStatistic)];

const branches = [
  new Branch('collection', StatisticCollectionAPIProviders),
  new Branch('testCollection', StatisticTestCollectionAPIProviders)
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationStatistic = buildCommunication<IStatisticConnectedProps>(strategy);
