import React, { useCallback, useMemo, useState } from 'react';
import { Button, Col, DatePicker, Form, Input, InputNumber, Row, Select } from 'antd';
import { useFormMapper } from '@axmit/antd4-helpers';
import { Link } from 'react-router-dom';
import { StoreBranch } from '@axmit/redux-communications';
import moment, { Moment } from 'moment';
import { useForm } from 'antd/es/form/Form';
import { ERoutesPrivate } from 'common/models/routesModel';
import {
  EGiftcardCreateTypes,
  EGiftcardStatuses,
  EGiftcardTypes,
  IGiftcardCreateParams,
  IGiftcardModel,
  IGiftcardParamsModel,
  IGiftcardUpdateStatusParams
} from 'entities/Giftcard/Giftcard.models';
import { MentorSelector } from 'entities/User/components/MentorSelector';
import { PlayerSelector } from 'entities/Player/components/PlayerSelector';
import { IPlayerModel } from 'entities/Player/Player.models';
import { IUserModel } from 'entities/User/User.models';

interface IComponentProps {
  giftcardModel: StoreBranch<IGiftcardModel, IGiftcardParamsModel>;
  giftcardId?: string;
  addGiftcardModel?: (data: IGiftcardCreateParams) => void;
  updateStatusGiftcardModel?: (data: IGiftcardUpdateStatusParams) => void;
}

const GiftcardFormComponent: React.FC<IComponentProps> = props => {
  const [form] = useForm();
  const [giftcardType, setGiftcardType] = useState<EGiftcardCreateTypes>(EGiftcardCreateTypes.Standards);
  const { giftcardModel, giftcardId, addGiftcardModel, updateStatusGiftcardModel } = props;
  const { errors, loading, data, params } = giftcardModel;
  const isViewMode = useMemo(() => !!giftcardId, [giftcardId]);
  const disabled = useMemo(() => loading || isViewMode, [loading, isViewMode]);
  const isBanned = useMemo(() => data?.status === EGiftcardStatuses.Banned, [data]);

  const setInitGiftcardType = useCallback(() => {
    switch (data?.type) {
      case EGiftcardTypes.Mentor:
        setGiftcardType(EGiftcardCreateTypes.Mentors);
        break;
      case EGiftcardTypes.Player:
        setGiftcardType(EGiftcardCreateTypes.Players);
        break;
      case EGiftcardTypes.Standard:
        setGiftcardType(EGiftcardCreateTypes.Standards);
        break;
    }
  }, [data]);

  const formData = useMemo(() => {
    setInitGiftcardType();

    const returnData: {
      player?: IPlayerModel;
      mentor?: IUserModel;
      expireAt?: Moment;
    } = {
      ...data,
      player: undefined,
      mentor: undefined,
      expireAt: undefined
    };
    if (data?.ownerPlayer) {
      returnData.player = data.ownerPlayer;
    }
    if (data?.ownerMentor) {
      returnData.mentor = data.ownerMentor;
    }
    if (data?.expireAt) {
      returnData.expireAt = moment(data?.expireAt);
    }

    if (data?.sponsor) {
      form.setFieldsValue({ sponsor: data.sponsor });
    }

    return { ...returnData };
  }, [data, setInitGiftcardType]);

  const errorData = useMemo(() => {
    if (errors && 'validation' in (errors as any) && typeof (errors as any).validation === 'object') {
      const { validation, ...rest } = errors as any;
      const formattedErrors = { ...rest };

      formattedErrors.validation = Object.keys(validation).reduce((acc, errorKey) => {
        const formattedError = errorKey.split('.');

        return { ...acc, [formattedError[formattedError.length - 1]]: validation[errorKey] };
      }, {});

      return formattedErrors;
    }

    return errors;
  }, [errors]);

  const { fields } = useFormMapper(
    ['description', 'amount', 'expireAt', 'count', 'mentor', 'player', 'sponsor'],
    formData,
    params,
    errorData
  );

  const disabledDate = useCallback((current: Moment) => {
    // Can not select days before today and today
    return current && current < moment().endOf('day');
  }, []);

  const onChangeStatus = useCallback(() => {
    const id = data?.id;
    if (id && updateStatusGiftcardModel) {
      const body = {
        id,
        status: isBanned ? EGiftcardStatuses.Active : EGiftcardStatuses.Banned
      };
      updateStatusGiftcardModel(body);
    }
  }, [isBanned, data, updateStatusGiftcardModel]);

  const onFinish = useCallback(
    (values: any) => {
      const { sponsor, ...rest } = values;
      const formBody = { ...rest, amountType: 'months' };

      if (giftcardType === EGiftcardCreateTypes.Mentors) {
        formBody.mentor = values.mentor?.id;
      }

      if (giftcardType === EGiftcardCreateTypes.Players) {
        formBody.player = values.player?.id;
      }

      const body: IGiftcardCreateParams = {
        [giftcardType]: [formBody]
      };

      if (sponsor?.imageUrl && sponsor?.linkUrl) {
        body.sponsor = sponsor;
      }

      if (addGiftcardModel) {
        addGiftcardModel(body);
      }
    },
    [giftcardType, addGiftcardModel]
  );

  return (
    <Form className="mt-5" onFinish={onFinish} fields={fields} form={form}>
      <Row className="mb-5" align="middle">
        <span className="basic__text_title" title="Personal info">
          Giftcard type
        </span>
      </Row>
      <Select value={giftcardType} onSelect={setGiftcardType} disabled={isViewMode || disabled} className="mb-5">
        <Select.Option value={EGiftcardCreateTypes.Standards}>{EGiftcardCreateTypes.Standards}</Select.Option>
        <Select.Option value={EGiftcardCreateTypes.Mentors}>{EGiftcardCreateTypes.Mentors}</Select.Option>
        <Select.Option value={EGiftcardCreateTypes.Players}>{EGiftcardCreateTypes.Players}</Select.Option>
      </Select>
      <Row className="mb-5" align="middle">
        <span className="basic__text_title" title="Personal info">
          Giftcard info
        </span>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Description is required'
              }
            ]}
            name="description"
            label="Description"
          >
            <Input.TextArea name="description" placeholder="Description" disabled={disabled} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            rules={[
              {
                type: 'url',
                message: 'This field must be a valid url.'
              }
            ]}
            name={['sponsor', 'imageUrl']}
            label="Sponsor image url"
          >
            <Input name="sponsor.image" placeholder="Sponsor image url" disabled={disabled} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Amount is required'
              },
              {
                type: 'number',
                max: 32,
                message: 'Must be less or equal to 32'
              }
            ]}
            name="amount"
            label="Amount(months)"
          >
            <InputNumber name="amount" min={0} placeholder="Amount(months)" disabled={disabled} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            rules={[
              {
                type: 'url',
                message: 'This field must be a valid url.'
              }
            ]}
            name={['sponsor', 'linkUrl']}
            label="Sponsor url"
          >
            <Input name="sponsor.url" placeholder="Sponsor url" disabled={disabled} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[28, 10]}>
        <Col span={12}>
          <Form.Item rules={[]} name="expireAt" label="Giftcard expiration date">
            <DatePicker
              name="expireAt"
              format="DD-MM-YYYY"
              disabledDate={disabledDate}
              placeholder="Giftcard expiration date"
              disabled={disabled}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        {!isViewMode && (
          <Col span={12}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Count is required'
                },
                {
                  type: 'number',
                  max: 1000,
                  message: 'Must be less or equal to 1000'
                }
              ]}
              name="count"
              label="Giftcards count"
            >
              <InputNumber name="count" min={0} placeholder="Giftcards count" disabled={disabled} />
            </Form.Item>
          </Col>
        )}
      </Row>
      <Col span={12}>
        {giftcardType === EGiftcardCreateTypes.Mentors && (
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Mentor is required'
              }
            ]}
            name="mentor"
            label="Mentor"
          >
            <MentorSelector disabled={disabled} />
          </Form.Item>
        )}
        {giftcardType === EGiftcardCreateTypes.Players && (
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Player is required'
              }
            ]}
            name="player"
            label="Player"
          >
            <PlayerSelector disabled={disabled} />
          </Form.Item>
        )}
      </Col>
      <Row justify="space-between" align="middle">
        {!isViewMode && (
          <Button className="mr-8" htmlType="submit" type="primary" disabled={loading} loading={loading} title="Submit">
            Submit
          </Button>
        )}
        {isViewMode && (
          <Button type="primary" danger={!isBanned} onClick={onChangeStatus} disabled={loading} loading={loading}>
            {isBanned ? 'Activate' : 'Ban'}
          </Button>
        )}
        <Link to={ERoutesPrivate.Giftcards}>
          <Button disabled={loading} loading={loading} title="Cancel">
            Cancel
          </Button>
        </Link>
      </Row>
    </Form>
  );
};

export const GiftcardForm = GiftcardFormComponent;
