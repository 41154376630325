import React, { useEffect, useState, useCallback } from 'react';
import { Col, Row, Form, Button, Input, Popconfirm, Upload, Tabs, Divider } from 'antd';
import Select from 'antd/es/select';
import { useFormMapper } from '@axmit/antd4-helpers';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { communicationNotification, INotificationConnectedProps } from 'entities/Notification/Notification.communication';
import { IMailCreate } from 'entities/Notification/Notification.models';
import { UserRoleSelector } from 'entities/User/components/UserRoleSelector';
import { LanguageSelect } from 'entities/Language/components/Selectors/LanguageSelect';
import { notificationTransport } from 'entities/Notification/Notification.transport';

type AllProps = INotificationConnectedProps & IAuthConnectedProps;

enum EMailDataBase {
  JuniStat = 'JuniStat',
  Custom = 'Custom'
}
const MailPageComponent: React.FC<AllProps> = props => {
  const { sendNotificationMail, clearNotificationMail, notificationMail, authModel } = props;
  const { loading: modelLoading, params, errors } = notificationMail;
  const token = authModel.data?.access.token;
  const [tab, setTab] = useState<EMailDataBase>(EMailDataBase.JuniStat);
  const [form] = Form.useForm();
  const [queueCount, setQueueCount] = useState<number>(0);
  const [queueLoading, setQueueLoading] = useState<boolean>(false);
  const loading = queueLoading || modelLoading;

  const { fields } = useFormMapper(['title', 'emails', 'lang', 'template', 'roles'], null, params, errors);

  const getQueue = useCallback(async () => {
    const queue: any = await notificationTransport.getMailingQueue();
    setQueueCount(queue?.count || 0);
  }, []);

  const skipQueue = useCallback(async e => {
    e.stopPropagation();
    e.preventDefault();
    if (!loading) {
      setQueueLoading(true);
      await notificationTransport.skipMailingQueue();
      await getQueue();
      setQueueLoading(false);
    }
  }, []);
  const sendMail = (values: any) => {
    const { template, ...body } = values;

    const params: IMailCreate = {
      lang: 'en',
      ...body
    };

    if (template) {
      params.template = template.file?.response?.id;
      sendNotificationMail(params);
      form.resetFields();
    }
  };
  useEffect(() => {
    const interval = setInterval(getQueue, 5000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    (async () => await getQueue())();
    return () => {
      clearNotificationMail();
    };
  }, [clearNotificationMail]);

  useEffect(() => {
    form.resetFields();
    clearNotificationMail();
  }, [tab]);

  useEffect(() => {
    if (params === null && !loading) {
      form.resetFields();
    }
  }, [params, loading]);

  return (
    <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10 pb-10">
      <Col span={24}>
        <span className="basic__text_title d-block" title="Mailing">
          Mailing
        </span>
      </Col>
      <Col span={24}>
        <Row className="mt-5" align="middle">
          <Button
            className="mr-8"
            type="primary"
            disabled={loading || queueCount === 0}
            loading={loading}
            title="Clear queue"
            onClick={skipQueue}
          >
            Clear queue
          </Button>
          <span className="mr-5 fs-sm font-weight-4 d-block" title="Number of tasks">
            Number of tasks: {queueCount || '0'}
          </span>
          <span className="basic__text_label d-block" title="Each task contains up to 200 emails">
            (Each task contains up to 200 emails)
          </span>
        </Row>
      </Col>
      <Divider />

      <Form form={form} className="mb-5" onFinish={sendMail} fields={fields}>
        <Row justify="space-between" className="mb-5" gutter={[16, 16]}>
          <Col className="mb-5" span={11}>
            <Row gutter={[16, 8]}>
              <Col span={24}>
                <span className="basic__text_title d-block" title="Create Mailing">
                  Create Mailing
                </span>
              </Col>

              <Col span={24}>
                <Tabs destroyInactiveTabPane onChange={(tabVal: string) => setTab(tabVal as EMailDataBase)} activeKey={tab}>
                  <Tabs.TabPane key={EMailDataBase.JuniStat} tab={EMailDataBase.JuniStat} />
                  <Tabs.TabPane key={EMailDataBase.Custom} tab={EMailDataBase.Custom} />
                </Tabs>
              </Col>
              <Col span={12}>
                <Form.Item name="title" label="Title">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Template is required'
                    }
                  ]}
                  name="template"
                  label="Template"
                  valuePropName="file"
                >
                  <Upload
                    maxCount={1}
                    action="/api/files"
                    headers={{ authorization: `Bearer ${token}` }}
                    showUploadList={{ showRemoveIcon: false }}
                    name="file"
                    className="width-full"
                    multiple={false}
                  >
                    <Button block type="primary">
                      Upload model
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>

              {tab === EMailDataBase.JuniStat && (
                <>
                  <Col span={8}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Language is required'
                        }
                      ]}
                      name="lang"
                      label="Language"
                    >
                      <LanguageSelect />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="roles" label="Select role">
                      <UserRoleSelector mode="multiple" />
                    </Form.Item>
                  </Col>
                </>
              )}
              {tab === EMailDataBase.Custom && (
                <Col span={8}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Must be at least 1 email'
                      }
                    ]}
                    name="emails"
                    label="Emails"
                  >
                    <Select tokenSeparators={[' ', ',', ', ']} mode="tags" placeholder="Emails" style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              )}
              <Col span={24}>
                <Form.Item className="mb-0">
                  <Popconfirm title="Are you sure?" onConfirm={() => form.submit()} okText="Yes" cancelText="No">
                    <Button className="mr-8" htmlType="submit" type="primary" disabled={loading} loading={loading} title="Send">
                      Send
                    </Button>
                  </Popconfirm>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </CommonLayout>
  );
};

export const MailPage = communicationAuth.injector(communicationNotification.injector(MailPageComponent));
