import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { EProductTargetGroup } from 'entities/Product/Product.models';

interface IComponentProps {
  onChange?: (value?: EProductTargetGroup[]) => void;
  disabled?: boolean;
}

type AllProps = SelectProps<any> & IComponentProps;

export class ProductTargetGroupSelector extends React.PureComponent<AllProps> {
  render() {
    const { value: propValue, onChange, disabled = false } = this.props;

    return (
      <Select
        allowClear
        mode="multiple"
        className="width-full"
        placeholder="Select target"
        value={propValue}
        defaultActiveFirstOption={false}
        showArrow
        onChange={(value: EProductTargetGroup[]) => onChange && onChange(value)}
        disabled={disabled}
      >
        <Select.Option key={EProductTargetGroup.Academy} value={EProductTargetGroup.Academy} label={EProductTargetGroup.Academy}>
          {EProductTargetGroup.Academy}
        </Select.Option>
        <Select.Option key={EProductTargetGroup.Mentor} value={EProductTargetGroup.Mentor} label={EProductTargetGroup.Mentor}>
          {EProductTargetGroup.Mentor}
        </Select.Option>
        <Select.Option key={EProductTargetGroup.Player} value={EProductTargetGroup.Player} label={EProductTargetGroup.Player}>
          {EProductTargetGroup.Player}
        </Select.Option>
        <Select.Option key={EProductTargetGroup.Trainer} value={EProductTargetGroup.Trainer} label={EProductTargetGroup.Trainer}>
          {EProductTargetGroup.Trainer}
        </Select.Option>
      </Select>
    );
  }
}
