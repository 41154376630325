import React from 'react';
import { Col, Row, Statistic } from 'antd';
import { normalize } from 'common/helpers/normalize.helper';
import { communicationAdmin, IAdminConnectedProps } from 'entities/Admin/Admin.communication';

type AllProps = IAdminConnectedProps;

const AdminPanelStatisticComponent = ({ adminCollection }: AllProps) => {
  const { data } = adminCollection;
  const { meta } = data ?? {};
  const { count = 0 } = meta ?? {};

  return (
    <Row gutter={[32, 16]}>
      <Col>
        <Statistic title="User count" value={normalize(count)} />
      </Col>
    </Row>
  );
};

export const AdminPanelStatistic = communicationAdmin.injector(AdminPanelStatisticComponent);
