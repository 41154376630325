import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { ETariffStatus } from 'entities/Tariff/Tariff.models';

interface IComponentProps {
  onChange?: (value?: ETariffStatus[]) => void;
  disabled?: boolean;
}

type AllProps = SelectProps<any> & IComponentProps;

export class TariffStatusSelector extends React.PureComponent<AllProps> {
  render() {
    const { value: propValue, onChange, disabled = false } = this.props;

    return (
      <Select
        allowClear
        mode="multiple"
        className="width-full"
        placeholder="Select statuses"
        value={propValue}
        defaultActiveFirstOption={false}
        showArrow
        onChange={(value: ETariffStatus[]) => onChange && onChange(value)}
        disabled={disabled}
      >
        <Select.Option key={ETariffStatus.Active} value={ETariffStatus.Active} label={ETariffStatus.Active}>
          {ETariffStatus.Active}
        </Select.Option>
        <Select.Option key={ETariffStatus.Archived} value={ETariffStatus.Archived} label={ETariffStatus.Archived}>
          {ETariffStatus.Archived}
        </Select.Option>
      </Select>
    );
  }
}
