import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Row, Input, Popconfirm, InputNumber, Switch, Avatar } from 'antd';
import { useFormMapper } from '@axmit/antd4-helpers';
import { CameraOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { StoreBranch } from '@axmit/redux-communications';
import { ERoutesPrivate } from 'common/models/routesModel';
import { ImageUpload } from 'common/components/ImageUpload/ImageUpload';
import { VideoUpload } from 'common/components/Video/VideoUpload';
import { useFilesLoading } from 'common/components/UseFilesLoading';
import { VideoPrivate } from 'common/components/Video/VideoPrivate';
import { getImageLink } from 'common/helpers/loader.helper';
import { ISkinCreateParams, ISkinModel, ISkinUpdateParams } from 'entities/Skin/Skin.models';
import { EImageSize } from 'entities/Image/Image.models';

interface IComponentProps {
  createSkin?: (params: ISkinCreateParams) => void;
  updateSkin?: (params: ISkinUpdateParams) => void;
  skinModel: StoreBranch<ISkinModel>;
  skinId?: string;
}

type AllProps = IComponentProps;

const SkinFormComponent: React.FC<AllProps> = props => {
  const { skinModel, updateSkin, skinId, createSkin } = props;
  const [avatarSrc, setAvatarSrc] = useState('');
  const [filesLoading, onChangeFileLoading] = useFilesLoading(['video']);
  const { errors, loading: skinLoading, data, params } = skinModel;
  const loading = skinLoading || filesLoading;
  const submitButtonText = 'Save';
  const videoId = data?.video?.id;
  const imageId = data?.image?.id;

  const [form] = Form.useForm();

  useEffect(() => {
    if (imageId) {
      getImageLink(imageId, EImageSize.X96).then(url => setAvatarSrc(url));
    } else {
      setAvatarSrc('');
    }
  }, [imageId]);

  const memoizedValue = useMemo(
    () => ({
      ...data,
      image: imageId
    }),
    [data]
  );
  const { fields } = useFormMapper(['name', 'video', 'image', 'cost', 'isEnable'], memoizedValue, params, errors);

  const editSkin = (values: any) => {
    if (skinId && updateSkin) {
      const params: ISkinUpdateParams = {
        id: skinId,
        ...values
      };
      updateSkin(params);
    } else {
      if (createSkin) {
        const params: ISkinCreateParams = {
          ...values
        };
        createSkin(params);
      }
    }
  };

  return (
    <Row gutter={16}>
      <Col span={12}>
        <Form form={form} className="mt-5" onFinish={editSkin} fields={fields}>
          <Row gutter={8} className="mb-5">
            <Col span={24} className="mb-5">
              <span className="basic__text_title" title="Skin information">
                Skin information
              </span>
            </Col>
            <Col span={12}>
              <Form.Item name="name" label="Name">
                <Input disabled={!!skinId} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="cost" label="Cost">
                <InputNumber min={0} placeholder="Cost" disabled={!!skinId} />
              </Form.Item>
            </Col>
            {skinId && (
              <Col span={24}>
                <Form.Item valuePropName="checked" name="isEnable" label="Is enable">
                  <Switch checkedChildren="Yes" unCheckedChildren="No" />
                </Form.Item>
              </Col>
            )}
            <Col span={24}>
              <Form.Item name="image" label="Image">
                {skinId ? (
                  <Avatar shape="square" size={100} className="avatar-editor__avatar" icon={<CameraOutlined />} src={avatarSrc} />
                ) : (
                  <ImageUpload aspect={0} circularCrop={false} />
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="video" label="Video">
                {!skinId ? (
                  <VideoUpload onLoading={(isLoading: boolean) => onChangeFileLoading('video', isLoading)} />
                ) : videoId ? (
                  <VideoPrivate width={200} height={200} videoId={videoId} previewClass="radius-default" />
                ) : (
                  <div className="mt-4">No video</div>
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row justify="space-between" align="middle">
            <Col span={16}>
              <Form.Item className="mb-0">
                <Popconfirm title="Are you sure?" onConfirm={() => form.submit()} okText="Yes" cancelText="No">
                  <Button
                    className="mr-8"
                    htmlType="submit"
                    type="primary"
                    disabled={loading}
                    loading={loading}
                    title={submitButtonText}
                  >
                    {submitButtonText}
                  </Button>
                </Popconfirm>

                <Link to={ERoutesPrivate.Skins}>
                  <Button disabled={loading} loading={loading} title="Cancel">
                    Cancel
                  </Button>
                </Link>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export const SkinForm = SkinFormComponent;
