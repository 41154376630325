import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { EProductStatus } from 'entities/Product/Product.models';

interface IComponentProps {
  onChange?: (value?: EProductStatus) => void;
  disabled?: boolean;
}

type AllProps = SelectProps<any> & IComponentProps;

export class ProductStatusSelector extends React.PureComponent<AllProps> {
  render() {
    const { value: propValue, onChange, disabled = false } = this.props;

    return (
      <Select
        allowClear
        className="width-full"
        placeholder="Select status"
        value={propValue}
        defaultActiveFirstOption={false}
        showArrow
        onChange={(value: EProductStatus) => onChange && onChange(value)}
        disabled={disabled}
      >
        <Select.Option key={EProductStatus.Active} value={EProductStatus.Active} label={EProductStatus.Active}>
          {EProductStatus.Active}
        </Select.Option>
        <Select.Option key={EProductStatus.Canceled} value={EProductStatus.Canceled} label={EProductStatus.Canceled}>
          {EProductStatus.Canceled}
        </Select.Option>
      </Select>
    );
  }
}
