import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Row } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { ISkinCollectionFilter, tableSkinConfig } from 'entities/Skin/Skin.models';
import { SkinTable } from 'entities/Skin/components/SkinTable';

type AllProps = RouteComponentProps;

interface IComponentState {
  filter: ISkinCollectionFilter;
}

class SkinTablePageComponent extends React.PureComponent<AllProps, IComponentState> {
  public debounceSearch: any;
  state: IComponentState = {
    filter: {
      pageIndex: 1
    }
  };
  constructor(props: AllProps) {
    super(props);
    this.state.filter = { ...this.state.filter, ...queryToObject<ISkinCollectionFilter>(this.state.filter) };
  }

  render() {
    const { filter } = this.state;

    return (
      <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10">
        <Row className="mb-10 width-full" justify="space-between" align="middle">
          <Col span={4}>
            <Link to={ERoutesPrivate.SkinsCreate}>
              <Button block type="primary" title="Add skin">
                Add skin
              </Button>
            </Link>
          </Col>
        </Row>
        <SkinTable config={tableSkinConfig} filter={filter} />
      </CommonLayout>
    );
  }

  setFilter = (partialFilter: Partial<ISkinCollectionFilter>) => {
    const oldFilter = this.state.filter;
    const filter = { ...oldFilter, ...partialFilter };

    this.props.history.replace({ search: objectToQuery(filter) });
    this.setState({ filter });
  };
}

export const SkinTablePage = withRouter(SkinTablePageComponent);
