import React from 'react';
import { Breadcrumb, Col, Skeleton } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { communicationAdmin, IAdminConnectedProps } from 'entities/Admin/Admin.communication';
import { AdminForm } from 'entities/Admin/components/Form/AdminForm';

interface IParams {
  id?: string;
}

type AllProps = IAdminConnectedProps & RouteComponentProps<IParams>;

class AdminPageComponent extends React.PureComponent<AllProps> {
  componentDidMount() {
    const { getAdminModel, match } = this.props;
    const { params } = match;
    const { id } = params;

    if (id) {
      getAdminModel(id);
    }
  }

  componentWillUnmount() {
    const { clearAdminModel } = this.props;

    clearAdminModel();
  }

  render() {
    const { adminModel, match, addAdminModel, updateAdminModel, deleteAdminModel } = this.props;
    const { params } = match;
    const { id } = params;
    const { data: adminModelData, loading } = adminModel;
    const adminName =
      adminModelData?.firstName && adminModelData?.lastName
        ? `${adminModelData?.firstName} ${adminModelData?.lastName}`
        : 'Unknown name';
    const isEdit = !!id;

    return (
      <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10 pb-10">
        <Col span={12}>
          <Breadcrumb className="fs-sm font-weight-5 font-family__default mb-8">
            <Breadcrumb.Item href={ERoutesPrivate.UserList}>User list</Breadcrumb.Item>
            <Breadcrumb.Item>
              {isEdit ? !loading ? adminName : <Skeleton.Input active style={{ width: '200px' }} size="small" /> : 'Add user'}
            </Breadcrumb.Item>
          </Breadcrumb>
          {!loading || !isEdit ? (
            <AdminForm
              adminModel={adminModel}
              createAdmin={addAdminModel}
              updateAdmin={updateAdminModel}
              deleteAdmin={deleteAdminModel}
              adminId={id}
            />
          ) : null}
        </Col>
      </CommonLayout>
    );
  }
}

export const AdminPage = communicationAdmin.injector(withRouter(AdminPageComponent));
