import { ColumnsType } from 'antd/lib/table';
import { RouteComponentProps, withRouter } from 'react-router';
import { TableCommon } from 'common/components/TableCommon';
import { IBaseFilterModel } from 'common/models/requestModels';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { IInfluencerCollection, IInfluencerCollectionFilter, IInfluencerModel } from 'entities/Influencer/Influencer.models';
import { communicationInfluencer, IInfluencerConnectedProps } from 'entities/Influencer/Influencer.communication';

interface IComponentProps {
  config: ColumnsType<any>;
}

type AllProps = IComponentProps & IInfluencerConnectedProps & RouteComponentProps;

class InfluencerTableComponent extends TableCommon<
  IInfluencerCollection,
  IInfluencerModel,
  ColumnsType<any>,
  AllProps,
  IInfluencerCollectionFilter
> {
  loadCollection = (params: IBaseFilterModel) => {
    const { getInfluencerCollection, filter } = this.props;
    const filterParams: IInfluencerCollectionFilter = {
      ...params
    };

    if (filter?.tags) {
      filterParams.tags = filter.tags;
    }

    if (filter?.from) {
      filterParams.from = filter.from;
    }

    if (filter?.to) {
      filterParams.to = filter.to;
    }

    getInfluencerCollection(filterParams);
  };

  getCollection = () => {
    const { influencerCollection } = this.props;
    return influencerCollection;
  };

  addQuery = (pageIndex: number) => {
    const searchPath = queryToObject<IInfluencerCollectionFilter>({
      pageIndex: 1,
      tags: undefined,
      from: undefined,
      to: undefined
    });
    const search = objectToQuery({ ...searchPath, pageIndex });

    return this.props.history.replace({ search });
  };

  onRowClick = () => {};

  clearCollection = () => {
    return this.props.clearInfluencerCollection();
  };
}

export const InfluencerTable = communicationInfluencer.injector(withRouter(InfluencerTableComponent));
