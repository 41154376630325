import axios, { AxiosStatic } from 'axios';
import { BaseHttpTransport } from '@axmit/transport';
import { objectToQuery } from 'common/helpers/filters.helper';
import {
  EStatisticAcademyType,
  IStatisticCollection,
  IStatisticCollectionFilter,
  IStatisticModel,
  IStatisticTestCollection,
  IStatisticTestCollectionFilter
} from 'entities/Statistic/Statistic.models';
import { IDate } from 'entities/Academy/components/Form/AcademyForm';

const basePath = '/statistics';

class StatisticTransport extends BaseHttpTransport<
  AxiosStatic,
  IStatisticModel,
  IStatisticCollection,
  void,
  void,
  IStatisticCollectionFilter
> {
  public getTestStatistic = (params: IStatisticTestCollectionFilter): Promise<IStatisticTestCollection> =>
    axios.get(`${basePath}/player-tests${objectToQuery(params)}`);
}

export const getStatisticFileUrl = (params: {
  id: string;
  statisticType: EStatisticAcademyType;
  token?: string;
  date?: IDate;
  isBilling?: boolean;
}) => {
  return `/api${basePath}/${params.statisticType}/${params.id}${params?.isBilling ? '/billing' : ''}/csv${objectToQuery({
    token: params?.token,
    from: params?.date?.from,
    to: params?.date?.to
  })}`;
};

export const statisticTransport = new StatisticTransport(basePath, axios);
