import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { EPlayerStatuses, EPlayerStatusText } from 'entities/Player/Player.models';

interface IComponentProps {
  onChange?: (value?: EPlayerStatuses[]) => void;
  disabled?: boolean;
}

type AllProps = SelectProps<any> & IComponentProps;

export class PlayerStatusSelector extends React.PureComponent<AllProps> {
  render() {
    const { value: propValue, onChange, disabled = false } = this.props;

    return (
      <Select
        allowClear
        mode="multiple"
        className="width-full"
        placeholder="Select statuses"
        value={propValue}
        defaultActiveFirstOption={false}
        showArrow
        onChange={(value: EPlayerStatuses[]) => onChange && onChange(value)}
        disabled={disabled}
      >
        <Select.Option key={EPlayerStatuses.Active} value={EPlayerStatuses.Active} label={EPlayerStatusText.Active}>
          {EPlayerStatusText.Active}
        </Select.Option>
        <Select.Option key={EPlayerStatuses.Banned} value={EPlayerStatuses.Banned} label={EPlayerStatusText.Banned}>
          {EPlayerStatusText.Banned}
        </Select.Option>
      </Select>
    );
  }
}
