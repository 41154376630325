import React from 'react';
import { Col, Row, Spin, Statistic } from 'antd';
import { normalize } from 'common/helpers/normalize.helper';
import { communicationAcademy, IAcademyConnectedProps } from 'entities/Academy/Academy.communication';

type AllProps = IAcademyConnectedProps;

const AcademiesStatisticComponent: React.FC<AllProps> = props => {
  const { academyCollection } = props;
  const { data, loading } = academyCollection;
  const count = data?.meta?.count;

  return (
    <Row gutter={[32, 16]}>
      <Col>
        <Spin spinning={loading}>
          <Statistic title="Academies count" value={normalize(count)} />
        </Spin>
      </Col>
    </Row>
  );
};

export const AcademiesStatistic = communicationAcademy.injector(AcademiesStatisticComponent);
