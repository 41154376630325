import React, { ChangeEvent } from 'react';
import { Button, Col, Input, Row } from 'antd';
import debounce from 'lodash.debounce';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { IUserModel } from 'entities/User/User.models';
import { PlayerSelector } from 'entities/Player/components/PlayerSelector';
import { MentorSelector } from 'entities/User/components/MentorSelector';
import { IPlayerModel } from 'entities/Player/Player.models';
import { GiftcardTable } from 'entities/Giftcard/components/GiftcardTable';
import {
  EGiftcardStatuses,
  EGiftcardTypes,
  IGiftcardCollectionFilter,
  tableGiftcardConfig
} from 'entities/Giftcard/Giftcard.models';
import { GiftcardStatusSelector } from 'entities/Giftcard/components/Selector/GiftcardStatusSelector';
import { GiftcardTypeSelector } from 'entities/Giftcard/components/Selector/GiftcardTypeSelector';
import { communicationGiftcard, IGiftcardConnectedProps } from 'entities/Giftcard/Giftcard.communication';
import { getGifcardFileUrl } from 'entities/Giftcard/Giftcard.transport';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';

type AllProps = RouteComponentProps & IGiftcardConnectedProps & IAuthConnectedProps;

interface IComponentState {
  filter: IGiftcardCollectionFilter;
}

class GiftcardPageComponent extends React.PureComponent<AllProps, IComponentState> {
  public debounceNumberSearch: any;
  public debounceDescriptionSearch: any;
  state: IComponentState = {
    filter: {
      pageIndex: 1,
      number: undefined,
      description: undefined,
      statuses: undefined,
      type: undefined,
      playerFilter: undefined,
      mentorFilter: undefined
    }
  };
  constructor(props: AllProps) {
    super(props);
    this.state.filter = { ...this.state.filter, ...queryToObject<IGiftcardCollectionFilter>(this.state.filter) };
    this.debounceNumberSearch = debounce(this.autocompleteNumberSearch, 300);
    this.debounceDescriptionSearch = debounce(this.autocompleteDescriptionSearch, 300);
  }

  render() {
    const { giftcardCollection, authModel } = this.props;
    const { params } = giftcardCollection ?? {};
    const { data } = authModel ?? {};
    const { access } = data ?? {};
    const { token } = access ?? {};
    const { filter } = this.state;
    const { number, statuses, type, playerFilter, mentorFilter, description } = filter;

    return (
      <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10">
        <Row className="mb-10 width-full" justify="space-between" align="middle" gutter={8}>
          <Col span={19}>
            <Row justify="space-between" align="middle" gutter={8}>
              <Col span={4}>
                <Input.Search
                  placeholder="Search by number"
                  defaultValue={number}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => this.debounceNumberSearch(e.target.value)}
                />
              </Col>
              <Col span={4}>
                <Input.Search
                  placeholder="Search by description"
                  defaultValue={description}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => this.debounceDescriptionSearch(e.target.value)}
                />
              </Col>
              <Col span={4}>
                <GiftcardStatusSelector onChange={this.onChangeStatus} value={statuses} placeholder="Search by status" />
              </Col>
              <Col span={4}>
                <GiftcardTypeSelector onChange={this.onChangeType} value={type} placeholder="Search by type" />
              </Col>
              <Col span={4}>
                <PlayerSelector onChange={this.onChangePlayer} value={playerFilter} placeholder="Search by player" />
              </Col>
              <Col span={4}>
                <MentorSelector onChange={this.onChangeMentor} value={mentorFilter} placeholder="Search by mentor" />
              </Col>
            </Row>
          </Col>
          <Col span={2}>
            <Link to={ERoutesPrivate.GiftcardCreate}>
              <Button block type="primary" title="Add giftcard">
                Add giftcard
              </Button>
            </Link>
          </Col>
          <Col span={3}>
            <Link target="_blank" to={getGifcardFileUrl(params, token)}>
              <Button block type="primary" title="Add giftcard">
                Download giftcard
              </Button>
            </Link>
          </Col>
        </Row>
        <GiftcardTable config={tableGiftcardConfig} filter={filter} />
      </CommonLayout>
    );
  }

  setFilter = (partialFilter: Partial<IGiftcardCollectionFilter>) => {
    const oldFilter = this.state.filter;
    const filter = { ...oldFilter, ...partialFilter };

    this.props.history.replace({ search: objectToQuery(filter) });
    this.setState({ filter });
  };

  onChangeStatus = (statuses?: EGiftcardStatuses[]) => {
    this.setFilter({ statuses });
  };

  onChangeType = (type?: EGiftcardTypes) => {
    this.setFilter({ type });
  };

  onChangePlayer = (playerModel?: IPlayerModel) => {
    const playerFilter = playerModel
      ? { id: playerModel.id, firstName: playerModel.firstName, lastName: playerModel.lastName }
      : undefined;

    this.setFilter({ playerFilter });
  };

  onChangeMentor = (mentorModel?: IUserModel) => {
    const mentorFilter = mentorModel
      ? { id: mentorModel.id, firstName: mentorModel.firstName, lastName: mentorModel.lastName }
      : undefined;

    this.setFilter({ mentorFilter });
  };

  autocompleteNumberSearch = (text: string) => {
    const number = text === '' ? undefined : text;
    this.setFilter({ number });
  };

  autocompleteDescriptionSearch = (text: string) => {
    const description = text === '' ? undefined : text;
    this.setFilter({ description });
  };
}

export const GiftcardTablePage = withRouter(communicationGiftcard.injector(communicationAuth.injector(GiftcardPageComponent)));
