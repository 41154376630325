import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Player, ControlBar } from 'video-react';
import YouTubePlayer from 'react-youtube';
import Row from 'antd/es/row';
import Skeleton from 'antd/es/skeleton';
import message from 'antd/es/message';
import { DownloadOutlined, CopyOutlined } from '@ant-design/icons';
import { getHost } from 'common/helpers/axios.helper';
import { getVideoLink } from 'common/helpers/loader.helper';

interface IModalProps {
  videoId?: string;
  youtubeId?: string;
}

type AllProps = IModalProps;

enum EAutoPLay {
  'Yes' = 1
}

const playerOptions = {
  width: '100%',
  playerVars: {
    autoplay: EAutoPLay.Yes // Хак, на просто цифру ругается ts
  }
};

export const VideoPrivateModalBody: React.FC<AllProps> = props => {
  const { videoId, youtubeId } = props;
  const [videoUrl, setVideoUrl] = useState<string>('');
  const [useFallbackImg, setUseFallbackImg] = useState<boolean>(false);
  const videoPlayerRef = useRef(null);
  const host = getHost();

  const enableFallback = useCallback(() => {
    setUseFallbackImg(true);
  }, []);

  const onVideoPlayerStateChange = useCallback(
    state => {
      const { error } = state;
      if (error) {
        console.error('Error while playing video', error);
        message.error('An error occurred during playing video');
        enableFallback();
      }
    },
    [enableFallback]
  );

  useEffect(() => {
    if (videoId && !youtubeId) {
      const videoPlayer = videoPlayerRef.current;
      if (videoPlayer) {
        // @ts-ignore
        videoPlayer.subscribeToStateChange(onVideoPlayerStateChange);
      }
    }
  }, [onVideoPlayerStateChange, videoId, youtubeId]);

  useEffect(() => {
    const getVideoUrl = async (id: string) => {
      const data = await getVideoLink(id);
      setVideoUrl(data);
    };

    if (videoId && !youtubeId) {
      getVideoUrl(videoId);
    }
  }, [videoId, youtubeId]);

  const onCopyYoutubeLink = () => {
    if (navigator.clipboard?.writeText) {
      navigator.clipboard.writeText(`https://www.youtube.com/watch?v=${youtubeId}`).then(
        function() {
          message.success('Link copied!');
          console.log('Async: Copying to clipboard was successful!');
        },
        function(err) {
          message.success('Error while copieng link!');
          console.error('Async: Could not copy text: ', err);
        }
      );
    }
  };

  return (
    <div className="video-private__modal__body">
      {youtubeId ? (
        <>
          <YouTubePlayer className="youtube-player" videoId={youtubeId} opts={playerOptions} />
          <Row onClick={onCopyYoutubeLink} className="cursor-pointer mt-3" align="middle">
            <CopyOutlined />
            Copy video link
          </Row>
        </>
      ) : useFallbackImg ? (
        <Skeleton.Image />
      ) : (
        <>
          <Player autoPlay ref={videoPlayerRef} fluid={false} width={playerOptions.width}>
            <source src={videoUrl} type="video/mp4" />
            <ControlBar autoHide={false} />
          </Player>
          <a href={`${host}${videoUrl}`} target="_blank" rel="noopener noreferrer" className="d-block mt-3">
            <DownloadOutlined />
            Download
          </a>
        </>
      )}
    </div>
  );
};
