import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { RouteComponentProps, withRouter } from 'react-router';
import { Header } from 'common/components/Header/Header';
import { LayoutBasic } from 'common/components/Layouts/BasicLayout';
import { ERoutesCommon, ERoutesPrivate, ERoutesPublic } from 'common/models/routesModel';
import { PermissionLayout } from 'common/components/Layouts/PermissionLayout';
import { RootPrivatePage } from 'common/components/Pages/RootPrivatePage';
import { RootPublicPage } from 'common/components/Pages/RootPublicPage';
import { RoleCheckLayout } from 'common/components/Layouts/RoleCheckLayout';
import { AdminPage } from 'app/pages/AdminPage';
import { AdminTablePage } from 'app/pages/AdminTablePage';
import { TestPage } from 'app/pages/TestPage';
import { TestTablePage } from 'app/pages/TestTablePage';
import { TestResultStatisticPage } from 'app/pages/TestResultStatisticPage';
import { PlayerTestPage } from 'app/pages/PlayerTestPage';
import { PlayerTestTablePage } from 'app/pages/PlayerTestTablePage';
import { AcademyTablePage } from 'app/pages/AcademyTablePage';
import { AcademyPage } from 'app/pages/AcademyPage';
import { GiftcardPage } from 'app/pages/GiftcardPage';
import { ProductTablePage } from 'app/pages/ProductTablePage';
import { ProductPage } from 'app/pages/ProductPage';
import { GiftcardTablePage } from 'app/pages/GiftcardTablePage';
import { TariffPage } from 'app/pages/TariffPage';
import { TariffTablePage } from 'app/pages/TariffTablePage';
import { PlayerTablePage } from 'app/pages/PlayersTablePage';
import { PlayerPage } from 'app/pages/PlayerPage';
import { PaymentPage } from 'app/pages/PaymentTablePage';
import { NotificationTemplatePage } from 'app/pages/NotificationTemplatePage';
import { NotificationTemplateTablePage } from 'app/pages/NotificationTemplateTablePage';
import { SettingPage } from 'app/pages/SettingPage';
import { NotificationPage } from 'app/pages/NotificationPage';
import { NotificationSmsPage } from 'app/pages/NotificationSmsPage';
import { NotificationEmailPage } from 'app/pages/NotificationEmailPage';
import { MailPage } from 'app/pages/MailPage';
import { LanguagePage } from 'app/pages/LanguagePage';
import { FeedTablePage } from 'app/pages/FeedTablePage';
import { FeedPage } from 'app/pages/FeedPage';
import { StatisticPage } from 'app/pages/StatisticTablePage';
import { TestLeaderboardPage } from 'app/pages/TestLeaderboardTablePage';
import { WalletTransactionTablePage } from 'app/pages/WalletTransactionTablePage';
import { WalletDirectTransactionModal } from 'app/pages/WalletDirectTransactionModal';
import { InfluecnerTablePage } from 'app/pages/InfluencerTablePage';
import { SkinPage } from 'app/pages/SkinPage';
import { SkinTablePage } from 'app/pages/SkinTablePage';
import { StoryPage } from 'app/pages/StoryPage';
import { StoryTablePage } from 'app/pages/StoryTablePage';
import { PasswordChangePage } from 'entities/Auth/components/PasswordChangePage';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { LoginPage } from 'entities/Auth/components/LoginPage';
import { RestorePage } from 'entities/Auth/components/RestorePage';

type AllProps = IAuthConnectedProps & RouteComponentProps;

class AppComponent extends React.Component<AllProps> {
  constructor(props: AllProps) {
    super(props);
    this.props.initAuthModel();
  }

  render() {
    return (
      <>
        <Header />
        <LayoutBasic id="basicLayout">
          <WalletDirectTransactionModal />
          <PermissionLayout
            publicRoutes={
              <Switch>
                <Route path={ERoutesPublic.Login} component={LoginPage} />
                <Route path={ERoutesPublic.Restore} component={RestorePage} />
                <Route path={ERoutesPublic.PasswordRestore} component={PasswordChangePage} />
                <Route path={`${ERoutesCommon.Root}*`} component={RootPublicPage} />
              </Switch>
            }
            privateRoutes={
              <RoleCheckLayout
                adminRoutes={
                  <Switch>
                    <Route path={ERoutesPrivate.Settings} component={SettingPage} />
                    <Route path={ERoutesPrivate.Mail} component={MailPage} />
                    <Route path={ERoutesPrivate.AdminCreate} component={AdminPage} />
                    <Route path={`${ERoutesPrivate.UserList}/:id`} component={AdminPage} />
                    <Route path={ERoutesPrivate.UserList} component={AdminTablePage} />

                    <Route path={`${ERoutesPrivate.TestLeaderboard}/:id`} component={TestLeaderboardPage} />

                    <Route path={`${ERoutesPrivate.TestResults}/:id`} component={PlayerTestPage} />
                    <Route path={ERoutesPrivate.TestResults} component={PlayerTestTablePage} />

                    <Route path={`${ERoutesPrivate.Academy}/:id/:tab?`} component={AcademyPage} />
                    <Route path={ERoutesPrivate.Academy} component={AcademyTablePage} />

                    <Route path={ERoutesPrivate.SkinsCreate} component={SkinPage} />
                    <Route path={`${ERoutesPrivate.Skins}/:id`} component={SkinPage} />
                    <Route path={ERoutesPrivate.Skins} component={SkinTablePage} />

                    <Route path={ERoutesPrivate.TestCreate} component={TestPage} />
                    <Route path={`${ERoutesPrivate.Test}/:id`} component={TestPage} />
                    <Route path={ERoutesPrivate.Test} component={TestTablePage} />

                    <Route path={ERoutesPrivate.FeedCreate} component={FeedPage} />
                    <Route path={`${ERoutesPrivate.Feed}/:id`} component={FeedPage} />
                    <Route path={ERoutesPrivate.Feed} component={FeedTablePage} />

                    <Route path={ERoutesPrivate.ProductCreate} component={ProductPage} />
                    <Route path={`${ERoutesPrivate.Product}/:id`} component={ProductPage} />
                    <Route path={ERoutesPrivate.Product} component={ProductTablePage} />

                    <Route path={ERoutesPrivate.StoryCreate} component={StoryPage} />
                    <Route path={`${ERoutesPrivate.Story}/:id`} component={StoryPage} />
                    <Route path={ERoutesPrivate.Story} component={StoryTablePage} />

                    <Route path={ERoutesPrivate.TestResultStatistic} component={TestResultStatisticPage} />

                    <Route path={ERoutesPrivate.GiftcardCreate} component={GiftcardPage} />
                    <Route path={`${ERoutesPrivate.Giftcards}/:id`} component={GiftcardPage} />
                    <Route path={ERoutesPrivate.Giftcards} component={GiftcardTablePage} />

                    <Route path={ERoutesPrivate.TariffCreate} component={TariffPage} />
                    <Route path={`${ERoutesPrivate.Tariffs}/:id`} component={TariffPage} />
                    <Route path={ERoutesPrivate.Tariffs} component={TariffTablePage} />

                    <Route path={ERoutesPrivate.WalletTransactions} component={WalletTransactionTablePage} />

                    <Route path={`${ERoutesPrivate.Players}/:id`} component={PlayerPage} />
                    <Route path={ERoutesPrivate.Players} component={PlayerTablePage} />

                    <Route path={ERoutesPrivate.Payments} component={PaymentPage} />
                    <Route path={ERoutesPrivate.Statistic} component={StatisticPage} />

                    <Route path={ERoutesPrivate.NotificationSend} component={NotificationPage} />
                    <Route path={ERoutesPrivate.NotificationSmsSend} component={NotificationSmsPage} />
                    <Route path={ERoutesPrivate.NotificationEmailSend} component={NotificationEmailPage} />

                    <Route path={`${ERoutesPrivate.NotificationTemplates}/:id`} component={NotificationTemplatePage} />
                    <Route path={ERoutesPrivate.NotificationTemplates} component={NotificationTemplateTablePage} />

                    <Route path={ERoutesPrivate.Languages} component={LanguagePage} />

                    <Route path={ERoutesPrivate.Influencers} component={InfluecnerTablePage} />

                    <Route path={`${ERoutesCommon.Root}*`} component={RootPrivatePage} />
                  </Switch>
                }
                taggerRoutes={
                  <Switch>
                    <Route path={ERoutesPrivate.Settings} component={SettingPage} />
                    <Route path={ERoutesPrivate.Mail} component={MailPage} />
                    <Route path={`${ERoutesPrivate.TestResults}/:id`} component={PlayerTestPage} />
                    <Route path={ERoutesPrivate.TestResults} component={PlayerTestTablePage} />
                    <Route path={`${ERoutesPrivate.Academy}/:id/:tab?`} component={AcademyPage} />
                    <Route path={ERoutesPrivate.Academy} component={AcademyTablePage} />
                    <Route path={`${ERoutesPrivate.TestLeaderboard}/:id`} component={TestLeaderboardPage} />
                    <Route path={ERoutesPrivate.FeedCreate} component={FeedPage} />
                    <Route path={`${ERoutesPrivate.Feed}/:id`} component={FeedPage} />
                    <Route path={ERoutesPrivate.Feed} component={FeedTablePage} />
                    <Route path={ERoutesPrivate.TestResultStatistic} component={TestResultStatisticPage} />
                    <Route path={`${ERoutesPrivate.Players}/:id`} component={PlayerPage} />
                    <Route path={ERoutesPrivate.Players} component={PlayerTablePage} />
                    <Route path={ERoutesPrivate.Payments} component={PaymentPage} />

                    <Route path={ERoutesPrivate.TestCreate} component={TestPage} />
                    <Route path={`${ERoutesPrivate.Test}/:id`} component={TestPage} />
                    <Route path={ERoutesPrivate.Test} component={TestTablePage} />

                    <Route path={`${ERoutesCommon.Root}*`} component={RootPrivatePage} />
                  </Switch>
                }
              />
            }
          />
        </LayoutBasic>
      </>
    );
  }
}

export const App = communicationAuth.injector(withRouter(AppComponent));
