import React, { useMemo } from 'react';
import Row from 'antd/es/row';
import Typography from 'antd/es/typography';
import List from 'antd/es/list';
import Col from 'antd/es/col';
import { VideoPrivate } from 'common/components/Video/VideoPrivate';
import { IPlayerVideoModel } from 'entities/Player/Player.models';

interface IComponentProps {
  item: IPlayerVideoModel;
}

type AllProps = IComponentProps;

const previewWidth = 136;
const previewHeight = 116;

export const PlayerVideosListItem: React.FC<AllProps> = props => {
  const { item } = props;
  const { id, title, description, preview, video, youtubeId } = item;

  const videoPreview = useMemo(() => {
    if (youtubeId) {
      return preview;
    }
    return video?.image?.id;
  }, [youtubeId, preview, video]);

  return (
    <List.Item key={id} className="height-full pb-3 pt-0 border-none">
      <Row className="players__videos-tab__element flex-noWrap" wrap={false}>
        <div className="players__videos-tab__element__img-container">
          <VideoPrivate
            preview={videoPreview}
            width={previewWidth}
            height={previewHeight}
            videoId={video?.id}
            youtubeId={youtubeId}
            previewClass="radius-default"
          />
        </div>
        <div className="players__videos-tab__element__data ml-5 pt-2 width-full">
          <Row justify="space-between" wrap={false} className="mb-2">
            <Col flex={1}>
              <Typography.Title level={5} className="mb-0 mr-2 width-full word-break-all">
                {title}
              </Typography.Title>
            </Col>
          </Row>
          <Typography.Paragraph className="mb-0 color-secondary">{description}</Typography.Paragraph>
        </div>
      </Row>
    </List.Item>
  );
};
