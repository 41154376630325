import { EActionsTypes, APIProvider, BaseStrategy, Branch, buildCommunication, StoreBranch } from '@axmit/redux-communications';
import { message } from 'antd';
import {
  INotificationTemplateModel,
  INotificationTemplateUpdateParams,
  INotificationTemplateCollectionFilter,
  INotificationTemplateCollection,
  INotificationTemplateModelFilter
} from 'entities/NotificationTemplate/NotificationTemplate.models';
import { notificationTemplateTransport } from 'entities/NotificationTemplate/NotificationTemplate.transport';

const namespace = 'notificationTemplate';

export interface INotificationTemplateConnectedProps {
  notificationTemplateModel: StoreBranch<INotificationTemplateModel, INotificationTemplateModelFilter>;
  notificationTemplateCollection: StoreBranch<INotificationTemplateCollection, INotificationTemplateCollectionFilter>;

  getNotificationTemplateModel(id: string): void;
  addNotificationTemplateModel(): void;
  updateNotificationTemplateModel(params: INotificationTemplateUpdateParams): void;
  getNotificationTemplateCollection(filter?: INotificationTemplateCollectionFilter): void;

  clearNotificationTemplateModel(): void;
  clearNotificationTemplateCollection(): void;
}

const NotificationTemplateModelAPIProviders = [
  new APIProvider(EActionsTypes.get, notificationTemplateTransport.get),
  new APIProvider(EActionsTypes.add, notificationTemplateTransport.add),
  new APIProvider(EActionsTypes.update, notificationTemplateTransport.update, {
    preRequestDataMapper: function(
      response: null,
      params: any,
      branch: StoreBranch<INotificationTemplateModel | null, any, any>
    ) {
      return { ...branch?.data, data: params.data } as INotificationTemplateModel;
    },
    onSuccess: function() {
      message.success('Notification template has been updated');
    },
    onFail: function() {
      message.error('Notification template update is failed');
    }
  })
];

const NotificationTemplateCollectionAPIProviders = [
  new APIProvider(EActionsTypes.get, notificationTemplateTransport.getCollection)
];

const branches = [
  new Branch('model', NotificationTemplateModelAPIProviders),
  new Branch('collection', NotificationTemplateCollectionAPIProviders)
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationNotificationTemplate = buildCommunication<INotificationTemplateConnectedProps>(strategy);
