import { put, select } from 'redux-saga/effects';
import { APIProvider, BaseStrategy, Branch, buildCommunication, getStartType, StoreBranch } from '@axmit/redux-communications';
import { IApplicationState } from 'app/store/reducers';
import {
  IModalModel,
  IWalletModalModel,
  IWalletModalParams,
  ILastScreenPointsModel,
  ILastScreenPointsParams
} from 'entities/UI/UI.models';
import { IPlayerTestGetNextParams } from 'entities/PlayerTest/PlayerTest.models';

const namespace = 'UI';

export interface IUIStoreProps {
  playerTestQuery: StoreBranch<Partial<IPlayerTestGetNextParams>>;
}

export interface IUIConnectedProps {
  UICommonModal: StoreBranch<IModalModel>;
  UIWalletModal: StoreBranch<IWalletModalModel>;
  UIPlayerTestQuery: StoreBranch<Partial<IPlayerTestGetNextParams>>;
  UILastScreenPointsModel: StoreBranch<ILastScreenPointsModel, ILastScreenPointsParams>;

  saveUIPlayerTestQuery(): void;
  clearUIPlayerTestQuery(): void;

  openUICommonModal(): void;
  closeUICommonModal(): void;

  openUIWalletModal(params?: IWalletModalParams): void;
  closeUIWalletModal(): void;
  clearUIWalletModal(): void;

  saveUILastScreenPointsModel(params: ILastScreenPointsParams): void;
}

const CommonModalApiProvider = [
  new APIProvider('open', (): Promise<IModalModel> => Promise.resolve({ isVisible: true })),
  new APIProvider('close', (): Promise<IModalModel> => Promise.resolve({ isVisible: false }))
];

const WalletModalApiProvider = [
  new APIProvider(
    'open',
    (params: IWalletModalParams): Promise<IWalletModalModel> => Promise.resolve({ ...params, isVisible: true })
  ),
  new APIProvider(
    'close',
    (): Promise<IWalletModalModel> => Promise.resolve({ isVisible: false, redirectUrl: undefined, player: undefined })
  )
];

const PlayerTestQueryApiProvider = [
  new APIProvider('save', function*(): any {
    return yield getUIPlayerTestQueryFromPlayerTestCollection();
  }),
  new APIProvider('clear', (): Promise<null> => Promise.resolve(null))
];

const lastScreenPointsModelApiProvider = [
  new APIProvider('save', (params: ILastScreenPointsParams): Promise<ILastScreenPointsModel> => Promise.resolve({ ...params }))
];

const branches = [
  new Branch('commonModal', CommonModalApiProvider),
  new Branch('playerTestQuery', PlayerTestQueryApiProvider),
  new Branch('walletModal', WalletModalApiProvider),
  new Branch('lastScreenPointsModel', lastScreenPointsModelApiProvider)
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export function* getUIPlayerTestQueryFromPlayerTestCollection() {
  return yield select((state: IApplicationState) => state?.playerTest.collection.params);
}

export function* getUIPlayerTestQuery() {
  return yield select((state: IApplicationState) => state?.UI.playerTestQuery.data);
}
export function* closeWalletModal() {
  yield put({ type: getStartType(namespace, 'walletModal', 'close') });
}

export const communicationUI = buildCommunication<IUIConnectedProps>(strategy);
