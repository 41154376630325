export interface IImageModel extends Components.Schemas.ImageDto {}
export interface IVideoModel extends Components.Schemas.VideoDto {}

export enum EFileStatus {
  Error = 'error',
  Success = 'success',
  Done = 'done',
  Uploading = 'uploading',
  Removed = 'removed'
}
