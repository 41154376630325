import React, { ChangeEvent } from 'react';
import { Button, Col, Input, Row } from 'antd';
import debounce from 'lodash.debounce';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { IUserModel } from 'entities/User/User.models';
import { PlayerSelector } from 'entities/Player/components/PlayerSelector';
import { MentorSelector } from 'entities/User/components/MentorSelector';
import { TariffVisibilitySelector } from 'entities/Tariff/components/Selectors/TariffVisibilitySelector';
import { IPlayerModel } from 'entities/Player/Player.models';
import { TariffTable } from 'entities/Tariff/components/TariffTable';
import { ETariffStatus, ETariffVisibility, ITariffCollectionFilter, tableTariffConfig } from 'entities/Tariff/Tariff.models';
import { TariffStatusSelector } from 'entities/Tariff/components/Selectors/TariffStatusSelector';

type AllProps = RouteComponentProps;

interface IComponentState {
  filter: ITariffCollectionFilter;
}

class TariffPageComponent extends React.PureComponent<AllProps, IComponentState> {
  public debounceSearch: any;
  state: IComponentState = {
    filter: {
      pageIndex: 1,
      localizationTag: undefined,
      statuses: undefined,
      playerFilter: undefined,
      mentorFilter: undefined,
      visibility: undefined
    }
  };
  constructor(props: AllProps) {
    super(props);
    this.state.filter = { ...this.state.filter, ...queryToObject<ITariffCollectionFilter>(this.state.filter) };
    this.debounceSearch = debounce(this.autocompleteSearch, 300);
  }

  render() {
    const { filter } = this.state;
    const { statuses, playerFilter, mentorFilter, localizationTag, visibility } = filter;

    return (
      <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10">
        <Row className="mb-10 width-full" justify="space-between" align="middle">
          <Col span={19}>
            <Row justify="space-between" align="middle">
              <Col span={5}>
                <Input.Search
                  placeholder="Search by localizationTag"
                  defaultValue={localizationTag}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => this.debounceSearch(e.target.value)}
                />
              </Col>
              <Col span={5}>
                <TariffStatusSelector onChange={this.onChangeStatus} value={statuses} placeholder="Search by status" />
              </Col>
              <Col span={4}>
                <PlayerSelector onChange={this.onChangePlayer} value={playerFilter} placeholder="Search by player" />
              </Col>
              <Col span={4}>
                <MentorSelector onChange={this.onChangeMentor} value={mentorFilter} placeholder="Search by mentor" />
              </Col>
              <Col span={4}>
                <TariffVisibilitySelector
                  onChange={this.onChangeVisibility}
                  value={visibility}
                  placeholder="Search by visibility"
                />
              </Col>
            </Row>
          </Col>
          <Col span={4}>
            <Link to={ERoutesPrivate.TariffCreate}>
              <Button block type="primary" title="Add tariff">
                Add tariff
              </Button>
            </Link>
          </Col>
        </Row>
        <TariffTable config={tableTariffConfig} filter={filter} />
      </CommonLayout>
    );
  }

  setFilter = (partialFilter: Partial<ITariffCollectionFilter>) => {
    const oldFilter = this.state.filter;
    const filter = { ...oldFilter, ...partialFilter };

    this.props.history.replace({ search: objectToQuery(filter) });
    this.setState({ filter });
  };

  onChangeStatus = (statuses?: ETariffStatus[]) => {
    this.setFilter({ statuses });
  };

  onChangeVisibility = (visibility?: ETariffVisibility) => {
    this.setFilter({ visibility });
  };

  onChangePlayer = (playerModel?: IPlayerModel) => {
    const playerFilter = playerModel
      ? { id: playerModel.id, firstName: playerModel.firstName, lastName: playerModel.lastName }
      : undefined;

    this.setFilter({ playerFilter });
  };

  onChangeMentor = (mentorModel?: IUserModel) => {
    const mentorFilter = mentorModel
      ? { id: mentorModel.id, firstName: mentorModel.firstName, lastName: mentorModel.lastName }
      : undefined;

    this.setFilter({ mentorFilter });
  };

  autocompleteSearch = (text: string) => {
    const localizationTag = text === '' ? undefined : text;
    this.setFilter({ localizationTag });
  };
}

export const TariffTablePage = withRouter(TariffPageComponent);
