import React from 'react';
import { Breadcrumb, Col } from 'antd';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { communicationNotification, INotificationConnectedProps } from 'entities/Notification/Notification.communication';
import { NotificationSendForm } from 'entities/Notification/components/NotificationSendForm';

type AllProps = INotificationConnectedProps;

const NotificationPageComponent: React.FC<AllProps> = props => {
  const { notificationModel, addNotificationModel } = props;
  const { loading } = notificationModel;

  return (
    <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10 pb-10">
      <Col span={12}>
        <Breadcrumb className="fs-sm font-weight-5 font-family__default mb-8">
          <Breadcrumb.Item href={ERoutesPrivate.NotificationTemplates}>Notification</Breadcrumb.Item>
          <Breadcrumb.Item>Custom notification</Breadcrumb.Item>
        </Breadcrumb>
        {!loading && <NotificationSendForm model={notificationModel} onSubmit={addNotificationModel} />}
      </Col>
    </CommonLayout>
  );
};

export const NotificationPage = communicationNotification.injector(NotificationPageComponent);
