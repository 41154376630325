import axios, { AxiosStatic } from 'axios';
import { BaseHttpTransport } from '@axmit/transport';
import { objectToQuery } from 'common/helpers/filters.helper';
import {
  IAdminCollection,
  IAdminCollectionFilter,
  IAdminCreateData,
  IAdminModel,
  IAdminUpdateData
} from 'entities/Admin/Admin.models';

const basePath = '/admins';

class AdminTransport extends BaseHttpTransport<
  AxiosStatic,
  IAdminModel,
  IAdminCollection,
  IAdminCreateData,
  IAdminUpdateData,
  IAdminCollectionFilter
> {
  public updatePassword = (id: string) => axios.put(`${basePath}/${id}/password`);
  public sendLoginLink = (id: string) => axios.put(`${basePath}/${id}/send-login-link`);
  public getAdminSearch = (params: IAdminCollectionFilter): Promise<IAdminCollection> =>
    axios.get(`users/search${objectToQuery(params)}`);
}

export const adminTransport = new AdminTransport(basePath, axios);
