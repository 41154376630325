export enum EErrorStatus {
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  NotConfirmedEmail = 406,
  Conflict = 409,
  PayloadTooLarge = 413,
  Validation = 422,
  FailedDependency = 424,
  TooManyRequests = 429,
  RetryWith = 449,
  InternalServerError = 500
}

export interface IBasePathParams {
  id: string;
}

export interface IBaseFilterModel {
  limit?: number;
  offset?: number;
}
