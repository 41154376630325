import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Button, Col, Row, Typography } from 'antd';
import { fabric } from 'fabric';
import { Canvas, Image, Rect, IEvent } from 'fabric/fabric-impl';
import { IPlayerTestVideoPoint, IPlayerTestVideoBoxPoint } from 'entities/PlayerTest/PlayerTest.models';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';

interface IProps {
  children: JSX.Element;
  videoHeight: number;
  videoWidth: number;
  markers: IPlayerTestVideoPoint[];
  setMarkers: (markers: IPlayerTestVideoPoint[] | ((markers: IPlayerTestVideoPoint[]) => IPlayerTestVideoPoint[])) => void;
  boxes: IPlayerTestVideoBoxPoint[];
  setBoxes: (boxes: IPlayerTestVideoBoxPoint[] | ((boxes: IPlayerTestVideoBoxPoint[]) => IPlayerTestVideoBoxPoint[])) => void;
  testId?: string;
  onZoomIn: () => void;
  onZoomOut: () => void;
  onZoomReset: () => void;
}

interface ITempRect {
  rect: fabric.Object;
  originalX: number;
  originalY: number;
}

type AllProps = IProps & IUIConnectedProps;

enum EDrawingTargetTypes {
  Box = 'box',
  Marker = 'marker'
}

const CANVAS_ID = 'editing-test-objects';
const IGNORED_CANVAS_TARGET_ACTIONS = ['drag', 'scale', 'scaleY', 'scaleX'];
const MARKER_OFFSET_LEFT = 9;
const MARKER_OFFSET_TOP = 10;
const DEFAULT_BOX_WIDTH = 30;
const DEFAULT_BOX_HEIGHT = 30;

const checkValueBoundaries = (value: number) => (value < 0 ? 0 : value > 100 ? 100 : value);

const getCoordinateFromPercents = (pos: number, dimension: number) => {
  return (dimension * pos) / 100;
};

const getCoordinateToPercents = (pos: number, dimension: number) => {
  return checkValueBoundaries(parseFloat(((pos / dimension) * 100).toFixed(3)));
};

const getMarkerFromCenterPosition = (
  { x, y }: { x: number; y: number },
  { width, height }: { width: number; height: number }
) => {
  return {
    xPos: getCoordinateToPercents(x, width),
    yPos: getCoordinateToPercents(y, height)
  };
};

const getBoxFromCorners = (
  [tl, br]: { x: number; y: number }[],
  { width, height }: { width: number; height: number }
): IPlayerTestVideoBoxPoint => {
  return {
    xPosUpLeft: getCoordinateToPercents(tl.x, width),
    yPosUpLeft: getCoordinateToPercents(tl.y, height),
    xPosDownRight: getCoordinateToPercents(br.x, width),
    yPosDownRight: getCoordinateToPercents(br.y, height)
  };
};

const TestMarkersEditComponent: React.FC<AllProps> = props => {
  const {
    children,
    videoHeight,
    videoWidth,
    markers,
    setMarkers,
    boxes,
    setBoxes,
    saveUILastScreenPointsModel,
    UILastScreenPointsModel,
    testId,
    onZoomIn,
    onZoomOut,
    onZoomReset
  } = props;

  const videoMarkersParentRef = useRef<HTMLDivElement>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const lastScreenPoints = UILastScreenPointsModel?.data?.lastScreenPoints;
  const lastBoxPoints = UILastScreenPointsModel?.data?.lastBoxPoints;
  const lastScreenPointsTestId = UILastScreenPointsModel?.data?.testId;

  const [canvas, setCanvas] = useState<Canvas>();
  const [isListenersInitialized, setListenersInitialized] = useState<boolean>(false);
  const [targetType, setTargetType] = useState<EDrawingTargetTypes | null>(null);
  const targetTypeRef = useRef<EDrawingTargetTypes | null>(targetType);
  const containerSizeRef = useRef<{
    width: number;
    height: number;
  }>({
    height: videoHeight,
    width: videoWidth
  });

  useEffect(() => {
    targetTypeRef.current = targetType;
  }, [targetType]);

  const getTarget: (center: { x: number; y: number }, origin?: IPlayerTestVideoPoint) => Promise<Image> = useCallback(
    ({ x, y }, origin) => {
      return new Promise<Image>(resolve => {
        fabric.Image.fromURL('/icons/target-icon.png', image => {
          image.scale(0.05).set({ top: y - MARKER_OFFSET_TOP, left: x - MARKER_OFFSET_LEFT });
          image.hasControls = false;
          image.hasBorders = false;
          (image as any).originType = EDrawingTargetTypes.Marker;

          if (origin) {
            (image as any).origin = origin;
          }

          resolve(image);
        });
      });
    },
    [canvas]
  );

  const getBox: (
    tl: { x: number; y: number; width?: number; height?: number },
    origin?: IPlayerTestVideoBoxPoint
  ) => Rect = useCallback(
    ({ x, y, width, height }, origin) => {
      const box = new fabric.Rect({
        top: y,
        left: x,
        width: Math.abs(width || DEFAULT_BOX_WIDTH),
        height: Math.abs(height || DEFAULT_BOX_HEIGHT),
        fill: undefined,
        stroke: 'red',
        cornerStrokeColor: 'black',
        padding: 8,
        cornerSize: 6,
        transparentCorners: false
      });

      box.lockRotation = true;
      box.setControlsVisibility({ mtr: false });
      (box as any).originType = EDrawingTargetTypes.Box;

      if (origin) {
        (box as any).origin = origin;
      }

      return box;
    },
    [canvas]
  );

  const drawTargets = useCallback(
    async (markers: IPlayerTestVideoPoint[], boxes: IPlayerTestVideoBoxPoint[]) => {
      const markerTargets = await Promise.all(
        markers?.map<Promise<Image>>(async marker => {
          return await getTarget(
            {
              x: getCoordinateFromPercents(marker.xPos, containerSizeRef.current.width),
              y: getCoordinateFromPercents(marker.yPos, containerSizeRef.current.height)
            },
            marker
          );
        })
      );

      boxes?.forEach(box => {
        const { xPosUpLeft, yPosUpLeft, xPosDownRight, yPosDownRight } = box;

        const tl = {
          x: getCoordinateFromPercents(xPosUpLeft, containerSizeRef.current.width),
          y: getCoordinateFromPercents(yPosUpLeft, containerSizeRef.current.height)
        };
        const br = {
          x: getCoordinateFromPercents(xPosDownRight, containerSizeRef.current.width),
          y: getCoordinateFromPercents(yPosDownRight, containerSizeRef.current.height)
        };

        const target = getBox(
          {
            ...tl,
            width: br.x - tl.x,
            height: tl.y - br.y
          },
          box
        );

        canvas?.add(target);
      });

      markerTargets.forEach(marker => {
        canvas?.add(marker);
      });

      canvas?.renderAll();
    },
    [canvas, markers, boxes]
  );

  const onAddMarker = useCallback(
    marker => {
      setMarkers(oldMarkers => [...oldMarkers, marker]);
    },
    [setMarkers]
  );

  const onAddBoxes = useCallback(
    box => {
      setBoxes(oldBoxes => [...oldBoxes, box]);
    },
    [setBoxes]
  );

  const onUpdateMarkers = useCallback(
    (marker: IPlayerTestVideoPoint, replace?: IPlayerTestVideoPoint) => {
      setMarkers(oldMarkers => {
        const markersTemp = [...oldMarkers];
        const indexToDelete = markersTemp.findIndex(el => el.yPos === marker.yPos && el.xPos === marker.xPos);

        if (indexToDelete > -1) {
          if (replace) {
            markersTemp.splice(indexToDelete, 1, replace);
          } else {
            markersTemp.splice(indexToDelete, 1);
          }
        }

        return markersTemp;
      });
    },
    [setMarkers]
  );

  const onUpdateBoxes = useCallback(
    (box: IPlayerTestVideoBoxPoint, replace?: IPlayerTestVideoBoxPoint) => {
      setBoxes(oldBoxes => {
        const boxesTemp = [...oldBoxes];
        const indexToDelete = boxesTemp.findIndex(
          el =>
            el.yPosDownRight === box.yPosDownRight &&
            el.xPosDownRight === box.xPosDownRight &&
            el.yPosUpLeft === box.yPosUpLeft &&
            el.xPosUpLeft === box.xPosUpLeft
        );

        if (indexToDelete > -1) {
          if (replace) {
            boxesTemp.splice(indexToDelete, 1, replace);
          } else {
            boxesTemp.splice(indexToDelete, 1);
          }
        }

        return boxesTemp;
      });
    },
    [setBoxes]
  );

  useEffect(() => {
    tempRectRef.current = undefined;
  }, [targetType]);

  const onMouseUpCallback = useCallback(
    async (event: IEvent<MouseEvent>) => {
      const { pointer, target } = event;

      if ((!target || tempRectRef.current) && pointer) {
        const freshTargetType = targetTypeRef.current;

        if (!freshTargetType || (freshTargetType === EDrawingTargetTypes.Box && (!tempRectRef.current || !canvas))) {
          return;
        }

        let originObject;
        if (freshTargetType === EDrawingTargetTypes.Marker) {
          originObject = getMarkerFromCenterPosition(pointer, containerSizeRef.current);
        }

        const { rect, originalX = 0, originalY = 0 } = tempRectRef.current ?? {};

        if (!rect && freshTargetType === EDrawingTargetTypes.Box) {
          return;
        }

        const { top = 0, left = 0, width = 0, height = 0, aCoords } = rect ?? {};

        if (freshTargetType === EDrawingTargetTypes.Box && aCoords) {
          originObject = getBoxFromCorners(
            [
              {
                x: Math.min(originalX, pointer.x),
                y: Math.min(originalY, pointer.y)
              },
              {
                x: Math.max(originalX, pointer.x),
                y: Math.max(originalY, pointer.y)
              }
            ],
            containerSizeRef.current
          );
        }

        const target =
          freshTargetType === EDrawingTargetTypes.Marker
            ? await getTarget(pointer, originObject as IPlayerTestVideoPoint)
            : getBox({ x: left, y: top + height, width, height }, originObject as IPlayerTestVideoBoxPoint);

        if (tempRectRef.current) {
          rect && canvas?.remove(rect);
          tempRectRef.current = undefined;
        }
        if (!target) {
          return;
        }
        canvas?.add(target);
        canvas?.setActiveObject(target);
        canvas?.renderAll();

        if (freshTargetType === EDrawingTargetTypes.Marker) {
          onAddMarker(originObject);
        }
        if (freshTargetType === EDrawingTargetTypes.Box) {
          onAddBoxes(originObject);
        }
      }
    },
    [canvas, getBox, getTarget, onUpdateMarkers, onUpdateBoxes, onAddMarker, onAddBoxes, targetType, setTargetType]
  );

  const onObjectsChanged = useCallback(
    (event: any) => {
      const { target = {}, transform = {} } = event;
      const { br, tl } = transform?.target?.aCoords;
      const origin = target?.origin;

      if (target.originType === EDrawingTargetTypes.Marker) {
        const newMarker = getMarkerFromCenterPosition(
          { x: (br?.x - (br?.x - tl?.x) / 2) as number, y: (tl?.y - (tl?.y - br?.y) / 2) as number },
          containerSizeRef.current
        );

        onUpdateMarkers(origin, newMarker);

        target.origin = newMarker;
      } else {
        const newBox = getBoxFromCorners([tl, br], containerSizeRef.current);

        onUpdateBoxes(origin, newBox);

        target.origin = newBox;
      }
    },
    [onUpdateMarkers, onUpdateBoxes]
  );

  const checkIfObjectStillInCanvas = useCallback(
    (event: IEvent<Event>) => {
      const { target }: any = event;

      // if object is too big ignore
      if (target?.currentHeight > target?.canvas?.height || target?.currentWidth > target?.canvas?.width) {
        return;
      }

      target?.setCoords();
      // top-left  corner
      if (target?.getBoundingRect()?.top < 0 || target?.getBoundingRect()?.left < 0) {
        target.top = Math.max(target?.top, target?.top - target?.getBoundingRect()?.top);
        target.left = Math.max(target?.left, target?.left - target?.getBoundingRect()?.left);
      }

      // bot-right corner
      if (
        Number(target?.getBoundingRect()?.top) + Number(target?.getBoundingRect()?.height) > target?.canvas?.height ||
        Number(target?.getBoundingRect()?.left) + Number(target?.getBoundingRect()?.width) > target?.canvas?.width
      ) {
        target.top = Math.min(
          target?.top,
          Number(target?.canvas?.height) -
            Number(target?.getBoundingRect()?.height) +
            Number(target?.top) -
            target?.getBoundingRect()?.top
        );
        target.left = Math.min(
          target?.left,
          Number(target?.canvas?.width) -
            Number(target?.getBoundingRect()?.width) +
            Number(target?.left) -
            target?.getBoundingRect()?.left
        );
      }
    },
    [canvas]
  );

  const tempRectRef = useRef<ITempRect>();

  const onMouseDownCallback = useCallback(
    (event: IEvent<MouseEvent>) => {
      if (!canvas || targetTypeRef.current !== EDrawingTargetTypes.Box || event.target) {
        return;
      }
      const pointer = canvas.getPointer(event.e);
      const originalX = pointer.x;
      const originalY = pointer.y;
      const rect = new fabric.Rect({
        left: originalX,
        top: originalY,
        originX: 'left',
        originY: 'top',
        width: 0,
        height: 0,
        angle: 0,
        fill: 'rgba(246,0,0,0.49)',
        transparentCorners: false
      });
      canvas?.add(rect);
      tempRectRef.current = {
        rect,
        originalY,
        originalX
      };
    },
    [canvas]
  );

  const onMouseMoveCallback = useCallback(
    event => {
      if (!tempRectRef.current || !canvas) {
        return;
      }

      const { rect, originalY, originalX } = tempRectRef.current;

      const pointer = canvas.getPointer(event.e);

      if (originalX > pointer.x) {
        rect?.set({ left: Math.abs(pointer.x) });
      }
      if (originalY > pointer.y) {
        rect.set({ top: Math.abs(pointer.y) });
      }

      rect.set({ width: Math.abs(originalX - pointer.x) });
      rect.set({ height: Math.abs(originalY - pointer.y) });

      canvas.renderAll();
    },
    [canvas]
  );

  const onMouseDblClickCallback = useCallback(
    async (event: IEvent<MouseEvent>) => {
      const { target, transform } = event;

      if (target) {
        if (IGNORED_CANVAS_TARGET_ACTIONS.includes((transform as any)?.action) && (transform as any)?.actionPerformed) {
          return;
        }
        const isRemove = confirm(`Remove ${(target as any)?.originType}?`);

        if (isRemove) {
          canvas?.remove(target);
          const origin = (target as any)?.origin;

          if ((target as any)?.originType === EDrawingTargetTypes.Marker) {
            onUpdateMarkers(origin);
          } else {
            onUpdateBoxes(origin);
          }
        }
      }
    },
    [canvas]
  );

  const setListeners = useCallback(() => {
    canvas?.on('mouse:up', onMouseUpCallback);
    canvas?.on('mouse:down', onMouseDownCallback);
    canvas?.on('mouse:move', onMouseMoveCallback);
    canvas?.on('mouse:dblclick', onMouseDblClickCallback);
    canvas?.on('object:modified', onObjectsChanged);
    canvas?.on('object:moving', checkIfObjectStillInCanvas);
    canvas?.on('object:scaling', checkIfObjectStillInCanvas);
    setListenersInitialized(true);
  }, [canvas, onMouseUpCallback, onMouseDownCallback, onMouseMoveCallback, onObjectsChanged, checkIfObjectStillInCanvas]);

  const onUnmount = useCallback(() => {
    setListenersInitialized(false);
    canvas?.removeListeners();
  }, [canvas]);

  useEffect(() => {
    if (!isListenersInitialized && canvas) {
      setListeners();
      void drawTargets(markers, boxes);
    }
  }, [canvas, isListenersInitialized, markers, boxes]);

  const initCanvas = useCallback(async () => {
    const canvas = await new fabric.Canvas(CANVAS_ID, {
      height: videoHeight,
      width: videoWidth,
      selection: false
    });

    setCanvas(canvas);
  }, []);

  useLayoutEffect(() => {
    void initCanvas();

    return onUnmount;
  }, []);

  useLayoutEffect(() => {
    containerSizeRef.current = {
      width: videoWidth,
      height: videoHeight
    };
    if (canvas) {
      canvas.setWidth(videoWidth);
      canvas.setHeight(videoHeight);
      canvas.clear();
      drawTargets(markers, boxes);
    }
  }, [canvas, markers, boxes, videoHeight, videoWidth]);

  useEffect(() => {
    if ((markers?.length || boxes?.length) && testId) {
      saveUILastScreenPointsModel({
        lastScreenPoints: markers,
        lastBoxPoints: boxes,
        testId
      });
    }
  }, [markers, boxes, testId]);

  const onEditingToggle = useCallback(() => {
    setIsEditing(!isEditing);
  }, [setIsEditing, isEditing]);

  const onUseLastMarkers = useCallback(() => {
    if (lastScreenPoints?.length) {
      setMarkers(lastScreenPoints);
    }
    if (lastBoxPoints?.length) {
      setBoxes(lastBoxPoints);
    }

    canvas?.clear();
    void drawTargets(lastScreenPoints || [], lastBoxPoints || []);
  }, [lastScreenPoints, lastBoxPoints, setMarkers, drawTargets, canvas]);

  useEffect(() => {
    if (isEditing) {
      setTargetType(EDrawingTargetTypes.Box);
    } else {
      setTargetType(null);
    }
  }, [isEditing]);

  return (
    <div className="test-markers-edit">
      <Row className="mb-3" gutter={[8, 8]} align="middle">
        <Col>
          <Button type="primary" danger={isEditing} onClick={onEditingToggle}>
            {isEditing ? 'Stop editing' : 'Edit markers'}
          </Button>
        </Col>
        <Col>
          <Button onClick={onZoomIn}>Zoom in</Button>
        </Col>
        <Col>
          <Button onClick={onZoomOut}>Zoom out</Button>
        </Col>
        <Col>
          <Button onClick={onZoomReset}>Reset zoom</Button>
        </Col>
        <Col hidden={!isEditing} xs={24}>
          <Row align="middle">
            <Button
              type={targetType === EDrawingTargetTypes.Marker ? 'primary' : undefined}
              onClick={() =>
                targetType === EDrawingTargetTypes.Marker ? setTargetType(null) : setTargetType(EDrawingTargetTypes.Marker)
              }
            >
              {targetType === EDrawingTargetTypes.Marker ? 'Deactivate draw markers' : 'Draw markers'}
            </Button>
            <Button
              type={targetType === EDrawingTargetTypes.Box ? 'primary' : undefined}
              onClick={() =>
                targetType === EDrawingTargetTypes.Box ? setTargetType(null) : setTargetType(EDrawingTargetTypes.Box)
              }
              className="ml-5"
            >
              {targetType === EDrawingTargetTypes.Box ? 'Deactivate draw boxes' : 'Draw boxes'}
            </Button>
            <Button
              type="text"
              disabled={(!lastScreenPoints?.length && !lastBoxPoints?.length) || testId === lastScreenPointsTestId}
              onClick={onUseLastMarkers}
              className="ml-5"
            >
              Clone previous
            </Button>
            <Col>
              <Typography.Text type="secondary">Dbl-click for remove</Typography.Text>
            </Col>
          </Row>
        </Col>
      </Row>
      <div ref={videoMarkersParentRef} className={`test-markers-edit__markers ${isEditing ? 'editing' : ''}`}>
        <canvas id={CANVAS_ID} width={videoWidth} height={videoHeight} />
        {children}
      </div>
    </div>
  );
};

export const TestMarkersEdit = communicationUI.injector(TestMarkersEditComponent);
