import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { EPlayerToTestUploaderType, EPlayerToTestUploaderTypeText } from 'entities/PlayerTest/PlayerTest.models';

interface IComponentProps {
  onChange?: (value?: EPlayerToTestUploaderType) => void;
  disabled?: boolean | void;
}

type AllProps = SelectProps<any> & IComponentProps;

export const PlayerTestUploaderSelector = ({ value: propValue, onChange, disabled = false }: AllProps) => (
  <Select
    allowClear
    className="width-full"
    placeholder="Search by uploader type"
    value={propValue?.toString()}
    defaultActiveFirstOption={false}
    showArrow
    onChange={(value: EPlayerToTestUploaderType) => onChange && onChange(value)}
    disabled={disabled}
  >
    <Select.Option
      key={EPlayerToTestUploaderType.Trainer}
      value={EPlayerToTestUploaderType.Trainer}
      label={EPlayerToTestUploaderTypeText.Trainer}
    >
      {EPlayerToTestUploaderTypeText.Trainer}
    </Select.Option>
    <Select.Option
      key={EPlayerToTestUploaderType.Player}
      value={EPlayerToTestUploaderType.Player}
      label={EPlayerToTestUploaderTypeText.Player}
    >
      {EPlayerToTestUploaderTypeText.Player}
    </Select.Option>
  </Select>
);
