import React, { ChangeEvent } from 'react';
import { Checkbox, Col, Input, Row } from 'antd';
import debounce from 'lodash.debounce';
import { RouteComponentProps, withRouter } from 'react-router';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { TestTable } from 'entities/Test/components/TestTable';
import { ITestCollectionFilter, tableTestConfig } from 'entities/Test/Test.models';
import { ETestType, TestTypeSelector } from 'entities/Test/components/Selector/TestTypeSelector';

type AllProps = RouteComponentProps;

interface IComponentState {
  filter: ITestCollectionFilter;
}

class TestTablePageComponent extends React.PureComponent<AllProps, IComponentState> {
  public debounceSearch: any;
  state: IComponentState = {
    filter: {
      pageIndex: 1,
      title: undefined,
      isVisibleForRfs: undefined
    }
  };
  constructor(props: AllProps) {
    super(props);
    this.state.filter = { ...this.state.filter, ...queryToObject<ITestCollectionFilter>(this.state.filter) };
    this.debounceSearch = debounce(this.autocompleteSearch, 300);
  }

  render() {
    const { filter } = this.state;
    const { isVisibleForRfs } = filter;

    return (
      <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10">
        <Row className="mb-10 width-full" justify="space-between" align="middle">
          <Col span={18}>
            <Row align="middle">
              <Col span={8}>
                <Input.Search
                  placeholder="Search by name"
                  defaultValue={filter.title}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => this.debounceSearch(e.target.value)}
                />
              </Col>
              <Col span={4} className="ml-4">
                <TestTypeSelector
                  onChange={this.onChangeRFS}
                  value={isVisibleForRfs ? ETestType.RFS : ETestType.JuniStat}
                  placeholder="Test type"
                />
              </Col>
            </Row>
          </Col>
          {/* <Col span={4}>*/}
          {/* <Link to={ERoutesPrivate.TestCreate}>*/}
          {/* <Button block type="primary" title="Add test">*/}
          {/* Add test*/}
          {/* </Button>*/}
          {/* </Link>*/}
          {/* </Col>*/}
        </Row>
        <TestTable config={tableTestConfig} filter={filter} />
      </CommonLayout>
    );
  }

  onChangeRFS = (val: ETestType) => {
    this.setFilter({ isVisibleForRfs: val === ETestType.RFS ? true : undefined });
  };

  setFilter = (partialFilter: Partial<ITestCollectionFilter>) => {
    const oldFilter = this.state.filter;
    const filter = { ...oldFilter, ...partialFilter };

    this.props.history.replace({ search: objectToQuery(filter) });
    this.setState({ filter });
  };

  autocompleteSearch = (text: string) => {
    const title = text === '' ? undefined : text;
    this.setFilter({ title });
  };
}

export const TestTablePage = withRouter(TestTablePageComponent);
