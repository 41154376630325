import {
  EActionsTypes,
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  StoreBranch,
  getStartType
} from '@axmit/redux-communications';
import { message } from 'antd';
import { put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { ERoutesPrivate } from 'common/models/routesModel';
import { playerTestTransport } from 'entities/PlayerTest/PlayerTest.transport';
import {
  EPlayerTestSuccessMessage,
  IPlayerTestModel,
  IPlayerTestUpdateParams,
  IPlayerTestCollectionFilter,
  IPlayerTestCollection,
  TPlayerTestParamsModel,
  IPlayerTestResultModel,
  TPlayerTestResultParamsModel,
  EPlayerTestFailMessage,
  IPlayerTestQueueTaskCount,
  IPlayerTestGetNextParams,
  EBaseResultExceptions,
  EBaseResultErrorMessages,
  IPlayerTestUpdateMove
} from 'entities/PlayerTest/PlayerTest.models';
import { ITestModel } from 'entities/Test/Test.models';
import { getUIPlayerTestQuery } from 'entities/UI/UI.communication';

const namespace = 'playerTest';

export interface IPlayerTestStoreProps {
  collection: StoreBranch<IPlayerTestCollection, IPlayerTestCollectionFilter>;
}

export interface IPlayerTestConnectedProps {
  playerTestModel: StoreBranch<IPlayerTestModel, TPlayerTestParamsModel>;
  playerTestCollection: StoreBranch<IPlayerTestCollection, IPlayerTestCollectionFilter>;

  playerTestQueueCount: StoreBranch<IPlayerTestQueueTaskCount>;
  playerTestResult: StoreBranch<IPlayerTestResultModel, TPlayerTestResultParamsModel>;

  getPlayerTestModel(id: string): void;
  getNextPlayerTestModel(params: IPlayerTestGetNextParams): void;
  updatePlayerTestModel(params: IPlayerTestUpdateParams): void;
  deletePlayerTestModel(id: string): void;
  getPlayerTestCollection(filter?: IPlayerTestCollectionFilter): void;
  getPlayerTestQueueCount(): void;

  movePlayerTestModel(params: IPlayerTestUpdateMove): Promise<IPlayerTestModel>;
  renderPlayerTestModel(id: string): void;

  clearPlayerTestModel(): void;
  clearPlayerTestCollection(): void;
}

const PlayerTestModelAPIProviders = [
  new APIProvider(EActionsTypes.get, playerTestTransport.get),
  new APIProvider('getNext', playerTestTransport.getNext, {
    mapParams: function*(originalParams) {
      const params = yield getUIPlayerTestQuery();
      return params ? { ...originalParams, ...params } : originalParams;
    },
    onSuccess: function*(response) {
      const { id } = response || {};
      if (id) {
        yield put(push(`${ERoutesPrivate.TestResults}/${id}`));
      }
    },
    onFail: function() {
      message.error('Fail');
    }
  }),
  new APIProvider('render', playerTestTransport.renderPlayerTest),
  new APIProvider('move', playerTestTransport.movePlayerTest, {
    onSuccess: function*(response, originalParams) {
      if (response?.id && response?.id !== originalParams?.id) {
        yield put(push(`${ERoutesPrivate.TestResults}/${response?.id}`));
      }
      message.success(EPlayerTestSuccessMessage.Edit);
    }
  }),
  new APIProvider(EActionsTypes.update, playerTestTransport.updatePlayerTest, {
    preRequestDataMapper: function(response: null, params: any, branch: StoreBranch<ITestModel | null, any, any>) {
      return branch?.data;
    },
    // eslint-disable-next-line sonarjs/no-identical-functions
    onSuccess: function*(response, originalParams) {
      if (response?.id !== originalParams?.id) {
        yield put(push(`${ERoutesPrivate.TestResults}/${response?.id}`));
      }
      message.success(EPlayerTestSuccessMessage.Edit);
    },
    onFail: function(response) {
      if (
        response?.data?.code === EBaseResultExceptions.WrongResult &&
        [
          EBaseResultErrorMessages.WrongArrowCircleTimes,
          EBaseResultErrorMessages.WrongArrowLeftCircleTimes,
          EBaseResultErrorMessages.WrongArrowRightCircleTimes
        ].includes(response?.data?.message)
      ) {
        message.error(EPlayerTestFailMessage.WrongArrowCircleMessages);
      } else if (response?.data?.message) {
        message.error(response?.data?.message);
      } else {
        message.error(EPlayerTestFailMessage.Edit);
      }
    }
  }),
  new APIProvider(EActionsTypes.delete, playerTestTransport.delete, {
    onSuccess: function*() {
      message.success(EPlayerTestSuccessMessage.Delete);
      yield put(push(ERoutesPrivate.TestResults));
    }
  })
];

const getPlayerTestQueueCountAPIProviders = [new APIProvider(EActionsTypes.get, playerTestTransport.getPlayerTestQueueTaskCount)];

const PlayerTestCollectionAPIProviders = [new APIProvider(EActionsTypes.get, playerTestTransport.getCollection)];

const branches = [
  new Branch('model', PlayerTestModelAPIProviders),
  new Branch('collection', PlayerTestCollectionAPIProviders),
  new Branch('queueCount', getPlayerTestQueueCountAPIProviders)
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export function* getNextPlayerToTest(id: string) {
  const params: Partial<IPlayerTestGetNextParams> = yield getUIPlayerTestQuery();
  yield put({ type: getStartType(namespace, 'model', 'getNext'), payload: { ...params, id } });
}

export const communicationPlayerTest = buildCommunication<IPlayerTestConnectedProps>(strategy);
