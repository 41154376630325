import React, { useEffect, useMemo, useState, useCallback } from 'react';
import Table, { ColumnsType } from 'antd/lib/table';
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import moment from 'moment';
import { Row, Col } from 'antd';
import { ISorter } from 'common/components/TableCommon';
import { normalizeNumber } from 'common/helpers/normalize.helper';
import { ITestStatisticCollectionFilter, ITestStatisticModel } from 'entities/Test/Test.models';
import { communicationTest, ITestConnectedProps } from 'entities/Test/Test.communication';

interface IComponentProps {
  config: ColumnsType<any>;
  filter: Partial<ITestStatisticCollectionFilter>;
}

type AllProps = IComponentProps & ITestConnectedProps;

const TestResultStatisticTableComponent: React.FC<AllProps> = props => {
  const { config, getTestStatisticCollection, testStatisticCollection, filter, clearTestStatisticCollection } = props;
  const { data, loading } = testStatisticCollection;
  const [state, setState] = useState<{ sorters?: ISorter[] }>({ sorters: undefined });

  const datesDifference = useMemo(() => {
    return moment(filter?.dateTo).diff(moment(filter?.dateFrom), 'seconds');
  }, [filter]);

  const spendAiTime = useMemo(
    () =>
      Math.round(
        data?.data?.reduce((acc: number, item: ITestStatisticModel) => {
          return acc + item.totalAiProcessedTime;
        }, 0) || 0
      ),
    [data]
  );

  const idleAiTime = useMemo(() => Math.round(datesDifference - spendAiTime), [datesDifference, spendAiTime]);

  const getHours = (seconds: number) => `${(seconds / 60 / 60).toFixed(2)}h`;

  const getPercentFromAllTime = (seconds: number) => `${Math.round((seconds / datesDifference) * 100)}%`;

  const items: ITestStatisticModel[] | undefined = useMemo(() => {
    return data?.data?.map((item: ITestStatisticModel) => {
      const percentFromTotal = normalizeNumber((item.totalAiProcessedTime * 100) / spendAiTime);

      return {
        ...item,
        commonHumanValue: normalizeNumber(item.avgModerationSpeed * (filter?.humanCost ?? 0)),
        commonAiValue: normalizeNumber((item.totalAiProcessedTime * (filter?.aiCost ?? 0)) / item.totalCount),
        percentFromTotal,
        totalCostAi: datesDifference * (filter?.aiCost ?? 0) * (percentFromTotal / 100)
      };
    });
  }, [data, filter, spendAiTime, datesDifference]);

  const loadData = useCallback(() => {
    const filterParams: ITestStatisticCollectionFilter = {};

    if (filter?.dateFrom) {
      filterParams.dateFrom = filter.dateFrom;
    }
    if (filter?.dateTo) {
      filterParams.dateTo = filter.dateTo;
    }

    getTestStatisticCollection(filterParams);
  }, [filter, getTestStatisticCollection]);

  useEffect(() => {
    loadData();
    return clearTestStatisticCollection;
  }, [loadData, state, filter, clearTestStatisticCollection]);

  const onChange = (
    pagination: any,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any> | SorterResult<any>[],
    extra: TableCurrentDataSource<any>
  ) => {
    if (extra?.action === 'sort') {
      const sorters = Array.isArray(sorter) ? sorter : [sorter];
      setState({ sorters: sorters?.map(({ order, field }) => ({ order, field })) });
    }
  };

  return (
    <Row className="pb-5">
      <Row className="width-full mb-5" gutter={[16, 0]}>
        <Col span={4}>
          <Row className="basic__text_title" title="Total AI work time, sec.">
            Total AI work time, .sec
          </Row>
          <Row className="basic__text_title" title={`${spendAiTime}`}>
            {spendAiTime}({getHours(spendAiTime)}, {getPercentFromAllTime(spendAiTime)})
          </Row>
        </Col>
        <Col span={4}>
          <Row className="basic__text_title" title="Idle AI time, sec.">
            Idle AI time, .sec
          </Row>
          <Row className="basic__text_title" title={`${idleAiTime}`}>
            {idleAiTime}({getHours(idleAiTime)}, {getPercentFromAllTime(idleAiTime)})
          </Row>
        </Col>
      </Row>
      <Table
        columns={config}
        dataSource={items}
        rowKey="id"
        bordered
        size="middle"
        pagination={false}
        onChange={onChange}
        loading={loading}
      />
    </Row>
  );
};

export const TestResultStatisticTable = communicationTest.injector(TestResultStatisticTableComponent);
