import axios, { AxiosStatic } from 'axios';
import { BaseHttpTransport } from '@axmit/transport';
import { objectToQuery } from 'common/helpers/filters.helper';
import {
  ISubscriptionCollection,
  ISubscriptionCollectionFilter,
  ISubscriptionCreate,
  ISubscriptionModel,
  ISubscriptionUpdateStatus,
  IPaymentRequestParams,
  ISubscriptionActivityModel,
  ISubscriptionActivityCollectionFilter,
  ISubscriptionActivityCollection,
  ISubscriptionActivityApplyGiftcard
} from 'entities/Subscription/Subscription.models';

const basePath = '/subscriptions';

class SubscriptionTransport extends BaseHttpTransport<
  AxiosStatic,
  ISubscriptionModel,
  ISubscriptionCollection,
  ISubscriptionCreate,
  void,
  ISubscriptionCollectionFilter
> {
  public updateStatus = (params: ISubscriptionUpdateStatus): Promise<ISubscriptionModel> =>
    axios.put(`${basePath}/${params.id}`, params);
  public stripeRefresh = (): Promise<void> => axios.post(`${basePath}/stripe/reschedule-failed/`);

  public createSubscriptionActivity = ({
    subscriptionId,
    ...rest
  }: IPaymentRequestParams): Promise<ISubscriptionActivityModel> => {
    const url = new URL(window.location.href);
    const params = { ...rest };
    const urlParams = new URLSearchParams(url.search.slice(1));
    urlParams.set('subscriptionId', subscriptionId);
    params.returnUrl = `${url.origin}${url.pathname}?${urlParams.toString()}`;

    return axios.post(`${basePath}/${subscriptionId}/activities/payments/`, params);
  };

  public applyGiftcardSubscriptionActivity = ({
    subscriptionId,
    ...rest
  }: ISubscriptionActivityApplyGiftcard): Promise<ISubscriptionActivityModel> =>
    axios.post(`${basePath}/${subscriptionId}/activities/giftcards`, rest);

  public getSubscriptionActivityCollection = (
    filters: ISubscriptionActivityCollectionFilter
  ): Promise<ISubscriptionActivityCollection> => axios.get(`${basePath}/activities/list${objectToQuery(filters)}`);

  public deleteSubscriptionActivity = (subscriptionId: string): Promise<void> =>
    axios.delete(`${basePath}/${subscriptionId}/activities/payments`);
}

export const subscriptionTransport = new SubscriptionTransport(basePath, axios);
