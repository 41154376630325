import React, { useCallback, useEffect } from 'react';
import Select, { SelectProps } from 'antd/es/select';
import { ILanguageModel } from 'entities/Language/Language.models';
import { ILanguageConnectedProps, communicationLanguage } from 'entities/Language/Language.communication';
import { EPlayerAgeGroups } from 'entities/Player/Player.models';

interface IProps {
  onChange?: (value?: string | string[]) => void;
  disabled?: boolean;
  isSingle?: boolean;
}

type AllProps = IProps & ILanguageConnectedProps & SelectProps<any>;

const LanguageSelectComponent: React.FC<AllProps> = props => {
  const {
    onChange,
    value,
    className,
    getLanguageCollection,
    clearLanguageCollection,
    languageCollection,
    disabled,
    placeholder,
    isSingle = true,
    size = 'middle'
  } = props;
  const languages = languageCollection?.data?.data;

  useEffect(() => {
    getLanguageCollection();
    return clearLanguageCollection;
  }, [getLanguageCollection, clearLanguageCollection]);

  const onClick = useCallback((event: any) => {
    event.stopPropagation();
  }, []);

  return (
    <Select
      placeholder={placeholder}
      className={className}
      onClick={onClick}
      onChange={onChange}
      value={value}
      disabled={disabled}
      size={size}
      mode={isSingle ? undefined : 'multiple'}
    >
      {languages?.map((language: ILanguageModel) => (
        <Select.Option key={language.locale} value={language.locale}>
          {language.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export const LanguageSelect = communicationLanguage.injector(LanguageSelectComponent);
