import { ColumnsType } from 'antd/lib/table';
import { RouteComponentProps, withRouter } from 'react-router';
import { TableCommon } from 'common/components/TableCommon';
import { IBaseFilterModel } from 'common/models/requestModels';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { ERoutesPrivate } from 'common/models/routesModel';
import { IGiftcardCollection, IGiftcardCollectionFilter, IGiftcardModel } from 'entities/Giftcard/Giftcard.models';
import { communicationGiftcard, IGiftcardConnectedProps } from 'entities/Giftcard/Giftcard.communication';

interface IComponentProps {
  config: ColumnsType<any>;
}

type AllProps = IComponentProps & IGiftcardConnectedProps & RouteComponentProps;

class GiftcardTableComponent extends TableCommon<
  IGiftcardCollection,
  IGiftcardModel,
  ColumnsType<any>,
  AllProps,
  IGiftcardCollectionFilter
> {
  loadCollection = (params: IBaseFilterModel) => {
    const { getGiftcardCollection, filter } = this.props;
    const filterParams: IGiftcardCollectionFilter = { ...params };

    if (filter?.playerFilter?.id) {
      filterParams.player = filter.playerFilter.id;
    }
    if (filter?.mentorFilter?.id) {
      filterParams.mentor = filter.mentorFilter?.id;
    }
    if (filter?.number) {
      filterParams.number = filter?.number;
    }
    if (filter?.description) {
      filterParams.description = filter?.description;
    }
    if (filter?.type) {
      filterParams.type = filter?.type;
    }
    if (filter?.statuses) {
      filterParams.statuses = filter?.statuses;
    }
    getGiftcardCollection(filterParams);
  };

  getCollection = () => {
    const { giftcardCollection } = this.props;
    if (giftcardCollection?.data?.data) {
      const data: IGiftcardModel[] = giftcardCollection.data.data.map(item => {
        const owner = item?.ownerMentor ? item.ownerMentor : item.ownerPlayer;
        return {
          ...item,
          owner: owner ? `${owner.firstName || 'Unknown'} ${owner.lastName || 'name'}` : undefined
        };
      });
      return {
        ...giftcardCollection,
        data: {
          ...giftcardCollection.data,
          data
        }
      };
    } else {
      return giftcardCollection;
    }
  };

  addQuery = (pageIndex: number) => {
    const searchPath = queryToObject<IGiftcardCollectionFilter>({
      pageIndex: 1,
      player: undefined,
      mentor: undefined,
      number: undefined,
      description: undefined,
      type: undefined,
      statuses: undefined
    });
    const search = objectToQuery({ ...searchPath, pageIndex });

    return this.props.history.replace({ search });
  };

  onRowClick = (item: IGiftcardModel) => {
    const { history } = this.props;
    history.push(`${ERoutesPrivate.Giftcards}/${item.id}`);
  };

  clearCollection = () => {
    return this.props.clearGiftcardCollection();
  };
}

export const GiftcardTable = communicationGiftcard.injector(withRouter(GiftcardTableComponent));
