import React from 'react';
import { Breadcrumb, Col, Skeleton } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { communicationTest, ITestConnectedProps } from 'entities/Test/Test.communication';
import { TestForm } from 'entities/Test/components/Form/TestForm';

interface IParams {
  id?: string;
}

type AllProps = ITestConnectedProps & RouteComponentProps<IParams>;

class TestPageComponent extends React.PureComponent<AllProps> {
  componentDidMount() {
    const { getTestModel, match } = this.props;
    const { params } = match;
    const { id } = params;
    if (id) {
      getTestModel(id);
    }
  }

  componentWillUnmount() {
    const { clearTestModel } = this.props;

    clearTestModel();
  }

  render() {
    const { testModel, match, updateTestModel, deleteTestModel } = this.props;
    const { params } = match;
    const { id } = params;
    const { data: testModelData, loading } = testModel;
    const testName = testModelData?.title || 'Unknown test';
    const isEdit = !!id;

    return (
      <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10 pb-10">
        <Col span={12}>
          <Breadcrumb className="fs-sm font-weight-5 font-family__default mb-8">
            <Breadcrumb.Item href={ERoutesPrivate.Test}>Test list</Breadcrumb.Item>
            <Breadcrumb.Item>
              {isEdit ? !loading ? testName : <Skeleton.Input active style={{ width: '200px' }} size="small" /> : 'Add test'}
            </Breadcrumb.Item>
          </Breadcrumb>
          {!loading || !isEdit ? (
            <TestForm testModel={testModel} updateTest={updateTestModel} deleteTest={deleteTestModel} testId={id} />
          ) : null}
        </Col>
      </CommonLayout>
    );
  }
}

export const TestPage = communicationTest.injector(withRouter(TestPageComponent));
