import React, { useCallback, useEffect, useState } from 'react';
import { Avatar, Dropdown, Menu, message, Modal, Spin, Upload } from 'antd';
import { Crop } from 'react-image-crop';
import { CameraOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/lib/upload/interface';
import { ImageUploadBody } from 'common/components/ImageUpload/ImageUploadBody';
import { getImageUrl } from 'common/helpers/image.helper';
import { checkImage, getImageLink, isBase64IsImage } from 'common/helpers/loader.helper';
import { uploadImage } from 'entities/Image/Image.transport';
import { EImageSize } from 'entities/Image/Image.models';

const modalWidth = 700;
const avatarSize = 88;

export interface IProps {
  onChange?: (imageId: string | null | undefined) => void;
  value?: string | null;
  aspect?: number;
  circularCrop?: boolean;
}

const cropOptions = {
  width: 100,
  height: 100,
  x: 0,
  y: 0
};

export const ImageUpload: React.FC<IProps> = ({ onChange, aspect, circularCrop, value }) => {
  const [isEditorVisible, setEditorVisible] = useState<boolean>(false);
  const [src, setSrc] = useState<string | ArrayBuffer | null | undefined>();
  const [fileName, setFilename] = useState<string>('');
  const [image, setImage] = useState<HTMLImageElement | undefined>(undefined);
  const [crop, setCrop] = useState<Crop>({ ...cropOptions, aspect: aspect === undefined ? 1 : aspect });
  const [loading, setLoading] = useState<boolean>(false);
  const [avatarSrc, setAvatarSrc] = useState('');

  useEffect(() => {
    if (value) {
      getImageLink(value, EImageSize.X96).then(url => setAvatarSrc(url));
    } else {
      setAvatarSrc('');
    }
  }, [value]);

  const openEditor = useCallback(() => {
    setEditorVisible(true);
  }, []);

  const closeEditor = useCallback(() => {
    setEditorVisible(false);
    setSrc(null);
    setImage(undefined);
  }, []);

  const handleUpload = useCallback(
    (file: RcFile): boolean => {
      if (checkImage(file)) {
        setFilename(file.name);
        const reader = new FileReader();
        reader.addEventListener('load', async () => {
          const isImage = await isBase64IsImage(reader.result as string);
          if (isImage) {
            setSrc(reader.result);
            openEditor();
          }
        });
        reader.readAsDataURL(file);
      }
      return false;
    },
    [openEditor]
  );
  const downloadImgLink = getImageUrl(value);

  const menu = React.useMemo(
    () => (
      <Menu>
        <Menu.Item>
          <Upload beforeUpload={handleUpload} accept="image/x-png,image/jpeg,image/png" showUploadList={false} multiple={false}>
            Change photo
          </Upload>
        </Menu.Item>
        {downloadImgLink && (
          <Menu.Item>
            <a href={downloadImgLink} target="_blank" rel="noopener noreferrer">
              Download photo
            </a>
          </Menu.Item>
        )}
        <Menu.Item
          onClick={() => {
            onChange && onChange(null);
          }}
        >
          Delete photo
        </Menu.Item>
      </Menu>
    ),
    [handleUpload, onChange, downloadImgLink]
  );
  const onSave = useCallback(() => {
    if (image) {
      const canvas = document.createElement('canvas');
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = Number(crop.width) * scaleX;
      canvas.height = Number(crop.height) * scaleY;
      const ctx = canvas.getContext('2d');

      if (ctx && crop) {
        ctx.drawImage(
          image,
          Number(crop.x) * scaleX,
          Number(crop.y) * scaleY,
          Number(crop.width) * scaleX,
          Number(crop.height) * scaleY,
          0,
          0,
          Number(crop.width) * scaleX,
          Number(crop.height) * scaleY
        );

        canvas.toBlob(blob => {
          if (!blob) {
            message.error('Error while uploading image');
            return;
          }
          setLoading(true);
          uploadImage(blob, fileName)
            .then(res => {
              const { id } = res;
              onChange && onChange(id);
              closeEditor();
            })
            .catch(() => {
              message.error('Error while uploading image');
            })
            .finally(() => {
              setLoading(false);
            });
        }, 'image/jpeg');
      }
    }
  }, [crop, image, fileName, onChange, closeEditor]);

  return (
    <>
      <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
        <div className="avatar-editor__avatar-container">
          <Avatar shape="square" size={avatarSize} className="avatar-editor__avatar" icon={<CameraOutlined />} src={avatarSrc} />
          <div className="avatar-editor__avatar-overlay">{/* <CameraIcon />*/}</div>
        </div>
      </Dropdown>
      <Modal
        title="Upload message"
        className="avatar-editor__modal"
        destroyOnClose
        centered
        maskClosable={false}
        width={modalWidth}
        visible={isEditorVisible}
        onCancel={closeEditor}
        okText="Select"
        cancelText="Close"
        onOk={onSave}
        okButtonProps={{ disabled: loading }}
      >
        <Spin spinning={loading} size="large">
          <ImageUploadBody src={src} crop={crop} setCrop={setCrop} setImage={setImage} circularCrop={circularCrop} />
        </Spin>
      </Modal>
    </>
  );
};
