import { all, takeEvery } from 'redux-saga/effects';
import { message } from 'antd';
import { EErrorStatus } from 'common/models/requestModels';
import { clearAuth, communicationAuth } from 'entities/Auth/Auth.communication';
import { communicationAdmin } from 'entities/Admin/Admin.communication';
import { communicationTest } from 'entities/Test/Test.communication';
import { communicationPlayerTest } from 'entities/PlayerTest/PlayerTest.communication';
import { communicationPlayer } from 'entities/Player/Player.communication';
import { communicationAcademy } from 'entities/Academy/Academy.communication';
import { communicationSubscription } from 'entities/Subscription/Subscription.communication';
import { communicationGiftcard } from 'entities/Giftcard/Giftcard.communication';
import { communicationNotificationTemplate } from 'entities/NotificationTemplate/NotificationTemplate.communication';
import { communicationNotification } from 'entities/Notification/Notification.communication';
import { communicationTariff } from 'entities/Tariff/Tariff.communication';
import { communicationFranchise } from 'entities/Franchise/Franchise.communication';
import { communicationLanguage } from 'entities/Language/Language.communication';
import { communicationProduct } from 'entities/Product/Product.communication';
import { communicationFeed } from 'entities/Feed/Feed.communication';
import { communicationWallet } from 'entities/Wallet/Wallet.communication';
import { communicationUI } from 'entities/UI/UI.communication';
import { communicationInfluencer } from 'entities/Influencer/Influencer.communication';
import { communicationSkin } from 'entities/Skin/Skin.communication';
import { communicationStatistic } from 'entities/Statistic/Statistic.communication';
import { communicationStory } from 'entities/Story/Story.communication';

function* errorWatcher() {
  yield takeEvery('*', function* logger(action: any) {
    if (action.type.match('FAIL')) {
      const status = action.payload && action.payload.status;

      switch (status) {
        case EErrorStatus.Validation:
          break;
        case EErrorStatus.Forbidden:
          break;
        case EErrorStatus.NotFound:
          break;
        case EErrorStatus.Unauthorized:
          yield clearAuth();
          break;
        case EErrorStatus.NotConfirmedEmail:
          message.warning('Please check your email and confirm account!');
          break;
        case EErrorStatus.InternalServerError:
          message.warning('Something went wrong');
          break;
        default:
          message.warning(JSON.stringify(action.payload));
          break;
      }
      console.log('ERROR', action.payload);
    }
  });
}

export default function* rootSaga(): any {
  yield all([
    errorWatcher(),
    ...communicationAuth.sagas,
    ...communicationPlayer.sagas,
    ...communicationAdmin.sagas,
    ...communicationTest.sagas,
    ...communicationPlayerTest.sagas,
    ...communicationAcademy.sagas,
    ...communicationSubscription.sagas,
    ...communicationGiftcard.sagas,
    ...communicationNotificationTemplate.sagas,
    ...communicationNotification.sagas,
    ...communicationFeed.sagas,
    ...communicationTariff.sagas,
    ...communicationFranchise.sagas,
    ...communicationLanguage.sagas,
    ...communicationProduct.sagas,
    ...communicationWallet.sagas,
    ...communicationUI.sagas,
    ...communicationSkin.sagas,
    ...communicationStatistic.sagas,
    ...communicationInfluencer.sagas,
    ...communicationStory.sagas
  ]);
}
