import React from 'react';
import { Breadcrumb, Col, Skeleton } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { communicationPlayerTest, IPlayerTestConnectedProps } from 'entities/PlayerTest/PlayerTest.communication';
import { PlayerTestForm } from 'entities/PlayerTest/components/Form/PlayerTestForm';

interface IParams {
  id?: string;
}

type AllProps = IPlayerTestConnectedProps & RouteComponentProps<IParams>;

class PlayerTestPageComponent extends React.PureComponent<AllProps> {
  componentDidMount() {
    const { getPlayerTestModel, match } = this.props;
    const { params } = match;
    const { id } = params;

    if (id) {
      getPlayerTestModel(id);
    }
  }
  componentDidUpdate(prevProps: AllProps) {
    const { getPlayerTestModel, match } = this.props;
    const id = match?.params?.id;
    const prevId = prevProps?.match?.params?.id;

    if (prevId && id && id !== prevId) {
      getPlayerTestModel(id);
    }
  }
  componentWillUnmount() {
    const { clearPlayerTestModel } = this.props;

    clearPlayerTestModel();
  }

  render() {
    const {
      playerTestModel,
      match,
      updatePlayerTestModel,
      renderPlayerTestModel,
      getNextPlayerTestModel,
      movePlayerTestModel
    } = this.props;
    const { params } = match;
    const { id } = params;
    const { data: playerTestModelData, loading } = playerTestModel;
    const playerTestName = playerTestModelData?.test?.tag || 'Unknown name';

    return (
      <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10 pb-10">
        <Col span={24}>
          <Breadcrumb className="fs-sm font-weight-5 font-family__default mb-8">
            <Breadcrumb.Item href={ERoutesPrivate.TestResults}>Player tests list</Breadcrumb.Item>
            <Breadcrumb.Item>
              {!loading ? playerTestName : <Skeleton.Input active style={{ width: '200px' }} size="small" />}
            </Breadcrumb.Item>
          </Breadcrumb>
          {!loading ? (
            <PlayerTestForm
              playerTestModel={playerTestModel}
              movePlayerTestModel={movePlayerTestModel}
              updatePlayerTest={updatePlayerTestModel}
              playerTestId={id}
              nextPlayerTest={id ? () => getNextPlayerTestModel({ id }) : undefined}
              renderPlayerTest={renderPlayerTestModel}
            />
          ) : null}
        </Col>
      </CommonLayout>
    );
  }
}

export const PlayerTestPage = communicationPlayerTest.injector(withRouter(PlayerTestPageComponent));
