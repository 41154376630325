import React from 'react';
import { Breadcrumb, Col, Skeleton } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { communicationProduct, IProductConnectedProps } from 'entities/Product/Product.communication';
import { ProductForm } from 'entities/Product/components/Form/ProductForm';

interface IParams {
  id?: string;
}

type AllProps = IProductConnectedProps & RouteComponentProps<IParams>;

class ProductPageComponent extends React.PureComponent<AllProps> {
  componentDidMount() {
    const { getProductModel, match } = this.props;
    const { params } = match;
    const { id } = params;

    if (id) {
      getProductModel(id);
    }
  }

  componentWillUnmount() {
    const { clearProductModel } = this.props;

    clearProductModel();
  }

  render() {
    const { productModel, match, addProductModel, updateProductModel, deleteProductModel } = this.props;
    const { params } = match;
    const { id } = params;
    const { data: productModelData, loading } = productModel;
    const productName = productModelData?.id || 'Unknown product';
    const isEdit = !!id;

    return (
      <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10 pb-10">
        <Col span={12}>
          <Breadcrumb className="fs-sm font-weight-5 font-family__default mb-8">
            <Breadcrumb.Item href={ERoutesPrivate.Product}>Product list</Breadcrumb.Item>
            <Breadcrumb.Item>
              {isEdit ? (
                !loading ? (
                  productName
                ) : (
                  <Skeleton.Input active style={{ width: '200px' }} size="small" />
                )
              ) : (
                'Add product'
              )}
            </Breadcrumb.Item>
          </Breadcrumb>
          {!loading || !isEdit ? (
            <ProductForm
              productModel={productModel}
              createProduct={addProductModel}
              updateProduct={updateProductModel}
              deleteProduct={deleteProductModel}
              productId={id}
            />
          ) : null}
        </Col>
      </CommonLayout>
    );
  }
}

export const ProductPage = communicationProduct.injector(withRouter(ProductPageComponent));
