import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { EFeedStatus } from 'entities/Feed/Feed.models';

interface IComponentProps {
  onChange?: (value?: EFeedStatus) => void;
  disabled?: boolean;
}

type AllProps = SelectProps<any> & IComponentProps;

export class FeedStatusSelector extends React.PureComponent<AllProps> {
  render() {
    const { value: propValue, onChange, disabled = false } = this.props;

    return (
      <Select
        allowClear
        className="width-full"
        placeholder="Select status"
        value={propValue}
        defaultActiveFirstOption={false}
        showArrow
        onChange={(value: EFeedStatus) => onChange && onChange(value)}
        disabled={disabled}
      >
        <Select.Option key={EFeedStatus.Accept} value={EFeedStatus.Accept} label={EFeedStatus.Accept}>
          {EFeedStatus.Accept}
        </Select.Option>
        <Select.Option key={EFeedStatus.Banned} value={EFeedStatus.Banned} label={EFeedStatus.Banned}>
          {EFeedStatus.Banned}
        </Select.Option>
        <Select.Option key={EFeedStatus.Moderation} value={EFeedStatus.Moderation} label={EFeedStatus.Moderation}>
          {EFeedStatus.Moderation}
        </Select.Option>
        <Select.Option key={EFeedStatus.Reject} value={EFeedStatus.Reject} label={EFeedStatus.Reject}>
          {EFeedStatus.Reject}
        </Select.Option>
      </Select>
    );
  }
}
