import React from 'react';
import { Row } from 'antd';
import { scrollToTop } from 'common/helpers/scrollToTop.helper';
import logo from 'app/assets/images/logo.png';

class LogoComponent extends React.Component {
  render() {
    return (
      <Row className="flex-noWrap cursor-pointer" align="middle" gutter={{ xs: 4, sm: 8 }} onClick={this.onClick}>
        <img className="basic_header-logo" src={logo} alt="logo" />
      </Row>
    );
  }
  onClick = () => {
    scrollToTop('basicLayout');
  };
}

export const Logo = LogoComponent;
