import { IBasePathParams } from 'common/models/requestModels';

export interface IInfluencerModel extends Components.Schemas.InfluencerStatisticDto {}
export interface IInfluencerParams extends IBasePathParams, Paths.CommonControllerInfluencerStatistics.QueryParameters {}

export interface IInfluencerCollection extends Components.Schemas.InfluencerStatisticCollectionDto {}
export interface IInfluencerCollectionFilter extends Paths.CommonControllerAllInfluencersStatistics.QueryParameters {
  pageIndex?: number;
}

export const statisticTableConfig = [
  {
    title: 'Influencer tag',
    dataIndex: 'influencer',
    key: 'influencer'
  },
  {
    title: 'Started registration',
    dataIndex: 'totalRegCount',
    key: 'totalRegCount'
  },
  {
    title: 'Completed registration',
    dataIndex: 'completeRegCount',
    key: 'completeRegCount'
  }
];
