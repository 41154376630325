import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { EPaymentActivitiesTypes, EPaymentActivitiesTypesText } from 'entities/Subscription/Subscription.models';

interface IComponentProps {
  onChange?: (value?: EPaymentActivitiesTypes) => void;
  disabled?: boolean;
}

type AllProps = SelectProps<any> & IComponentProps;

export class SubscriptionTypeSelector extends React.PureComponent<AllProps> {
  render() {
    const { value, onChange, disabled = false } = this.props;

    return (
      <Select
        allowClear
        className="width-full"
        placeholder="Select type"
        value={value}
        defaultActiveFirstOption={false}
        showArrow
        onChange={(value: EPaymentActivitiesTypes) => onChange && onChange(value)}
        disabled={disabled}
      >
        <Select.Option
          key={EPaymentActivitiesTypes.Giftcard}
          value={EPaymentActivitiesTypes.Giftcard}
          label={EPaymentActivitiesTypesText.Giftcard}
        >
          {EPaymentActivitiesTypesText.Giftcard}
        </Select.Option>
        <Select.Option
          key={EPaymentActivitiesTypes.Payment}
          value={EPaymentActivitiesTypes.Payment}
          label={EPaymentActivitiesTypesText.Payment}
        >
          {EPaymentActivitiesTypesText.Payment}
        </Select.Option>
      </Select>
    );
  }
}
