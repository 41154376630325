import { ISelectMapItem } from 'common/models/selectModels';
import { IAcademyModel } from 'entities/Academy/Academy.models';
import { IPlayerModel } from 'entities/Player/Player.models';

export interface IUserRoles extends Paths.AdminControllerList.Parameters.Roles {}
export interface IUserModel extends Components.Schemas.UserDto {}

export enum EUserRoles {
  Admin = 'admin',
  DataTagger = 'dataTagger',
  Mentor = 'mentor',
  AcademyWorker = 'academyWorker',
  Trainer = 'trainer'
}

export enum EUserRolesText {
  Admin = 'Admin',
  DataTagger = 'Tagger',
  Mentor = 'Mentor',
  AcademyWorker = 'Academy worker',
  Trainer = 'Coach',
  Player = 'Player'
}

export enum EEntityType {
  Academy = 'academy',
  User = 'user',
  Player = 'player'
}

export enum EEntityTypeText {
  Academy = 'Academy',
  User = 'User',
  Player = 'Player'
}

export const entityTypeArray: ISelectMapItem<EEntityTypeText, EEntityType>[] = [
  {
    title: EEntityTypeText.Academy,
    value: EEntityType.Academy
  },
  {
    title: EEntityTypeText.User,
    value: EEntityType.User
  },
  {
    title: EEntityTypeText.Player,
    value: EEntityType.Player
  }
];

export type TEntityValue = Partial<IUserModel & IAcademyModel & IPlayerModel>;
