import React, { useEffect, useState, useCallback, useMemo } from 'react';
import Skeleton from 'antd/es/skeleton';
import { VideoPrivateModal } from 'common/components/Video/VideoPrivateModal';
import { getImageLink } from 'common/helpers/loader.helper';
import { getImageSize } from 'common/helpers/image.helper';

interface IComponentProps {
  width?: number | string;
  height: number | string;
  videoId?: string;
  youtubeId?: string;
  className?: string;
  previewClass?: string;
  preview?: string;
}
type AllProps = IComponentProps;

const VideoPrivateComponent: React.FC<AllProps> = props => {
  const { width, height, videoId, className = '', preview = '', youtubeId, previewClass = '' } = props;
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [previewUrl, setPreviewUrl] = useState<string>('');
  const [useFallbackImg, setUseFallbackImg] = useState<boolean>(false);

  useEffect(() => {
    const getPreviewUrl = async () => {
      const url = await getImageLink(preview, getImageSize(Number(height), Number(width)));
      setPreviewUrl(url);
    };

    if (youtubeId) {
      setPreviewUrl(preview);
    } else {
      getPreviewUrl();
    }
  }, [previewUrl, height, width, preview, youtubeId]);

  const previewStyle = useMemo(
    () => ({
      width: width ?? '100%',
      minWidth: width ?? '100%',
      maxWidth: width ?? '100%',
      height,
      minHeight: height,
      maxHeight: height
    }),
    [width, height]
  );

  const onImageLoadError = useCallback(() => {
    setUseFallbackImg(false);
  }, []);

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  return (
    <div className={`video-private ${className}`}>
      <div className="video-private__preview-container cursor-pointer" style={previewStyle} onClick={openModal}>
        {useFallbackImg || !preview ? (
          <Skeleton.Image style={previewStyle} className={previewClass} />
        ) : (
          <img
            onError={onImageLoadError}
            src={previewUrl}
            alt="Preview"
            className={`video-private__preview-image ${previewClass}`}
          />
        )}
        <div className="video-private__preview-icon" />
      </div>
      <VideoPrivateModal visible={modalVisible} onCancel={closeModal} videoId={videoId} youtubeId={youtubeId} />
    </div>
  );
};

export const VideoPrivate = VideoPrivateComponent;
