export interface IImageModel extends Components.Schemas.ImageDto {}
export interface IImageUploadModel extends Components.Schemas.ImageUploadDto {}

export enum EImageSize {
  X56 = '56x56',
  X96 = '96x96',
  X344 = '344x344'
}

export type TImageSizes = '56' | '96' | '344';

type TImageSizeList = {
  [key in TImageSizes]: EImageSize;
};

export const IMAGE_SIZE_LIST: TImageSizeList = {
  '56': EImageSize.X56,
  '96': EImageSize.X96,
  '344': EImageSize.X344
};
