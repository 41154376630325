import axios, { AxiosStatic } from 'axios';
import { BaseHttpTransport } from '@axmit/transport';
import {
  IWalletTransactionParamsModel,
  IWalletTransactionModel,
  IWalletModel,
  IWalletTransactionCollectionFilter,
  IWalletTransactionCollection
} from 'entities/Wallet/Wallet.models';

const basePath = '/wallet-transactions';
const basePathWallet = '/wallets';

class WalletTransport extends BaseHttpTransport<
  AxiosStatic,
  IWalletModel,
  IWalletTransactionCollection,
  void,
  void,
  IWalletTransactionCollectionFilter
> {
  public directTransaction = ({
    id,
    redirectAfterSuccess,
    ...body
  }: IWalletTransactionParamsModel): Promise<IWalletTransactionModel> => axios.post(`${basePathWallet}/${id}/direct`, body);
}

export const walletTransport = new WalletTransport(basePath, axios);
