import { ColumnsType } from 'antd/lib/table';
import { RouteComponentProps, withRouter } from 'react-router';
import { TableCommon } from 'common/components/TableCommon';
import { dateFormatter } from 'common/helpers/date.helper';
import { simpleDateFormat } from 'common/models/dateModels';
import { IBaseFilterModel } from 'common/models/requestModels';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { ERoutesPrivate } from 'common/models/routesModel';
import { locationFormatter } from 'common/helpers/location.helper';
import { IAcademyCollection, IAcademyCollectionFilter, IAcademyModel } from 'entities/Academy/Academy.models';
import { communicationAcademy, IAcademyConnectedProps } from 'entities/Academy/Academy.communication';

interface IComponentProps {
  config: ColumnsType<any>;
}

type AllProps = IComponentProps & IAcademyConnectedProps & RouteComponentProps;

class AcademyTableComponent extends TableCommon<
  IAcademyCollection,
  IAcademyModel,
  ColumnsType<any>,
  AllProps,
  IAcademyCollectionFilter
> {
  loadCollection = (params: IBaseFilterModel) => {
    const { getAcademyCollection, filter } = this.props;
    const { location } = filter || {};

    const filterParams: IAcademyCollectionFilter = {
      ...params,
      ...location
    };

    if (filter?.name) {
      filterParams.name = filter.name;
    }
    if (filter?.status) {
      filterParams.status = filter?.status;
    }
    if (filter?.franchiseRequestType) {
      filterParams.franchiseRequestType = filter?.franchiseRequestType;
    }

    getAcademyCollection(filterParams);
  };

  getCollection = () => {
    const { academyCollection } = this.props;
    if (academyCollection?.data?.data) {
      const data: IAcademyModel[] = academyCollection.data.data.map(item => {
        return {
          ...item,
          createdAt: dateFormatter(item.createdAt, simpleDateFormat),
          addressText: locationFormatter(item.address)
        };
      });
      return {
        ...academyCollection,
        data: {
          ...academyCollection.data,
          data
        }
      };
    } else {
      return academyCollection;
    }
  };

  addQuery = (pageIndex: number) => {
    const searchPath = queryToObject<IAcademyCollectionFilter>({
      pageIndex: 1,
      location: undefined,
      status: undefined,
      name: undefined,
      franchiseRequestType: undefined
    });
    const search = objectToQuery({ ...searchPath, pageIndex });
    return this.props.history.replace({ search });
  };

  onRowClick = (item: IAcademyModel) => {
    const { history } = this.props;
    history.push(`${ERoutesPrivate.Academy}/${item.id}`);
  };

  clearCollection = () => {
    return this.props.clearAcademyCollection();
  };
}

export const AcademyTable = communicationAcademy.injector(withRouter(AcademyTableComponent));
