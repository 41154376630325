export interface IAuthParams extends Components.Schemas.CredentialsDto {}
export interface ITokenModel extends Components.Schemas.TokenPayloadDto {}
export interface IAuthModel extends Components.Schemas.TokenPairPayloadDto {}

export interface IPasswordForgotParams extends Components.Schemas.PasswordForgotDto {}
export interface IPasswordRestoreParams extends Components.Schemas.PasswordRecoveryDto {}
export interface IPasswordRestoreTokenCheckParams extends Components.Schemas.CheckTokenDto {}

export enum EAuthSuccessMessage {
  CheckEmailForConfirmedLink = 'Please check your email',
  PasswordForgot = 'Check email for password restore link',
  AccountConfirmed = 'Account successfully confirmed',
  ChangePasswordSuccess = 'Password changed'
}

export enum EAuthErrorMessage {
  InvalidRestoreCode = 'Invalid code. Try again.',
  InvalidCreds = 'Invalid credentials. Check and try again.'
}
