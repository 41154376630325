import React from 'react';
import { Checkbox, Col, Row } from 'antd';
import { useHistory } from 'react-router';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { objectToQuery } from 'common/helpers/filters.helper';
import { ITariffPermissionCollectionFilter } from 'entities/Tariff/Tariff.models';
import { IUserModel } from 'entities/User/User.models';
import { PlayerSelector } from 'entities/Player/components/PlayerSelector';
import { MentorSelector } from 'entities/User/components/MentorSelector';
import { IPlayerModel } from 'entities/Player/Player.models';

interface IProps {
  stateFilter: Partial<ITariffPermissionCollectionFilter>;
  setStateFilter: (filter: Partial<ITariffPermissionCollectionFilter>) => void;
  disabled: boolean;
}

type AllProps = IProps;

const TariffPermissionsTableFilterComponent: React.FC<AllProps> = props => {
  const { stateFilter, setStateFilter, disabled } = props;
  const history = useHistory();

  const setFilter = (partialFilter: Partial<ITariffPermissionCollectionFilter>) => {
    const filter = { ...stateFilter, ...partialFilter };

    history.replace({ search: objectToQuery(filter) });
    setStateFilter(filter);
  };

  const onChangeHasMentor = (e: CheckboxChangeEvent) => {
    setFilter({ hasMentor: e.target.checked });
  };

  const onChangeHasPlayer = (e: CheckboxChangeEvent) => {
    setFilter({ hasPlayer: e.target.checked });
  };

  const onChangePlayer = (playerModel?: IPlayerModel) => {
    const playerFilter = playerModel
      ? { id: playerModel.id, firstName: playerModel.firstName, lastName: playerModel.lastName }
      : undefined;

    setFilter({ playerFilter });
  };

  const onChangeMentor = (mentorModel?: IUserModel) => {
    const mentorFilter = mentorModel
      ? { id: mentorModel.id, firstName: mentorModel.firstName, lastName: mentorModel.lastName }
      : undefined;

    setFilter({ mentorFilter });
  };

  return (
    <Row justify="space-between" align="middle" className="mb-5">
      <Checkbox checked={stateFilter.hasMentor} disabled={disabled} onChange={onChangeHasMentor}>
        Has mentor
      </Checkbox>
      <Checkbox checked={stateFilter.hasPlayer} disabled={disabled} onChange={onChangeHasPlayer}>
        Has player
      </Checkbox>
      <Col span={8}>
        <PlayerSelector
          disabled={disabled}
          onChange={onChangePlayer}
          value={stateFilter.playerFilter}
          placeholder="Search by player"
        />
      </Col>
      <Col span={8}>
        <MentorSelector
          disabled={disabled}
          onChange={onChangeMentor}
          value={stateFilter.mentorFilter}
          placeholder="Search by mentor"
        />
      </Col>
    </Row>
  );
};

export const TariffPermissionsTableFilter = TariffPermissionsTableFilterComponent;
