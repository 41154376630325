import React from 'react';
import { Col, Row, Statistic } from 'antd';
import { normalize, normalizeMoney } from 'common/helpers/normalize.helper';
import { communicationSubscription, ISubscriptionConnectedProps } from 'entities/Subscription/Subscription.communication';
import { ISubscriptionActivityMeta } from 'entities/Subscription/Subscription.models';

type AllProps = ISubscriptionConnectedProps;

const SubscriptionPanelStatisticComponent = ({ subscriptionActivityCollection }: AllProps) => {
  const { data } = subscriptionActivityCollection;
  const { meta } = data ?? {};
  const { playerCount = 0, totalSum = 0 } = (meta ?? {}) as ISubscriptionActivityMeta;

  return (
    <Row gutter={[32, 16]}>
      <Col>
        <Statistic title="Players" value={normalize(playerCount)} />
      </Col>
      <Col>
        <Statistic title="Total" value={normalizeMoney(totalSum, 'RUB')} />
      </Col>
    </Row>
  );
};

export const SubscriptionPanelStatistic = communicationSubscription.injector(SubscriptionPanelStatisticComponent);
