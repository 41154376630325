import { IPlayerModel } from 'entities/Player/Player.models';

export const nameBuilder = (firstName?: string, lastName?: string, replace?: string): string =>
  firstName && lastName ? `${firstName} ${lastName}` : replace ? replace : 'Unknown name';

export const getPlayerName = (player?: IPlayerModel): string => {
  if (!player) {
    return 'Unknown name';
  }
  return nameBuilder(player.firstName, player.lastName);
};

export const addressBuilder = (country?: string, city?: string): string =>
  country && city ? `${country}, ${city}` : 'Unknown address';
