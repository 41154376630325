import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { EFranchiseTypes, EFranchiseTypesText } from 'entities/Franchise/Franchise.models';

interface IComponentProps {
  onChange?: (value?: EFranchiseTypes) => void;
  disabled?: boolean;
}

type AllProps = SelectProps<any> & IComponentProps;

export class AcademyFranchiseSelector extends React.PureComponent<AllProps> {
  render() {
    const { value, onChange, disabled = false } = this.props;

    return (
      <Select
        allowClear
        className="width-full"
        placeholder="Select franchise type"
        value={value}
        defaultActiveFirstOption={false}
        showArrow
        onChange={(value: EFranchiseTypes) => onChange && onChange(value)}
        disabled={disabled}
      >
        <Select.Option key={EFranchiseTypes.Main} value={EFranchiseTypes.Main} label={EFranchiseTypesText.Main}>
          {EFranchiseTypesText.Main}
        </Select.Option>
        <Select.Option key={EFranchiseTypes.Child} value={EFranchiseTypes.Child} label={EFranchiseTypesText.Child}>
          {EFranchiseTypesText.Child}
        </Select.Option>
      </Select>
    );
  }
}
