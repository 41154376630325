import React from 'react';
import queryString from 'query-string';
import { Button, Col, Form, Input, message, Row } from 'antd';
import { useUpdateFormValidation } from '@axmit/antd4-helpers';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { ERoutesPublic } from 'common/models/routesModel';
import { passwordRules, requiredRules } from 'common/helpers/rules.helper';
import { IPasswordRestoreParams } from 'entities/Auth/Auth.models';
import { IAuthConnectedProps, communicationAuth } from 'entities/Auth/Auth.communication';

type AllProps = IAuthConnectedProps;

const PasswordChangeFormComponent: React.FC<AllProps> = props => {
  const { updateAuthPasswordRestore, authModel } = props;
  const { loading, errors, params } = authModel;

  const { fields } = useUpdateFormValidation(['password'], null, errors, params);
  const { location } = useHistory();

  const token = queryString.parse(location.search).token as string;

  const onFinish = (values: any) => {
    if (!token) {
      return message.error('Token is not exist');
    }
    const { password } = values;
    const body: IPasswordRestoreParams = {
      password,
      token
    };
    updateAuthPasswordRestore(body);
    return true;
  };

  return (
    <CommonLayout typeLayout={ELayoutSize.Small} classStyle="basic__layout_m-t">
      <Row justify="center" className="mb-4">
        <h1 className="basic__text_title fs-md" title="Log in">
          Restore password
        </h1>
      </Row>
      <Form onFinish={onFinish} fields={fields}>
        <Form.Item rules={[requiredRules, passwordRules]} name="password" label="New Password">
          <Input.Password type="password" placeholder="Password" disabled={loading} />
        </Form.Item>

        <Form.Item>
          <Row justify="space-between" align="middle" gutter={3}>
            <Col span={10}>
              <Button htmlType="submit" block type="primary" title="Restore" disabled={loading} loading={loading}>
                Change password
              </Button>
            </Col>
            <Col>
              <Link to={ERoutesPublic.Login}>Login</Link>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </CommonLayout>
  );
};

export const PasswordChangePage = communicationAuth.injector(PasswordChangeFormComponent);
