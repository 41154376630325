import { RuleObject } from 'antd/lib/form';

export const isPhone = (phone: string) => /\d{11,16}/g.test(phone);

export const requiredRules = { required: true, message: 'Required field' };

export const phoneRule = () => ({
  validator(rule: RuleObject, value: string) {
    if (!value || !value.replace(/\+7/, '') || /\d{11,16}/g.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject('format error');
  }
});

export const passwordRules = () => ({
  validator(rule: RuleObject, value: string) {
    if (value) {
      if (/[^a-zA-Z0-9!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}]/.test(value)) {
        return Promise.reject('Only Latin characters, numbers and special characters are allowed');
      }
      if (value?.length < 8) {
        return Promise.reject('Password must be 8 or more characters');
      }
      if (value?.length > 64) {
        return Promise.reject('Password must contain a maximum of 64 characters');
      }
      if (!/[a-z]/.test(value)) {
        return Promise.reject('The password must contain at least 1 uppercase letter');
      }
      if (!/[A-Z]/.test(value)) {
        return Promise.reject('Password must contain at least 1 uppercase letter');
      }
      if (!/[0-9]/.test(value)) {
        return Promise.reject('Password must contain at least 1 digit');
      }
    }
    return Promise.resolve();
  }
});
