import React from 'react';
import { Col, Row, DatePicker, Checkbox } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import moment, { Moment } from 'moment';
import { SortOrder } from 'antd/lib/table/interface';
import { ColumnsType } from 'antd/es/table';
import Button from 'antd/es/button';
import { LocationInput } from 'common/components/Input/LocationInput';
import { ELocationType, ILocationModel } from 'common/helpers/location.helper';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { simpleDateFormatWithTime, simpleTimeFormat } from 'common/models/dateModels';
import { getUTCEndOfDay, getUTCStartOfDay } from 'common/helpers/date.helper';
import { PlayerAgeGroupSelector } from 'entities/Player/components/Selector/PlayerAgeGroupSelector';
import { IAdminModel } from 'entities/Admin/Admin.models';
import { PlayerTestTable } from 'entities/PlayerTest/components/PlayerTestTable';
import {
  EPlayerTestInvestigationStatus,
  EPlayerTestStatus,
  EPlayerToTestUploaderType,
  IPlayerTestCollectionFilter,
  tablePlayerTestConfig
} from 'entities/PlayerTest/PlayerTest.models';
import { TestSelector } from 'entities/Test/components/Selector/TestSelector';
import { ITestModel } from 'entities/Test/Test.models';
import { PlayerTestStatusSelector } from 'entities/PlayerTest/components/Selector/PlayerTestStatusSelector';
import { PlayerSelector } from 'entities/Player/components/PlayerSelector';
import { PlayerTestUploaderSelector } from 'entities/PlayerTest/components/Selector/PlayerTestUploaderSelector';
import { EPlayerAgeGroups, IPlayerModel } from 'entities/Player/Player.models';
import { PlayerTestQueueTaskStatistic } from 'entities/PlayerTest/components/PlayerTestQueueTaskStatistic';
import { TrainerSelector } from 'entities/User/components/TrainerSelector';
import { UserByRoleSelector } from 'entities/User/components/UserByRoleSelector';
import { EUserRoles } from 'entities/User/User.models';
import { IAuthConnectedProps, communicationAuth } from 'entities/Auth/Auth.communication';
import { PlayerTestInvestigationStatus } from 'entities/PlayerTest/components/Selector/PlayerTestInvestigationStatus';
import { PlayerTestOnlyBestSelector } from 'entities/PlayerTest/components/Selector/PlayerTestOnlyBestSelector';
import { AcademySelector } from 'entities/Academy/components/Selector/AcademySelector';
import { IAcademyModel } from 'entities/Academy/Academy.models';

type AllProps = RouteComponentProps & IAuthConnectedProps;

const { RangePicker } = DatePicker;

interface IComponentState {
  configTableValue: ColumnsType<any>;
  filter: IPlayerTestCollectionFilter;
}

const UserBySelectorRoles = [EUserRoles.AcademyWorker, EUserRoles.Trainer];

class PlayerTestPageComponent extends React.PureComponent<AllProps, IComponentState> {
  state: IComponentState = {
    configTableValue: tablePlayerTestConfig,
    filter: {
      pageIndex: 1,
      status: undefined,
      test: undefined,
      player: undefined,
      testFilter: undefined,
      playerFilter: undefined,
      uploadByFilter: undefined,
      trainerFilter: undefined,
      uploaderType: undefined,
      from: getUTCStartOfDay(moment().subtract(1, 'months')),
      to: getUTCEndOfDay(moment()),
      orderField: undefined,
      orderDirection: undefined,
      investigationStatus: undefined,
      onlyBestOfDay: undefined,
      academyFilter: undefined,
      onlyAutoReject: undefined,
      location: undefined,
      ageGroup: undefined
    }
  };

  constructor(props: AllProps) {
    super(props);
    this.state.filter = { ...this.state.filter, ...queryToObject<IPlayerTestCollectionFilter>(this.state.filter) };
    const orderField = this.state?.filter?.orderField;
    const orderDirection = this.state?.filter?.orderDirection;
    if (orderField === 'createdAt') {
      const defaultSortOrder = (orderDirection === 'ASC' ? 'ascend' : 'descend') as SortOrder;
      this.state.configTableValue = tablePlayerTestConfig?.map(item => {
        if (item?.dataIndex === 'testDate') {
          return { ...item, defaultSortOrder };
        }
        return item;
      });
    }
  }

  render() {
    const { filter, configTableValue } = this.state;
    const { authAdmin } = this.props;
    const {
      playerFilter,
      testFilter,
      status,
      from,
      to,
      uploaderType,
      uploadByFilter,
      trainerFilter,
      investigationStatus,
      onlyBestOfDay,
      ageGroup,
      location,
      onlyAutoReject,
      academyFilter
    } = filter;
    const fromValue = from ? moment(from) : null;
    const toValue = to ? moment(to) : null;
    const isAdmin = authAdmin?.data?.role === EUserRoles.Admin;

    return (
      <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10">
        <Row gutter={[16, 16]} className="mb-5 width-full" justify="start" align="middle">
          <Col span={4}>
            <TestSelector onChange={this.onChangeTest} value={testFilter} placeholder="Search by test" />
          </Col>
          <Col span={4}>
            <PlayerSelector onChange={this.onChangePlayer} value={playerFilter} placeholder="Search by player" />
          </Col>
          <Col span={4}>
            <AcademySelector
              onChange={this.onChangeAcademy}
              value={academyFilter}
              filter={{ status: 'active' }}
              placeholder="Search by academy"
            />
          </Col>
          <Col span={3}>
            <PlayerAgeGroupSelector
              onChange={this.onChangeAgeGroups}
              value={ageGroup}
              isSingle
              placeholder="Search by age group"
            />
          </Col>
          <Col span={4}>
            <PlayerTestStatusSelector onChange={this.onChangeStatus} value={status} placeholder="Search by status" />
          </Col>
          <Col span={4}>
            <PlayerTestUploaderSelector
              value={uploaderType}
              onChange={this.onChangeUploader}
              placeholder="Search by uploader type"
            />
          </Col>

          <Col span={4}>
            <RangePicker
              allowEmpty={[true, true]}
              showNow={false}
              showTime={{ format: simpleTimeFormat }}
              value={[fromValue, toValue]}
              format={simpleDateFormatWithTime}
              onChange={this.onChangeTimeDate}
            />
          </Col>
          <Col span={4}>
            <UserByRoleSelector
              onChange={this.onChangeUploadBy}
              roles={UserBySelectorRoles}
              value={uploadByFilter}
              placeholder="Search by uploaded user"
            />
          </Col>
          {isAdmin && (
            <Col span={4}>
              <TrainerSelector onChange={this.onChangeTrainer} value={trainerFilter} placeholder="Search by trainer" />
            </Col>
          )}
          <Col span={4}>
            <PlayerTestInvestigationStatus
              value={investigationStatus}
              placeholder="Investigation status"
              onChange={this.onChangeInvestigationStatus}
            />
          </Col>
          <Col span={4}>
            <PlayerTestOnlyBestSelector
              value={onlyBestOfDay}
              onChange={this.onChangeOnlyBest}
              placeholder="Select only best of day"
            />
          </Col>
          <Col span={4}>
            <LocationInput
              placeholder="Search by location"
              onChange={this.onChangeLocation}
              value={location}
              types={[ELocationType.Country]}
            />
          </Col>
          <Col span={4}>
            <Checkbox checked={onlyAutoReject} onChange={this.onChangeHideArchive}>
              Automoderation
            </Checkbox>
          </Col>
        </Row>
        <PlayerTestQueueTaskStatistic />
        <PlayerTestTable config={configTableValue} filter={filter} />
      </CommonLayout>
    );
  }

  onChangeHideArchive = (e: CheckboxChangeEvent) => {
    this.setFilter({ onlyAutoReject: e.target.checked });
  };

  onChangeLocation = (location?: ILocationModel) => {
    this.setFilter({ location });
  };

  setFilter = (partialFilter: Partial<IPlayerTestCollectionFilter>) => {
    const oldFilter = this.state.filter;
    const filter = { ...oldFilter, ...partialFilter };

    this.props.history.replace({ search: objectToQuery(filter) });
    this.setState({ filter });
  };

  onChangeAgeGroups = (ageGroup?: EPlayerAgeGroups | EPlayerAgeGroups[]) => {
    if (!Array.isArray(ageGroup)) {
      this.setFilter({ ageGroup });
    }
  };

  onChangeUploader = (uploaderType?: EPlayerToTestUploaderType) => {
    this.setFilter({ uploaderType });
  };

  onChangeOnlyBest = (onlyBest?: boolean) => {
    this.setFilter({ onlyBestOfDay: onlyBest });
  };

  onChangeTest = (testModel?: ITestModel) => {
    const { id, title } = testModel || {};
    const testFilter = id && title ? { id, title } : undefined;
    this.setFilter({ testFilter });
  };

  onChangePlayer = (playerModel?: IPlayerModel) => {
    const playerFilter = playerModel
      ? { id: playerModel.id, firstName: playerModel.firstName, lastName: playerModel.lastName }
      : undefined;

    this.setFilter({ playerFilter });
  };
  onChangeAcademy = (academyModel?: IAcademyModel) => {
    const academyFilter = academyModel
      ? {
          id: academyModel?.id,
          name: academyModel?.name
        }
      : undefined;

    this.setFilter({ academyFilter });
  };
  onChangeUploadBy = (userModel?: IAdminModel) => {
    const uploadByFilter = userModel
      ? { id: userModel.id, firstName: userModel.firstName, lastName: userModel.lastName }
      : undefined;

    this.setFilter({ uploadByFilter });
  };

  onChangeTrainer = (trainerModel?: IAdminModel) => {
    const trainerFilter = trainerModel
      ? { id: trainerModel.id, firstName: trainerModel.firstName, lastName: trainerModel.lastName }
      : undefined;

    this.setFilter({ trainerFilter });
  };

  onChangeStatus = (status?: EPlayerTestStatus) => {
    this.setFilter({ status });
  };
  onChangeInvestigationStatus = (investigationStatus?: EPlayerTestInvestigationStatus) => {
    this.setFilter({ investigationStatus });
  };

  onChangeTimeDate = (dates: [Moment | null, Moment | null] | null) => {
    const fromDate = dates?.[0];
    const toDate = dates?.[1];
    const from = fromDate ? fromDate.toISOString() : null;
    const to = toDate ? toDate.toISOString() : null;

    this.setFilter({ from, to });
  };
}

export const PlayerTestTablePage = communicationAuth.injector(withRouter(PlayerTestPageComponent));
