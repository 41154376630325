import React, { ChangeEvent } from 'react';
import { Button, Col, DatePicker, Input, Row } from 'antd';
import debounce from 'lodash.debounce';
import moment, { Moment } from 'moment';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { ColumnsType } from 'antd/es/table';
import { SortOrder } from 'antd/lib/table/interface';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { simpleDateFormatWithTime, simpleTimeFormat } from 'common/models/dateModels';
import { FeedTable } from 'entities/Feed/components/FeedTable';
import { IFeedCollectionFilter, tableFeedConfig, EFeedStatus, EFeedType } from 'entities/Feed/Feed.models';
import { FeedStatusSelector } from 'entities/Feed/components/Selectors/FeedStatusSelector';
import { FeedTypeSelector } from 'entities/Feed/components/Selectors/FeedTypeSelector';
import { EEntityType, TEntityValue } from 'entities/User/User.models';
import { EntityTypeSelector } from 'entities/User/components/EntityTypeSelector';
import { AcademySelector } from 'entities/Academy/components/Selector/AcademySelector';
import { PlayerSelector } from 'entities/Player/components/PlayerSelector';
import { UserByRoleSelector } from 'entities/User/components/UserByRoleSelector';

type AllProps = RouteComponentProps;

const { RangePicker } = DatePicker;

interface IComponentState {
  filter: IFeedCollectionFilter;
  configTableValue: ColumnsType<any>;
}

class FeedPageComponent extends React.PureComponent<AllProps, IComponentState> {
  public debounceSearch: any;
  state: IComponentState = {
    configTableValue: tableFeedConfig,
    filter: {
      pageIndex: 1,
      title: undefined,
      status: undefined,
      from: undefined,
      to: undefined,
      type: undefined,
      authorEntity: undefined,
      authorValue: undefined,
      orderDirection: undefined,
      orderField: undefined
    }
  };
  constructor(props: AllProps) {
    super(props);
    this.state.filter = { ...this.state.filter, ...queryToObject<IFeedCollectionFilter>(this.state.filter) };
    const orderField = this.state?.filter?.orderField;
    const orderDirection = this.state?.filter?.orderDirection;
    if (orderField === 'createdAt' || orderField === 'status' || orderField === 'type' || orderField === 'likes') {
      const defaultSortOrder = (orderDirection === 'ASC' ? 'ascend' : 'descend') as SortOrder;
      this.state.configTableValue = tableFeedConfig?.map(item => {
        if (item?.dataIndex === orderField) {
          return { ...item, defaultSortOrder };
        }
        return item;
      });
    }
    this.debounceSearch = debounce(this.autocompleteSearch, 300);
  }

  render() {
    const { filter, configTableValue } = this.state;
    const { title, status, from, to, type, authorEntity, authorValue } = filter;
    const fromValue = from ? moment(from) : null;
    const toValue = to ? moment(to) : null;
    const renderSelector = (entityTypeValue?: EEntityType, onChange?: () => void, value?: TEntityValue) => {
      switch (entityTypeValue) {
        case EEntityType.Academy: {
          return <AcademySelector onChange={onChange} value={value} placeholder="Select academy" disabled={true} />;
        }
        case EEntityType.Player: {
          return <PlayerSelector onChange={onChange} value={value} placeholder="Select player" />;
        }
        case EEntityType.User: {
          return <UserByRoleSelector roles={[]} onChange={onChange} value={value} placeholder="Select user" />;
        }
        default: {
          return null;
        }
      }
    };
    return (
      <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10">
        <Row gutter={[24, 16]} className="mb-10 width-full" justify="start">
          <Col span={6}>
            <Row gutter={[16, 8]}>
              <Input.Search
                placeholder="Search by title"
                defaultValue={title}
                onChange={(e: ChangeEvent<HTMLInputElement>) => this.debounceSearch(e.target.value)}
              />
              <RangePicker
                allowEmpty={[true, true]}
                showNow={false}
                showTime={{ format: simpleTimeFormat }}
                value={[fromValue, toValue]}
                format={simpleDateFormatWithTime}
                onChange={this.onChangeTimeDate}
              />
            </Row>
          </Col>
          <Col span={6}>
            <Row gutter={[16, 8]}>
              <FeedStatusSelector onChange={this.onChangeStatus} value={status} placeholder="Search by status" />
              <FeedTypeSelector onChange={this.onChangeType} value={type} placeholder="Search by type" />
            </Row>
          </Col>

          <Col span={6}>
            <Row gutter={[16, 8]} align="top">
              <EntityTypeSelector
                onChange={this.onChangeTargetEntityType}
                value={authorEntity}
                placeholder="Select author entity"
              />
              {renderSelector(authorEntity, this.onChangeAuthor, authorValue)}
            </Row>
          </Col>
          <Col span={6}>
            <Row justify="end">
              <Col span={14}>
                <Link to={ERoutesPrivate.FeedCreate}>
                  <Button block type="primary" title="Add feed">
                    Add feed
                  </Button>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
        <FeedTable config={configTableValue} filter={filter} />
      </CommonLayout>
    );
  }

  setFilter = (partialFilter: Partial<IFeedCollectionFilter>) => {
    const oldFilter = this.state.filter;
    const filter = { ...oldFilter, ...partialFilter };

    this.props.history.replace({ search: objectToQuery(filter) });
    this.setState({ filter });
  };

  onChangeTimeDate = (dates: [Moment | null, Moment | null] | null) => {
    const fromDate = dates?.[0];
    const toDate = dates?.[1];
    const from = fromDate ? fromDate.toISOString() : undefined;
    const to = toDate ? toDate.toISOString() : undefined;

    this.setFilter({ from, to });
  };

  onChangeStatus = (status?: EFeedStatus) => {
    this.setFilter({ status });
  };

  onChangeType = (type?: EFeedType) => {
    this.setFilter({ type });
  };

  autocompleteSearch = (text: string) => {
    const title = text === '' ? undefined : text;
    this.setFilter({ title });
  };

  onChangeTargetEntityType = (authorEntityValue?: EEntityType) => {
    const authorValue = this.state.filter || {};

    this.setFilter(
      !authorEntityValue || authorEntityValue !== authorValue
        ? { authorEntity: authorEntityValue, authorValue: undefined }
        : { authorEntity: authorEntityValue }
    );
  };
  onChangeAuthor = (authorValue?: TEntityValue) => {
    this.setFilter({ authorValue });
  };
}

export const FeedTablePage = withRouter(FeedPageComponent);
