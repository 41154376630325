import React, { useMemo, useState } from 'react';
import { Col, Row } from 'antd';
import { StoreBranch } from '@axmit/redux-communications';
import { queryToObject } from 'common/helpers/filters.helper';
import { IPlayerModel } from 'entities/Player/Player.models';
import { IUserModel } from 'entities/User/User.models';
import {
  EAddPermissionType,
  EAddPermissionTypeSingle,
  ETariffVisibility,
  ITariffModel,
  ITariffPermissionCollectionFilter,
  ITariffPermissionModel,
  ITariffUpdatePermissions,
  tableTariffPermissionsConfig,
  TTariffParamsModel
} from 'entities/Tariff/Tariff.models';
import { MentorSelector } from 'entities/User/components/MentorSelector';
import { PlayerSelector } from 'entities/Player/components/PlayerSelector';
import { TariffPermissionsTable } from 'entities/Tariff/components/Permissions/TariffPermissionsTable';
import { TariffPermissionsTableFilter } from 'entities/Tariff/components/Permissions/TariffPermissionsTableFilters';
import { TariffForm } from 'entities/Tariff/components/Form/TariffForm';

interface IComponentProps {
  tariffModel: StoreBranch<ITariffModel, TTariffParamsModel>;
  tariffId?: string;
  addTariffPermissionModel: (params: ITariffUpdatePermissions) => void;
  tariffPermissionModel: StoreBranch<ITariffPermissionModel, ITariffUpdatePermissions>;
}

const TariffFormComponent: React.FC<IComponentProps> = props => {
  const { tariffModel, tariffId, addTariffPermissionModel, tariffPermissionModel } = props;
  const { loading, data } = tariffModel;
  const { loading: tariffPermissionsLoading } = tariffPermissionModel;
  const [stateFilter, setStateFilter] = useState<Partial<ITariffPermissionCollectionFilter>>(
    queryToObject<Partial<ITariffPermissionCollectionFilter>>({
      pageIndex: 1,
      hasMentor: undefined,
      hasPlayer: undefined,
      playerFilter: undefined,
      mentorFilter: undefined
    })
  );
  const isEdit = !!tariffId;
  const permissionsLoading = useMemo(() => loading || tariffPermissionsLoading, [tariffPermissionsLoading, loading]);
  const isPrivate = useMemo(() => !!data && data?.visibility === ETariffVisibility.Private, [data]);

  const onAddPermission = (entity: IPlayerModel | IUserModel | undefined, type: EAddPermissionType) => {
    if (entity?.id && tariffId) {
      addTariffPermissionModel({
        id: tariffId,
        [type]: [{ [EAddPermissionTypeSingle[type]]: entity.id }]
      });
    }
  };

  return (
    <Row className="mt-5">
      <Row justify="space-between" className="width-full">
        <Col span={11}>
          <TariffForm isEdit={isEdit} />
        </Col>
        {isEdit && isPrivate && tariffId && (
          <Col span={12}>
            <>
              <Row className="mb-5">
                <span className="basic__text_title" title="Personal info">
                  Add permission <span className="color-danger">(submit tariff changes before changing permissions)</span>
                </span>
              </Row>
              <Row justify="space-between" className="mb-5">
                <Col span={2}>
                  <span className="basic__text_label" title="Personal info">
                    Mentor
                  </span>
                </Col>
                <Col span={21}>
                  <MentorSelector
                    disabled={permissionsLoading}
                    onChange={(mentor: IUserModel | undefined) => onAddPermission(mentor, EAddPermissionType.Mentors)}
                  />
                </Col>
              </Row>
              <Row justify="space-between" className="mb-5">
                <Col span={2}>
                  <span className="basic__text_label" title="Personal info">
                    Player
                  </span>
                </Col>
                <Col span={21}>
                  <PlayerSelector
                    disabled={permissionsLoading}
                    onChange={(player: IPlayerModel | undefined) => onAddPermission(player, EAddPermissionType.Players)}
                  />
                </Col>
              </Row>
            </>
            <Row className="my-5">
              <span className="basic__text_title" title="Personal info">
                Permissions
              </span>
            </Row>
            <TariffPermissionsTableFilter
              disabled={permissionsLoading}
              setStateFilter={setStateFilter}
              stateFilter={stateFilter}
            />
            <TariffPermissionsTable config={tableTariffPermissionsConfig} filter={stateFilter} tariffId={tariffId} />
          </Col>
        )}
      </Row>
    </Row>
  );
};

export const TariffComponent = TariffFormComponent;
