import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { useMediaPredicate } from 'react-media-hook';
import { Row, Col, Button, Dropdown, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';
import { MEDIA_DESKTOP } from 'common/const/config.const';
import { ERoutesCommon, ERoutesPrivate } from 'common/models/routesModel';
import { Logo } from 'common/components/Header/Logo';
import { IAuthConnectedProps, communicationAuth } from 'entities/Auth/Auth.communication';
import { EAdminRole } from 'entities/Admin/Admin.models';
import { isAvailable } from 'entities/Auth/components/PrivateContent';
import { EUserRoles } from 'entities/User/User.models';

type AllProps = IAuthConnectedProps & RouteComponentProps;

const AdminRoutes = [
  {
    to: ERoutesPrivate.Academy,
    title: 'Academy',
    dropdown: false
  },
  {
    to: ERoutesPrivate.TestResults,
    title: 'Test results',
    dropdown: false
  },
  {
    to: ERoutesPrivate.Test,
    title: 'Tests',
    dropdown: false
  },
  {
    to: ERoutesPrivate.UserList,
    title: 'User list',
    dropdown: false
  },
  {
    to: ERoutesPrivate.Giftcards,
    title: 'Giftcards',
    dropdown: true
  },
  {
    to: ERoutesPrivate.Tariffs,
    title: 'Tariffs',
    dropdown: true
  },
  {
    to: ERoutesPrivate.Players,
    title: 'Players',
    dropdown: false
  },
  {
    to: ERoutesPrivate.Payments,
    title: 'Payments',
    dropdown: false
  },
  {
    to: ERoutesPrivate.Statistic,
    title: 'Statistic',
    dropdown: false
  },
  {
    to: ERoutesPrivate.NotificationTemplates,
    title: 'Notification templates',
    dropdown: true
  },
  {
    to: ERoutesPrivate.TestResultStatistic,
    title: 'Test result dashboard',
    dropdown: true
  },
  {
    to: ERoutesPrivate.Mail,
    title: 'Mail',
    dropdown: true
  },
  {
    to: ERoutesPrivate.Languages,
    title: 'Languages',
    dropdown: false
  },
  {
    to: ERoutesPrivate.Influencers,
    title: 'Influencers',
    dropdown: true
  },
  {
    to: ERoutesPrivate.Skins,
    title: 'Skins',
    dropdown: true
  },
  {
    to: ERoutesPrivate.Feed,
    title: 'Feed',
    dropdown: true
  }
];

const TaggerRoutes = [
  {
    to: ERoutesPrivate.TestResults,
    title: 'Test results',
    dropdown: false
  },
  {
    to: ERoutesPrivate.Academy,
    title: 'Academy',
    dropdown: false
  },
  {
    to: ERoutesPrivate.Test,
    title: 'Tests',
    dropdown: false
  },
  {
    to: ERoutesPrivate.Players,
    title: 'Players',
    dropdown: false
  },
  {
    to: ERoutesPrivate.TestResultStatistic,
    title: 'Test result dashboard',
    dropdown: true
  },
  {
    to: ERoutesPrivate.Mail,
    title: 'Mail',
    dropdown: true
  },
  {
    to: ERoutesPrivate.Feed,
    title: 'Feed',
    dropdown: true
  }
];

const HeaderComponent: React.FC<AllProps> = props => {
  const { authModel, history, authAdmin, deleteAuthModel } = props;
  const { data: authData } = authModel;
  const isDesktop: boolean = useMediaPredicate(`(min-width: ${MEDIA_DESKTOP})`);
  const isAdminAuthorized = authData?.access;
  const isAdminRole = authAdmin?.data?.role === EAdminRole.Admin;
  const currentPage = history.location.pathname;
  const mainMenu = isAdminRole ? AdminRoutes : TaggerRoutes;
  const isAvailableForUser = isAvailable(EUserRoles.Admin);
  const getItemTextColor = (to: ERoutesPrivate | ERoutesCommon): React.CSSProperties | undefined => {
    return {
      color: to === currentPage ? 'black' : '#0289d6'
    };
  };

  return (
    <Row className="basic_header flex-noWrap" align="middle">
      <Col span={4}>
        <Row align="middle" justify="center">
          <Link to={ERoutesCommon.Root}>
            <Logo />
          </Link>
        </Row>
      </Col>

      {isAdminAuthorized && (
        <Col span={18}>
          <Row align="middle" justify="space-between" className="ml-8">
            {isDesktop && (
              <Col>
                {mainMenu.map(item =>
                  !item?.dropdown ? (
                    <Link key={item.to} to={item.to} title={item.title} className="mr-8" style={getItemTextColor(item?.to)}>
                      {item.title}
                    </Link>
                  ) : null
                )}
              </Col>
            )}

            <Dropdown
              overlay={
                <Menu>
                  {isAvailableForUser && (
                    <>
                      <Menu.Item key="Products">
                        <Link to={ERoutesPrivate.Product} style={getItemTextColor(ERoutesPrivate.Product)}>
                          Products
                        </Link>
                      </Menu.Item>
                      <Menu.Item key="Story">
                        <Link to={ERoutesPrivate.Story} style={getItemTextColor(ERoutesPrivate.Story)}>
                          Story
                        </Link>
                      </Menu.Item>
                      <Menu.Item key="Coins">
                        <Link to={ERoutesPrivate.WalletTransactions} style={getItemTextColor(ERoutesPrivate.WalletTransactions)}>
                          Coins
                        </Link>
                      </Menu.Item>
                    </>
                  )}
                  <Menu.Item key="Settings">
                    <Link to={ERoutesPrivate.Settings} style={getItemTextColor(ERoutesPrivate.Settings)}>
                      Setting
                    </Link>
                  </Menu.Item>
                  {mainMenu.map(item =>
                    (isDesktop && item?.dropdown) || !isDesktop ? (
                      <Menu.Item className="ant-dropdown-menu-item ant-dropdown-menu-item-only-child" key={item.to}>
                        <Link to={item.to} style={getItemTextColor(item.to)}>
                          {item.title}
                        </Link>
                      </Menu.Item>
                    ) : null
                  )}
                  <Menu.Item key="Log out">
                    <div className="cursor-pointer" key="logout" title="Log Out" onClick={deleteAuthModel}>
                      Log Out
                    </div>
                  </Menu.Item>
                </Menu>
              }
              placement="bottomLeft"
            >
              <Button type="text">
                <MenuOutlined />
              </Button>
            </Dropdown>
          </Row>
        </Col>
      )}
    </Row>
  );
};

export const Header = communicationAuth.injector(withRouter(HeaderComponent));
