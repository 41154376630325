import { AbstractSearchableSelector } from 'common/components/Selector/AbstractSearchableSelector';
import { IAdminCollectionFilter, IAdminModel } from 'entities/Admin/Admin.models';
import { EUserRoles } from 'entities/User/User.models';
import { adminTransport } from 'entities/Admin/Admin.transport';

interface IComponentProps {}

export class TrainerSelector extends AbstractSearchableSelector<IAdminModel, IComponentProps> {
  getItemLabel = (item: IAdminModel): string => {
    return `${item?.firstName || 'Unknown'} ${item?.lastName || 'name'}`;
  };

  loadData = (value?: string): Promise<{ data: IAdminModel[] }> => {
    const filter: IAdminCollectionFilter = {};
    if (value) {
      filter.search = value;
    }
    filter.roles = [EUserRoles.Trainer];
    return adminTransport.getAdminSearch(filter);
  };
}
