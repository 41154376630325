import React, { ChangeEvent } from 'react';
import { Col, DatePicker, Input, Row } from 'antd';
import debounce from 'lodash.debounce';
import { RouteComponentProps, withRouter } from 'react-router';
import moment, { Moment } from 'moment';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { simpleDateFormat } from 'common/models/dateModels';
import { getUTCEndOfDay, getUTCStartOfDay } from 'common/helpers/date.helper';
import { InfluencerTable } from 'entities/Influencer/components/InfluencerTable';
import { IInfluencerCollectionFilter, statisticTableConfig } from 'entities/Influencer/Influencer.models';

type AllProps = RouteComponentProps;

interface IComponentState {
  filter: IInfluencerCollectionFilter;
}

const { RangePicker } = DatePicker;

class InfluecnerPageComponent extends React.PureComponent<AllProps, IComponentState> {
  public debounceSearch: any;
  state: IComponentState = {
    filter: {
      pageIndex: 1,
      tags: undefined,
      from: moment()
        .subtract(2, 'week')
        .toISOString(),
      to: moment().toISOString()
    }
  };
  constructor(props: AllProps) {
    super(props);
    this.state.filter = { ...this.state.filter, ...queryToObject<IInfluencerCollectionFilter>(this.state.filter) };
    this.debounceSearch = debounce(this.autocompleteSearch, 300);
  }

  render() {
    const { filter } = this.state;
    const { tags, from, to } = filter;
    const tagValue = tags?.[0] || '';
    const fromValue = from ? moment(from) : moment().subtract(2, 'week');
    const toValue = to ? moment(to).utc() : moment();

    return (
      <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10">
        <Row className="mb-10 width-full" justify="space-between" align="middle">
          <Col span={19}>
            <Row gutter={[8, 8]} align="middle">
              <Col span={5}>
                <Input.Search
                  placeholder="Search by influencer tag"
                  defaultValue={tagValue}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => this.debounceSearch(e.target.value)}
                />
              </Col>
              <Col span={5}>
                <RangePicker
                  allowEmpty={[false, false]}
                  clearIcon={false}
                  showNow={false}
                  showTime={false}
                  value={[fromValue, toValue]}
                  format={simpleDateFormat}
                  onChange={this.onChangeTimeDate}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <InfluencerTable config={statisticTableConfig} filter={filter} />
      </CommonLayout>
    );
  }

  setFilter = (partialFilter: Partial<IInfluencerCollectionFilter>) => {
    const oldFilter = this.state.filter;
    const filter = { ...oldFilter, ...partialFilter };

    this.props.history.replace({ search: objectToQuery(filter) });
    this.setState({ filter });
  };

  onChangeTimeDate = (dates: [Moment | null, Moment | null] | null) => {
    const fromDate = dates?.[0];
    const toDate = dates?.[1];
    const from = fromDate ? getUTCStartOfDay(fromDate) : undefined;
    const to = toDate ? getUTCEndOfDay(toDate) : undefined;

    this.setFilter({ from, to });
  };

  autocompleteSearch = (text: string) => {
    const tags = text === '' ? undefined : [text];
    this.setFilter({ tags });
  };
}

export const InfluecnerTablePage = withRouter(InfluecnerPageComponent);
