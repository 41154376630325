import { put } from 'redux-saga/effects';
import { EActionsTypes, APIProvider, BaseStrategy, Branch, buildCommunication, StoreBranch } from '@axmit/redux-communications';
import { push } from 'react-router-redux';
import { ERoutesPrivate } from 'common/models/routesModel';
import { giftcardTransport } from 'entities/Giftcard/Giftcard.transport';
import {
  IGiftcardCollection,
  IGiftcardCollectionFilter,
  IGiftcardCreateParams,
  IGiftcardModel,
  IGiftcardParamsModel,
  IGiftcardUpdateStatusParams
} from 'entities/Giftcard/Giftcard.models';

const namespace = 'giftcard';

export interface IGiftcardConnectedProps {
  giftcardModel: StoreBranch<IGiftcardModel, IGiftcardParamsModel>;
  giftcardCollection: StoreBranch<IGiftcardCollection, IGiftcardCollectionFilter>;

  getGiftcardModel(id: string): void;
  addGiftcardModel(params: IGiftcardCreateParams): IGiftcardModel;
  updateStatusGiftcardModel(params: IGiftcardUpdateStatusParams): void;
  getGiftcardCollection(filter?: IGiftcardCollectionFilter): void;

  clearGiftcardModel(): void;
  clearGiftcardCollection(): void;
}

const GiftcardModelAPIProviders = [
  new APIProvider(EActionsTypes.get, giftcardTransport.get),
  new APIProvider(EActionsTypes.add, giftcardTransport.add, {
    onSuccess: function*() {
      yield put(push(ERoutesPrivate.Giftcards));
    }
  }),
  new APIProvider('updateStatus', giftcardTransport.updateStatus)
];

const GiftcardCollectionAPIProviders = [new APIProvider(EActionsTypes.get, giftcardTransport.getCollection)];

const branches = [new Branch('model', GiftcardModelAPIProviders), new Branch('collection', GiftcardCollectionAPIProviders)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationGiftcard = buildCommunication<IGiftcardConnectedProps>(strategy);
