import React from 'react';
import { Col, Row, Button } from 'antd';
import { Link } from 'react-router-dom';
import { RouteComponentProps, withRouter } from 'react-router';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { StoryTable } from 'entities/Story/components/StoryTable';
import { EStoryStatus, IStoryCollectionFilter, tableStoryConfig } from 'entities/Story/Story.models';
import { StoryStatusSelector } from 'entities/Story/components/Selectors/StoryStatusSelector';
import { LanguageSelect } from 'entities/Language/components/Selectors/LanguageSelect';
import { EPlayerAgeGroups } from 'entities/Player/Player.models';
import { PlayerAgeGroupSelector } from 'entities/Player/components/Selector/PlayerAgeGroupSelector';

type AllProps = RouteComponentProps;

interface IComponentState {
  filter: IStoryCollectionFilter;
}

class StoryPageComponent extends React.PureComponent<AllProps, IComponentState> {
  public debounceSearch: any;
  state: IComponentState = {
    filter: {
      pageIndex: 1,
      langs: undefined,
      ageGroups: undefined
    }
  };
  constructor(props: AllProps) {
    super(props);
    this.state.filter = { ...this.state.filter, ...queryToObject<IStoryCollectionFilter>(this.state.filter) };
  }

  render() {
    const { filter } = this.state;
    const { ageGroups, statuses, langs } = filter;

    return (
      <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10">
        <Row gutter={[16, 0]} className="mb-5 width-full" justify="space-between" align="middle">
          <Col span={15}>
            <Row gutter={[16, 0]} className="width-full" justify="space-between" align="middle">
              <Col span={8}>
                <LanguageSelect
                  className="width-full"
                  onChange={this.onChangeLang}
                  value={langs}
                  placeholder="Search by lang"
                  isSingle={false}
                />
              </Col>
              <Col span={8}>
                <PlayerAgeGroupSelector
                  onChange={this.onChangeAgeGroups}
                  value={ageGroups}
                  placeholder="Search by age group"
                  isSingle={false}
                />
              </Col>
              <Col span={8}>
                <StoryStatusSelector onChange={this.onChangeStatus} value={statuses} placeholder="Search by status" />
              </Col>
            </Row>
          </Col>
          <Col span={4}>
            <Link to={ERoutesPrivate.StoryCreate}>
              <Button block type="primary" title="Add story">
                Add story
              </Button>
            </Link>
          </Col>
        </Row>
        <StoryTable config={tableStoryConfig} filter={filter} />
      </CommonLayout>
    );
  }

  setFilter = (partialFilter: Partial<IStoryCollectionFilter>) => {
    const oldFilter = this.state.filter;
    const filter = { ...oldFilter, ...partialFilter };

    this.props.history.replace({ search: objectToQuery(filter) });
    this.setState({ filter });
  };

  onChangeAgeGroups = (ageGroups?: EPlayerAgeGroups | EPlayerAgeGroups[]) => {
    if (Array.isArray(ageGroups)) {
      this.setFilter({ ageGroups });
    }
  };

  onChangeStatus = (status?: EStoryStatus) => {
    this.setFilter({ statuses: status ? [status] : undefined });
  };
  onChangeLang = (langs?: string | string[]) => {
    if (Array.isArray(langs)) {
      this.setFilter({ langs });
    }
  };
}

export const StoryTablePage = withRouter(StoryPageComponent);
