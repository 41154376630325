import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { queryToObject } from 'common/helpers/filters.helper';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { communicationTest, ITestConnectedProps } from 'entities/Test/Test.communication';
import { ITestLeaderboardCollectionFilter, tableLeaderboardTestConfig } from 'entities/Test/Test.models';
import { TestLeaderboardTable } from 'entities/Test/components/Leaderboard/TestLeaderboardTable';

interface IParams {
  id?: string;
}

type AllProps = RouteComponentProps<IParams> & ITestConnectedProps;

interface IComponentState {
  filter: Partial<ITestLeaderboardCollectionFilter>;
}

class TestLeaderboardPageComponent extends React.PureComponent<AllProps, IComponentState> {
  public debounceSearch: any;
  state: IComponentState = {
    filter: {
      pageIndex: 1
    }
  };
  constructor(props: AllProps) {
    super(props);
    this.state.filter = { ...this.state.filter, ...queryToObject<Partial<ITestLeaderboardCollectionFilter>>(this.state.filter) };
  }
  componentDidMount(): void {
    const { match, getTestModel } = this.props;
    const { params } = match;
    const { id } = params;
    if (id) {
      getTestModel(id);
    }
  }

  render() {
    const { match, testModel } = this.props;
    const { filter } = this.state;
    const { params } = match;
    const { id } = params;
    const testTitle = testModel?.data?.title;

    return (
      <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10">
        <div className="fs-sm font-family__default mb-5">
          Leaderboard: <Link to={`${ERoutesPrivate.Test}/${id}`}>{testTitle}</Link>
        </div>
        <TestLeaderboardTable config={tableLeaderboardTestConfig} filter={{ id, ...filter }} />
      </CommonLayout>
    );
  }
}

export const TestLeaderboardPage = communicationTest.injector(withRouter(TestLeaderboardPageComponent));
