import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';

interface IComponentProps {
  onChange?: (value?: boolean) => void;
  disabled?: boolean;
}

type AllProps = SelectProps<any> & IComponentProps;

export class PlayerRegCompleteSelector extends React.PureComponent<AllProps> {
  render() {
    const { value: propValue, onChange, disabled = false } = this.props;

    return (
      <Select
        allowClear
        className="width-full"
        placeholder="Select registration status"
        value={propValue}
        defaultActiveFirstOption={false}
        showArrow
        onChange={value => onChange && onChange(value)}
        disabled={disabled}
      >
        <Select.Option key="true" value={true as any} label="Registration complete">
          Registration complete
        </Select.Option>
        <Select.Option key="false" value={false as any} label="Registration not completed">
          Registration not completed
        </Select.Option>
      </Select>
    );
  }
}
