import React, { useMemo, useState, useEffect } from 'react';
import { Button, Checkbox, Col, Form, Input, InputNumber, Row, Switch, Select } from 'antd';
import { useFormMapper } from '@axmit/antd4-helpers';
import { Link } from 'react-router-dom';
import { StoreBranch } from '@axmit/redux-communications';
import { ERoutesPrivate } from 'common/models/routesModel';
import { formRules } from 'common/const/common.const';
import { ImageUpload } from 'common/components/Image/ImageUpload';
import { VideoUpload } from 'common/components/Video/VideoUpload';
import { useFilesLoading } from 'common/components/UseFilesLoading';
import { ITestModel, TTestParamsModel, ITestUpdateData } from 'entities/Test/Test.models';
import { AvailabilitySelector } from 'entities/Test/components/Selector/AvailabilitySelector';
import { OrientationSelector } from 'entities/Test/components/Selector/OrientationSelector';
import { AnchorTypeSelector } from 'entities/Test/components/Selector/AnchorTypeSelector';
import { ComplexitySelector } from 'entities/Test/components/Selector/ComplexitySelector';
import { TestModelUpload, ETestModelType } from 'entities/Test/components/TestModelUpload';
import { TestFormTypeSelector } from 'entities/Test/components/Selector/TestFormTypeSelector';

interface IComponentProps {
  updateTest?: (data: ITestUpdateData) => void;
  deleteTest?: (id: string) => void;
  testModel: StoreBranch<ITestModel, TTestParamsModel>;
  testId?: string;
}

interface IStateModel {
  id: string | null;
  createdAt?: string;
}

const TestFormComponent: React.FC<IComponentProps> = props => {
  const [androidModel, setAndroidModel] = useState<IStateModel | undefined>(undefined);
  const [iosModel, setIosModel] = useState<IStateModel | undefined>(undefined);
  const [filesLoading, onChangeFileLoading] = useFilesLoading(['iosModel', 'androidModel', 'video']);
  const { testModel, updateTest, testId, deleteTest } = props;
  const { errors, loading, data, params } = testModel;
  const isUpdateForm = !!testId;
  const submitButtonText = 'Save';
  const dataAndroidModel = data?.androidModel;
  const dataIosModel = data?.iosModel;
  const memoizedValue = useMemo(
    () => ({
      ...data,
      firstImage: data?.firstImage?.id,
      secondImage: data?.secondImage?.id,
      markerTipImage: data?.markerTipImage?.id,
      icon: data?.icon?.id,
      video: data?.video?.id,
      firstImageJC: data?.firstImageJC?.id,
      secondImageJC: data?.secondImageJC?.id,
      markerTipImageJC: data?.markerTipImageJC?.id,
      iconJC: data?.iconJC?.id,
      videoJC: data?.videoJC?.id,
      week: data?.week,
      showRender: data?.showRender,
      repeatCount: data?.repeatCount,
      countOfCycle: data?.countOfCycle
    }),
    [data]
  );

  useEffect(() => {
    setAndroidModel(undefined);
    setIosModel(undefined);
  }, [data]);

  const { fields } = useFormMapper(
    [
      'title',
      'description',
      'autoRejectReasons',
      'autoRejectReasonsCoach',
      'firstImage',
      'icon',
      'secondImage',
      'video',
      'shortDescription',
      'playground',
      'timeBeforeStart',
      'order',
      'selectablePlane',
      'minHeight',
      'minWidth',
      'anchorDetectionTypes',
      'displayPointCloud',
      'maxDistance',
      'highAccuracyEvents',
      'defaultAccept',
      'orientation',
      'complexity',
      'maxVideoTime',
      'maxVideoTimeJC',
      'availability',
      'isVisibleForPlayer',
      'isVisibleForRfs',
      'isBySub',
      'isVisibleForTrainer',
      'isNeedAr',
      'avgModerationSpeed',
      'costForTry',
      'bonusForSuccess',
      'bonusForFail',
      'markerTipImage',
      'firstImageJC',
      'secondImageJC',
      'markerTipImageJC',
      'iconJC',
      'videoJC',
      'timeBeforeStartJC',
      'defaultAcceptJC',
      'week',
      'showRender',
      'typeOfTraining',
      'tag',
      'formType',
      'repeatCount',
      'countOfCycle'
    ],
    memoizedValue,
    params,
    errors
  );

  const editTest = (values: any) => {
    const {
      isBySub,
      isVisibleForPlayer,
      isVisibleForRfs,
      isVisibleForTrainer,
      title,
      description,
      firstImage,
      icon,
      secondImage,
      video,
      shortDescription,
      playground,
      timeBeforeStart,
      order,
      selectablePlane,
      minHeight,
      minWidth,
      anchorDetectionTypes,
      displayPointCloud,
      maxDistance,
      highAccuracyEvents,
      defaultAccept,
      orientation,
      complexity,
      maxVideoTime,
      maxVideoTimeJC,
      availability,
      isNeedAr,
      avgModerationSpeed,
      costForTry,
      bonusForSuccess,
      bonusForFail,
      markerTipImage,
      firstImageJC,
      markerTipImageJC,
      secondImageJC,
      iconJC,
      videoJC,
      timeBeforeStartJC,
      defaultAcceptJC,
      week,
      showRender,
      repeatCount,
      countOfCycle,
      typeOfTraining,
      formType,
      autoRejectReasons,
      autoRejectReasonsCoach
    } = values;

    if (testId && updateTest) {
      const newData: ITestUpdateData = {
        id: testId,
        isBySub,
        formType,
        isVisibleForPlayer,
        isVisibleForTrainer,
        title,
        description: description === '' ? null : description,
        firstImage,
        icon,
        secondImage,
        video,
        shortDescription: shortDescription === '' ? null : shortDescription,
        playground,
        timeBeforeStart,
        order,
        typeOfTraining,
        selectablePlane,
        minHeight,
        minWidth,
        anchorDetectionTypes,
        displayPointCloud,
        maxDistance,
        highAccuracyEvents,
        defaultAccept,
        maxVideoTime,
        maxVideoTimeJC,
        availability,
        orientation,
        complexity,
        isNeedAr,
        avgModerationSpeed,
        costForTry,
        bonusForSuccess,
        bonusForFail,
        markerTipImage,
        firstImageJC,
        markerTipImageJC,
        secondImageJC,
        iconJC,
        videoJC,
        timeBeforeStartJC,
        defaultAcceptJC,
        week,
        showRender,
        repeatCount,
        countOfCycle,
        autoRejectReasons,
        autoRejectReasonsCoach
      };

      if (androidModel?.id || androidModel?.id === null) {
        newData.androidModel = androidModel.id;
      }

      if (iosModel?.id || iosModel?.id === null) {
        newData.iosModel = iosModel.id;
      }
      if (isVisibleForRfs !== undefined) {
        newData.isVisibleForRfs = isVisibleForRfs;
      }

      updateTest(newData);
    }
  };

  return (
    <Form className="mt-5" onFinish={editTest} fields={fields}>
      <>
        {testId && (
          <>
            <Row>
              <span className="basic__text_title" title="Information">
                Update test models
              </span>
            </Row>
            <Row className="mt-5 mb-5 width-full">
              <Col span={5} className="mr-5">
                <TestModelUpload
                  onLoading={(isLoading: boolean) => onChangeFileLoading('androidModel', isLoading)}
                  dataModel={dataAndroidModel}
                  model={androidModel}
                  setAndroidModel={setAndroidModel}
                  modelType={ETestModelType.Android}
                />
              </Col>
              <Col span={5}>
                <TestModelUpload
                  onLoading={(isLoading: boolean) => onChangeFileLoading('iosModel', isLoading)}
                  dataModel={dataIosModel}
                  model={iosModel}
                  setIosModel={setIosModel}
                  modelType={ETestModelType.Ios}
                />
              </Col>
            </Row>
          </>
        )}
        {testId && (
          <div className="mb-5">
            <Link to={`${ERoutesPrivate.TestLeaderboard}/${testId}`} className="fs-sm">
              <Button>View Leaderboard</Button>
            </Link>
          </div>
        )}
        <Row className="mb-5" align="middle">
          <span className="basic__text_title" title="Information">
            Information
          </span>
        </Row>
        <Row gutter={[28, 10]}>
          <Col>
            <Form.Item valuePropName="checked" name="isBySub" label="Is need sub for test?">
              <Switch checkedChildren="Yes" unCheckedChildren="No" />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item valuePropName="checked" name="isVisibleForPlayer" label="Test is visible for player?">
              <Switch checkedChildren="Yes" unCheckedChildren="No" />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item valuePropName="checked" name="isVisibleForRfs" label="Test is visible for RFS?">
              <Switch checkedChildren="Yes" unCheckedChildren="No" />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item valuePropName="checked" name="isVisibleForTrainer" label="Test is visible for coach?">
              <Switch checkedChildren="Yes" unCheckedChildren="No" />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item valuePropName="checked" name="isNeedAr" label="Need AR for test?">
              <Switch checkedChildren="Yes" unCheckedChildren="No" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="tag" label="Tag">
              <Input name="tag" type="text" placeholder="Tag" disabled />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Title is required'
                },
                {
                  max: 100,
                  message: 'Title must be shorter than or equal 100 characters'
                }
              ]}
              name="title"
              label="Title"
            >
              <Input name="title" type="text" placeholder="Title" disabled={loading} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              rules={[
                {
                  max: 100,
                  message: 'Short description must be shorter than or equal 100 characters'
                }
              ]}
              name="shortDescription"
              label="Short description"
            >
              <Input.TextArea
                name="shortDescription"
                placeholder="Short description"
                disabled={loading}
                autoSize={{ minRows: 2, maxRows: 4 }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="typeOfTraining" label="Type of training">
              <Input name="typeOfTraining" type="text" placeholder="Type of training" disabled={loading} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="formType" label="Form type">
              <TestFormTypeSelector placeholder="Form type" disabled={loading} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              dependencies={['isNeedAr']}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (getFieldValue('isNeedAr') && !value) {
                      return Promise.reject(new Error('Playground is required'));
                    }
                    return Promise.resolve();
                  }
                }),
                { type: 'number', min: 1, max: 50 }
              ]}
              name="playground"
              label="Playground"
            >
              <InputNumber min={1} max={50} name="playground" placeholder="Playground index (1-50)" disabled={loading} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Order is required'
                },
                { type: 'number', min: 0, max: 100 }
              ]}
              name="order"
              label="Order"
            >
              <InputNumber min={0} max={100} name="order" placeholder="Order" disabled={loading} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Delay is required'
                },
                { type: 'number', min: 3, max: 100 }
              ]}
              name="timeBeforeStart"
              label="Delay"
            >
              <InputNumber name="timeBeforeStart" placeholder="Delay (sec)" disabled={loading} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item rules={[{ type: 'number', min: 3, max: 100 }]} name="timeBeforeStartJC" label="Delay JC">
              <InputNumber name="timeBeforeStartJC" placeholder="Delay (sec)" disabled={loading} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              rules={[
                {
                  min: 2,
                  message: 'Description must be longer than or equal 2 characters'
                },
                {
                  max: 1024,
                  message: 'Description must be shorter than or equal 1024 characters'
                }
              ]}
              name="description"
              label="Description"
            >
              <Input.TextArea placeholder="Description" disabled={loading} autoSize={{ minRows: 10, maxRows: 15 }} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="showRender" valuePropName="checked">
              <Checkbox defaultChecked={false}>Show render</Checkbox>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="defaultAccept" valuePropName="checked">
              <Checkbox defaultChecked={false}>Default accept</Checkbox>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="defaultAcceptJC" valuePropName="checked">
              <Checkbox defaultChecked={false}>Default accept JC</Checkbox>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="selectablePlane" valuePropName="checked">
              <Checkbox defaultChecked={false}>Selectable plane</Checkbox>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              rules={[formRules.required, { type: 'number', min: 0, max: 100 }]}
              name="countOfCycle"
              label="Count of cycle"
            >
              <InputNumber min={0} max={100} name="countOfCycle" placeholder="Count of cycle" disabled={loading} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item rules={[formRules.required, { type: 'number', min: 0, max: 100 }]} name="minHeight" label="Min height">
              <InputNumber min={0} max={100} name="minHeight" placeholder="Min height" disabled={loading} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item rules={[formRules.required, { type: 'number', min: 0, max: 100 }]} name="minWidth" label="Min width">
              <InputNumber min={0} max={100} name="minWidth" placeholder="Min width" disabled={loading} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item rules={[formRules.required]} name="anchorDetectionTypes" label="Anchor detection types">
              <AnchorTypeSelector />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item rules={[formRules.required]} name="orientation" label="Orientation types">
              <OrientationSelector />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="complexity" label="Complexity">
              <ComplexitySelector />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              rules={[formRules.required, { type: 'number', min: 0, max: 9999999 }]}
              name="displayPointCloud"
              label="JC order"
            >
              <InputNumber min={0} max={9999999} name="displayPointCloud" placeholder="JC order 0-9999999" disabled={loading} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              rules={[formRules.required, { type: 'number', min: 1, max: 20 }]}
              name="maxDistance"
              label="Space before test 1 - no space, 2 - with space"
            >
              <InputNumber
                min={1}
                max={20}
                name="maxDistance"
                placeholder="Space before test 1 - no space, 2 - with space"
                disabled={loading}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              rules={[formRules.required, { type: 'number', min: 1, max: 999 }]}
              name="maxVideoTime"
              label="Max video Time JS"
            >
              <InputNumber name="maxVideoTime" min={1} max={999} placeholder="Max video Time 1-999" disabled={loading} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              rules={[formRules.required, { type: 'number', min: 1, max: 999 }]}
              name="maxVideoTimeJC"
              label="Max video Time JC"
            >
              <InputNumber name="maxVideoTimeJC" min={1} max={999} placeholder="Max video Time 1-999" disabled={loading} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              rules={[{ type: 'number', min: 0, max: 99999 }]}
              name="costForTry"
              label="Cost in unicoins for try this test (0-99999)"
            >
              <InputNumber
                name="costForTry"
                min={0}
                max={99999}
                precision={0}
                placeholder="Cost for try 0-99999"
                disabled={loading}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              rules={[{ type: 'number', min: 0, max: 99999 }]}
              name="bonusForSuccess"
              label="Bonus in unicoins for successful test (0-99999)"
            >
              <InputNumber
                name="bonusForSuccess"
                min={0}
                max={99999}
                precision={0}
                placeholder="Bonus in unicoins for successful test 0-99999"
                disabled={loading}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              rules={[{ type: 'number', min: 0, max: 99999 }]}
              name="bonusForFail"
              label="Bonus in unicoins for fail test (0-99999)"
            >
              <InputNumber
                name="bonusForFail"
                min={0}
                max={99999}
                precision={0}
                placeholder="Bonus in unicoins for fail test 0-99999"
                disabled={loading}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item rules={[{ type: 'number', min: 1, max: 999 }]} name="week" label="Week for test (1-999)">
              <InputNumber name="week" min={1} max={999} precision={0} placeholder="Week for test 1-999" disabled={loading} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              rules={[{ type: 'number', min: 1, max: 99 }]}
              name="repeatCount"
              label="Number of video chunks for test (1-99)"
            >
              <InputNumber
                name="repeatCount"
                min={1}
                max={99}
                precision={0}
                placeholder="Number of video chunks for test (1-99)"
                disabled={loading}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item rules={[formRules.required]} name="availability" label="Availability">
              <AvailabilitySelector />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="autoRejectReasons" label="Auto reject reasons">
              <Select mode="tags" notFoundContent={null} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="autoRejectReasonsCoach" label="Auto reject reasons (Coach)">
              <Select mode="tags" notFoundContent={null} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="highAccuracyEvents" valuePropName="checked">
              <Checkbox defaultChecked={true}>High accuracy events</Checkbox>
            </Form.Item>
          </Col>
          <Row gutter={[16, 8]}>
            <Col span={12}>
              <Form.Item name="firstImage" label="First image">
                <ImageUpload />
              </Form.Item>
              <Form.Item name="secondImage" label="Second image">
                <ImageUpload />
              </Form.Item>
              <Form.Item name="markerTipImage" label="Marker tip image">
                <ImageUpload />
              </Form.Item>
              <Form.Item name="icon" label="Icon image">
                <ImageUpload />
              </Form.Item>
              <Form.Item name="video" label="Add video">
                <VideoUpload onLoading={(isLoading: boolean) => onChangeFileLoading('video', isLoading)} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="firstImageJC" label="First image JC">
                <ImageUpload />
              </Form.Item>
              <Form.Item name="secondImageJC" label="Second image JC">
                <ImageUpload />
              </Form.Item>
              <Form.Item name="markerTipImageJC" label="Marker tip image JC">
                <ImageUpload />
              </Form.Item>
              <Form.Item name="iconJC" label="Icon image JC">
                <ImageUpload />
              </Form.Item>
              <Form.Item name="videoJC" label="Add video JC">
                <VideoUpload onLoading={(isLoading: boolean) => onChangeFileLoading('video', isLoading)} />
              </Form.Item>
            </Col>
          </Row>
          <Col span={24} className="mb-5">
            <Form.Item name="avgModerationSpeed" label="Expected moderation time(sec)">
              <InputNumber name="avgModerationSpeed" min={0} max={999} placeholder="" disabled={loading} />
            </Form.Item>
          </Col>
        </Row>
      </>

      <Row justify="space-between" align="middle">
        <Col span={16}>
          <Form.Item className="mb-0">
            <Button
              className="mr-8"
              htmlType="submit"
              type="primary"
              disabled={loading || filesLoading}
              loading={loading || filesLoading}
              title={submitButtonText}
            >
              {submitButtonText}
            </Button>
            <Link to={ERoutesPrivate.Test}>
              <Button disabled={loading} loading={loading} title="Cancel">
                Cancel
              </Button>
            </Link>
          </Form.Item>
        </Col>
        {isUpdateForm && (
          <Button
            danger
            disabled={loading}
            loading={loading}
            title="Delete"
            onClick={() => deleteTest && testId && deleteTest(testId)}
          >
            Delete
          </Button>
        )}
      </Row>
    </Form>
  );
};

export const TestForm = TestFormComponent;
