import { ColumnsType } from 'antd/lib/table';
import { RouteComponentProps, withRouter } from 'react-router';
import { TableCommon } from 'common/components/TableCommon';
import { IBaseFilterModel } from 'common/models/requestModels';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { IStatisticCollection, IStatisticCollectionFilter, IStatisticModel } from 'entities/Statistic/Statistic.models';
import { communicationStatistic, IStatisticConnectedProps } from 'entities/Statistic/Statistic.communication';

interface IComponentProps {
  config: ColumnsType<any>;
}

type AllProps = IComponentProps & IStatisticConnectedProps & RouteComponentProps;

class StatisticTableComponent extends TableCommon<
  IStatisticCollection,
  IStatisticModel,
  ColumnsType<any>,
  AllProps,
  IStatisticCollectionFilter
> {
  loadCollection = (params: IBaseFilterModel) => {
    const { getStatisticCollection, filter } = this.props;
    const { location, from, to } = filter || {};

    const filterParams: IStatisticCollectionFilter = {
      ...params,
      ...location
    };
    if (filter?.from) {
      filterParams.from = from;
    }
    if (filter?.to) {
      filterParams.to = to;
    }

    getStatisticCollection(filterParams);
  };

  getCollection = () => {
    const { statisticCollection } = this.props;
    return statisticCollection;
  };

  addQuery = (pageIndex: number) => {
    const searchPath = queryToObject<IStatisticCollectionFilter>({
      pageIndex: 1,
      location: undefined,
      from: undefined,
      to: undefined,
      tab: undefined
    });
    const search = objectToQuery({ ...searchPath, pageIndex });
    return this.props.history.replace({ search });
  };

  clearCollection = () => {
    return this.props.clearStatisticCollection();
  };
}

export const StatisticTable = communicationStatistic.injector(withRouter(StatisticTableComponent));
