import { IBasePathParams } from 'common/models/requestModels';

export interface IMentorRequestModel extends Components.Schemas.MentorRequestDto {}
export interface IMentorRequestCollection extends Components.Schemas.MentorRequestCollectionDto {}

export interface IMentorRequestCreateParams extends Components.Schemas.CreateMentorRequestDto {}
export interface IMentorRequestUpdateParams extends Components.Schemas.UpdateMentorRequestStatusDto {
  id: string;
}
export interface IMentorRequestCollectionFilter extends Paths.MentorRequestControllerList.QueryParameters {}

export interface IInviteMentorParams extends Components.Schemas.InviteMentorForPrivatePlayerDto {
  inviteFromList?: boolean;
}

export type TMentorRequestParamsModel = IMentorRequestCreateParams | IMentorRequestUpdateParams | IBasePathParams;

export enum EMentorRequestSuccessMessage {
  Add = 'Request successfully sent',
  Update = 'Successfully updated'
}

export const PLAYER_NOT_EXIST_CODE = 'validation.entityNotExist';

export enum EMentorRequestStatus {
  New = 'new',
  Accepted = 'accepted',
  Declined = 'declined',
  Aborted = 'aborted'
}

export enum EMentorRequestAcceptSide {
  Player = 'player',
  Mentor = 'mentor'
}

export const gridMentorRequestConfig = {
  gutter: 16,
  xs: 1,
  sm: 2,
  md: 3,
  lg: 4,
  xl: 4,
  xxl: 4
};
