import React from 'react';
import { Breadcrumb, Col, Skeleton } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { communicationFeed, IFeedConnectedProps } from 'entities/Feed/Feed.communication';
import { FeedForm } from 'entities/Feed/components/Form/FeedForm';

interface IParams {
  id?: string;
}

type AllProps = IFeedConnectedProps & RouteComponentProps<IParams>;

class FeedPageComponent extends React.PureComponent<AllProps> {
  componentDidMount() {
    const { getFeedModel, match } = this.props;
    const { params } = match;
    const { id } = params;

    if (id) {
      getFeedModel(id);
    }
  }

  componentWillUnmount() {
    const { clearFeedModel } = this.props;

    clearFeedModel();
  }

  render() {
    const { feedModel, match, addFeedModel, updateFeedModel, deleteFeedModel } = this.props;
    const { params } = match;
    const { id } = params;
    const { data: feedModelData, loading } = feedModel;
    const feedName = feedModelData?.id || 'Unknown feed';
    const isEdit = !!id;

    return (
      <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10 pb-10">
        <Col span={24}>
          <Breadcrumb className="fs-sm font-weight-5 font-family__default mb-8">
            <Breadcrumb.Item href={ERoutesPrivate.Feed}>Feed list</Breadcrumb.Item>
            <Breadcrumb.Item>
              {isEdit ? !loading ? feedName : <Skeleton.Input active style={{ width: '200px' }} size="small" /> : 'Add feed'}
            </Breadcrumb.Item>
          </Breadcrumb>
          {!loading || !isEdit ? (
            <FeedForm
              feedModel={feedModel}
              createFeed={addFeedModel}
              updateFeed={updateFeedModel}
              deleteFeed={deleteFeedModel}
              feedId={id}
            />
          ) : null}
        </Col>
      </CommonLayout>
    );
  }
}

export const FeedPage = communicationFeed.injector(withRouter(FeedPageComponent));
