import axios, { AxiosStatic } from 'axios';
import { BaseHttpTransport, objectToQuery } from '@axmit/transport';
import {
  IPlayerTestCollection,
  IPlayerTestCollectionFilter,
  IPlayerTestGetNextParams,
  IPlayerTestModel,
  IPlayerTestQueueTaskCount,
  IPlayerTestUpdateMove,
  IPlayerTestUpdateParams
} from 'entities/PlayerTest/PlayerTest.models';

const basePath = '/player-to-tests';

class PlayerTestTransport extends BaseHttpTransport<
  AxiosStatic,
  IPlayerTestModel,
  IPlayerTestCollection,
  void,
  IPlayerTestUpdateParams,
  IPlayerTestCollectionFilter
> {
  public getNext = ({ id, ...params }: IPlayerTestGetNextParams) => axios.get(`${basePath}/${id}/next${objectToQuery(params)}`);
  public updatePlayerTest = ({ query, id, ...params }: IPlayerTestUpdateParams) =>
    axios.put(`${basePath}/${id}${query ? objectToQuery(query) : ''}`, params);
  public getPlayerTestQueueTaskCount = (): Promise<IPlayerTestQueueTaskCount> => axios.get(`${basePath}/queue-task/count`);
  public renderPlayerTest = (id: string) => axios.put(`${basePath}/${id}/render`);
  public movePlayerTest = ({ id, ...params }: IPlayerTestUpdateMove) => axios.put(`${basePath}/${id}/move`, params);
  public deleteQueuePlayerTest = () => axios.delete(`${basePath}/queue-task`);
}
export const playerTestTransport = new PlayerTestTransport(basePath, axios);
