import {
  EActionsTypes,
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  StoreBranch,
  getStartType
} from '@axmit/redux-communications';
import { put } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { ERoutesPrivate } from 'common/models/routesModel';
import {
  ITariffCollection,
  ITariffCollectionFilter,
  ITariffModel,
  TTariffParamsModel,
  ITariffCreate,
  ITariffUpdateParams,
  ITariffUpdateStatus,
  ITariffUpdatePermissions,
  ITariffPermissionCollection,
  ITariffPermissionCollectionFilter,
  ITariffPermissionModel
} from 'entities/Tariff/Tariff.models';
import { tariffTransport } from 'entities/Tariff/Tariff.transport';

const namespace = 'tariff';

export interface ITariffConnectedProps {
  tariffModel: StoreBranch<ITariffModel, TTariffParamsModel>;
  tariffCollection: StoreBranch<ITariffCollection, ITariffCollectionFilter>;
  tariffPermissionModel: StoreBranch<ITariffPermissionModel, ITariffUpdatePermissions>;
  tariffPermissionCollection: StoreBranch<ITariffPermissionCollection, ITariffPermissionCollectionFilter>;

  getTariffModel(id: string): void;
  addTariffModel(params: ITariffCreate): void;
  updateTariffModel(params: ITariffUpdateParams): void;
  updateStatusTariffModel(params: ITariffUpdateStatus): void;
  getTariffCollection(filter?: ITariffCollectionFilter): void;

  addTariffPermissionModel(params: ITariffUpdatePermissions): void;
  removeTariffPermissionModel(params: ITariffUpdatePermissions): void;
  getTariffPermissionCollection(filter?: ITariffPermissionCollectionFilter): void;

  clearTariffModel(): void;
  clearTariffCollection(): void;
  clearTariffPermissionCollection(): void;
}

function* updateTariff(fullState: any) {
  const collectionParams = fullState[namespace].permissionCollection.params;

  yield put({
    type: getStartType(namespace, 'permissionCollection', EActionsTypes.get),
    payload: collectionParams
  });
}

const TariffModelAPIProviders = [
  new APIProvider(EActionsTypes.get, tariffTransport.get),
  new APIProvider(EActionsTypes.update, tariffTransport.update, {
    preRequestDataMapper: function(response: null, params: any, branch: StoreBranch<ITariffModel | null, any, any>) {
      return branch?.data;
    }
  }),
  new APIProvider(EActionsTypes.add, tariffTransport.add, {
    onSuccess: function*(response) {
      const { id } = response;

      yield put(push(`${ERoutesPrivate.Tariffs}/${id}`));
    }
  }),
  new APIProvider('updateStatus', tariffTransport.updateStatus)
];
const TariffCollectionAPIProviders = [new APIProvider(EActionsTypes.get, tariffTransport.getCollection)];
const TariffPermissionModelAPIProviders = [
  new APIProvider(EActionsTypes.add, tariffTransport.addPermissions, {
    onSuccess: function*(response, originalParams, branchState, fullState) {
      yield updateTariff(fullState);
    }
  }),
  new APIProvider('remove', tariffTransport.removePermissions, {
    onSuccess: function*(response, originalParams, branchState, fullState) {
      yield updateTariff(fullState);
    }
  })
];
const TariffPermissionCollectionAPIProviders = [
  new APIProvider(EActionsTypes.get, tariffTransport.getTariffPermissionCollection)
];

const branches = [
  new Branch('model', TariffModelAPIProviders),
  new Branch('collection', TariffCollectionAPIProviders),
  new Branch('permissionModel', TariffPermissionModelAPIProviders),
  new Branch('permissionCollection', TariffPermissionCollectionAPIProviders)
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationTariff = buildCommunication<ITariffConnectedProps>(strategy);
