import React from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import { useUpdateFormValidation } from '@axmit/antd4-helpers';
import { Link } from 'react-router-dom';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { ERoutesPublic } from 'common/models/routesModel';
import { IAuthConnectedProps, communicationAuth } from 'entities/Auth/Auth.communication';

type AllProps = IAuthConnectedProps;

const LoginFormComponent: React.FC<AllProps> = props => {
  const { addAuthModel, authModel } = props;
  const { loading, errors, params } = authModel;

  const { fields } = useUpdateFormValidation(['email', 'password'], null, errors, params);

  const login = (values: any) => {
    addAuthModel(values);
  };

  return (
    <CommonLayout typeLayout={ELayoutSize.Small} classStyle="basic__layout_m-t">
      <Row justify="center" className="mb-4">
        <h1 className="basic__text_title fs-md" title="Log in">
          Log in
        </h1>
      </Row>
      <Form onFinish={login} fields={fields}>
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Email is required'
            }
          ]}
          name="email"
          label="Email"
        >
          <Input name="email" type="email" placeholder="Email" disabled={loading} />
        </Form.Item>

        <Form.Item
          rules={[
            {
              required: true,
              message: 'Password is required'
            }
          ]}
          name="password"
          label="Password"
        >
          <Input.Password type="password" placeholder="Password" disabled={loading} />
        </Form.Item>
        <Form.Item>
          <Row justify="space-between" align="middle" gutter={3}>
            <Col span={10}>
              <Button htmlType="submit" block type="primary" title="Log in" disabled={loading} loading={loading}>
                Log in
              </Button>
            </Col>
            <Col>
              <Link to={ERoutesPublic.Restore}>Restore password</Link>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </CommonLayout>
  );
};

export const LoginPage = communicationAuth.injector(LoginFormComponent);
