import {
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  EActionsTypes,
  getStartType,
  StoreBranch
} from '@axmit/redux-communications';
import { put } from 'redux-saga/effects';
import { walletTransport } from 'entities/Wallet/Wallet.transport';
import {
  IWalletTransactionParamsModel,
  IWalletTransactionModel,
  IWalletTransactionCollectionFilter,
  IWalletTransactionCollection
} from 'entities/Wallet/Wallet.models';
import { closeWalletModal } from 'entities/UI/UI.communication';

const namespace = 'wallet';

export interface IWalletConnectedProps {
  walletDirectTransaction: StoreBranch<IWalletTransactionModel, IWalletTransactionParamsModel>;
  walletTransactionCollection: StoreBranch<IWalletTransactionCollection, IWalletTransactionCollectionFilter>;

  addWalletDirectTransaction(params: IWalletTransactionParamsModel): void;
  clearWalletDirectTransaction(): void;

  getWalletTransactionCollection(params: IWalletTransactionCollectionFilter): void;
  clearWalletTransactionCollection(): void;
}

const WalletDirectTransactionAPIProviders = [
  new APIProvider(EActionsTypes.add, walletTransport.directTransaction, {
    onSuccess: function*(response, originalParams) {
      const { redirectAfterSuccess } = originalParams || {};
      yield closeWalletModal();

      if (redirectAfterSuccess) {
        window.location.replace(redirectAfterSuccess);
      }
    }
  })
];
const WalletTransactionCollectionAPIProviders = [new APIProvider(EActionsTypes.get, walletTransport.getCollection)];

const branches = [
  new Branch('directTransaction', WalletDirectTransactionAPIProviders),
  new Branch('transactionCollection', WalletTransactionCollectionAPIProviders)
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export function* clearWalletTransaction() {
  yield put({ type: getStartType(namespace, 'directTransaction', EActionsTypes.clear) });
}

export const communicationWallet = buildCommunication<IWalletConnectedProps>(strategy);
