import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { EStoryStatus } from 'entities/Story/Story.models';

interface IComponentProps {
  onChange?: (value?: EStoryStatus) => void;
  disabled?: boolean;
}

type AllProps = SelectProps<any> & IComponentProps;

export class StoryStatusSelector extends React.PureComponent<AllProps> {
  render() {
    const { value: propValue, onChange, disabled = false } = this.props;

    return (
      <Select
        allowClear
        className="width-full"
        placeholder="Select status"
        value={propValue}
        defaultActiveFirstOption={false}
        showArrow
        onChange={(value: EStoryStatus) => onChange && onChange(value)}
        disabled={disabled}
      >
        <Select.Option key={EStoryStatus.Active} value={EStoryStatus.Active} label={EStoryStatus.Active}>
          {EStoryStatus.Active}
        </Select.Option>
        <Select.Option key={EStoryStatus.Cancel} value={EStoryStatus.Cancel} label={EStoryStatus.Cancel}>
          {EStoryStatus.Cancel}
        </Select.Option>
      </Select>
    );
  }
}
