import axios, { AxiosStatic } from 'axios';
import { BaseHttpTransport } from '@axmit/transport';
import { stringify } from 'query-string';
import {
  IGiftcardModel,
  IGiftcardCreateParams,
  IGiftcardCollectionFilter,
  IGiftcardCollection,
  IGiftcardUpdateStatusParams
} from 'entities/Giftcard/Giftcard.models';

const basePath = '/giftcards';

class GiftcardTransport extends BaseHttpTransport<
  AxiosStatic,
  IGiftcardModel,
  IGiftcardCollection,
  IGiftcardCreateParams,
  null,
  IGiftcardCollectionFilter
> {
  public updateStatus = ({ id, ...params }: IGiftcardUpdateStatusParams) => axios.put(`${basePath}/${id}`, params);
}

export const getGifcardFileUrl = (params: IGiftcardCollectionFilter | null, token?: string) => {
  const url = `/api${basePath}/to-csv`;
  delete params?.limit;
  delete params?.offset;

  if (params) {
    return `${url}?${stringify({ token: token, ...params })}`;
  }
  return url;
};

export const giftcardTransport = new GiftcardTransport(basePath, axios);
