import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { EProductType } from 'entities/Product/Product.models';

interface IComponentProps {
  onChange?: (value?: EProductType) => void;
  disabled?: boolean;
}

type AllProps = SelectProps<any> & IComponentProps;

export class ProductTypeSelector extends React.PureComponent<AllProps> {
  render() {
    const { value: propValue, onChange, disabled = false } = this.props;

    return (
      <Select
        allowClear
        className="width-full"
        placeholder="Select type"
        value={propValue}
        defaultActiveFirstOption={false}
        showArrow
        onChange={(value: EProductType) => onChange && onChange(value)}
        disabled={disabled}
      >
        <Select.Option key={EProductType.Coins} value={EProductType.Coins} label={EProductType.Coins}>
          {EProductType.Coins}
        </Select.Option>
      </Select>
    );
  }
}
