import { IBasePathParams } from 'common/models/requestModels';
import { IPlayerModel } from 'entities/Player/Player.models';
import { IUserModel } from 'entities/User/User.models';

export interface ITariffModel extends Components.Schemas.TariffDto {}
export interface ITariffPrice extends Components.Schemas.TariffPriceDto {}
export interface ITariffCollection extends Components.Schemas.TariffCollectionDto {}
export interface ITariffCollectionFilter extends Paths.TariffControllerList.QueryParameters {
  pageIndex?: number;
  playerFilter?: Partial<IPlayerModel>;
  mentorFilter?: Partial<IUserModel>;
}

export interface ITariffCreate extends Components.Schemas.CreateTariffDto {}
export interface ITariffUpdateParams extends Components.Schemas.UpdateTariffDto, IBasePathParams {}

export interface ITariffUpdateStatus extends Components.Schemas.UpdateTariffStatusDto, IBasePathParams {}

export interface ITariffPermissionModel extends Components.Schemas.TariffPermissionDto {}
export interface ITariffPermissionCollection extends Components.Schemas.TariffPermissionCollectionDto {}
export interface ITariffPermissionCollectionFilter
  extends Paths.TariffControllerListPermissions.QueryParameters,
    IBasePathParams {
  pageIndex?: number;
  playerFilter?: Partial<IPlayerModel>;
  mentorFilter?: Partial<IUserModel>;
}
export interface ITariffUpdatePermissions extends Components.Schemas.UpdateTariffPermissionsDto, IBasePathParams {}

export type TTariffParamsModel =
  | ITariffCreate
  | ITariffUpdateStatus
  | ITariffUpdatePermissions
  | IBasePathParams
  | ITariffUpdateParams;

export enum ETariffTypes {
  Repeatable = 'repeatable'
}

export enum ETariffCurrencys {
  RUB = 'RUB',
  USD = 'USD'
}

export enum ETariffModules {
  JuniStat = 'juniStat',
  JuniCoach = 'juniCoach'
}

export enum ETariffModulesText {
  JuniStat = 'JuniStat',
  JuniCoach = 'JuniCoach',
  JuniMultiTariff = 'JS + JC'
}

export enum ETariffCountries {
  Russia = 'RUS',
  UnitedStates = 'USA',
  Italy = 'ITA',
  Spain = 'ESP',
  Portugal = 'PRT',
  Chile = 'CHL',
  Argentina = 'ARG',
  Brazil = 'BRA',
  Colombia = 'COL',
  Romania = 'ROU',
  UnitedKingdom = 'GBR',
  Turkey = 'TUR',
  Bulgaria = 'BGR',
  Austria = 'AUT',
  Belgium = 'BEL',
  Hungary = 'HUN',
  Germany = 'DEU',
  Greece = 'GRC',
  Denmark = 'DNK',
  Ireland = 'IRL',
  Cyprus = 'CYP',
  Latvia = 'LVA',
  Lithuania = 'LTU',
  Luxembourg = 'LUX',
  Malta = 'MLT',
  Netherlands = 'NLD',
  Poland = 'POL',
  Slovakia = 'SVK',
  Slovenia = 'SVN',
  Finland = 'FIN',
  France = 'FRA',
  Croatia = 'HRV',
  Czechia = 'CZE',
  Sweden = 'SWE',
  Estonia = 'EST',
  Canada = 'CAN'
}

export enum ETariffCountriesCurrency {
  RUS = 'RUB',
  USA = 'USD',
  ITA = 'EUR',
  ESP = 'EUR',
  PRT = 'EUR',
  CHL = 'CLP',
  ARG = 'ARS',
  BRA = 'BRL',
  COL = 'COP',
  ROU = 'RON',
  GBR = 'GBP',
  TUR = 'TRY',
  BGR = 'BGN',
  AUT = 'EUR',
  BEL = 'EUR',
  HUN = 'EUR',
  DEU = 'EUR',
  GRC = 'EUR',
  DNK = 'EUR',
  IRL = 'EUR',
  CYP = 'EUR',
  LVA = 'EUR',
  LTU = 'EUR',
  LUX = 'EUR',
  MLT = 'EUR',
  NLD = 'EUR',
  POL = 'EUR',
  SVK = 'EUR',
  SVN = 'EUR',
  FIN = 'EUR',
  FRA = 'EUR',
  HRV = 'EUR',
  CZE = 'EUR',
  SWE = 'EUR',
  EST = 'EUR',
  CAN = 'USD'
}

export const TariffCountries = [
  { value: ETariffCountries.Russia, currency: ETariffCountriesCurrency.RUS, title: 'Russia' },
  { value: ETariffCountries.UnitedStates, currency: ETariffCountriesCurrency.USA, title: 'United States' },
  { value: ETariffCountries.Italy, currency: ETariffCountriesCurrency.ITA, title: 'Italy' },
  { value: ETariffCountries.Spain, currency: ETariffCountriesCurrency.ESP, title: 'Spain' },
  { value: ETariffCountries.Portugal, currency: ETariffCountriesCurrency.PRT, title: 'Portugal' },
  { value: ETariffCountries.Chile, currency: ETariffCountriesCurrency.CHL, title: 'Chile' },
  { value: ETariffCountries.Argentina, currency: ETariffCountriesCurrency.ARG, title: 'Argentina' },
  { value: ETariffCountries.Brazil, currency: ETariffCountriesCurrency.BRA, title: 'Brazil' },
  { value: ETariffCountries.Colombia, currency: ETariffCountriesCurrency.COL, title: 'Colombia' },
  { value: ETariffCountries.Romania, currency: ETariffCountriesCurrency.ROU, title: 'Romania' },
  { value: ETariffCountries.UnitedKingdom, currency: ETariffCountriesCurrency.GBR, title: 'United Kingdom' },
  { value: ETariffCountries.Turkey, currency: ETariffCountriesCurrency.TUR, title: 'Turkey' },
  { value: ETariffCountries.Bulgaria, currency: ETariffCountriesCurrency.BGR, title: 'Bulgaria' },
  { value: ETariffCountries.Austria, currency: ETariffCountriesCurrency.AUT, title: 'Austria' },
  { value: ETariffCountries.Belgium, currency: ETariffCountriesCurrency.BEL, title: 'Belgium' },
  { value: ETariffCountries.Hungary, currency: ETariffCountriesCurrency.HUN, title: 'Hungary' },
  { value: ETariffCountries.Germany, currency: ETariffCountriesCurrency.DEU, title: 'Germany' },
  { value: ETariffCountries.Greece, currency: ETariffCountriesCurrency.GRC, title: 'Greece' },
  { value: ETariffCountries.Denmark, currency: ETariffCountriesCurrency.DNK, title: 'Denmark' },
  { value: ETariffCountries.Ireland, currency: ETariffCountriesCurrency.IRL, title: 'Ireland' },
  { value: ETariffCountries.Cyprus, currency: ETariffCountriesCurrency.CYP, title: 'Cyprus' },
  { value: ETariffCountries.Latvia, currency: ETariffCountriesCurrency.LVA, title: 'Latvia' },
  { value: ETariffCountries.Lithuania, currency: ETariffCountriesCurrency.LTU, title: 'Lithuania' },
  { value: ETariffCountries.Luxembourg, currency: ETariffCountriesCurrency.LUX, title: 'Luxembourg' },
  { value: ETariffCountries.Malta, currency: ETariffCountriesCurrency.MLT, title: 'Malta' },
  { value: ETariffCountries.Netherlands, currency: ETariffCountriesCurrency.NLD, title: 'Netherlands' },
  { value: ETariffCountries.Poland, currency: ETariffCountriesCurrency.POL, title: 'Poland' },
  { value: ETariffCountries.Slovakia, currency: ETariffCountriesCurrency.SVK, title: 'Slovakia' },
  { value: ETariffCountries.Slovenia, currency: ETariffCountriesCurrency.SVN, title: 'Slovenia' },
  { value: ETariffCountries.Finland, currency: ETariffCountriesCurrency.FIN, title: 'Finland' },
  { value: ETariffCountries.France, currency: ETariffCountriesCurrency.FRA, title: 'France' },
  { value: ETariffCountries.Croatia, currency: ETariffCountriesCurrency.HRV, title: 'Croatia' },
  { value: ETariffCountries.Czechia, currency: ETariffCountriesCurrency.CZE, title: 'Czechia' },
  { value: ETariffCountries.Sweden, currency: ETariffCountriesCurrency.SWE, title: 'Sweden' },
  { value: ETariffCountries.Estonia, currency: ETariffCountriesCurrency.EST, title: 'Estonia' },
  { value: ETariffCountries.Canada, currency: ETariffCountriesCurrency.CAN, title: 'Canada' }
];

export enum ETariffStatus {
  Active = 'active',
  Archived = 'archived'
}

export enum EAddPermissionType {
  Players = 'players',
  Mentors = 'mentors'
}

export enum EAddPermissionTypeSingle {
  players = 'player',
  mentors = 'mentor'
}

export enum ETariffVisibility {
  Public = 'public',
  Private = 'private'
}

export enum ETariffVisibilityText {
  Public = 'Public',
  Private = 'Private'
}

export const tableTariffConfig = [
  {
    title: 'Localization tag',
    dataIndex: 'localizationTag',
    key: 'localizationTag',
    ellipsis: true
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    ellipsis: true
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    ellipsis: true
  },
  {
    title: 'Module',
    dataIndex: 'module',
    key: 'module',
    ellipsis: true,
    render: (value: string, { juniStat, juniCoach }: ITariffModel) => {
      switch (true) {
        case juniStat && juniCoach: {
          return ETariffModulesText.JuniMultiTariff;
        }
        case juniStat: {
          return ETariffModulesText.JuniStat;
        }
        case juniCoach: {
          return ETariffModulesText.JuniCoach;
        }
      }
      return '-';
    }
  },
  {
    title: 'Private',
    dataIndex: 'visibility',
    key: 'visibility',
    ellipsis: true,
    render: (visibility: ETariffVisibility) => (visibility === ETariffVisibility.Private ? '+' : '')
  },
  {
    title: 'Active to',
    dataIndex: 'activeToMoment',
    key: 'activeToMoment',
    ellipsis: true
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    ellipsis: true
  }
];

export const tableTariffPermissionsConfig = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
    ellipsis: true
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    ellipsis: true
  },
  {
    title: 'Type',
    dataIndex: 'entityName',
    key: 'entityName',
    width: 100
  },
  {
    title: '',
    dataIndex: 'remove',
    key: 'remove',
    width: 100
  }
];

export enum ETariffFieldsHint {
  Amount = 'Сколько пользователь будет платить за 1 период(например, за 1 месяц, если тариф месячный)',
  Period = 'Это поле устанавливает период, т.е. как часто будут списываться деньги(например, 1 месяц для оплат каждый месяц, 12 месяцев - оплата за год)',
  Lifetime = 'Срок персонального действия тарифа после перехода на него. По истечению - принудительное перекидывание на next tariff',
  ActiveTo = 'Дата, после которой пользователи уже не смогут воспользовать этим тарифом. После архивации пользователя переводит на next tariff',
  UseDefaultPrice = 'Флаг того, что если для страны не задана индивидуальная цена, то разрешается использовать цену по-умолчанию (One-time payment amount)'
}
