import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { EProductMarket } from 'entities/Product/Product.models';

interface IComponentProps {
  onChange?: (value?: EProductMarket) => void;
  disabled?: boolean;
}

type AllProps = SelectProps<any> & IComponentProps;

export class ProductMarketSelector extends React.PureComponent<AllProps> {
  render() {
    const { value: propValue, onChange, disabled = false } = this.props;

    return (
      <Select
        allowClear
        className="width-full"
        placeholder="Select market"
        value={propValue}
        defaultActiveFirstOption={false}
        showArrow
        onChange={(value: EProductMarket) => onChange && onChange(value)}
        disabled={disabled}
      >
        <Select.Option key={EProductMarket.GooglePlay} value={EProductMarket.GooglePlay} label={EProductMarket.GooglePlay}>
          {EProductMarket.GooglePlay}
        </Select.Option>
        <Select.Option key={EProductMarket.AppStore} value={EProductMarket.AppStore} label={EProductMarket.AppStore}>
          {EProductMarket.AppStore}
        </Select.Option>
        <Select.Option key={EProductMarket.Yookassa} value={EProductMarket.Yookassa} label={EProductMarket.Yookassa}>
          {EProductMarket.Yookassa}
        </Select.Option>
      </Select>
    );
  }
}
