import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { EWalletType } from 'entities/Wallet/Wallet.models';

interface IComponentProps {
  onChange?: (value: EWalletType) => void;
  placeholder?: string;
}

type AllProps = SelectProps<any> & IComponentProps;

export class WalletTypeSelector extends React.PureComponent<AllProps> {
  handleChange = (value: EWalletType) => {
    const { onChange } = this.props;
    onChange && onChange(value);
  };

  render() {
    const { value, defaultValue, placeholder = 'Select' } = this.props;

    return (
      <Select
        allowClear
        className="width-full"
        value={value}
        placeholder={placeholder}
        defaultActiveFirstOption={false}
        defaultValue={defaultValue}
        showArrow
        onChange={(value: EWalletType) => this.handleChange(value)}
      >
        <Select.Option key={EWalletType.BuyTestPassTry} value={EWalletType.BuyTestPassTry} label={EWalletType.BuyTestPassTry}>
          {EWalletType.BuyTestPassTry}
        </Select.Option>
        <Select.Option key={EWalletType.CoinsPurchase} value={EWalletType.CoinsPurchase} label={EWalletType.CoinsPurchase}>
          {EWalletType.CoinsPurchase}
        </Select.Option>
        <Select.Option key={EWalletType.Direct} value={EWalletType.Direct} label={EWalletType.Direct}>
          {EWalletType.Direct}
        </Select.Option>
        <Select.Option
          key={EWalletType.RewardForCompleteRegistration}
          value={EWalletType.RewardForCompleteRegistration}
          label={EWalletType.RewardForCompleteRegistration}
        >
          {EWalletType.RewardForCompleteRegistration}
        </Select.Option>
        <Select.Option
          key={EWalletType.RewardForSuccessTestPass}
          value={EWalletType.RewardForSuccessTestPass}
          label={EWalletType.RewardForSuccessTestPass}
        >
          {EWalletType.RewardForSuccessTestPass}
        </Select.Option>
        <Select.Option key={EWalletType.TransferCredit} value={EWalletType.TransferCredit} label={EWalletType.TransferCredit}>
          {EWalletType.TransferCredit}
        </Select.Option>
        <Select.Option key={EWalletType.TransferDebit} value={EWalletType.TransferDebit} label={EWalletType.TransferDebit}>
          {EWalletType.TransferDebit}
        </Select.Option>
      </Select>
    );
  }
}
