import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';

type AllProps = SelectProps<any>;

export enum ETestType {
  JuniStat = 'JuniStat',
  RFS = 'RFS'
}

export class TestTypeSelector extends React.PureComponent<AllProps> {
  render() {
    const { ...props } = this.props;

    return (
      <Select className="width-full" defaultActiveFirstOption={false} showArrow {...props}>
        <Select.Option key={ETestType.JuniStat} value={ETestType.JuniStat} label={ETestType.JuniStat}>
          {ETestType.JuniStat}
        </Select.Option>
        <Select.Option key={ETestType.RFS} value={ETestType.RFS} label={ETestType.RFS}>
          {ETestType.RFS}
        </Select.Option>
      </Select>
    );
  }
}
