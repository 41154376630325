import { ColumnsType } from 'antd/lib/table';
import { RouteComponentProps, withRouter } from 'react-router';
import { TableCommon } from 'common/components/TableCommon';
import { IBaseFilterModel } from 'common/models/requestModels';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { ERoutesPrivate } from 'common/models/routesModel';
import { ITestCollection, ITestCollectionFilter, ITestModel } from 'entities/Test/Test.models';
import { communicationTest, ITestConnectedProps } from 'entities/Test/Test.communication';

interface IComponentProps {
  config: ColumnsType<any>;
}

type AllProps = IComponentProps & ITestConnectedProps & RouteComponentProps;

class TestTableComponent extends TableCommon<ITestCollection, ITestModel, ColumnsType<any>, AllProps, ITestCollectionFilter> {
  loadCollection = (params: IBaseFilterModel) => {
    const { getTestCollection, filter } = this.props;
    const filterParams: ITestCollectionFilter = { ...params };

    if (filter?.title) {
      filterParams.title = filter?.title;
    }
    if (filter?.isVisibleForRfs) {
      filterParams.isVisibleForRfs = true;
    }
    getTestCollection(filterParams);
  };

  getCollection = () => {
    const { testCollection } = this.props;
    if (testCollection?.data?.data) {
      const data: ITestModel[] = testCollection.data.data.map(item => {
        return { ...item, authorName: `${item.author?.firstName || 'Unknown'} ${item.author?.lastName || 'name'}` };
      });
      return {
        ...testCollection,
        data: {
          ...testCollection.data,
          data
        }
      };
    } else {
      return testCollection;
    }
  };

  addQuery = (pageIndex: number) => {
    const searchPath = queryToObject<ITestCollectionFilter>({
      pageIndex: 1,
      title: undefined,
      isVisibleForRfs: undefined
    });
    const search = objectToQuery({ ...searchPath, pageIndex });
    return this.props.history.replace({ search });
  };

  onRowClick = (item: ITestModel) => {
    const { history } = this.props;
    history.push(`${ERoutesPrivate.Test}/${item.id}`);
  };

  clearCollection = () => {
    return this.props.clearTestCollection();
  };
}

export const TestTable = communicationTest.injector(withRouter(TestTableComponent));
