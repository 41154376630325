import React from 'react';
import { Breadcrumb, Col, Skeleton } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { communicationStory, IStoryConnectedProps } from 'entities/Story/Story.communication';
import { StoryForm } from 'entities/Story/components/Form/StoryForm';

interface IParams {
  id?: string;
}

type AllProps = IStoryConnectedProps & RouteComponentProps<IParams>;

class StoryPageComponent extends React.PureComponent<AllProps> {
  componentDidMount() {
    const { getStoryModel, match } = this.props;
    const { params } = match;
    const { id } = params;

    if (id) {
      getStoryModel(id);
    }
  }

  componentWillUnmount() {
    const { clearStoryModel } = this.props;

    clearStoryModel();
  }

  render() {
    const { storyModel, match, addStoryModel, updateStoryModel, deleteStoryModel } = this.props;
    const { params } = match;
    const { id } = params;
    const { data: storyModelData, loading } = storyModel;
    const storyName = storyModelData?.id || 'Unknown story';
    const isEdit = !!id;

    return (
      <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10 pb-10">
        <Col span={12}>
          <Breadcrumb className="fs-sm font-weight-5 font-family__default mb-8">
            <Breadcrumb.Item href={ERoutesPrivate.Story}>Story list</Breadcrumb.Item>
            <Breadcrumb.Item>
              {isEdit ? !loading ? storyName : <Skeleton.Input active style={{ width: '200px' }} size="small" /> : 'Add story'}
            </Breadcrumb.Item>
          </Breadcrumb>
          {!loading || !isEdit ? (
            <StoryForm
              storyModel={storyModel}
              createStory={addStoryModel}
              updateStory={updateStoryModel}
              deleteStory={deleteStoryModel}
              storyId={id}
            />
          ) : null}
        </Col>
      </CommonLayout>
    );
  }
}

export const StoryPage = communicationStory.injector(withRouter(StoryPageComponent));
