import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { EProcessings, EProcessingsText } from 'entities/Subscription/Subscription.models';

interface IComponentProps {
  onChange?: (value?: EProcessings) => void;
  disabled?: boolean;
}

type AllProps = SelectProps<any> & IComponentProps;

export class SubscriptionProcessingSelector extends React.PureComponent<AllProps> {
  render() {
    const { value, onChange, disabled = false } = this.props;

    return (
      <Select
        allowClear
        className="width-full"
        placeholder="Select processing"
        value={value}
        defaultActiveFirstOption={false}
        showArrow
        onChange={(value: EProcessings) => onChange && onChange(value)}
        disabled={disabled}
      >
        <Select.Option key={EProcessings.Yookassa} value={EProcessings.Yookassa} label={EProcessingsText.Yookassa}>
          {EProcessingsText.Yookassa}
        </Select.Option>
        <Select.Option key={EProcessings.Internal} value={EProcessings.Internal} label={EProcessingsText.Internal}>
          {EProcessingsText.Internal}
        </Select.Option>
      </Select>
    );
  }
}
