import { ILocationModel } from 'common/helpers/location.helper';
import { normalizeDate } from 'common/helpers/normalize.helper';

export interface IStatisticModel extends Components.Schemas.PlayerStatisticDto {}
export interface IStatisticCollection extends Components.Schemas.PlayerStatisticCollectionDto {}

export interface IStatisticTestModel extends Components.Schemas.PlayerTestStatisticDto {}
export interface IStatisticTestCollection extends Components.Schemas.PlayerTestStatisticCollectionDto {}

export interface IStatisticCollectionFilter extends Paths.StatisticControllerList.QueryParameters {
  pageIndex?: number;
  location?: ILocationModel;
  tab?: EStatisticTab;
}
export interface IStatisticTestCollectionFilter extends Paths.StatisticControllerPlayerTestList.QueryParameters {
  pageIndex?: number;
  location?: ILocationModel;
  tab?: EStatisticTab;
}

export type IStatisticFilter = IStatisticCollectionFilter | IStatisticTestCollectionFilter;

export enum EStatisticTab {
  PlayerStatistic = 'playerStatistic',
  TestStatistic = 'testStatistic'
}

export const tableStatisticConfig = [
  {
    title: 'Reg date',
    dataIndex: 'regDate',
    key: 'regDate',
    ellipsis: true,
    render: normalizeDate
  },
  {
    title: 'Country',
    dataIndex: 'country',
    key: 'country',
    ellipsis: true
  },
  {
    title: 'Reg count',
    dataIndex: 'regCount',
    key: 'regCount',
    ellipsis: true
  },
  {
    title: 'Complete count',
    dataIndex: 'completeCount',
    key: 'completeCount',
    ellipsis: true
  },
  {
    title: 'Mentor count',
    dataIndex: 'mentorCount',
    key: 'mentorCount',
    ellipsis: true
  },
  {
    title: 'Test count',
    dataIndex: 'testCount',
    key: 'testCount',
    ellipsis: true
  },

  {
    title: 'Subscription count',
    dataIndex: 'subscriptionCount',
    key: 'subscriptionCount',
    ellipsis: true
  }
];

export enum EStatisticAcademyType {
  Franchise = 'franchise',
  Academy = 'academy'
}

export const tableStatisticTestConfig = [
  {
    title: 'Test date',
    dataIndex: 'testDate',
    key: 'testDate',
    ellipsis: true,
    render: normalizeDate
  },
  {
    title: 'Total count',
    dataIndex: 'totalCount',
    key: 'totalCount',
    ellipsis: true
  },
  {
    title: 'Accept count',
    dataIndex: 'acceptCount',
    key: 'acceptCount',
    ellipsis: true
  },
  {
    title: 'Reject count',
    dataIndex: 'rejectCount',
    key: 'rejectCount',
    ellipsis: true
  },
  {
    title: 'Moderation count',
    dataIndex: 'moderationCount',
    key: 'moderationCount',
    ellipsis: true
  }
];
