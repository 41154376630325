import React from 'react';
import { Modal } from 'antd';
import { LanguageModalBody } from 'entities/Language/components/LanguageModalBody';
import { ILanguageModel } from 'entities/Language/Language.models';

interface IProps {
  visible: boolean;
  onCancel: () => void;
  modalData: ILanguageModel | null;
}

type AllProps = IProps;

const LanguageModalComponent: React.FC<AllProps> = props => {
  const { visible, onCancel, modalData } = props;

  return (
    <Modal
      title={modalData ? 'Edit locale' : 'Add locale'}
      footer={null}
      visible={visible}
      onCancel={onCancel}
      destroyOnClose
      centered
      width={350}
    >
      <LanguageModalBody modalData={modalData} onCancel={onCancel} />
    </Modal>
  );
};

export const LanguageModal = LanguageModalComponent;
