import React from 'react';
import { store } from 'index';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { EUserRoles, IUserModel } from 'entities/User/User.models';

type TShowForFunction = (user: IUserModel) => boolean;

type TRole = EUserRoles | EUserRoles[] | TShowForFunction;

interface IComponentProps {
  availableFor: TRole;
  alternative?: React.ReactElement;
}

type AllProps = IAuthConnectedProps & IComponentProps;

const isAvailableForUser = (showFor: TRole, user: IUserModel | null) => {
  const { role } = user ?? {};

  if (!role) {
    return false;
  }

  switch (true) {
    case Array.isArray(showFor): {
      return (showFor as EUserRoles[]).includes(role as EUserRoles);
    }
    case typeof showFor === 'function': {
      return (showFor as TShowForFunction)(user as IUserModel);
    }
    case typeof showFor === 'string': {
      return showFor === role;
    }
    default: {
      return false;
    }
  }
};

const PrivateContentComponent: React.FC<AllProps> = props => {
  const { availableFor, children, authAdmin, alternative } = props;
  const { data } = authAdmin;

  const isAvailable = React.useMemo(() => {
    return isAvailableForUser(availableFor, data);
  }, [availableFor, data]);

  if (isAvailable) {
    return children as React.ReactElement;
  } else if (alternative) {
    return alternative;
  }

  return null;
};

export const isAvailable = (role: TRole) => {
  const state = store.getState();
  const user = state.auth?.admin.data;
  return isAvailableForUser(role, user);
};

export const PrivateContent = communicationAuth.injector(PrivateContentComponent);
