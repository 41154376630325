import { IBasePathParams } from 'common/models/requestModels';
import { IPlayerModel } from 'entities/Player/Player.models';
import { IUserModel } from 'entities/User/User.models';

export interface IGiftcardModel extends Components.Schemas.GiftcardDto {}
export interface IGiftcardCreateParams extends Components.Schemas.CreateGiftcardsDto {}
export interface IGiftcardCreateStandartParams extends Components.Schemas.CreateStandardGiftcardsDto {}
export interface IGiftcardCreateMentorParams extends Components.Schemas.CreateMentorGiftcardsDto {}
export interface IGiftcardCreatePlayerParams extends Components.Schemas.CreatePlayerGiftcardsDto {}
export interface IGiftcardParamsModel extends IGiftcardCreateParams, IBasePathParams {}

export interface IGiftcardCollection extends Components.Schemas.GiftcardCollectionDto {}
export interface IGiftcardCollectionFilter extends Paths.GiftcardControllerList.QueryParameters {
  pageIndex?: number;
  playerFilter?: Partial<IPlayerModel>;
  mentorFilter?: Partial<IUserModel>;
}

export interface IGiftcardUpdateStatusParams extends Components.Schemas.UpdateGiftcardStatusDto, IBasePathParams {}

export const tableGiftcardConfig = [
  {
    title: 'Number',
    dataIndex: 'number',
    key: 'number',
    ellipsis: true
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    ellipsis: true
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    ellipsis: true
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    ellipsis: true
  },
  {
    title: 'Owner',
    dataIndex: 'owner',
    key: 'owner',
    ellipsis: true
  }
];

export enum EGiftcardTypes {
  Standard = 'standard',
  Mentor = 'mentor',
  Player = 'player'
}
export enum EGiftcardTypesText {
  Standard = 'Standard',
  Mentor = 'Mentor',
  Player = 'Player'
}

export enum EGiftcardCreateTypes {
  Standards = 'standards',
  Mentors = 'mentors',
  Players = 'players'
}

export enum EGiftcardStatuses {
  New = 'new',
  Active = 'active',
  Used = 'used',
  Expired = 'expired',
  Banned = 'banned'
}
export enum EGiftcardStatusText {
  New = 'New',
  Active = 'Active',
  Used = 'Used',
  Expired = 'Expired',
  Banned = 'Banned'
}
