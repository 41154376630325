import React, { useMemo } from 'react';
import PhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import ru from 'react-phone-input-2/lang/ru.json';

interface IComponentProps {
  size?: 'small' | 'middle' | 'large';
}

type AllProps = IComponentProps & PhoneInputProps;

export const Phone = ({ onChange, size = 'large', value, ...props }: AllProps) => {
  const phoneValue = useMemo(() => value || '', [value]);

  const antdClass = useMemo(() => {
    switch (size) {
      case 'large':
        return 'ant-input-lg';
      case 'middle':
        return '';
      case 'small':
        return 'ant-input-sm';
    }
  }, [size]);

  const onChangePhone = (
    item: string,
    country: { format: string; dialCode?: string },
    event: React.ChangeEvent<HTMLInputElement>,
    formattedValue: string
  ) => {
    const phone = !item || item === country?.dialCode ? '' : `+${item}`;
    onChange && onChange(phone, country, event, formattedValue);
  };

  return (
    <PhoneInput
      value={phoneValue}
      country="ru"
      localization={ru}
      onChange={onChangePhone}
      specialLabel=""
      containerClass={`ant-input size__${size} ${antdClass}`}
      inputClass=""
      {...props}
    />
  );
};
