import React from 'react';
import { Button, Row, Col, Menu, Dropdown, Popconfirm } from 'antd';
import { getHost } from 'common/helpers/axios.helper';
import { communicationLanguage, ILanguageConnectedProps } from 'entities/Language/Language.communication';
import { ILanguageModel, ELocalesFilesAppTypes } from 'entities/Language/Language.models';
import { UploadTranslationFile } from 'entities/Language/components/UploadTranslationFile';

interface IProps {
  language: ILanguageModel;
  onEdit: (language: ILanguageModel) => void;
}

type AllProps = IProps & ILanguageConnectedProps;

enum EMenuActionTypes {
  Edit = 'edit',
  UploadJSTranslation = 'uploadJSTranslation',
  UploadMailTranslation = 'uploadMailTranslation',
  UploadNotificationsTranslation = 'uploadNotificationsTranslation',
  DownloadJSTranslation = 'downloadJSTranslation',
  DownloadMailTranslation = 'downloadMailTranslation',
  DownloadNotificationsTranslation = 'downloadNotificationsTranslation',
  Delete = 'delete'
}

const LanguageFormComponent: React.FC<AllProps> = props => {
  const { language, onEdit, deleteLanguageModel } = props;
  const { locale, name } = language;
  const host = getHost();

  const onMenuClick = (event: any) => {
    if (event?.key === EMenuActionTypes.Edit) {
      onEdit(language);
    }
  };

  const onDeleteLocale = () => {
    deleteLanguageModel(locale);
  };

  const menu = (
    <Menu onClick={onMenuClick}>
      <Menu.Item key={EMenuActionTypes.Edit}>Edit</Menu.Item>
      <Menu.Divider />
      <Menu.Item key={EMenuActionTypes.UploadJSTranslation}>
        <UploadTranslationFile app={ELocalesFilesAppTypes.Js} locale={locale}>
          Upload Junistat translations
        </UploadTranslationFile>
      </Menu.Item>
      <Menu.Item key={EMenuActionTypes.UploadMailTranslation}>
        <UploadTranslationFile app={ELocalesFilesAppTypes.Mail} locale={locale}>
          Upload e-mails translations
        </UploadTranslationFile>
      </Menu.Item>
      <Menu.Item key={EMenuActionTypes.UploadNotificationsTranslation}>
        <UploadTranslationFile app={ELocalesFilesAppTypes.Notifications} locale={locale}>
          Upload notifications translations
        </UploadTranslationFile>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key={EMenuActionTypes.DownloadJSTranslation}>
        <a href={`${host}/api/languages/${locale}/file/${ELocalesFilesAppTypes.Js}`} target="_blank" rel="noreferrer noopener">
          Download Junistat translations
        </a>
      </Menu.Item>
      <Menu.Item key={EMenuActionTypes.DownloadMailTranslation}>
        <a href={`${host}/api/languages/${locale}/file/${ELocalesFilesAppTypes.Mail}`} target="_blank" rel="noreferrer noopener">
          Download e-mails translations
        </a>
      </Menu.Item>
      <Menu.Item key={EMenuActionTypes.DownloadNotificationsTranslation}>
        <a
          href={`${host}/api/languages/${locale}/file/${ELocalesFilesAppTypes.Notifications}`}
          target="_blank"
          rel="noreferrer noopener"
        >
          Download notifications translations
        </a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key={EMenuActionTypes.Delete}
        danger
        onClick={e => {
          e?.domEvent?.stopPropagation();
          e?.domEvent?.preventDefault();
        }}
      >
        <Popconfirm placement="topRight" title="Are you sure?" onConfirm={onDeleteLocale} okText="Yes" cancelText="No">
          Delete
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );

  return (
    <Col className="p-3 ant-card-bordered">
      <Row align="middle" className="mb-3">
        <span className="basic__text_label mr-3" title="Locale">
          Locale:
        </span>
        <span className="basic__text_default" title={locale}>
          {locale}
        </span>
      </Row>
      <Row align="middle" className="mb-5">
        <span className="basic__text_label mr-3" title="Locale name">
          Locale name:
        </span>
        <span className="basic__text_default" title={name}>
          {name}
        </span>
      </Row>
      <Dropdown overlay={menu} trigger={['click']}>
        <Button type="primary" block>
          Menu
        </Button>
      </Dropdown>
    </Col>
  );
};

export const LanguageForm = communicationLanguage.injector(LanguageFormComponent);
