import { EActionsTypes, APIProvider, BaseStrategy, Branch, buildCommunication, StoreBranch } from '@axmit/redux-communications';
import {
  IInfluencerModel,
  IInfluencerParams,
  IInfluencerCollection,
  IInfluencerCollectionFilter
} from 'entities/Influencer/Influencer.models';
import { influencerTransport } from 'entities/Influencer/Influencer.transport';

const namespace = 'influencer';

export interface IInfluencerConnectedProps {
  influencerModel: StoreBranch<IInfluencerModel, IInfluencerParams>;
  influencerCollection: StoreBranch<IInfluencerCollection, IInfluencerCollectionFilter>;

  getInfluencerModel(params: IInfluencerParams): void;

  getInfluencerCollection(params?: IInfluencerCollectionFilter): void;

  clearInfluencerModel(): void;
  clearInfluencerCollection(): void;
}

const InfluencerModelAPIProviders = [new APIProvider(EActionsTypes.get, influencerTransport.getInfluencerModel)];
const InfluencerCollectionAPIProviders = [new APIProvider(EActionsTypes.get, influencerTransport.getCollection)];

const branches = [new Branch('model', InfluencerModelAPIProviders), new Branch('collection', InfluencerCollectionAPIProviders)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationInfluencer = buildCommunication<IInfluencerConnectedProps>(strategy);
