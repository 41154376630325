import { ColumnsType } from 'antd/lib/table';
import { RouteComponentProps, withRouter } from 'react-router';
import moment from 'moment';
import { TableCommon } from 'common/components/TableCommon';
import { IBaseFilterModel } from 'common/models/requestModels';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { ERoutesPrivate } from 'common/models/routesModel';
import { simpleDateFormat } from 'common/models/dateModels';
import { ITariffCollection, ITariffCollectionFilter, ITariffModel } from 'entities/Tariff/Tariff.models';
import { communicationTariff, ITariffConnectedProps } from 'entities/Tariff/Tariff.communication';

interface IComponentProps {
  config: ColumnsType<any>;
}

type AllProps = IComponentProps & ITariffConnectedProps & RouteComponentProps;

class TariffTableComponent extends TableCommon<
  ITariffCollection,
  ITariffModel,
  ColumnsType<any>,
  AllProps,
  ITariffCollectionFilter
> {
  loadCollection = (params: IBaseFilterModel) => {
    const { getTariffCollection, filter } = this.props;
    const filterParams: ITariffCollectionFilter = {
      ...params,
      orderField: 'status',
      orderDirection: 'ASC'
    };

    if (filter?.statuses) {
      filterParams.statuses = filter?.statuses;
    }

    if (filter?.localizationTag) {
      filterParams.localizationTag = filter?.localizationTag;
    }

    if (filter?.playerFilter) {
      filterParams.forPlayer = filter?.playerFilter?.id;
    }

    if (filter?.mentorFilter) {
      filterParams.forMentor = filter?.mentorFilter?.id;
    }

    if (filter?.visibility) {
      filterParams.visibility = filter.visibility;
    }

    getTariffCollection(filterParams);
  };

  getCollection = () => {
    const { tariffCollection } = this.props;
    if (tariffCollection?.data?.data) {
      const data: ITariffModel[] = tariffCollection.data.data.map(item => {
        return {
          activeToMoment: moment(item.activeTo).format(simpleDateFormat),
          ...item
        };
      });
      return {
        ...tariffCollection,
        data: {
          ...tariffCollection.data,
          data
        }
      };
    } else {
      return tariffCollection;
    }
  };

  addQuery = (pageIndex: number) => {
    const searchPath = queryToObject<ITariffCollectionFilter>({
      pageIndex: 1,
      localizationTag: undefined,
      statuses: undefined,
      playerFilter: undefined,
      mentorFilter: undefined,
      visibility: undefined
    });
    const search = objectToQuery({ ...searchPath, pageIndex });

    return this.props.history.replace({ search });
  };

  onRowClick = (item: ITariffModel) => {
    const { history } = this.props;
    history.push(`${ERoutesPrivate.Tariffs}/${item.id}`);
  };

  clearCollection = () => {
    return this.props.clearTariffCollection();
  };
}

export const TariffTable = communicationTariff.injector(withRouter(TariffTableComponent));
