import { ColumnsType } from 'antd/lib/table';
import { RouteComponentProps, withRouter } from 'react-router';
import { TableCommon } from 'common/components/TableCommon';
import { IBaseFilterModel } from 'common/models/requestModels';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { ERoutesPrivate } from 'common/models/routesModel';
import {
  INotificationTemplateCollection,
  INotificationTemplateCollectionFilter,
  INotificationTemplateModel
} from 'entities/NotificationTemplate/NotificationTemplate.models';
import {
  communicationNotificationTemplate,
  INotificationTemplateConnectedProps
} from 'entities/NotificationTemplate/NotificationTemplate.communication';

interface IComponentProps {
  config: ColumnsType<any>;
}

type AllProps = IComponentProps & INotificationTemplateConnectedProps & RouteComponentProps;

class NotificationTemplateTableComponent extends TableCommon<
  INotificationTemplateCollection,
  INotificationTemplateModel,
  ColumnsType<any>,
  AllProps,
  INotificationTemplateCollectionFilter
> {
  loadCollection = (params: IBaseFilterModel) => {
    const { getNotificationTemplateCollection, filter } = this.props;
    const filterParams: INotificationTemplateCollectionFilter = { ...params } as INotificationTemplateCollectionFilter;

    if (filter?.type) {
      filterParams.type = filter.type;
    }
    if (filter?.event) {
      filterParams.event = filter.event;
    }
    if (filter?.receiverType) {
      filterParams.receiverType = filter.receiverType;
    }

    getNotificationTemplateCollection(filterParams);
  };

  getCollection = () => {
    const { notificationTemplateCollection } = this.props;
    return notificationTemplateCollection;
  };

  addQuery = (pageIndex: number) => {
    const searchPath = queryToObject<INotificationTemplateCollectionFilter>({
      pageIndex: 1,
      type: undefined,
      event: undefined,
      receiverType: undefined
    });
    const search = objectToQuery({ ...searchPath, pageIndex });

    return this.props.history.replace({ search });
  };

  onRowClick = (item: INotificationTemplateModel) => {
    const { history } = this.props;
    history.push(`${ERoutesPrivate.NotificationTemplates}/${item.id}`);
  };

  clearCollection = () => {
    return this.props.clearNotificationTemplateCollection();
  };
}

export const NotificationTemplateTable = communicationNotificationTemplate.injector(
  withRouter(NotificationTemplateTableComponent)
);
