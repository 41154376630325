import {
  EActionsTypes,
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  StoreBranch,
  buildCollectionPreRequestDataMapper
} from '@axmit/redux-communications';
import { message } from 'antd';
import { put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { ERoutesPrivate } from 'common/models/routesModel';
import { testTransport } from 'entities/Test/Test.transport';
import {
  ETestSuccessMessage,
  ITestModel,
  ITestUpdateData,
  ITestCollectionFilter,
  ITestCollection,
  TTestParamsModel,
  ITestStatisticCollection,
  ITestStatisticCollectionFilter,
  ITestLeaderboardCollection,
  ITestLeaderboardCollectionFilter
} from 'entities/Test/Test.models';

const namespace = 'test';

export interface ITestConnectedProps {
  testModel: StoreBranch<ITestModel, TTestParamsModel>;
  testCollection: StoreBranch<ITestCollection, ITestCollectionFilter>;
  testStatisticCollection: StoreBranch<ITestStatisticCollection, ITestStatisticCollectionFilter>;
  testLeaderboardCollection: StoreBranch<ITestLeaderboardCollection, ITestLeaderboardCollectionFilter>;

  getTestModel(id: string): void;
  updateTestModel(params: ITestUpdateData): void;
  deleteTestModel(id: string): void;
  getTestCollection(filter?: ITestCollectionFilter): void;
  getTestLeaderboardCollection(filter?: ITestLeaderboardCollectionFilter): Promise<void>;

  getTestStatisticCollection(filter?: ITestStatisticCollectionFilter): void;

  clearTestModel(): void;
  clearTestCollection(): void;
  clearTestStatisticCollection(): void;
  clearTestLeaderboardCollection(): void;
}

const TestModelAPIProviders = [
  new APIProvider(EActionsTypes.get, testTransport.get),
  new APIProvider(EActionsTypes.update, testTransport.update, {
    onSuccess: function*() {
      message.success(ETestSuccessMessage.Edit);
      yield put(push(ERoutesPrivate.Test));
    }
  }),
  new APIProvider(EActionsTypes.delete, testTransport.delete, {
    onSuccess: function*() {
      message.success(ETestSuccessMessage.Delete);
      yield put(push(ERoutesPrivate.Test));
    }
  })
];

const TestCollectionAPIProviders = [new APIProvider(EActionsTypes.get, testTransport.getCollection)];
const TestStatisticCollectionAPIProviders = [new APIProvider(EActionsTypes.get, testTransport.getTestStatisticCollection)];
const TestLeaderboardCollectionAPIProviders = [
  new APIProvider(EActionsTypes.get, testTransport.getLeaderboardCollection, {
    preRequestDataMapper: buildCollectionPreRequestDataMapper()
  })
];
const branches = [
  new Branch('model', TestModelAPIProviders),
  new Branch('collection', TestCollectionAPIProviders),
  new Branch('statisticCollection', TestStatisticCollectionAPIProviders),
  new Branch('leaderboardCollection', TestLeaderboardCollectionAPIProviders)
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationTest = buildCommunication<ITestConnectedProps>(strategy);
