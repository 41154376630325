import React, { useCallback, useEffect, useMemo } from 'react';
import { Button, DatePicker, Form, Input, InputNumber, Popover, Row, Switch, Space, Col } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import moment, { Moment } from 'moment';
import { useFormMapper } from '@axmit/antd4-helpers';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { ERoutesPrivate } from 'common/models/routesModel';
import { requiredRules } from 'common/helpers/rules.helper';
import { TariffVisibilitySelector } from 'entities/Tariff/components/Selectors/TariffVisibilitySelector';
import { TariffSelector } from 'entities/Tariff/components/Selectors/TariffsSelector';
import {
  ETariffCountries,
  ETariffCountriesCurrency,
  ETariffCurrencys,
  ETariffFieldsHint,
  ETariffStatus,
  ETariffTypes,
  ETariffVisibility,
  ITariffCreate,
  ITariffPrice,
  TariffCountries
} from 'entities/Tariff/Tariff.models';
import { communicationTariff, ITariffConnectedProps } from 'entities/Tariff/Tariff.communication';
import { TariffCountryValue } from 'entities/Tariff/components/Form/TariffCountryValue';
import { TariffCurrencyValue } from 'entities/Tariff/components/Form/TariffCurrencyValue';

interface IProps {
  isEdit: boolean;
}

type AllProps = IProps & ITariffConnectedProps;

const pricesConverter = (prices: { country: ETariffCountries; amount: number }[]) =>
  prices?.map(price => ({
    ...price,
    amount: price.amount - 0,
    currency: ETariffCountriesCurrency[price.country]
  }));

const TariffFormComponent: React.FC<AllProps> = props => {
  const { id: tariffId } = useParams();
  const [form] = Form.useForm();
  const { tariffModel, addTariffModel, updateTariffModel, isEdit, updateStatusTariffModel } = props;
  const { errors, loading, data, params } = tariffModel;
  const hasSubscriptions = useMemo(() => !!data && !!data?.subscriptionCount, [data]);
  const disabled = useMemo(() => loading || isEdit, [loading, isEdit]);
  const isArchived = useMemo(() => !!data && data?.status === ETariffStatus.Archived, [data]);

  const formData = useMemo(() => {
    const formattedData: {
      visibility: ETariffVisibility;
      activeTo?: Moment;
      juniStat?: boolean;
      juniCoach?: boolean;
      prices?: Partial<ITariffPrice>[];
    } = {
      ...data,
      activeTo: undefined,
      visibility: ETariffVisibility.Private
    };

    if (data?.activeTo) {
      formattedData.activeTo = moment(data?.activeTo);
    }

    if (data?.visibility) {
      formattedData.visibility = data.visibility as ETariffVisibility;
    }

    if (!isEdit) {
      formattedData.juniStat = true;
      formattedData.juniCoach = true;
    }

    formattedData.prices = TariffCountries.map(({ value, currency }) => {
      const currentValue = data?.prices?.find(({ country }) => country === value);
      if (currentValue) {
        return currentValue;
      } else {
        return {
          country: value,
          currency: currency
        };
      }
    });

    return formattedData;
  }, [data]);

  const { fields } = useFormMapper(
    [
      'localizationTag',
      'amount',
      'period',
      'lifetime',
      'activeTo',
      'next',
      'visibility',
      'order',
      'description',
      'isHidePeriod',
      'restrictPrepayment',
      'useDefaultPrice',
      'juniStat',
      'juniCoach',
      'prices'
    ],
    formData,
    params,
    errors
  );

  useEffect(() => {
    const validationErrors = (errors as any)?.validation || {};

    if (Object.keys(validationErrors).length) {
      const content = Object.entries(validationErrors).reduce(
        (acc: { name: (string | number)[]; errors: string[] }[], [name, fieldErrors]) => {
          if (name.includes('prices')) {
            const errorFieldName: (string | number)[] = name.split('.');

            errorFieldName.splice(1, 1, Number(errorFieldName[1]));

            return [
              ...acc,
              {
                name: errorFieldName,
                errors: fieldErrors as string[]
              }
            ];
          }

          return acc;
        },
        []
      );

      setTimeout(() => form.setFields(content));
    }
  }, [errors]);

  const disabledDate = useCallback((current: Moment) => {
    // Can not select days before today and today
    return current && current < moment().endOf('day');
  }, []);

  const onFinish = useCallback(
    (values: any) => {
      if (isEdit) {
        if (tariffId) {
          const {
            description,
            order,
            visibility,
            isHidePeriod,
            restrictPrepayment,
            next,
            activeTo,
            localizationTag,
            prices,
            juniStat,
            juniCoach,
            useDefaultPrice
          } = values;

          const body = {
            id: tariffId,
            localizationTag: localizationTag === '' ? null : localizationTag,
            description: description === '' ? null : description,
            order: order === '' ? null : order,
            visibility,
            isHidePeriod,
            restrictPrepayment,
            juniStat,
            juniCoach,
            next: next?.id ? next.id : next,
            activeTo,
            prices: pricesConverter(prices),
            useDefaultPrice
          };

          if (!hasSubscriptions) {
            body.next = next ? next.id : null;
            body.activeTo = activeTo ? activeTo.format() : null;
          }

          updateTariffModel(body);
        }
      } else {
        const { mentors, players, next, prices, juniStat, juniCoach, ...rest } = values;
        const body: ITariffCreate = {
          ...rest,
          currency: ETariffCurrencys.USD,
          type: ETariffTypes.Repeatable,
          juniStat,
          juniCoach,
          prices: pricesConverter(prices)
        };

        if (next && next?.id) {
          body.next = next.id;
        }
        addTariffModel(body);
      }
    },
    [addTariffModel, updateTariffModel, isEdit, tariffId, hasSubscriptions]
  );

  const onArchive = () => {
    if (tariffId && updateStatusTariffModel) {
      updateStatusTariffModel({
        id: tariffId,
        status: ETariffStatus.Archived
      });
    }
  };

  const onActivate = () => {
    if (tariffId && updateStatusTariffModel) {
      updateStatusTariffModel({
        id: tariffId,
        status: ETariffStatus.Active
      });
    }
  };

  return (
    <Form className="mt-5 width-full" form={form} onFinish={onFinish} fields={fields} name="tariff-jinustat-form">
      <Form.Item
        rules={[
          {
            required: true,
            message: 'Localization tag is required'
          }
        ]}
        name="localizationTag"
        label="Localization tag"
      >
        <Input disabled={loading} />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: 'Amount is required'
          }
        ]}
        name="amount"
        label={
          <Row align="middle">
            <span className="mr-3">One-time payment amount</span>
            <Popover content={ETariffFieldsHint.Amount} children={<InfoCircleOutlined />} />
          </Row>
        }
      >
        <InputNumber disabled={disabled} min={0} />
      </Form.Item>
      <Form.Item
        rules={[
          {
            type: 'number',
            max: 60,
            message: 'Maximum 60'
          },
          {
            required: true,
            message: 'Validity period is required'
          }
        ]}
        name="period"
        label={
          <Row align="middle">
            <span className="mr-3">Validity period of a one-time payment, months (max 60)</span>
            <Popover content={ETariffFieldsHint.Period} children={<InfoCircleOutlined />} />
          </Row>
        }
      >
        <InputNumber precision={0} disabled={disabled} min={0} max={60} />
      </Form.Item>
      <Form.Item valuePropName="checked" name="isHidePeriod" label="Hide validity period of a one-time payment for users">
        <Switch checkedChildren="Yes" unCheckedChildren="No" />
      </Form.Item>
      <Form.Item valuePropName="checked" name="juniStat" label="Is JuniStat tariff">
        <Switch checkedChildren="Yes" unCheckedChildren="No" />
      </Form.Item>
      <Form.Item valuePropName="checked" name="juniCoach" label="Is JuniCoach tariff">
        <Switch checkedChildren="Yes" unCheckedChildren="No" />
      </Form.Item>
      <Form.Item
        rules={[
          {
            type: 'number',
            max: 12,
            message: 'Maximum 12'
          }
        ]}
        name="lifetime"
        label={
          <Row align="middle">
            <span className="mr-3">Validity period of the tariff plan after its activation, months (max 12)</span>
            <Popover content={ETariffFieldsHint.Lifetime} children={<InfoCircleOutlined />} />
          </Row>
        }
      >
        <InputNumber precision={0} disabled={disabled} min={0} max={12} />
      </Form.Item>
      <Form.Item
        name="activeTo"
        label={
          <Row align="middle">
            <span className="mr-3">Date after which the tariff will be archived</span>
            <Popover content={ETariffFieldsHint.ActiveTo} children={<InfoCircleOutlined />} />
          </Row>
        }
      >
        <DatePicker
          name="activeTo"
          format="DD-MM-YYYY"
          disabledDate={disabledDate}
          placeholder="Tariff expiration date"
          disabled={loading || hasSubscriptions}
          style={{ width: '100%' }}
        />
      </Form.Item>
      <Form.Item name="next" label="Next tariff">
        <TariffSelector
          disabled={loading || hasSubscriptions}
          visibility={ETariffVisibility.Public}
          statuses={[ETariffStatus.Active]}
        />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: 'Visibility name is required'
          }
        ]}
        name="visibility"
        label="Visibility"
      >
        <TariffVisibilitySelector disabled={loading} />
      </Form.Item>
      <Form.Item
        rules={[
          {
            type: 'number',
            max: 999,
            message: 'Maximum 999'
          },
          {
            required: true,
            message: 'Order is required'
          }
        ]}
        name="order"
        label="Order"
      >
        <InputNumber precision={0} disabled={loading} min={0} />
      </Form.Item>
      <Form.Item name="description" label="Description (not shown for users)">
        <Input disabled={loading} />
      </Form.Item>
      <Form.Item valuePropName="checked" name="restrictPrepayment" label="Restrict prepayment">
        <Switch checkedChildren="Yes" unCheckedChildren="No" />
      </Form.Item>

      <Form.Item
        valuePropName="checked"
        name="useDefaultPrice"
        label={
          <Row align="middle">
            <span className="mr-3">Use default price</span>
            <Popover content={ETariffFieldsHint.UseDefaultPrice} children={<InfoCircleOutlined />} />
          </Row>
        }
      >
        <Switch checkedChildren="Yes" unCheckedChildren="No" />
      </Form.Item>

      <div className="ant-col ant-form-item-label">
        <label className="ant-form-item-required" title="Prices">
          Prices
        </label>
      </div>
      <Space direction="vertical" className="mb-5">
        <Form.List name="prices">
          {(priceFields, _, { errors }) =>
            priceFields.map(({ key, name, ...restField }) => (
              <div key={key}>
                <Row gutter={8} align="middle" wrap={false}>
                  <Col xs={8}>
                    <Form.Item name={[name, 'country']} {...restField}>
                      <TariffCountryValue />
                    </Form.Item>
                  </Col>
                  <Col xs={8}>
                    <Form.Item name={[name, 'amount']} rules={[requiredRules]} {...restField}>
                      <InputNumber min={0} />
                    </Form.Item>
                  </Col>
                  <Col xs={8}>
                    <Form.Item name={[name, 'currency']} {...restField}>
                      <TariffCurrencyValue />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.ErrorList errors={errors} />
              </div>
            ))
          }
        </Form.List>
      </Space>
      <Row justify="space-between" align="middle">
        <Button className="mr-8" htmlType="submit" type="primary" disabled={loading} loading={loading} title="Submit">
          Submit
        </Button>
        {isEdit && !isArchived && (
          <Button type="primary" danger onClick={onArchive} disabled={loading} loading={loading}>
            Archive
          </Button>
        )}
        {isEdit && isArchived && (
          <Button type="primary" onClick={onActivate} disabled={loading} loading={loading}>
            Activate
          </Button>
        )}
        <Link to={ERoutesPrivate.Tariffs}>
          <Button disabled={loading} loading={loading} title="Cancel">
            Cancel
          </Button>
        </Link>
      </Row>
    </Form>
  );
};

export const TariffForm = communicationTariff.injector(TariffFormComponent);
