import { IBasePathParams } from 'common/models/requestModels';

export interface ILanguageModel extends Components.Schemas.LanguageDto {}
export interface ICreateLanguageParams extends Components.Schemas.CreateLanguageDto {}
export interface IUpdateLanguageParams extends Components.Schemas.UpdateLanguageDto, IBasePathParams {}

export type TLanguageParamsModel = ICreateLanguageParams | IUpdateLanguageParams | IBasePathParams;

export interface ILanguageCollection extends Components.Schemas.LanguageCollectionDto {}
export interface ILanguageCollectionFilter extends Paths.LanguageControllerList.QueryParameters {}

export interface IGetLanguageLocaleFileParams {
  locale: string;
  app: ELocalesFilesAppTypes;
}

export enum ELocalesFilesAppTypes {
  Notifications = 'notifications',
  Js = 'js',
  Mail = 'mail'
}
