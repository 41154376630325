import axios, { AxiosStatic } from 'axios';
import { BaseHttpTransport } from '@axmit/transport';
import { IUpdateParams } from '@axmit/transport/src/index';
import { objectToQuery } from 'common/helpers/filters.helper';
import {
  IAcademyCollection,
  IAcademyCollectionFilter,
  IAcademyCreateParams,
  IAcademyModel,
  IAcademyUpdateParams,
  IAcademyUpdateStatusParams,
  ITrainerCreateInviteParams
} from 'entities/Academy/Academy.models';

const basePath = '/academies';

class AcademyTransport extends BaseHttpTransport<
  AxiosStatic,
  IAcademyModel,
  IAcademyCollection,
  IAcademyCreateParams,
  IAcademyUpdateParams,
  IAcademyCollectionFilter
> {
  public updateStatus = (params: IAcademyUpdateStatusParams) => axios.put(`${basePath}/${params.id}/set-status`, params);
  public update = ({ id, ...rest }: Partial<IAcademyModel> & IUpdateParams): Promise<IAcademyModel> => {
    const preparedData =
      this.mapper && this.mapper.mapTo && typeof this.mapper.mapTo === 'function' ? this.mapper.mapTo(rest) : rest;
    return this.axios.put(`${this.path}/${id}/by-admin`, preparedData).then((r: any) => {
      return this.mapper && this.mapper.mapFrom && typeof this.mapper.mapFrom === 'function' ? this.mapper.mapFrom(r) : r;
    });
  };
  public getAcademySearch = (params: IAcademyCollectionFilter): Promise<IAcademyCollection> =>
    axios.get(`${basePath}/search${objectToQuery(params)}`);
  public createInviteTrainerToken = (params: ITrainerCreateInviteParams): Promise<{ token?: string }> =>
    axios.post(`/trainers/invite-trainers/create-token`, params);
}

export const academyTransport = new AcademyTransport(basePath, axios);
