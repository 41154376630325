import { IBasePathParams } from 'common/models/requestModels';

export interface IProductModel extends Components.Schemas.ProductDto {}
export interface IProductCollection extends Components.Schemas.ProductCollectionDto {}

export interface IProductCollectionFilter extends Paths.ProductControllerList.QueryParameters {
  pageIndex?: number;
}
export interface IProductCreateData extends Components.Schemas.CreateProductDto {}

export interface IProductUpdateData extends Components.Schemas.UpdateProductDto, IProductUpdateParams {}
export interface IProductUpdateParams extends Paths.ProductControllerUpdate.PathParameters {}

export type TProductParamsModel = IProductCreateData | IProductUpdateData | IBasePathParams;

export enum EProductSuccessMessage {
  Delete = 'Product has been successfully deleted',
  Add = 'Product has been successfully created',
  Edit = 'Product has been successfully edited'
}
export enum EProductStatus {
  Active = 'active',
  Canceled = 'canceled'
}

export enum EProductType {
  Coins = 'coins'
}

export enum EProductMarket {
  GooglePlay = 'googlePlay',
  AppStore = 'appStore',
  Yookassa = 'yookassa'
}

export enum EProductTargetGroup {
  Player = 'player',
  Mentor = 'mentor',
  Trainer = 'trainer',
  Academy = 'academy'
}

export const tableProductConfig = [
  {
    title: 'id',
    dataIndex: 'id',
    key: 'id',
    ellipsis: true
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    ellipsis: true
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    ellipsis: true
  },
  {
    title: 'Market',
    dataIndex: 'market',
    key: 'market',
    ellipsis: true
  },
  {
    title: 'External Id',
    dataIndex: 'externalId',
    key: 'externalId',
    ellipsis: true
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    ellipsis: true
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    ellipsis: true
  }
];
