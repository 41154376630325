import { IBasePathParams } from 'common/models/requestModels';
import { ILocationModel } from 'common/helpers/location.helper';

export interface IAcademyCollection extends Components.Schemas.AcademyCollectionDto {}
export interface IAcademyCollectionFilter extends Paths.AcademyControllerList.QueryParameters {
  pageIndex?: number;
  location?: ILocationModel;
  franchiseId?: string;
}
export interface ITrainerCreateInviteParams extends Components.Schemas.CreateInviteTrainerTokenDto {}

export interface IAcademyModel extends Components.Schemas.AcademyDto {}
export interface IAcademyCreateParams extends Components.Schemas.CreateAcademyDto {}
export interface IAcademyUpdateParams extends Components.Schemas.UpdateAcademyByAdminDto, IBasePathParams {}
export interface IAcademyUpdateStatusParams extends Components.Schemas.UpdateAcademyStatusDto, IBasePathParams {}
export interface IAcademyParamsModel
  extends Partial<IAcademyCreateParams>,
    IAcademyUpdateParams,
    IAcademyUpdateStatusParams,
    IBasePathParams {}
export interface IAddressModel extends Components.Schemas.AddressDto {}

export enum EAcademyErrorMessages {
  ForbidFranchiseToModeration = 'The franchise academy cannot be in moderation status'
}

export enum EAcademyErrorCode {
  Forbidden = 'error.forbiddenException'
}

export enum EAcademyStatus {
  New = 'new',
  Moderation = 'moderation',
  Active = 'active',
  Banned = 'banned'
}

export enum EAcademyStatusText {
  New = 'New',
  Moderation = 'Moderation',
  Active = 'Active',
  Banned = 'Banned'
}

export const tableAcademyConfig = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    ellipsis: true
  },
  {
    title: 'Address',
    dataIndex: 'addressText',
    key: 'addressText',
    ellipsis: true
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    ellipsis: true
  },
  {
    title: 'Rating',
    dataIndex: 'rating',
    key: 'rating',
    ellipsis: true
  },
  {
    title: 'Franchise type',
    dataIndex: ['franchiseRequest', 'type'],
    ket: 'franchiseRequest',
    ellipsis: true
  },
  {
    title: 'Created',
    dataIndex: 'createdAt',
    key: 'createdAt',
    ellipsis: true
  }
];
