import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { ETariffVisibility, ETariffVisibilityText } from 'entities/Tariff/Tariff.models';

interface IComponentProps {
  onChange?: (value?: ETariffVisibility) => void;
  disabled?: boolean;
}

type AllProps = SelectProps<any> & IComponentProps;

export class TariffVisibilitySelector extends React.PureComponent<AllProps> {
  render() {
    const { value: propValue, onChange, disabled = false } = this.props;

    return (
      <Select
        allowClear
        className="width-full"
        placeholder="Select visibiliy"
        value={propValue}
        defaultActiveFirstOption={false}
        showArrow
        onChange={(value: ETariffVisibility) => onChange && onChange(value)}
        disabled={disabled}
      >
        <Select.Option key={ETariffVisibility.Private} value={ETariffVisibility.Private} label={ETariffVisibilityText.Private}>
          {ETariffVisibilityText.Private}
        </Select.Option>
        <Select.Option key={ETariffVisibility.Public} value={ETariffVisibility.Public} label={ETariffVisibilityText.Public}>
          {ETariffVisibilityText.Public}
        </Select.Option>
      </Select>
    );
  }
}
