import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { WalletDirectTransactionForm } from 'entities/Wallet/components/Form/WalletDirectTransactionForm';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';
import { PlayerSelector } from 'entities/Player/components/PlayerSelector';
import { IPlayerModel } from 'entities/Player/Player.models';
import { communicationPlayer, IPlayerConnectedProps } from 'entities/Player/Player.communication';

type AllProps = IUIConnectedProps & IPlayerConnectedProps;

const WalletDirectTransactionModalComponent: React.FC<AllProps> = props => {
  const { closeUIWalletModal, UIWalletModal, clearUIWalletModal, getPlayerModel, playerModel } = props;
  const { data } = UIWalletModal;
  const { isVisible, player, redirectUrl } = data || {};
  const [playerModelState, setPlayerModelState] = useState<IPlayerModel | undefined>(player);

  useEffect(() => {
    setPlayerModelState(player);
  }, [player]);

  useEffect(() => {
    if (!playerModelState?.wallet?.id && playerModelState?.id) {
      getPlayerModel(playerModelState?.id);
    }
  }, [playerModelState]);

  useEffect(() => {
    if (!playerModelState?.wallet?.id && playerModel?.data) {
      setPlayerModelState(playerModel?.data);
    }
  }, [playerModel, playerModelState]);

  useEffect(() => {
    return () => clearUIWalletModal();
  }, [clearUIWalletModal]);

  return (
    <Modal
      bodyStyle={{ border: 'none' }}
      visible={isVisible}
      onCancel={closeUIWalletModal}
      footer={false}
      title="Direct transaction"
    >
      <PlayerSelector
        onChange={setPlayerModelState}
        value={playerModelState}
        placeholder="Search by player"
        disabled={!!player}
      />
      {!!playerModelState && <WalletDirectTransactionForm player={playerModelState} redirectUrl={redirectUrl} />}
    </Modal>
  );
};

export const WalletDirectTransactionModal = communicationPlayer.injector(
  communicationUI.injector(WalletDirectTransactionModalComponent)
);
