import React from 'react';
import { Upload, Button, message, Typography, Row } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload/interface';
import moment from 'moment';
import { checkArModel } from 'common/helpers/loader.helper';
import { EFileStatus } from 'common/components/Image/Image.models';
import { EErrorStatus } from 'common/models/requestModels';
import { getHost } from 'common/helpers/axios.helper';
import { simpleDateFormat } from 'common/models/dateModels';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';

export enum ETestModelType {
  Android = 'android',
  Ios = 'ios'
}

interface IPropsModel {
  id: string | null;
  createdAt?: string;
}

interface IProps {
  modelType: ETestModelType;
  setAndroidModel?: (model: IPropsModel) => void;
  setIosModel?: (model: IPropsModel) => void;
  dataModel?: IPropsModel;
  model?: IPropsModel;
  onLoading?: (isLoading: boolean) => void;
}

type AllProps = IProps & IAuthConnectedProps;

const TestModelUploadComponent: React.FC<AllProps> = props => {
  const { authModel, modelType, setAndroidModel, setIosModel, dataModel, model, onLoading } = props;
  const host = getHost();
  const token = authModel.data?.access.token;
  const data = model || dataModel;

  const sendModel = (modelData: IPropsModel) => {
    if (modelType === ETestModelType.Android) {
      if (setAndroidModel) {
        setAndroidModel(modelData);
      }
    } else {
      if (setIosModel) {
        setIosModel(modelData);
      }
    }
  };

  const handleChange = (info: UploadChangeParam) => {
    const { file } = info;
    const { status, response } = file;

    if (info.file.status === EFileStatus.Uploading) {
      onLoading && onLoading(true);
      return;
    }

    if (status === EFileStatus.Done) {
      const id = response?.id;
      const createdAt = response?.createdAt;
      const modelData = { id, createdAt };

      sendModel(modelData);
      onLoading && onLoading(false);
    }

    if (status === EFileStatus.Error || file.error?.status === EErrorStatus.Unauthorized) {
      message.warning('Something went wrong, reload page and try again');
      onLoading && onLoading(false);
    }
  };

  const onDeleteModel = () => {
    const modelData = { id: null };

    sendModel(modelData);
  };

  return (
    <>
      <Upload
        action="/api/files"
        headers={{ authorization: `Bearer ${token}` }}
        showUploadList={false}
        name="file"
        className="width-full"
        beforeUpload={checkArModel as any}
        multiple={false}
        onChange={handleChange}
      >
        <Button block type="primary">
          Upload {modelType} model
        </Button>
      </Upload>
      {data?.id && data.createdAt && (
        <div className="mt-5">
          <Row>
            <Typography.Text>Uploaded at: {moment(data.createdAt).format(simpleDateFormat)}</Typography.Text>
          </Row>
          <Row>
            <Button type="text">
              <Typography.Link href={`${host}/api/files/${data.id}?token=${token}`} target="_blank" rel="noopener noreferrer">
                Download
              </Typography.Link>
            </Button>
            <Button danger type="text" onClick={onDeleteModel}>
              Delete
            </Button>
          </Row>
        </div>
      )}
    </>
  );
};

export const TestModelUpload = communicationAuth.injector(TestModelUploadComponent);
