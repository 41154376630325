import { EActionsTypes, APIProvider, BaseStrategy, Branch, buildCommunication, StoreBranch } from '@axmit/redux-communications';
import { message } from 'antd';
import { put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { ERoutesPrivate } from 'common/models/routesModel';
import { productTransport } from 'entities/Product/Product.transport';
import {
  EProductSuccessMessage,
  IProductCreateData,
  IProductModel,
  IProductUpdateData,
  IProductCollectionFilter,
  IProductCollection,
  TProductParamsModel
} from 'entities/Product/Product.models';

const namespace = 'product';

export interface IProductConnectedProps {
  productModel: StoreBranch<IProductModel, TProductParamsModel>;
  productCollection: StoreBranch<IProductCollection, IProductCollectionFilter>;

  getProductModel(id: string): void;
  addProductModel(params: IProductCreateData): void;
  updateProductModel(params: IProductUpdateData): void;
  deleteProductModel(id: string): void;
  getProductCollection(filter?: IProductCollectionFilter): void;

  clearProductModel(): void;
  clearProductCollection(): void;
}

const ProductModelAPIProviders = [
  new APIProvider(EActionsTypes.get, productTransport.get),
  new APIProvider(EActionsTypes.update, productTransport.update, {
    onSuccess: function*() {
      message.success(EProductSuccessMessage.Edit);
      yield put(push(ERoutesPrivate.Product));
    }
  }),
  new APIProvider(EActionsTypes.delete, productTransport.delete, {
    onSuccess: function*() {
      message.success(EProductSuccessMessage.Delete);
      yield put(push(ERoutesPrivate.Product));
    }
  }),
  new APIProvider(EActionsTypes.add, productTransport.add, {
    onSuccess: function*() {
      message.success(EProductSuccessMessage.Add);
      yield put(push(ERoutesPrivate.Product));
    }
  })
];

const ProductCollectionAPIProviders = [new APIProvider(EActionsTypes.get, productTransport.getCollection)];

const branches = [new Branch('model', ProductModelAPIProviders), new Branch('collection', ProductCollectionAPIProviders)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationProduct = buildCommunication<IProductConnectedProps>(strategy);
