import React from 'react';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { Link } from 'react-router-dom';
import { StoreBranch } from '@axmit/redux-communications';
import { useFormMapper } from '@axmit/antd4-helpers';
import { ERoutesPrivate } from 'common/models/routesModel';
import { isPhone } from 'common/helpers/rules.helper';
import { INotificationTemplateCreateParams } from 'entities/NotificationTemplate/NotificationTemplate.models';

interface IComponentProps {
  model: StoreBranch<void>;
  onSubmit: (data: INotificationTemplateCreateParams) => void;
}

const NotificationSmsSendFormComponent: React.FC<IComponentProps> = props => {
  const { model, onSubmit } = props;
  const { errors, loading } = model;

  const { fields } = useFormMapper(['phones', 'text'], null, null, errors);

  const handleSubmit = (results: INotificationTemplateCreateParams) => {
    onSubmit({
      ...results
    });
  };

  const normalizePhone = (values: string[]) => values.filter(value => isPhone(value));

  return (
    <Form className="mt-5" onFinish={handleSubmit} fields={fields}>
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Must be at least 1 phone number'
              }
            ]}
            normalize={normalizePhone}
            name="phones"
            label="Phones"
          >
            <Select tokenSeparators={[' ', ',', ', ']} mode="tags" placeholder="Phones" style={{ width: '100%' }} />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Message text is required'
              },
              {
                min: 2,
                message: 'Message text must be longer than or equal 2 characters'
              },
              {
                max: 1024,
                message: 'Message text must be shorter than or equal 1024 characters'
              }
            ]}
            name="text"
            label="Message text"
          >
            <Input.TextArea rows={3} placeholder="Message text" disabled={loading} />
          </Form.Item>
        </Col>
      </Row>

      <Row justify="space-between" align="middle">
        <Col span={16}>
          <Form.Item className="mb-0">
            <Button className="mr-8" htmlType="submit" type="primary" disabled={loading} loading={loading} title="Save">
              Send
            </Button>
            <Link to={ERoutesPrivate.NotificationTemplates}>
              <Button disabled={loading} loading={loading} title="Cancel">
                Cancel
              </Button>
            </Link>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export const NotificationSmsSendForm = NotificationSmsSendFormComponent;
