import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { EPlayerTestRejectReason, EPlayerTestRejectReasonText } from 'entities/PlayerTest/PlayerTest.models';

interface IComponentProps {
  onChange?: (value: EPlayerTestRejectReason) => void;
  placeholder?: string;
}

type AllProps = SelectProps<any> & IComponentProps;

export class PlayerTestRejectSelector extends React.PureComponent<AllProps> {
  handleChange = (value: EPlayerTestRejectReason) => {
    const { onChange } = this.props;
    onChange && onChange(value);
  };

  render() {
    const { value, defaultValue, placeholder = 'Select' } = this.props;

    return (
      <Select
        allowClear
        className="width-full"
        value={value}
        placeholder={placeholder}
        defaultActiveFirstOption={false}
        defaultValue={defaultValue}
        showArrow
        onChange={(value: EPlayerTestRejectReason) => this.handleChange(value)}
      >
        <Select.Option
          key={EPlayerTestRejectReason.One}
          value={EPlayerTestRejectReason.One}
          label={EPlayerTestRejectReasonText.One}
        >
          {EPlayerTestRejectReasonText.One}
        </Select.Option>
        <Select.Option
          key={EPlayerTestRejectReason.Two}
          value={EPlayerTestRejectReason.Two}
          label={EPlayerTestRejectReasonText.Two}
        >
          {EPlayerTestRejectReasonText.Two}
        </Select.Option>
        <Select.Option
          key={EPlayerTestRejectReason.Three}
          value={EPlayerTestRejectReason.Three}
          label={EPlayerTestRejectReasonText.Three}
        >
          {EPlayerTestRejectReasonText.Three}
        </Select.Option>
        <Select.Option
          key={EPlayerTestRejectReason.Four}
          value={EPlayerTestRejectReason.Four}
          label={EPlayerTestRejectReasonText.Four}
        >
          {EPlayerTestRejectReasonText.Four}
        </Select.Option>
        <Select.Option
          key={EPlayerTestRejectReason.Five}
          value={EPlayerTestRejectReason.Five}
          label={EPlayerTestRejectReasonText.Five}
        >
          {EPlayerTestRejectReasonText.Five}
        </Select.Option>
        <Select.Option
          key={EPlayerTestRejectReason.Six}
          value={EPlayerTestRejectReason.Six}
          label={EPlayerTestRejectReasonText.Six}
        >
          {EPlayerTestRejectReasonText.Six}
        </Select.Option>
        <Select.Option
          key={EPlayerTestRejectReason.Seven}
          value={EPlayerTestRejectReason.Seven}
          label={EPlayerTestRejectReasonText.Seven}
        >
          {EPlayerTestRejectReasonText.Seven}
        </Select.Option>
        <Select.Option
          key={EPlayerTestRejectReason.Eight}
          value={EPlayerTestRejectReason.Eight}
          label={EPlayerTestRejectReasonText.Eight}
        >
          {EPlayerTestRejectReasonText.Eight}
        </Select.Option>
        <Select.Option
          key={EPlayerTestRejectReason.Nine}
          value={EPlayerTestRejectReason.Nine}
          label={EPlayerTestRejectReasonText.Nine}
        >
          {EPlayerTestRejectReasonText.Nine}
        </Select.Option>
        <Select.Option
          key={EPlayerTestRejectReason.Ten}
          value={EPlayerTestRejectReason.Ten}
          label={EPlayerTestRejectReasonText.Ten}
        >
          {EPlayerTestRejectReasonText.Ten}
        </Select.Option>
        <Select.Option
          key={EPlayerTestRejectReason.Eleven}
          value={EPlayerTestRejectReason.Eleven}
          label={EPlayerTestRejectReasonText.Eleven}
        >
          {EPlayerTestRejectReasonText.Eleven}
        </Select.Option>
        <Select.Option
          key={EPlayerTestRejectReason.Twelve}
          value={EPlayerTestRejectReason.Twelve}
          label={EPlayerTestRejectReasonText.Twelve}
        >
          {EPlayerTestRejectReasonText.Twelve}
        </Select.Option>
        <Select.Option
          key={EPlayerTestRejectReason.Thirteen}
          value={EPlayerTestRejectReason.Thirteen}
          label={EPlayerTestRejectReasonText.Thirteen}
        >
          {EPlayerTestRejectReasonText.Thirteen}
        </Select.Option>
        <Select.Option
          key={EPlayerTestRejectReason.Fourteen}
          value={EPlayerTestRejectReason.Fourteen}
          label={EPlayerTestRejectReasonText.Fourteen}
        >
          {EPlayerTestRejectReasonText.Fourteen}
        </Select.Option>
        <Select.Option
          key={EPlayerTestRejectReason.Fifteen}
          value={EPlayerTestRejectReason.Fifteen}
          label={EPlayerTestRejectReasonText.Fifteen}
        >
          {EPlayerTestRejectReasonText.Fifteen}
        </Select.Option>
      </Select>
    );
  }
}
