import {
  EActionsTypes,
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  StoreBranch,
  getStartType,
  buildCollectionResponseFormatter,
  buildCollectionPreRequestDataMapper
} from '@axmit/redux-communications';
import { put, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import { isAcademyFranchiseActive } from 'common/helpers/franchise.helper';
import { academyTransport } from 'entities/Academy/Academy.transport';
import {
  EAcademyErrorCode,
  EAcademyErrorMessages,
  EAcademyStatus,
  IAcademyCollection,
  IAcademyCollectionFilter,
  IAcademyModel,
  IAcademyParamsModel,
  IAcademyUpdateParams,
  IAcademyUpdateStatusParams
} from 'entities/Academy/Academy.models';

export const namespace = 'academy';

export interface IAcademyConnectedProps {
  academyModel: StoreBranch<IAcademyModel, IAcademyParamsModel>;
  academyCollection: StoreBranch<IAcademyCollection, IAcademyCollectionFilter>;

  getAcademyModel(id: string): void;
  updateAcademyModel(params: IAcademyUpdateParams): void;
  updateStatusAcademyModel(params: IAcademyUpdateStatusParams): void;
  getAcademyCollection(filter?: IAcademyCollectionFilter): void;
  getSearchAcademyCollection(filter?: IAcademyCollectionFilter): void;

  clearAcademyModel(): void;
  clearAcademyCollection(): void;
}

const AcademyModelAPIProviders = [
  new APIProvider(EActionsTypes.get, academyTransport.get),
  new APIProvider(EActionsTypes.update, academyTransport.update, {
    preRequestDataMapper: function(response: null, params: any, branch: StoreBranch<IAcademyModel | null, any, any>) {
      return branch?.data;
    }
  }),
  new APIProvider(`updateStatus`, academyTransport.updateStatus, {
    preRequestDataMapper: function(response: null, params: any, branch: StoreBranch<IAcademyModel | null, any, any>) {
      return branch?.data;
    },
    onFail: function(response, originalParams, branchState) {
      if (
        isAcademyFranchiseActive(branchState?.data) &&
        originalParams?.status === EAcademyStatus.Moderation &&
        response?.data?.code === EAcademyErrorCode.Forbidden
      ) {
        message.error(EAcademyErrorMessages.ForbidFranchiseToModeration);
      }
    }
  })
];

const AcademyCollectionAPIProviders = [
  new APIProvider(EActionsTypes.get, academyTransport.getCollection),
  new APIProvider(
    'getSearch',
    academyTransport.getAcademySearch,
    {
      mapSuccess: buildCollectionResponseFormatter<any, any>(),
      preRequestDataMapper: buildCollectionPreRequestDataMapper<IAcademyCollection, IAcademyCollectionFilter>()
    },
    takeLatest
  )
];

const branches = [new Branch('model', AcademyModelAPIProviders), new Branch('collection', AcademyCollectionAPIProviders)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationAcademy = buildCommunication<IAcademyConnectedProps>(strategy);

export function* updateAcademyModel(id?: string) {
  if (id) {
    yield put({ type: getStartType(namespace, 'model', EActionsTypes.get), payload: id });
  }
}
