import { Row } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { ERoutesPrivate } from 'common/models/routesModel';
import { nameBuilder } from 'common/helpers/name.helper';
import { IBasePathParams } from 'common/models/requestModels';
import { VideoPrivate } from 'common/components/Video/VideoPrivate';
import { IVideoModel } from 'common/components/Image/Image.models';
import { IPlayerModel } from 'entities/Player/Player.models';
import { EEntityType, IUserModel, TEntityValue } from 'entities/User/User.models';

export interface IFeedCollection extends Components.Schemas.FeedCollectionDto {}
export interface IFeedCollectionFilter extends Paths.FeedControllerList.QueryParameters {
  pageIndex?: number;
  authorValue?: TEntityValue;
  authorEntity?: EEntityType;
}
export interface IFeedModel extends Components.Schemas.FeedDto {}
export interface IFeedCreateParams extends Components.Schemas.CreateFeedDto {}
export interface IFeedUpdateParams extends Components.Schemas.UpdateFeedDto, IBasePathParams {}
export interface IFeedParamsModel extends IFeedUpdateParams, IBasePathParams {}

export enum EFeedSuccessMessage {
  Delete = 'Feed has been successfully deleted',
  Add = 'Feed has been successfully created',
  Edit = 'Feed has been successfully edited'
}

const stopPropagation = (event: React.MouseEvent) => {
  event.stopPropagation();
};

export const tableFeedConfig = [
  {
    title: 'Video',
    dataIndex: 'video',
    key: 'video',
    ellipsis: true,
    render: (video: IVideoModel) => {
      const { id, image } = video || {};
      return (
        <Row justify="center" onClick={stopPropagation}>
          {id ? <VideoPrivate height={50} width={100} videoId={id} preview={image?.id} /> : 'No video'}
        </Row>
      );
    }
  },
  {
    title: 'Player',
    dataIndex: 'authorPlayer',
    key: 'authorPlayer',
    ellipsis: true,
    render: (model: IPlayerModel) => {
      const { id, firstName, lastName } = model || {};
      return id ? <Link to={`${ERoutesPrivate.Players}/${id}`}>{nameBuilder(firstName, lastName)}</Link> : '-';
    }
  },
  {
    title: 'Author user',
    dataIndex: 'authorUser',
    key: 'authorUser',
    ellipsis: true,
    render: (model: IUserModel) => {
      const { id, firstName, lastName } = model || {};
      return id ? (
        <Link to={`${ERoutesPrivate.UserList}/${id}`} onClick={stopPropagation}>
          {nameBuilder(firstName, lastName)}
        </Link>
      ) : (
        '-'
      );
    }
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    ellipsis: true,
    sorter: true
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    ellipsis: true,
    sorter: true
  },
  {
    title: 'Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    ellipsis: true,
    sorter: true
  },
  {
    title: 'Likes',
    dataIndex: 'likes',
    key: 'likes',
    ellipsis: true,
    sorter: true
  }
];

export enum EFeedStatus {
  Accept = 'accept',
  Reject = 'reject',
  Moderation = 'moderation',
  Banned = 'banned'
}

export enum EFeedType {
  News = 'news',
  Passing = 'passing',
  Freestyle = 'freestyle'
}
