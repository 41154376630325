import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { ESubscriptionActivityStatus, ESubscriptionActivityStatusText } from 'entities/Subscription/Subscription.models';

interface IComponentProps {
  onChange?: (value?: ESubscriptionActivityStatus[]) => void;
  disabled?: boolean;
}

type AllProps = SelectProps<any> & IComponentProps;

export class SubscriptionStatusSelector extends React.PureComponent<AllProps> {
  render() {
    const { value, onChange, disabled = false } = this.props;

    return (
      <Select
        allowClear
        mode="multiple"
        className="width-full"
        placeholder="Select statuses"
        value={value}
        defaultActiveFirstOption={false}
        showArrow
        onChange={(value: ESubscriptionActivityStatus[]) => onChange && onChange(value)}
        disabled={disabled}
      >
        <Select.Option
          key={ESubscriptionActivityStatus.New}
          value={ESubscriptionActivityStatus.New}
          label={ESubscriptionActivityStatusText.New}
        >
          {ESubscriptionActivityStatusText.New}
        </Select.Option>
        <Select.Option
          key={ESubscriptionActivityStatus.Confirmed}
          value={ESubscriptionActivityStatus.Confirmed}
          label={ESubscriptionActivityStatusText.Confirmed}
        >
          {ESubscriptionActivityStatusText.Confirmed}
        </Select.Option>
        <Select.Option
          key={ESubscriptionActivityStatus.Canceled}
          value={ESubscriptionActivityStatus.Canceled}
          label={ESubscriptionActivityStatusText.Canceled}
        >
          {ESubscriptionActivityStatusText.Canceled}
        </Select.Option>
      </Select>
    );
  }
}
