import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Button, Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import {
  ENotificationTemplateEvent,
  ENotificationTemplateTypes,
  INotificationTemplateCollectionFilter,
  tableNotificationTemplateConfig
} from 'entities/NotificationTemplate/NotificationTemplate.models';
import { NotificationTemplateTable } from 'entities/NotificationTemplate/components/NotificationTemplateTable';
import { NotificationTemplateEventSelector } from 'entities/NotificationTemplate/components/Selector/NotificationTemplateEventSelector';
import { NotificationTemplateTypeSelector } from 'entities/NotificationTemplate/components/Selector/NotificationTemplateTypeSelector';
import { NotificationQueueTaskStatistic } from 'entities/Notification/components/NotificationQueueTaskStatistic';
import { EEntityType } from 'entities/User/User.models';
import { EntityTypeSelector } from 'entities/User/components/EntityTypeSelector';

type AllProps = RouteComponentProps;

interface IComponentState {
  filter: INotificationTemplateCollectionFilter;
}

class NotificationTemplateTableComponent extends React.PureComponent<AllProps, IComponentState> {
  state: IComponentState = {
    filter: {
      pageIndex: 1,
      type: undefined,
      event: undefined,
      receiverType: undefined
    }
  };
  constructor(props: AllProps) {
    super(props);
    this.state.filter = { ...this.state.filter, ...queryToObject<INotificationTemplateCollectionFilter>(this.state.filter) };
  }

  render() {
    const { filter } = this.state;
    const { event, receiverType, type } = filter;

    return (
      <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10">
        <Row gutter={16} className="mb-10 width-full">
          <Col span={5}>
            <NotificationTemplateEventSelector value={event} onChange={this.onChangeEvent} />
          </Col>
          <Col span={5}>
            <NotificationTemplateTypeSelector value={type} onChange={this.onChangeType} />
          </Col>
          <Col span={5}>
            <EntityTypeSelector value={receiverType} onChange={this.onChangeReceiverType} placeholder="Select receiver type" />
          </Col>
          <Col span={3}>
            <Link to={ERoutesPrivate.NotificationSend}>
              <Button block type="primary" title="Send custom notification">
                Send custom notification
              </Button>
            </Link>
          </Col>
          <Col span={3}>
            <Link to={ERoutesPrivate.NotificationSmsSend}>
              <Button block type="primary" title="Sending sms">
                Sending sms
              </Button>
            </Link>
          </Col>
          <Col span={3}>
            <Link to={ERoutesPrivate.NotificationEmailSend}>
              <Button block type="primary" title="Sending email">
                Sending email
              </Button>
            </Link>
          </Col>
        </Row>
        <NotificationQueueTaskStatistic />
        <NotificationTemplateTable config={tableNotificationTemplateConfig} filter={filter} />
      </CommonLayout>
    );
  }

  setFilter = (partialFilter: Partial<INotificationTemplateCollectionFilter>) => {
    const oldFilter = this.state.filter;
    const filter = { ...oldFilter, ...partialFilter };
    this.props.history.replace({ search: objectToQuery(filter) });
    this.setState({ filter });
  };

  onChangeEvent = (value?: ENotificationTemplateEvent) => {
    this.setFilter({ event: value });
  };

  onChangeType = (value?: ENotificationTemplateTypes) => {
    this.setFilter({ type: value });
  };

  onChangeReceiverType = (value?: EEntityType) => {
    this.setFilter({ receiverType: value });
  };
}

export const NotificationTemplateTablePage = NotificationTemplateTableComponent;
