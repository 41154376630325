import React from 'react';
import { Breadcrumb, Col, Skeleton, Spin } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { communicationAcademy, IAcademyConnectedProps } from 'entities/Academy/Academy.communication';
import { communicationFranchise, IFranchiseConnectedProps } from 'entities/Franchise/Franchise.communication';
import { AcademyForm } from 'entities/Academy/components/Form/AcademyForm';

interface IParams {
  id?: string;
}

type AllProps = IAcademyConnectedProps & IFranchiseConnectedProps & RouteComponentProps<IParams>;

class AcademyPageComponent extends React.PureComponent<AllProps> {
  componentDidMount() {
    const { getAcademyModel, match } = this.props;
    const { params } = match;
    const { id } = params;

    if (id) {
      getAcademyModel(id);
    }
  }

  componentWillUnmount() {
    const { clearAcademyModel, clearFranchiseModel } = this.props;

    clearAcademyModel();
    clearFranchiseModel();
  }

  render() {
    const { academyModel, match, updateStatusAcademyModel, updateAcademyModel } = this.props;
    const { params } = match;
    const { id } = params;
    const { data: academyModelData, loading } = academyModel;
    const academyName = academyModelData?.name || 'Unknown name';

    return (
      <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10 pb-10">
        <Col span={24}>
          <Breadcrumb className="fs-sm font-weight-5 font-family__default mb-8">
            <Breadcrumb.Item href={ERoutesPrivate.Academy}>Academy list</Breadcrumb.Item>
            <Breadcrumb.Item>
              {!loading ? academyName : <Skeleton.Input active style={{ width: '200px' }} size="small" />}
            </Breadcrumb.Item>
          </Breadcrumb>
          <Spin size="large" spinning={loading}>
            <AcademyForm
              academyModel={academyModel}
              updateAcademyStatus={updateStatusAcademyModel}
              updateAcademy={updateAcademyModel}
              academyId={id}
            />
          </Spin>
        </Col>
      </CommonLayout>
    );
  }
}

export const AcademyPage = communicationFranchise.injector(communicationAcademy.injector(withRouter(AcademyPageComponent)));
