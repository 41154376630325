import { AbstractSearchableSelector } from 'common/components/Selector/AbstractSearchableSelector';
import { IAdminCollectionFilter } from 'entities/Admin/Admin.models';
import { EUserRoles, IUserModel } from 'entities/User/User.models';
import { adminTransport } from 'entities/Admin/Admin.transport';

interface IComponentProps {}

export class MentorSelector extends AbstractSearchableSelector<IUserModel, IComponentProps> {
  getItemLabel = (item: IUserModel): string => {
    return `${item?.firstName || 'Unknown'} ${item?.lastName || 'name'}`;
  };

  loadData = (value?: string): Promise<{ data: IUserModel[] }> => {
    const filter: IAdminCollectionFilter = {};
    if (value) {
      filter.search = value;
    }
    filter.roles = [EUserRoles.Mentor];
    return adminTransport.getAdminSearch(filter);
  };
}
