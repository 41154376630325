import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { EPlayerAgeGroups, EPlayerAgeGroupToText } from 'entities/Player/Player.models';

interface IComponentProps {
  onChange?: (value?: EPlayerAgeGroups | EPlayerAgeGroups[]) => void;
  disabled?: boolean;
  isSingle?: boolean;
}

type AllProps = SelectProps<any> & IComponentProps;

export class PlayerAgeGroupSelector extends React.PureComponent<AllProps> {
  render() {
    const { value: propValue, onChange, disabled = false, isSingle = true } = this.props;

    return (
      <Select
        allowClear
        mode={isSingle ? undefined : 'multiple'}
        className="width-full"
        placeholder="Select age groups"
        value={propValue}
        defaultActiveFirstOption={false}
        showArrow
        onChange={(value: EPlayerAgeGroups[]) => onChange && onChange(value)}
        disabled={disabled}
      >
        <Select.Option key={EPlayerAgeGroups.U7} value={EPlayerAgeGroups.U7} label={EPlayerAgeGroupToText.U7}>
          {EPlayerAgeGroupToText.U7}
        </Select.Option>
        <Select.Option key={EPlayerAgeGroups.U8} value={EPlayerAgeGroups.U8} label={EPlayerAgeGroupToText.U8}>
          {EPlayerAgeGroupToText.U8}
        </Select.Option>
        <Select.Option key={EPlayerAgeGroups.U9} value={EPlayerAgeGroups.U9} label={EPlayerAgeGroupToText.U9}>
          {EPlayerAgeGroupToText.U9}
        </Select.Option>
        <Select.Option key={EPlayerAgeGroups.U10} value={EPlayerAgeGroups.U10} label={EPlayerAgeGroupToText.U10}>
          {EPlayerAgeGroupToText.U10}
        </Select.Option>
        <Select.Option key={EPlayerAgeGroups.U11} value={EPlayerAgeGroups.U11} label={EPlayerAgeGroupToText.U11}>
          {EPlayerAgeGroupToText.U11}
        </Select.Option>
        <Select.Option key={EPlayerAgeGroups.U12} value={EPlayerAgeGroups.U12} label={EPlayerAgeGroupToText.U12}>
          {EPlayerAgeGroupToText.U12}
        </Select.Option>
        <Select.Option key={EPlayerAgeGroups.U13} value={EPlayerAgeGroups.U13} label={EPlayerAgeGroupToText.U13}>
          {EPlayerAgeGroupToText.U13}
        </Select.Option>
        <Select.Option key={EPlayerAgeGroups.U14} value={EPlayerAgeGroups.U14} label={EPlayerAgeGroupToText.U14}>
          {EPlayerAgeGroupToText.U14}
        </Select.Option>
        <Select.Option key={EPlayerAgeGroups.U15} value={EPlayerAgeGroups.U15} label={EPlayerAgeGroupToText.U15}>
          {EPlayerAgeGroupToText.U15}
        </Select.Option>
        <Select.Option key={EPlayerAgeGroups.U16} value={EPlayerAgeGroups.U16} label={EPlayerAgeGroupToText.U16}>
          {EPlayerAgeGroupToText.U16}
        </Select.Option>
        <Select.Option key={EPlayerAgeGroups.U17} value={EPlayerAgeGroups.U17} label={EPlayerAgeGroupToText.U17}>
          {EPlayerAgeGroupToText.U17}
        </Select.Option>
        <Select.Option key={EPlayerAgeGroups.U17p} value={EPlayerAgeGroups.U17p} label={EPlayerAgeGroupToText['U17+']}>
          {EPlayerAgeGroupToText['U17+']}
        </Select.Option>
      </Select>
    );
  }
}
