import React, { useEffect } from 'react';
import { Col, Row, Spin, Statistic } from 'antd';
import Button from 'antd/es/button';
import { normalize } from 'common/helpers/normalize.helper';
import { communicationPlayerTest, IPlayerTestConnectedProps } from 'entities/PlayerTest/PlayerTest.communication';
import { playerTestTransport } from 'entities/PlayerTest/PlayerTest.transport';

type AllProps = IPlayerTestConnectedProps;

const PlayerTestQueueTaskStatisticComponent = ({ playerTestQueueCount, getPlayerTestQueueCount }: AllProps) => {
  const { data, loading } = playerTestQueueCount;
  const { count = 0 } = data ?? {};

  useEffect(() => {
    getPlayerTestQueueCount();
  }, [getPlayerTestQueueCount]);

  return (
    <Row gutter={[32, 16]} align="bottom" className="mb-4">
      <Col>
        <Spin spinning={loading}>
          <Statistic title="Queue task count" value={normalize(count)} />
        </Spin>
      </Col>
      <Button
        disabled={loading}
        onClick={async () => {
          await playerTestTransport.deleteQueuePlayerTest();
          getPlayerTestQueueCount();
        }}
        type="primary"
        size="large"
      >
        Clear queue
      </Button>
    </Row>
  );
};

export const PlayerTestQueueTaskStatistic = communicationPlayerTest.injector(PlayerTestQueueTaskStatisticComponent);
