import React from 'react';
import { Col, Row, Button } from 'antd';
import { Link } from 'react-router-dom';
import { RouteComponentProps, withRouter } from 'react-router';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { ProductTable } from 'entities/Product/components/ProductTable';
import {
  EProductMarket,
  EProductStatus,
  EProductTargetGroup,
  EProductType,
  IProductCollectionFilter,
  tableProductConfig
} from 'entities/Product/Product.models';
import { ProductMarketSelector } from 'entities/Product/components/Selectors/ProductMarketSelector';
import { ProductStatusSelector } from 'entities/Product/components/Selectors/ProductStatusSelector';
import { ProductTypeSelector } from 'entities/Product/components/Selectors/ProductTypeSelector';
import { ProductTargetGroupSelector } from 'entities/Product/components/Selectors/ProductTargetGroupSelector';

type AllProps = RouteComponentProps;

interface IComponentState {
  filter: IProductCollectionFilter;
}

class ProductPageComponent extends React.PureComponent<AllProps, IComponentState> {
  public debounceSearch: any;
  state: IComponentState = {
    filter: {
      pageIndex: 1,
      markets: undefined,
      statuses: undefined,
      targetGroups: undefined,
      types: undefined
    }
  };
  constructor(props: AllProps) {
    super(props);
    this.state.filter = { ...this.state.filter, ...queryToObject<IProductCollectionFilter>(this.state.filter) };
  }

  render() {
    const { filter } = this.state;
    const { statuses, markets, targetGroup, types } = filter;

    return (
      <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10">
        <Row gutter={[16, 0]} className="mb-5 width-full" justify="space-between" align="middle">
          <>
            <Col span={5}>
              <ProductTypeSelector onChange={this.onChangeType} value={types} placeholder="Search by type" />
            </Col>
            <Col span={5}>
              <ProductStatusSelector onChange={this.onChangeStatus} value={statuses} placeholder="Search by status" />
            </Col>
            <Col span={4}>
              <ProductMarketSelector onChange={this.onChangeMarket} value={markets} placeholder="Search by market" />
            </Col>
            <Col span={4}>
              <ProductTargetGroupSelector onChange={this.onChangeTarget} value={targetGroup} placeholder="Search by target" />
            </Col>
          </>
          <Col span={4}>
            <Link to={ERoutesPrivate.ProductCreate}>
              <Button block type="primary" title="Add product">
                Add product
              </Button>
            </Link>
          </Col>
        </Row>
        <ProductTable config={tableProductConfig} filter={filter} />
      </CommonLayout>
    );
  }

  setFilter = (partialFilter: Partial<IProductCollectionFilter>) => {
    const oldFilter = this.state.filter;
    const filter = { ...oldFilter, ...partialFilter };

    this.props.history.replace({ search: objectToQuery(filter) });
    this.setState({ filter });
  };

  onChangeType = (type?: EProductType) => {
    this.setFilter({ types: type ? [type] : undefined });
  };
  onChangeStatus = (status?: EProductStatus) => {
    this.setFilter({ statuses: status ? [status] : undefined });
  };
  onChangeMarket = (market?: EProductMarket) => {
    this.setFilter({ markets: market ? [market] : undefined });
  };
  onChangeTarget = (targetGroup?: EProductTargetGroup[]) => {
    this.setFilter({ targetGroups: targetGroup ? targetGroup : undefined });
  };
}

export const ProductTablePage = withRouter(ProductPageComponent);
