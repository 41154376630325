import { IBasePathParams } from 'common/models/requestModels';
import { normalizeDate, normalizeMoney } from 'common/helpers/normalize.helper';
import { getPlayerName } from 'common/helpers/name.helper';
import { IPlayerModel } from 'entities/Player/Player.models';
import { IUserModel } from 'entities/User/User.models';
import { IGiftcardModel } from 'entities/Giftcard/Giftcard.models';

export interface ISubscriptionModel extends Components.Schemas.SubscriptionDto {}
export interface ISubscriptionCollection extends Components.Schemas.SubscriptionCollectionDto {}
export interface ISubscriptionCollectionFilter extends Paths.SubscriptionControllerList.QueryParameters {}

export interface ISubscriptionCreate extends Components.Schemas.CreateSubscriptionDto {
  giftcard?: string;
  isTest?: boolean;
  tariff: string;
}
export interface ISubscriptionUpdateStatus extends Components.Schemas.UpdateSubscriptionDto, IBasePathParams {}

export interface IPaymentRequestParams extends Omit<Components.Schemas.CreateSubscriptionActivityPaymentDto, 'returnUrl'> {
  subscriptionId: string;
  returnUrl?: string;
}
export interface ISubscriptionActivityModel extends Components.Schemas.SubscriptionActivityDto {}
export interface ISubscriptionActivityApplyGiftcard extends Components.Schemas.CreateSubscriptionActivityGiftcardDto {
  subscriptionId: string;
}

export interface ISubscriptionActivityCollection extends Components.Schemas.SubscriptionActivityCollectionDto {}
export interface ISubscriptionActivityCollectionFilter extends Paths.SubscriptionActivityControllerList.QueryParameters {
  pageIndex: number;
  playerFilter?: Partial<IPlayerModel>;
  mentorFilter?: Partial<IUserModel>;
  giftcardFilter?: Partial<IGiftcardModel>;
}

export type TSubscriptionParamsModel = ISubscriptionCreate | ISubscriptionUpdateStatus | IBasePathParams;
export type TSubscriptionActivityParamsModel = IPaymentRequestParams | ISubscriptionActivityApplyGiftcard | IBasePathParams;

export interface ISubscriptionActivityMeta {
  count: number;
  playerCount: number;
  totalSum: number;
}

export enum ESubscriptionStatus {
  New = 'expired',
  Active = 'active',
  Expired = 'expired',
  Canceled = 'canceled'
}

export enum ESubscriptionStatusText {
  New = 'New',
  Active = 'Active',
  Expired = 'Expired',
  Canceled = 'Canceled'
}

export enum ESubscriptionFailMessages {
  ErrorProcessingPayment = 'Error processing payment',
  ErrorApplyGiftcard = 'Error while activating giftcard'
}

export enum ESubscriptionActivityStatus {
  New = 'new',
  Confirmed = 'confirmed',
  Canceled = 'canceled'
}

export enum ESubscriptionActivityStatusText {
  New = 'New',
  Confirmed = 'Confirmed',
  Canceled = 'Canceled'
}

export enum EProcessings {
  Yookassa = 'yookassa',
  Internal = 'internal'
}

export enum EProcessingsText {
  Yookassa = 'Yookassa',
  Internal = 'Internal'
}

export type TProcessings = 'yookassa' | 'internal';

export enum EPaymentActivitiesStatus {
  New = 'new',
  Confirmed = 'confirmed',
  Canceled = 'canceled'
}

export enum EPaymentActivitiesStatusText {
  New = 'New',
  Confirmed = 'Confirmed',
  Canceled = 'Canceled'
}

export enum EPaymentActivitiesTypes {
  Giftcard = 'giftcard',
  Payment = 'payment'
}

export enum EPaymentActivitiesTypesText {
  Giftcard = 'Giftcard',
  Payment = 'Payment'
}

export enum EPaymentAndSubscriptionsTabKeys {
  Subscriptions = 'subscriptions',
  PaymentHistory = 'paymentHistory'
}

export enum ETariffChooser {
  Month = 'month',
  Year = 'year'
}

export const tableSubscriptionConfig = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
    ellipsis: true
  },
  {
    title: 'Mentor',
    dataIndex: ['subscription', 'mentorRequest', 'mentor'],
    key: 'subscription.mentorRequest.mentor',
    ellipsis: true,
    render: getPlayerName
  },
  {
    title: 'Mentor registration date',
    dataIndex: ['subscription', 'mentorRequest', 'mentor', 'createdAt'],
    key: 'mentorRegistrationDate',
    ellipsis: true,
    render: normalizeDate
  },
  {
    title: 'Player',
    dataIndex: ['subscription', 'mentorRequest', 'player'],
    key: 'subscription.mentorRequest.player',
    ellipsis: true,
    render: getPlayerName
  },
  {
    title: 'Player registration date',
    dataIndex: ['subscription', 'mentorRequest', 'player', 'createdAt'],
    key: 'playerRegistrationDate',
    ellipsis: true,
    render: normalizeDate
  },
  {
    title: 'Tariff',
    dataIndex: ['subscription', 'tariff', 'name'],
    key: 'tariff.name',
    ellipsis: true
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    ellipsis: true
  },
  {
    title: 'Giftcard',
    dataIndex: ['giftcard', 'number'],
    key: 'giftcard.number',
    ellipsis: true
  },
  {
    title: 'Processing',
    dataIndex: 'processing',
    key: 'processing',
    ellipsis: true
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    ellipsis: true
  },
  {
    title: 'Amount value',
    dataIndex: 'amountValue',
    key: 'amountValue',
    ellipsis: true,
    render: (value: number, row: ISubscriptionActivityModel) => normalizeMoney(value, row.amountCurrency)
  },
  {
    title: 'Amount currency',
    dataIndex: 'amountCurrency',
    key: 'amountCurrency',
    ellipsis: true
  },
  {
    title: 'Payed Date',
    dataIndex: 'payedAt',
    key: 'payedAt',
    ellipsis: true,
    render: (date: string) => normalizeDate(date)
  }
];
