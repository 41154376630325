import React from 'react';
import { Col, Row, Statistic } from 'antd';
import { normalize } from 'common/helpers/normalize.helper';
import { communicationPlayer, IPlayerConnectedProps } from 'entities/Player/Player.communication';

type AllProps = IPlayerConnectedProps;

const PlayerPanelStatisticComponent = ({ playerCollection }: AllProps) => {
  const { data } = playerCollection;
  const { meta } = data ?? {};
  const { count } = meta ?? {};

  return (
    <Row gutter={[32, 16]}>
      <Col>
        <Statistic title="Player count" value={normalize(count)} />
      </Col>
    </Row>
  );
};

export const PlayerPanelStatistic = communicationPlayer.injector(PlayerPanelStatisticComponent);
