import axios, { AxiosStatic } from 'axios';
import { BaseHttpTransport } from '@axmit/transport';
import {
  IStoryCollection,
  IStoryCollectionFilter,
  IStoryCreateData,
  IStoryModel,
  IStoryUpdateData
} from 'entities/Story/Story.models';

const basePath = '/stories';

class StoryTransport extends BaseHttpTransport<
  AxiosStatic,
  IStoryModel,
  IStoryCollection,
  IStoryCreateData,
  IStoryUpdateData,
  IStoryCollectionFilter
> {}

export const storyTransport = new StoryTransport(basePath, axios);
