import React, { useEffect } from 'react';
import { Col, Row, Form, Button, Input, Switch, Popconfirm } from 'antd';
import { Link } from 'react-router-dom';
import { useFormMapper } from '@axmit/antd4-helpers';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { communicationAdmin, IAdminConnectedProps } from 'entities/Admin/Admin.communication';
import { EAdminRole, IAdminUpdateData } from 'entities/Admin/Admin.models';

type AllProps = IAdminConnectedProps & IAuthConnectedProps;

const SettingPageComponent: React.FC<AllProps> = props => {
  const { adminModel, getAdminModel, updateAdminModel, clearAdminModel, authModel } = props;
  const { loading, params, errors, data } = adminModel;
  const { data: currentUser } = authModel;
  const { access } = currentUser ?? {};
  const { userId } = access ?? {};

  const { role } = data ?? {};
  const [form] = Form.useForm();

  useEffect(() => {
    if (userId) {
      getAdminModel(userId);
    }
    return () => {
      clearAdminModel();
    };
  }, []); // eslint-disable-line

  const { fields } = useFormMapper(
    ['email', 'firstName', 'lastName', 'isReceiveAcademyMails', 'isReceivePlayerMails', 'isReceiveTestModerationMails'],
    data,
    params,
    errors
  );

  const editPlayer = (values: any) => {
    const { ...body } = values;

    if (userId) {
      const params: IAdminUpdateData = {
        id: userId,
        ...body
      };

      updateAdminModel(params);
    }
  };

  return (
    <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10 pb-10">
      <Form form={form} className="mb-5" onFinish={editPlayer} fields={fields}>
        <Row justify="space-between" className="mb-5" gutter={[16, 16]}>
          <Col className="mb-5" span={11}>
            <Row gutter={[16, 8]}>
              <Col span={24}>
                <span className="basic__text_title d-block" title="Player">
                  Player
                </span>
              </Col>
              <Col span={8}>
                <Form.Item name="email" label="Email">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="firstName" label="First name">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="lastName" label="Last name">
                  <Input disabled />
                </Form.Item>
              </Col>
              {role === EAdminRole.Admin && (
                <>
                  <Col span={8}>
                    <Form.Item valuePropName="checked" name="isReceiveAcademyMails" label="Receive academy mails">
                      <Switch />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item valuePropName="checked" name="isReceivePlayerMails" label="Receive player mails">
                      <Switch />
                    </Form.Item>
                  </Col>
                </>
              )}
              <Col span={8}>
                <Form.Item valuePropName="checked" name="isReceiveTestModerationMails" label="Receive test moderation mails">
                  <Switch />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item className="mb-0">
                  <Popconfirm title="Are you sure?" onConfirm={() => form.submit()} okText="Yes" cancelText="No">
                    <Button className="mr-8" htmlType="submit" type="primary" disabled={loading} loading={loading} title="Save">
                      Save
                    </Button>
                  </Popconfirm>
                  <Link to={ERoutesPrivate.TestResults}>
                    <Button disabled={loading} loading={loading} title="Cancel">
                      Cancel
                    </Button>
                  </Link>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </CommonLayout>
  );
};

export const SettingPage = communicationAuth.injector(communicationAdmin.injector(SettingPageComponent));
