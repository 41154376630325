import React, { useEffect } from 'react';
import { Breadcrumb, Col, Skeleton } from 'antd';
import { useParams } from 'react-router';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { communicationTariff, ITariffConnectedProps } from 'entities/Tariff/Tariff.communication';
import { TariffComponent } from 'entities/Tariff/components/Form/TariffComponent';

type AllProps = ITariffConnectedProps;

const TariffPageComponent: React.FC<AllProps> = props => {
  const { tariffModel, getTariffModel, clearTariffModel, addTariffPermissionModel, tariffPermissionModel } = props;
  const { data, loading } = tariffModel;
  const { id } = useParams();
  const isEdit = id;

  useEffect(() => {
    if (id) {
      getTariffModel(id);
    }
    return () => {
      clearTariffModel();
    };
  }, []); // eslint-disable-line

  return (
    <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10 pb-10">
      <Col span={24}>
        <Breadcrumb className="fs-sm font-weight-5 font-family__default mb-8">
          <Breadcrumb.Item href={ERoutesPrivate.Tariffs}>Tariffs</Breadcrumb.Item>
          <Breadcrumb.Item>
            {isEdit ? (
              !loading ? (
                `"${data?.localizationTag}"`
              ) : (
                <Skeleton.Input active style={{ width: '200px' }} size="small" />
              )
            ) : (
              'Add tariff'
            )}
          </Breadcrumb.Item>
        </Breadcrumb>
        {!loading || !isEdit ? (
          <TariffComponent
            tariffModel={tariffModel}
            tariffId={id}
            addTariffPermissionModel={addTariffPermissionModel}
            tariffPermissionModel={tariffPermissionModel}
          />
        ) : null}
      </Col>
    </CommonLayout>
  );
};

export const TariffPage = communicationTariff.injector(TariffPageComponent);
