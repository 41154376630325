import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { EComplexity, EComplexityText } from 'entities/Test/Test.models';

type AllProps = SelectProps<any>;

const items = [
  { value: EComplexity.Low, title: EComplexityText.Low },
  { value: EComplexity.Medium, title: EComplexityText.Medium },
  { value: EComplexity.Pro, title: EComplexityText.Pro }
];

export class ComplexitySelector extends React.PureComponent<AllProps> {
  render() {
    const { ...props } = this.props;

    return (
      <Select className="width-full" defaultActiveFirstOption={false} showArrow {...props}>
        {items.map(item => (
          <Select.Option key={item.value} value={item.value} label={item.title}>
            {item.title}
          </Select.Option>
        ))}
      </Select>
    );
  }
}
