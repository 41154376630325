import React, { ChangeEvent } from 'react';
import { Col, Input, Row } from 'antd';
import debounce from 'lodash.debounce';
import { RouteComponentProps, withRouter } from 'react-router';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { AdminTable } from 'entities/Admin/components/AdminTable';
import { EAdminStatus, IAdminCollectionFilter, tableAdminConfig } from 'entities/Admin/Admin.models';
import { AdminStatusSelector } from 'entities/Admin/components/Selector/AdminStatusSelector';
import { EUserRoles } from 'entities/User/User.models';

interface IComponentState {
  filter: IAdminCollectionFilter;
}

interface IComponentProps {
  academyId?: string;
}

type AllProps = RouteComponentProps & IComponentProps;

class AcademyTrainersTableComponent extends React.PureComponent<AllProps, IComponentState> {
  public debounceSearch: any;
  state: IComponentState = {
    filter: {
      pageIndex: 1,
      search: undefined
    }
  };
  constructor(props: AllProps) {
    super(props);
    this.state.filter = { ...this.state.filter, ...queryToObject<IAdminCollectionFilter>(this.state.filter) };
    this.debounceSearch = debounce(this.autocompleteSearch, 300);
  }

  render() {
    const { academyId } = this.props;
    const { filter } = this.state;
    const { status, search } = filter;

    return (
      <>
        <Row className="mb-10 width-full" justify="space-between" align="middle">
          <Col span={24}>
            <Row justify="space-between" align="middle" gutter={16}>
              <Col span={12}>
                <Input.Search
                  placeholder="Search by name or email"
                  defaultValue={search}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => this.debounceSearch(e.target.value)}
                />
              </Col>
              <Col span={12}>
                <AdminStatusSelector onChange={this.onChangeStatus} value={status} placeholder="Search by status" />
              </Col>
            </Row>
          </Col>
        </Row>
        <AdminTable
          config={tableAdminConfig}
          filter={{
            ...filter,
            academy: academyId,
            roles: [EUserRoles.Trainer]
          }}
        />
      </>
    );
  }

  setFilter = (partialFilter: Partial<IAdminCollectionFilter>) => {
    const oldFilter = this.state.filter;
    const filter = { ...oldFilter, ...partialFilter };

    this.props.history.replace({ search: objectToQuery(filter) });
    this.setState({ filter });
  };

  onChangeStatus = (status?: EAdminStatus) => {
    this.setFilter({ status });
  };

  onChangeRole = (roles?: EUserRoles) => {
    this.setFilter({ roles: roles ? [roles] : undefined });
  };

  autocompleteSearch = (text: string) => {
    const search = text === '' ? undefined : text;
    this.setFilter({ search });
  };
}

export const AcademyTrainersTable = withRouter(AcademyTrainersTableComponent);
