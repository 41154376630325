import { AbstractSearchableSelector } from 'common/components/Selector/AbstractSearchableSelector';
import { ETariffVisibility, ITariffCollectionFilter, ITariffModel, ETariffStatus } from 'entities/Tariff/Tariff.models';
import { tariffTransport } from 'entities/Tariff/Tariff.transport';

interface IComponentProps {
  visibility?: ETariffVisibility;
  statuses?: ETariffStatus[];
}

export class TariffSelector extends AbstractSearchableSelector<ITariffModel, IComponentProps> {
  getItemLabel = (item: ITariffModel): string => {
    return `${item?.localizationTag || 'Unknown'}`;
  };

  loadData = (value?: string): Promise<{ data: ITariffModel[] }> => {
    const { visibility, statuses } = this.props;
    const filter: ITariffCollectionFilter = {};

    if (visibility) {
      filter.visibility = visibility;
    }

    if (statuses) {
      filter.statuses = statuses;
    }

    if (value) {
      filter.localizationTag = value;
    }

    return tariffTransport.getCollection(filter);
  };
}
