import axios, { AxiosStatic } from 'axios';
import { BaseHttpTransport } from '@axmit/transport';
import { objectToQuery } from 'common/helpers/filters.helper';
import {
  ITariffModel,
  ITariffCollectionFilter,
  ITariffCollection,
  ITariffUpdateStatus,
  ITariffUpdatePermissions,
  ITariffPermissionCollection,
  ITariffPermissionCollectionFilter
} from 'entities/Tariff/Tariff.models';

const basePath = '/tariffs';

class TariffTransport extends BaseHttpTransport<
  AxiosStatic,
  ITariffModel,
  ITariffCollection,
  null,
  void,
  ITariffCollectionFilter
> {
  public updateStatus = ({ id, ...params }: ITariffUpdateStatus) => axios.put(`${basePath}/${id}/set-status`, params);

  public addPermissions = ({ id, ...params }: ITariffUpdatePermissions) =>
    axios.patch(`${basePath}/${id}/permissions?action=add`, params);

  public removePermissions = ({ id, ...params }: ITariffUpdatePermissions) =>
    axios.patch(`${basePath}/${id}/permissions?action=remove`, params);

  public getTariffPermissionCollection = ({
    id,
    ...filters
  }: ITariffPermissionCollectionFilter): Promise<ITariffPermissionCollection> =>
    axios.get(`${basePath}/${id}/permissions${objectToQuery(filters)}`);
}

export const tariffTransport = new TariffTransport(basePath, axios);
