import React from 'react';
import { Upload, message } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { EFileStatus, IImageModel } from 'common/components/Image/Image.models';
import { EErrorStatus } from 'common/models/requestModels';
import { ELocalesFilesAppTypes } from 'entities/Language/Language.models';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';

interface IProps {
  app: ELocalesFilesAppTypes;
  locale: string;
}

type AllProps = IProps & IAuthConnectedProps;

const UploadTranslationFileComponent: React.FC<AllProps> = props => {
  const { app, locale, children, authModel } = props;
  const token = authModel.data?.access.token;

  const handleChange = (info: UploadChangeParam<UploadFile<IImageModel>>) => {
    const { file } = info;
    const { status } = file;

    if (status === EFileStatus.Done) {
      message.success('File successfully uploaded');
      return;
    }
    if (status === EFileStatus.Error && file.error.status === EErrorStatus.Unauthorized) {
      message.warning('Something went wrong, try another file');
    }
  };

  return (
    <Upload
      action={`/api/languages/${locale}/file/${app}`}
      headers={{ authorization: `Bearer ${token}` }}
      onChange={handleChange}
      name="file"
      accept=".json"
      showUploadList={false}
    >
      {children}
    </Upload>
  );
};

export const UploadTranslationFile = communicationAuth.injector(UploadTranslationFileComponent);
