import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import {
  ENotificationTemplateEvent,
  NotificationTemplateEventMap
} from 'entities/NotificationTemplate/NotificationTemplate.models';

interface IComponentProps {
  onChange?: (value?: ENotificationTemplateEvent) => void;
  disabled?: boolean;
}

type AllProps = SelectProps<any> & IComponentProps;

export class NotificationTemplateEventSelector extends React.PureComponent<AllProps> {
  render() {
    const { value, onChange, disabled = false } = this.props;

    return (
      <Select
        allowClear
        className="width-full"
        placeholder="Select event"
        value={value}
        defaultActiveFirstOption={false}
        showArrow
        onChange={(value: ENotificationTemplateEvent) => onChange && onChange(value)}
        disabled={disabled}
      >
        {NotificationTemplateEventMap.map(item => (
          <Select.Option key={item.value} value={item.value} label={item.title}>
            {item.title}
          </Select.Option>
        ))}
      </Select>
    );
  }
}
