import { ColumnsType } from 'antd/lib/table';
import { RouteComponentProps, withRouter } from 'react-router';
import moment from 'moment';
import { ISorter, TableCommon } from 'common/components/TableCommon';
import { IBaseFilterModel } from 'common/models/requestModels';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { ERoutesPrivate } from 'common/models/routesModel';
import { simpleDateFormatWithTime } from 'common/models/dateModels';
import {
  EPlayerTestStatus,
  IPlayerTestCollection,
  IPlayerTestCollectionFilter,
  IPlayerTestPureModel
} from 'entities/PlayerTest/PlayerTest.models';
import { communicationPlayerTest, IPlayerTestConnectedProps } from 'entities/PlayerTest/PlayerTest.communication';
import { EUserRolesText } from 'entities/User/User.models';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';

interface IComponentProps {
  config: ColumnsType<any>;
}

type AllProps = IComponentProps & IPlayerTestConnectedProps & IUIConnectedProps & RouteComponentProps;

class PlayerTestTableComponent extends TableCommon<
  // @ts-ignore TODO fix me
  IPlayerTestCollection,
  IPlayerTestPureModel,
  ColumnsType<any>,
  AllProps,
  IPlayerTestCollectionFilter
> {
  loadCollection = (params: IBaseFilterModel, sorter?: ISorter) => {
    const { getPlayerTestCollection, filter } = this.props;
    const filterParams: IPlayerTestCollectionFilter = { ...params };

    if (filter?.testFilter) {
      filterParams.test = filter.testFilter.id;
    }

    if (filter?.ageGroup) {
      filterParams.ageGroup = filter.ageGroup;
    }

    if (filter?.status) {
      filterParams.status = filter?.status;
    }
    if (filter?.playerFilter) {
      filterParams.player = filter.playerFilter.id;
    }
    if (filter?.academyFilter?.id) {
      filterParams.academy = filter?.academyFilter?.id;
    }
    if (filter?.uploadByFilter) {
      filterParams.uploadBy = filter.uploadByFilter.id;
    }
    if (filter?.trainerFilter) {
      filterParams.trainer = filter.trainerFilter.id;
    }
    if (filter?.investigationStatus) {
      filterParams.investigationStatus = filter.investigationStatus;
    }
    if (filter?.uploaderType !== undefined) {
      filterParams.uploaderType = filter?.uploaderType;
    }
    if (filter?.onlyAutoReject !== undefined) {
      filterParams.onlyAutoReject = filter?.onlyAutoReject;
    }
    if (filter?.from) {
      filterParams.from = filter.from;
    }
    if (filter?.to) {
      filterParams.to = filter.to;
    }

    filterParams.onlyBestOfDay = !!filter?.onlyBestOfDay;

    if (filter?.orderField && filter?.orderDirection) {
      filterParams.orderField = filter?.orderField;
      filterParams.orderDirection = filter.orderDirection;
    }
    if (filter?.location) {
      filterParams.country = filter.location?.country;
    }
    if (sorter?.field === 'testDate') {
      if (sorter?.order) {
        const orderField = 'createdAt';
        const orderDirection = sorter.order === 'ascend' ? 'ASC' : 'DESC';
        filterParams.orderField = orderField;
        filterParams.orderDirection = orderDirection;
      } else {
        filterParams.orderField = 'status';
        filterParams.orderDirection = 'ASC';
      }
    } else {
      filterParams.orderField = filter?.orderField || 'status';
      filterParams.orderDirection = filter?.orderDirection || 'ASC';
    }
    getPlayerTestCollection(filterParams);
  };

  getCollection = () => {
    const { playerTestCollection } = this.props;
    if (playerTestCollection?.data?.data) {
      const data: any[] = playerTestCollection.data.data.map(item => {
        const testType = item?.uploadById ? EUserRolesText.Trainer : EUserRolesText.Player;
        const { player, players } = item;

        return {
          ...item,
          testName: item.test.tag,
          playerTest: players?.length
            ? players?.map(
                (item, index) =>
                  `${item?.firstName || 'Unknown'} ${item?.lastName || 'name'}` + `${index + 1 !== players.length ? ', ' : ''}`
              )
            : `${player?.firstName || 'Unknown'} ${player?.lastName || 'name'}`,
          videoId: item.video?.id,
          testDate: moment(item.createdAt).format(simpleDateFormatWithTime),
          testType
        };
      });
      return {
        ...playerTestCollection,
        data: {
          ...playerTestCollection.data,
          data
        }
      };
    } else {
      return playerTestCollection;
    }
  };

  addQuery = (pageIndex: number, sorterValue?: ISorter) => {
    const searchPath = queryToObject<IPlayerTestCollectionFilter>({
      pageIndex: 1,
      status: EPlayerTestStatus.New,
      test: undefined,
      player: undefined,
      playerFilter: undefined,
      uploaderType: undefined,
      testFilter: undefined,
      from: undefined,
      to: undefined,
      ageGroup: undefined,
      orderField: undefined,
      orderDirection: undefined,
      uploadByFilter: undefined,
      trainerFilter: undefined,
      academyFilter: undefined,
      investigationStatus: undefined,
      location: undefined,
      onlyAutoReject: undefined,
      onlyBestOfDay: undefined
    });
    let search;

    if (sorterValue?.field === 'testDate') {
      if (sorterValue?.order) {
        const orderField = 'createdAt';
        const orderDirection = sorterValue.order === 'ascend' ? 'ASC' : 'DESC';
        search = objectToQuery({ ...searchPath, pageIndex, orderField, orderDirection });
      } else {
        search = objectToQuery({ ...searchPath, pageIndex, orderField: undefined, orderDirection: undefined });
      }
    } else {
      search = objectToQuery({ orderField: undefined, orderDirection: undefined, ...searchPath, pageIndex });
    }

    return this.props.history.replace({ search });
  };

  onRowClick = (item: IPlayerTestPureModel) => {
    const { history } = this.props;
    history.push(`${ERoutesPrivate.TestResults}/${item.id}`);
  };

  clearCollection = async () => {
    const { clearPlayerTestCollection, saveUIPlayerTestQuery } = this.props;

    await saveUIPlayerTestQuery();
    return clearPlayerTestCollection();
  };
}

export const PlayerTestTable = communicationUI.injector(communicationPlayerTest.injector(withRouter(PlayerTestTableComponent)));
