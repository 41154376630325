import React from 'react';
import { Popconfirm } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { RouteComponentProps, withRouter } from 'react-router';
import { TableCommon } from 'common/components/TableCommon';
import { IBaseFilterModel } from 'common/models/requestModels';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import {
  ITariffPermissionCollection,
  ITariffPermissionCollectionFilter,
  ITariffPermissionModel,
  EAddPermissionType,
  EAddPermissionTypeSingle
} from 'entities/Tariff/Tariff.models';
import { communicationTariff, ITariffConnectedProps } from 'entities/Tariff/Tariff.communication';

interface IComponentProps {
  tariffId: string;
  config: ColumnsType<any>;
}

type AllProps = IComponentProps & ITariffConnectedProps & RouteComponentProps;

class TariffPermissionsTableComponent extends TableCommon<
  ITariffPermissionCollection,
  ITariffPermissionModel,
  ColumnsType<any>,
  AllProps,
  Partial<ITariffPermissionCollectionFilter>
> {
  loadCollection = (params: IBaseFilterModel) => {
    const { getTariffPermissionCollection, filter, tariffId } = this.props;
    const filterParams: ITariffPermissionCollectionFilter = {
      id: tariffId,
      orderField: 'createdAt',
      orderDirection: 'DESC',
      ...params
    };

    if (filter?.hasPlayer) {
      filterParams.hasPlayer = filter?.hasPlayer;
    }

    if (filter?.hasMentor) {
      filterParams.hasMentor = filter?.hasMentor;
    }

    if (filter?.playerFilter) {
      filterParams.forPlayer = filter?.playerFilter?.id;
    }

    if (filter?.mentorFilter) {
      filterParams.forMentor = filter?.mentorFilter?.id;
    }

    getTariffPermissionCollection(filterParams);
  };

  getCollection = () => {
    const { tariffPermissionCollection, removeTariffPermissionModel, tariffId } = this.props;
    if (tariffPermissionCollection?.data?.data) {
      const data: ITariffPermissionModel[] = tariffPermissionCollection.data.data.map((item: ITariffPermissionModel) => {
        const entityPermission = item.player || item.mentor;

        const onRemovePermission = () => {
          const type: EAddPermissionType = 'player' in item ? EAddPermissionType.Players : EAddPermissionType.Mentors;
          removeTariffPermissionModel({
            id: tariffId,
            [type]: [{ [EAddPermissionTypeSingle[type]]: entityPermission.id }]
          });
        };

        return {
          ...item,
          name: `${entityPermission.firstName || 'Unknown'} ${entityPermission.lastName || 'name'}`,
          phone: entityPermission.phone,
          email: item.mentor ? item.mentor.email : '',
          entityName: 'player' in item ? 'Player' : 'Mentor',
          remove: (
            <Popconfirm title="Are you sure to remove premission?" onConfirm={onRemovePermission} okText="Yes" cancelText="No">
              <span className="color-danger">Remove</span>
            </Popconfirm>
          )
        };
      });
      return {
        ...tariffPermissionCollection,
        data: {
          ...tariffPermissionCollection.data,
          data
        }
      };
    } else {
      return tariffPermissionCollection;
    }
  };

  addQuery = (pageIndex: number) => {
    const searchPath = queryToObject<Partial<ITariffPermissionCollectionFilter>>({
      pageIndex: 1,
      hasPlayer: undefined,
      hasMentor: undefined,
      playerFilter: undefined,
      mentorFilter: undefined
    });
    const search = objectToQuery({ ...searchPath, pageIndex });
    return this.props.history.replace({ search });
  };

  clearCollection = () => {
    return this.props.clearTariffPermissionCollection();
  };
}

export const TariffPermissionsTable = communicationTariff.injector(withRouter(TariffPermissionsTableComponent));
