export const colLayoutSize = {
  large: 21,
  medium: 13,
  small: 5
};
export enum ELayoutSize {
  Large = 'large',
  Medium = 'medium',
  Small = 'small'
}
export const requireText = 'Field is required';
export const HOST =
  window.location?.hostname === 'backofficedev.junistat.com' || window.location?.hostname === 'localhost'
    ? 'https://dev.junistat.com'
    : 'https://app.junistat.com';

export const formRules = {
  required: {
    required: true,
    message: requireText
  }
};
