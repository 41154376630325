import {
  EActionsTypes,
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  getStartType,
  StoreBranch,
  getSuccessType
} from '@axmit/redux-communications';
import { call, put } from 'redux-saga/effects';
import { clearCreds, getCreds, saveCreds } from 'axios-patch-jwt';
import { message } from 'antd';
import { push } from 'connected-react-router';
import { IError } from 'fe-error-helper';
import { ERoutesCommon } from 'common/models/routesModel';
import { EErrorStatus } from 'common/models/requestModels';
import { authTransport, passwordRestoreTransport } from 'entities/Auth/Auth.transport';
import {
  EAuthErrorMessage,
  EAuthSuccessMessage,
  IAuthModel,
  IAuthParams,
  IPasswordForgotParams,
  IPasswordRestoreParams,
  ITokenModel
} from 'entities/Auth/Auth.models';
import { IAdminModel } from 'entities/Admin/Admin.models';
import { adminTransport } from 'entities/Admin/Admin.transport';
import { IUserModel } from 'entities/User/User.models';

const namespace = 'auth';

export interface IAuthStoreProps {
  admin: StoreBranch<IUserModel>;
  model: StoreBranch<ITokenModel>;
}

export interface IAuthConnectedProps {
  authModel: StoreBranch<IAuthModel>;
  authAdmin: StoreBranch<IAdminModel>;
  authPasswordRestore: StoreBranch<any>;

  addAuthModel(params: IAuthParams): void;
  deleteAuthModel(): void;
  initAuthModel(): void;

  forgotAuthPasswordRestore(params: IPasswordForgotParams): void;
  updateAuthPasswordRestore(params: IPasswordRestoreParams): void;
}

const successAuth = function*(response: IAuthModel) {
  const userId = response?.access?.userId;

  if (userId) {
    yield getAuthAdmin(userId);
  }
};

const modelApiProvider = [
  new APIProvider(EActionsTypes.add, authTransport.add, {
    onSuccess: function*(response: IAuthModel) {
      yield call(saveCreds, response);
      yield successAuth(response);
      yield put(push(ERoutesCommon.Root));
    },
    onFail: function(e: IError) {
      if (e.status === EErrorStatus.Forbidden) {
        message.error(EAuthErrorMessage.InvalidCreds);
      }
    }
  }),
  new APIProvider(EActionsTypes.delete, authTransport.logout, {
    onSuccess: function*() {
      yield clearAuth();
    }
  }),
  new APIProvider(EActionsTypes.init, (): Promise<IAuthModel> => getCreds(), {
    onSuccess: successAuth
  })
];

const passwordRestoreApiProvider = [
  new APIProvider('forgot', passwordRestoreTransport.add, {
    onSuccess: function() {
      message.success(EAuthSuccessMessage.PasswordForgot);
    }
  }),
  new APIProvider(EActionsTypes.update, passwordRestoreTransport.passwordRestore, {
    onSuccess: function*(response: IAuthModel) {
      message.success(EAuthSuccessMessage.ChangePasswordSuccess);
      yield call(saveCreds, response);
      yield put({ type: getSuccessType(namespace, 'model', EActionsTypes.add), payload: response });
      yield put({ type: getStartType(namespace, 'admin', EActionsTypes.get), payload: response.access.userId });
    },
    onFail: function(e: IError) {
      if (e.status === EErrorStatus.NotFound) {
        message.error(EAuthErrorMessage.InvalidRestoreCode);
      }
    }
  })
];

const authAdminApiProvider = [new APIProvider(EActionsTypes.get, adminTransport.get)];

const branches = [
  new Branch('model', modelApiProvider),
  new Branch('admin', authAdminApiProvider),
  new Branch('passwordRestore', passwordRestoreApiProvider)
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export function* clearAuth() {
  yield clearAuthModel();
  yield clearAuthAdmin();
  yield call(clearCreds);
}

function* clearAuthModel() {
  yield put({ type: getStartType(namespace, 'model', EActionsTypes.clear) });
}

function* clearAuthAdmin() {
  yield put({ type: getStartType(namespace, 'admin', EActionsTypes.clear) });
}

function* getAuthAdmin(id: string) {
  yield put({ type: getStartType(namespace, 'admin', EActionsTypes.get), payload: id });
}

export const communicationAuth = buildCommunication<IAuthConnectedProps>(strategy);
