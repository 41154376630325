import {
  EActionsTypes,
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  StoreBranch,
  getStartType,
  buildCollectionResponseFormatter,
  buildCollectionPreRequestDataMapper
} from '@axmit/redux-communications';
import { put, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import { push } from 'connected-react-router';
import { ERoutesPrivate } from 'common/models/routesModel';
import { playerTransport } from 'entities/Player/Player.transport';
import {
  IPlayerCollection,
  IPlayerCollectionFilter,
  IPlayerModel,
  IPlayerParamsModel,
  IPlayerUpdateParams,
  IPlayerVideoCollection,
  IPlayerVideoCollectionFilter,
  IPlayerTestStatisticModel,
  IPlayerTestStatisticFilter,
  IPlayerChangeAcademyParams,
  IPlayerChangeStatusParams,
  IPlayerChangeMentorParams
} from 'entities/Player/Player.models';

export interface IPlayerStoreProps {
  model: StoreBranch<IPlayerModel>;
}

const namespace = 'player';

export interface IPlayerConnectedProps {
  playerModel: StoreBranch<IPlayerModel, IPlayerParamsModel>;
  playerCollection: StoreBranch<IPlayerCollection, IPlayerCollectionFilter>;
  playerVideosCollection: StoreBranch<IPlayerVideoCollection, IPlayerVideoCollectionFilter>;
  playerTestStatisticModel: StoreBranch<IPlayerTestStatisticModel, IPlayerTestStatisticFilter>;

  changeMentorPlayerModel(params: IPlayerChangeMentorParams): void;
  getPlayerModel(id: string): void;
  updatePlayerModel(params: IPlayerUpdateParams): void;
  changeAcademyPlayerModel(params: IPlayerChangeAcademyParams): void;
  deletePlayerModel(id: string): void;
  updateStatusPlayerModel(params: IPlayerChangeStatusParams): void;

  getPlayerCollection(filter?: IPlayerCollectionFilter): void;
  getSearchPlayerCollection(filter: IPlayerCollectionFilter): void;

  getPlayerVideosCollection(params: IPlayerVideoCollectionFilter): Promise<void>;

  getPlayerTestStatisticModel(params: IPlayerTestStatisticFilter): void;

  clearPlayerModel(): void;
  clearPlayerCollection(): void;
  clearPlayerVideosCollection(): void;
  clearPlayerTestStatisticModel(): void;
}

const PlayerModelAPIProviders = [
  new APIProvider(EActionsTypes.get, playerTransport.get),
  new APIProvider(EActionsTypes.update, playerTransport.update),
  new APIProvider('updateStatus', playerTransport.updateStatus),
  new APIProvider('changeMentor', playerTransport.changeMentor),
  new APIProvider(EActionsTypes.delete, playerTransport.delete, {
    onSuccess: function*() {
      message.success('Success');
      yield put(push(ERoutesPrivate.Players));
    }
  }),
  new APIProvider('changeAcademy', playerTransport.changePlayerAcademy)
];

const PlayerCollectionAPIProviders = [
  new APIProvider(EActionsTypes.get, playerTransport.getCollection),
  new APIProvider(
    'getSearch',
    playerTransport.getPlayerSearch,
    {
      mapSuccess: buildCollectionResponseFormatter<any, any>(),
      preRequestDataMapper: buildCollectionPreRequestDataMapper<IPlayerCollection, IPlayerCollectionFilter>()
    },
    takeLatest
  )
];

const PlayerVideosCollectionAPIProviders = [new APIProvider(EActionsTypes.get, playerTransport.getPlayerVideosCollection)];

const PlayerTestStatisticAPIProviders = [new APIProvider(EActionsTypes.get, playerTransport.getPlayerTestStatisticModel)];

const branches = [
  new Branch('model', PlayerModelAPIProviders),
  new Branch('collection', PlayerCollectionAPIProviders),
  new Branch('videosCollection', PlayerVideosCollectionAPIProviders),
  new Branch('testStatisticModel', PlayerTestStatisticAPIProviders)
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export function* getPlayerModel(id?: string) {
  if (id) {
    yield put({ type: getStartType(namespace, 'model', EActionsTypes.get), payload: id });
  }
}

export const communicationPlayer = buildCommunication<IPlayerConnectedProps>(strategy);
