import { AbstractSearchableSelector } from 'common/components/Selector/AbstractSearchableSelector';
import { IPlayerCollectionFilter, IPlayerModel } from 'entities/Player/Player.models';
import { playerTransport } from 'entities/Player/Player.transport';

interface IComponentProps {}

export class PlayerSelector extends AbstractSearchableSelector<IPlayerModel, IComponentProps> {
  getItemLabel = (item: IPlayerModel): string => {
    return `${item?.firstName || 'Unknown'} ${item?.lastName || 'name'}`;
  };

  loadData = (value?: string): Promise<{ data: IPlayerModel[] }> => {
    const filter: IPlayerCollectionFilter = {};
    if (value) {
      filter.name = value;
    }
    return playerTransport.getPlayerSearch(filter);
  };
}
