import React from 'react';
import { Upload, Modal, message, Row } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { CloudUploadOutlined } from '@ant-design/icons/lib';
import { checkImage } from 'common/helpers/loader.helper';
import { EFileStatus, IImageModel } from 'common/components/Image/Image.models';
import { EErrorStatus } from 'common/models/requestModels';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';

interface IComponentProps {
  disabled?: boolean;
  onChange?: (imageId: string | null) => void;
  value?: string;
}

interface IComponentState {
  previewVisible: boolean;
  imageId: string;
  fileList: UploadFile<IImageModel>[];
}

type AllProps = IComponentProps & IAuthConnectedProps;

class ImageUploadComponent extends React.Component<AllProps, IComponentState> {
  state: IComponentState = {
    previewVisible: false,
    imageId: '',
    fileList: []
  };

  componentDidUpdate(prevProps: AllProps): void {
    const { value } = this.props;
    if (value !== prevProps.value) {
      this.inItValue(value);
    }
  }
  componentDidMount(): void {
    const { value } = this.props;
    this.inItValue(value);
  }
  render() {
    const { authModel, disabled } = this.props;
    const token = authModel.data?.access.token;
    const { previewVisible, imageId, fileList } = this.state;

    const uploadButton = (
      <Row justify="center" align="middle">
        <CloudUploadOutlined />
        <div className="ant-upload-text width-full">Upload</div>
      </Row>
    );
    return (
      <>
        <Upload
          action="/api/images"
          headers={{ authorization: `Bearer ${token}` }}
          listType="picture-card"
          fileList={fileList}
          onPreview={this.handlePreview}
          onChange={this.handleChange}
          name="image"
          beforeUpload={checkImage as any}
          showUploadList={{
            showPreviewIcon: true,
            showDownloadIcon: false,
            showRemoveIcon: true
          }}
          disabled={disabled}
        >
          {fileList.length >= 1 ? null : uploadButton}
        </Upload>
        <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
          <img alt="example" className="width-full p-6" src={`/api/images/${imageId}?token=${token}`} />
        </Modal>
      </>
    );
  }

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = (file: UploadFile<IImageModel>) => {
    const imageId = file.response?.id || this.props.value;

    if (imageId) {
      this.setState({
        imageId: imageId,
        previewVisible: true
      });
    }
  };

  inItValue = (value?: string) => {
    const { authModel } = this.props;
    const token = authModel.data?.access.token;
    const fileList: UploadFile<IImageModel>[] = value
      ? ([
          {
            uid: '1',
            name: 'image',
            status: 'done',
            size: 1,
            type: '',
            url: `${window.location.origin}/api/images/${value}?token=${token}`
          }
        ] as UploadFile<IImageModel>[])
      : ([] as UploadFile<IImageModel>[]);
    this.setState({ fileList });
  };

  handleChange = (info: UploadChangeParam<UploadFile<IImageModel>>) => {
    const { file } = info;
    const { status, response } = file;
    const { onChange } = this.props;

    if (file.status === EFileStatus.Removed && onChange) {
      this.setState({ fileList: [] });
      onChange(null);
      return;
    }

    if (status === EFileStatus.Done) {
      const imageId = response?.id;

      this.setState({ fileList: [file] });
      if (onChange) {
        onChange(imageId || null);
      }
      return;
    }
    if (status === EFileStatus.Error && file.error.status === EErrorStatus.Unauthorized) {
      message.warning('Something went wrong, reload page and try again');
    }
    this.setState({ fileList: [file] });
  };
}

export const ImageUpload = communicationAuth.injector(ImageUploadComponent);
