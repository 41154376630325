import React from 'react';
import Autocomplete from 'react-google-autocomplete';
import debounce from 'lodash.debounce';
import { SelectProps } from 'antd/es/select';
import { ELocationType, ILocationModel, locationFormatter, parseLocation } from 'common/helpers/location.helper';

interface IComponentProps {
  placeholder?: string;
  value?: ILocationModel;
  onChange?: (value?: ILocationModel) => void;
  disabled?: boolean;
  types?: ELocationType[];
}

interface IComponentState {
  active: boolean;
  remount: boolean;
}

type AllProps = IComponentProps & SelectProps<ILocationModel>;

export class LocationInput extends React.PureComponent<AllProps, IComponentState> {
  state: IComponentState = {
    active: false,
    remount: false
  };

  onChange = debounce((value?: ILocationModel) => {
    const { onChange } = this.props;
    if (onChange) {
      value ? onChange(value) : onChange(undefined);
    }
  }, 300);

  onKey = (event: KeyboardEvent) => {
    const { active } = this.state;

    if (active && event.keyCode === 13) {
      event.preventDefault();
    }
  };

  componentDidUpdate(prevProps: AllProps) {
    if (!prevProps.value && this.props.value) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ remount: true }, () => {
        this.setState({ remount: false });
      });
    }
  }

  render() {
    const { placeholder = '', value, disabled, className = '', types = [ELocationType.Address] } = this.props;
    const { remount } = this.state;
    const initValue = locationFormatter(value);

    return !remount ? (
      <Autocomplete
        className={`ant-input ${className}`}
        placeholder={placeholder}
        style={{ width: '100%', textOverflow: 'ellipsis' }}
        defaultValue={initValue}
        onFocus={() => {
          this.setState({ active: true });
        }}
        onPlaceSelected={(place: any) => {
          if (!place || !place.geometry) {
            this.onChange(undefined);
            return;
          }
          const res: ILocationModel = {
            region: parseLocation(place, ELocationType.RegionLevelOne) || parseLocation(place, ELocationType.RegionLevelTwo),
            country: parseLocation(place, ELocationType.Country),
            city: parseLocation(place, ELocationType.City),
            placeId: place.place_id || ''
          };

          this.onChange(res);
          this.setState({ active: false });
        }}
        types={types}
        onKeyPress={this.onKey}
        onKeyDown={this.onKey}
        onKeyUp={this.onKey}
        onChange={() => this.onChange(undefined)}
        disabled={disabled}
      />
    ) : null;
  }
}
