import { ColumnsType } from 'antd/lib/table';
import { RouteComponentProps, withRouter } from 'react-router';
import { TableCommon } from 'common/components/TableCommon';
import { IBaseFilterModel } from 'common/models/requestModels';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { ERoutesPrivate } from 'common/models/routesModel';
import { IProductCollection, IProductCollectionFilter, IProductModel } from 'entities/Product/Product.models';
import { communicationProduct, IProductConnectedProps } from 'entities/Product/Product.communication';

interface IComponentProps {
  config: ColumnsType<any>;
}

type AllProps = IComponentProps & IProductConnectedProps & RouteComponentProps;

class ProductTableComponent extends TableCommon<
  IProductCollection,
  IProductModel,
  ColumnsType<any>,
  AllProps,
  IProductCollectionFilter
> {
  loadCollection = (params: IBaseFilterModel) => {
    const { getProductCollection, filter } = this.props;
    const filterParams: IProductCollectionFilter = { ...params };

    if (filter?.markets) {
      filterParams.markets = filter?.markets;
    }
    if (filter?.statuses) {
      filterParams.statuses = filter?.statuses;
    }
    if (filter?.targetGroups) {
      filterParams.targetGroups = filter?.targetGroups;
    }
    if (filter?.types) {
      filterParams.types = filter?.types;
    }
    getProductCollection(filterParams);
  };

  getCollection = () => {
    const { productCollection } = this.props;

    return productCollection;
  };

  addQuery = (pageIndex: number) => {
    const searchPath = queryToObject<IProductCollectionFilter>({
      pageIndex: 1,
      markets: undefined,
      statuses: undefined,
      targetGroups: undefined,
      types: undefined
    });
    const search = objectToQuery({ ...searchPath, pageIndex });
    return this.props.history.replace({ search });
  };

  onRowClick = (item: IProductModel) => {
    const { history } = this.props;
    history.push(`${ERoutesPrivate.Product}/${item.id}`);
  };

  clearCollection = () => {
    return this.props.clearProductCollection();
  };
}

export const ProductTable = communicationProduct.injector(withRouter(ProductTableComponent));
