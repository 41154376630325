import { message } from 'antd';
import { axiosConfig } from 'index';
import { refreshToken, getCreds, ICreds } from 'axios-patch-jwt';
import { getVideosUrl, getImageUrl } from 'common/helpers/image.helper';
import { EImageSize } from 'entities/Image/Image.models';

export function getBase64(img: Blob, callback: { (): void; (arg0: string | ArrayBuffer | null): any }) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

export async function downloadFile(linkUrl: string, token?: string, method: string = 'GET') {
  const response = await fetch(
    linkUrl,
    token
      ? {
          method,
          headers: {
            'Source-Service': 'admin',
            Authorization: `Bearer ${token}`
          }
        }
      : undefined
  );

  if (response.status === 200) {
    const blob = await response.blob();
    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadUrl;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}

export async function checkImage(file: { type: string; size: number }) {
  await refreshToken(axiosConfig);

  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('Only JPG/PNG!');
  }
  const isLt2M = file.size / 1024 / 1024 < 15;
  if (!isLt2M) {
    message.error('Size should be less than 15MB!');
  }
  return isJpgOrPng && isLt2M;
}

export function isBase64IsImage(base64String: string) {
  return new Promise(resolve => {
    const onError = () => {
      message.error('File is not an image');
      resolve(false);
    };

    if (base64String) {
      const image = new Image();
      image.src = base64String;
      image.onload = () => {
        resolve(!(image.height === 0 || image.width === 0));
      };
      image.onerror = onError;
    } else {
      onError();
    }
  });
}

export async function checkArModel(file: { type: string; size: number }) {
  await refreshToken(axiosConfig);

  const isLt15M = file.size / 1024 / 1024 < 15;
  if (!isLt15M) {
    message.error('Size should be less than 15MB!');
  }
  return isLt15M;
}

export async function checkVideo(file: { type: string; size: number }) {
  await refreshToken(axiosConfig);

  const isVideo = file?.type?.search('video/') > -1;
  if (!isVideo) {
    message.error('Only video files (.mp4 or else)');
  }
  const isLt2M = file.size / 1024 / 1024 < 50;
  if (!isLt2M) {
    message.error('Size should be less than 50MB!');
  }
}

export const getImageLink = async (imageId?: string, size?: EImageSize | null): Promise<string> => {
  const creds: ICreds = await getCreds();
  const token = creds?.access?.token || '';
  let link = token && imageId ? `${getImageUrl(imageId)}?token=${token}` : '';

  if (size) {
    link += `&size=${size}`;
  }
  return link;
};

export const getVideoLink = async (videoId?: string): Promise<string> => {
  const creds: ICreds = await getCreds();
  const token = creds?.access?.token || '';

  return token && videoId ? `${getVideosUrl(videoId)}?token=${token}` : '';
};
