import { ColumnsType } from 'antd/lib/table';
import { RouteComponentProps, withRouter } from 'react-router';
import { TableCommon } from 'common/components/TableCommon';
import { IBaseFilterModel } from 'common/models/requestModels';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import {
  ISubscriptionActivityCollection,
  ISubscriptionActivityCollectionFilter,
  ISubscriptionActivityModel
} from 'entities/Subscription/Subscription.models';
import { communicationSubscription, ISubscriptionConnectedProps } from 'entities/Subscription/Subscription.communication';

interface IComponentProps {
  config: ColumnsType<any>;
}

type AllProps = IComponentProps & ISubscriptionConnectedProps & RouteComponentProps;

class SubscriptionTableComponent extends TableCommon<
  ISubscriptionActivityCollection,
  ISubscriptionActivityModel,
  ColumnsType<any>,
  AllProps,
  ISubscriptionActivityCollectionFilter
> {
  loadCollection = (params: IBaseFilterModel) => {
    const { getSubscriptionActivityCollection, filter } = this.props;
    const filterParams: ISubscriptionActivityCollectionFilter = { ...params } as ISubscriptionActivityCollectionFilter;

    if (filter?.playerFilter?.id) {
      filterParams.player = filter.playerFilter.id;
    }
    if (filter?.mentorFilter?.id) {
      filterParams.mentor = filter.mentorFilter?.id;
    }
    if (filter?.from) {
      filterParams.from = filter?.from;
    }
    if (filter?.to) {
      filterParams.to = filter?.to;
    }
    if (filter?.type) {
      filterParams.type = filter?.type;
    }
    if (filter?.statuses) {
      filterParams.statuses = filter?.statuses;
    }
    if (filter?.processing) {
      filterParams.processing = filter.processing;
    }
    if (filter?.giftcardFilter?.number) {
      filterParams.giftcard = filter?.giftcardFilter?.number;
    }

    getSubscriptionActivityCollection(filterParams);
  };

  getCollection = () => {
    const { subscriptionActivityCollection } = this.props;
    return subscriptionActivityCollection;
  };

  addQuery = (pageIndex: number) => {
    const searchPath = queryToObject<ISubscriptionActivityCollectionFilter>({
      pageIndex: 1,
      playerFilter: undefined,
      mentorFilter: undefined,
      processing: undefined,
      from: undefined,
      to: undefined,
      type: undefined,
      statuses: undefined,
      giftcardFilter: undefined
    });
    const search = objectToQuery({ ...searchPath, pageIndex });

    return this.props.history.replace({ search });
  };

  clearCollection = () => {
    return this.props.clearSubscriptionActivityCollection();
  };
}

export const SubscriptionTable = communicationSubscription.injector(withRouter(SubscriptionTableComponent));
