import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { EFeedType } from 'entities/Feed/Feed.models';

interface IComponentProps {
  onChange?: (value?: EFeedType) => void;
  disabled?: boolean;
}

type AllProps = SelectProps<any> & IComponentProps;

export class FeedTypeSelector extends React.PureComponent<AllProps> {
  render() {
    const { value: propValue, onChange, disabled = false } = this.props;

    return (
      <Select
        allowClear
        className="width-full"
        placeholder="Select type"
        value={propValue}
        defaultActiveFirstOption={false}
        showArrow
        onChange={(value: EFeedType) => onChange && onChange(value)}
        disabled={disabled}
      >
        <Select.Option key={EFeedType.Freestyle} value={EFeedType.Freestyle} label={EFeedType.Freestyle}>
          {EFeedType.Freestyle}
        </Select.Option>
        <Select.Option key={EFeedType.News} value={EFeedType.News} label={EFeedType.News}>
          {EFeedType.News}
        </Select.Option>
        <Select.Option key={EFeedType.Passing} value={EFeedType.Passing} label={EFeedType.Passing}>
          {EFeedType.Passing}
        </Select.Option>
      </Select>
    );
  }
}
