import { message } from 'antd';
export interface ILocationModel extends Components.Schemas.AddressDto {}

export enum ELocationType {
  Country = 'country',
  Political = 'political',
  Address = 'address',
  City = 'locality',
  Street = 'route',
  Cities = '(cities)',
  Regions = '(regions)',
  HouseNumber = 'street_number',
  RegionLevelOne = 'administrative_area_level_1',
  RegionLevelTwo = 'administrative_area_level_2',
  RegionLevelThree = 'administrative_area_level_3',
  RegionLevelFour = 'administrative_area_level_4',
  RegionLevelFive = 'administrative_area_level_5'
}

export function parseLocation(location: any, type: ELocationType) {
  if (!location.address_components) {
    message.error('Incorrect request!');
  }

  const locationArr = location.address_components.filter((item: any) => item.types?.includes(type));

  return locationArr.length !== 0 ? locationArr[0].long_name : undefined;
}

export function locationFormatter(location?: ILocationModel) {
  const { city, region, country } = location ?? {};
  return location ? `${city || ''} ${region && region !== city ? region : ''} ${country || ''}` : undefined;
}
