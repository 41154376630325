export enum ERoutesCommon {
  Root = '/'
}
export enum ERoutesPublic {
  Login = '/login',
  Restore = '/restore',
  PasswordRestore = '/password-restore/token'
}

export enum ERoutesPrivate {
  TestLeaderboard = '/test-leaderboard',
  TestResults = '/test-results',
  Academy = '/academy',
  Test = '/tests',
  TestResultStatistic = '/tests-result-statistic',
  TestCreate = '/tests/new',
  Feed = '/feed',
  FeedCreate = '/feed/new',
  Product = '/products',
  ProductCreate = '/products/new',
  Story = '/story',
  StoryCreate = '/story/new',
  UserList = '/user-list',
  AdminCreate = '/admin/new',
  Giftcards = '/giftcards',
  GiftcardCreate = `/giftcards/new`,
  Tariffs = '/tariffs',
  TariffCreate = `/tariffs/new`,
  Players = '/players',
  Payments = '/payments',
  Statistic = '/statistic',
  WalletTransactions = '/wallet-transactions',
  NotificationTemplates = '/notification-templates',
  NotificationSend = `/notification/send`,
  NotificationSmsSend = `/notification/sms`,
  NotificationEmailSend = `/notification/email`,
  Settings = '/settings',
  Mail = '/mail',
  Languages = '/languages',
  Skins = '/skins',
  SkinsCreate = '/skins/new',
  Influencers = '/influencers'
}

export const imgApiRoute = '/api/images/';
