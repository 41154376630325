import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { EUserRoles, EUserRolesText } from 'entities/User/User.models';

type AllProps = SelectProps<any>;

export class UserRoleSelector extends React.PureComponent<AllProps> {
  render() {
    const { value, onChange, disabled = false } = this.props;

    return (
      <Select
        allowClear
        className="width-full"
        placeholder="Select role"
        value={value}
        defaultActiveFirstOption={false}
        showArrow
        disabled={disabled}
        {...this.props}
      >
        <Select.Option key={EUserRoles.Admin} value={EUserRoles.Admin} label={EUserRolesText.Admin}>
          {EUserRolesText.Admin}
        </Select.Option>
        <Select.Option key={EUserRoles.AcademyWorker} value={EUserRoles.AcademyWorker} label={EUserRolesText.AcademyWorker}>
          {EUserRolesText.AcademyWorker}
        </Select.Option>
        <Select.Option key={EUserRoles.DataTagger} value={EUserRoles.DataTagger} label={EUserRolesText.DataTagger}>
          {EUserRolesText.DataTagger}
        </Select.Option>
        <Select.Option key={EUserRoles.Mentor} value={EUserRoles.Mentor} label={EUserRolesText.Mentor}>
          {EUserRolesText.Mentor}
        </Select.Option>
        <Select.Option key={EUserRoles.Trainer} value={EUserRoles.Trainer} label={EUserRolesText.Trainer}>
          {EUserRolesText.Trainer}
        </Select.Option>
      </Select>
    );
  }
}
