import React from 'react';
import { Link } from 'react-router-dom';
import { Row } from 'antd';
import { VideoPrivate } from 'common/components/Video/VideoPrivate';
import { IBasePathParams } from 'common/models/requestModels';
import { ERoutesPrivate } from 'common/models/routesModel';
import { nameBuilder } from 'common/helpers/name.helper';
import { IPlayerModel } from 'entities/Player/Player.models';

export interface ITestModel extends Components.Schemas.TestDto {}
export interface ITestCollection extends Components.Schemas.TestCollectionDto {}

export interface ITestCollectionFilter extends Paths.TestControllerList.QueryParameters {
  pageIndex?: number;
}

export interface ITestUpdateData extends Omit<Components.Schemas.UpdateTestDto, 'iosModel' | 'androidModel'>, ITestUpdateParams {
  iosModel?: string | null;
  androidModel?: string | null;
}
export interface ITestUpdateParams extends Paths.TestControllerUpdate.PathParameters {}

export type TTestParamsModel = ITestUpdateData | IBasePathParams;

export interface ITestStatisticModel extends Components.Schemas.TestStatisticDto {
  commonHumanValue?: number;
  commonAiValue?: number;
  percentFromTotal?: number;
  totalCostAi?: number;
}

export interface ITestStatisticCollection extends Components.Schemas.TestStatisticCollectionDto {}
export interface ITestStatisticCollectionFilter extends Paths.TestControllerGetStatistic.QueryParameters {
  humanCost?: number;
  aiCost?: number;
}

export interface ITestLeaderboardModel extends Components.Schemas.LeaderboardItemDto {}
export interface ITestLeaderboardCollection extends Components.Schemas.LeaderboardItemsDto {}
export interface ITestLeaderboardCollectionFilter
  extends Paths.TestControllerGetLeaderboard.QueryParameters,
    Paths.TestControllerGetLeaderboard.PathParameters {
  pageIndex?: number;
}

export enum ETestSuccessMessage {
  Delete = 'Test has been successfully deleted',
  Add = 'Test has been successfully created',
  Edit = 'Test has been successfully edited'
}

export enum EAvailabilityTypes {
  Ready = 'ready',
  Test = 'test',
  None = 'none'
}

export enum EAvailabilityTypesText {
  Ready = 'Ready',
  Test = 'Test',
  None = 'None'
}
export enum EComplexity {
  Low = 'low',
  Medium = 'medium',
  Pro = 'pro'
}

export enum EComplexityText {
  Low = 'Low',
  Medium = 'Medium',
  Pro = 'Pro'
}

export enum ETestAnchorDetectionTypes {
  Horizontal = 'PlanesHorizontal',
  None = 'None'
}

export enum ETestAnchorDetectionTypesText {
  Horizontal = 'Horizontal planes',
  None = 'none'
}

export enum ETestOrientationTypesText {
  Portrait = 'Portrait',
  Landscape = 'Landscape'
}
export enum ETestOrientationTypes {
  Portrait = 'portrait',
  Landscape = 'landscape'
}

export const tableTestConfig = [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    ellipsis: true,
    width: 150,
    render: (title: string, test: ITestModel) => <Link to={`${ERoutesPrivate.Test}/${test?.id}`}>{title}</Link>
  },
  {
    title: 'Tag',
    dataIndex: 'tag',
    key: 'tag',
    ellipsis: true,
    width: 150
  },
  {
    title: 'Max video time (JS)',
    dataIndex: 'maxVideoTime',
    key: 'maxVideoTime',
    ellipsis: true,
    width: 70
  },
  {
    title: 'Max video time (JC)',
    dataIndex: 'maxVideoTimeJC',
    key: 'maxVideoTimeJC',
    ellipsis: true,
    width: 70
  },
  {
    title: 'Delay (JS)',
    dataIndex: 'timeBeforeStart',
    key: 'timeBeforeStart',
    ellipsis: true,
    width: 70
  },
  {
    title: 'Delay (JC)',
    dataIndex: 'timeBeforeStartJC',
    key: 'timeBeforeStartJC',
    ellipsis: true,
    width: 70
  },
  {
    title: 'Default accept (JS)',
    dataIndex: 'defaultAccept',
    key: 'defaultAccept',
    ellipsis: true,
    render: (isNeedAr: boolean) => (isNeedAr ? <span>+</span> : null),
    width: 70
  },
  {
    title: 'Default accept (JC)',
    dataIndex: 'defaultAcceptJC',
    key: 'defaultAcceptJC',
    ellipsis: true,
    render: (isNeedAr: boolean) => (isNeedAr ? <span>+</span> : null),
    width: 70
  },
  {
    title: 'Is need AR',
    dataIndex: 'isNeedAr',
    key: 'isNeedAr',
    ellipsis: true,
    render: (isNeedAr: boolean) => (isNeedAr ? <span>+</span> : null),
    width: 70
  },
  {
    title: 'For coach',
    dataIndex: 'isVisibleForTrainer',
    key: 'isVisibleForTrainer',
    render: (isVisibleForTrainer: boolean) => (isVisibleForTrainer ? <span>+</span> : null),
    width: 70
  },
  {
    title: 'For player',
    dataIndex: 'isVisibleForPlayer',
    key: 'isVisibleForTrainer',
    render: (isVisibleForPlayer: boolean) => (isVisibleForPlayer ? <span>+</span> : null),
    width: 70
  },
  {
    title: 'Cost for try',
    dataIndex: 'costForTry',
    key: 'costForTry',
    render: (costForTry?: number) => (typeof costForTry === 'number' && costForTry ? costForTry : <span>-</span>),
    width: 70
  },
  {
    title: 'Bonus for success',
    dataIndex: 'bonusForSuccess',
    key: 'bonusForSuccess',
    render: (bonusForSuccess?: number) =>
      typeof bonusForSuccess === 'number' && bonusForSuccess ? bonusForSuccess : <span>-</span>,
    width: 70
  }
];

export const tableTestResultStatisticConfig = [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    ellipsis: true,
    width: 250
  },
  {
    title: 'Cost per 1 test(human)',
    dataIndex: 'commonHumanValue',
    key: 'commonHumanValue',
    sorter: {
      compare: (a: ITestStatisticModel, b: ITestStatisticModel) => (a?.commonHumanValue || 0) - (b?.commonHumanValue || 0),
      multiple: 1
    }
  },
  {
    title: 'Cost per 1 test(ai)',
    dataIndex: 'commonAiValue',
    key: 'commonAiValue',
    sorter: {
      compare: (a: ITestStatisticModel, b: ITestStatisticModel) => (a?.commonAiValue || 0) - (b?.commonAiValue || 0),
      multiple: 1
    }
  },
  {
    title: 'From total AI work time, %',
    dataIndex: 'percentFromTotal',
    key: 'percentFromTotal',
    sorter: {
      compare: (a: ITestStatisticModel, b: ITestStatisticModel) => (a?.percentFromTotal || 0) - (b?.percentFromTotal || 0),
      multiple: 1
    }
  },
  {
    title: 'Total cost ai',
    dataIndex: 'totalCostAi',
    key: 'totalCostAi',
    sorter: {
      compare: (a: ITestStatisticModel, b: ITestStatisticModel) => (a?.totalCostAi || 0) - (b?.totalCostAi || 0),
      multiple: 1
    }
  },
  {
    title: 'Expected moderation speed, sec',
    dataIndex: 'avgModerationSpeed',
    key: 'avgModerationSpeed',
    sorter: {
      compare: (a: ITestStatisticModel, b: ITestStatisticModel) => (a?.avgModerationSpeed || 0) - (b?.avgModerationSpeed || 0),
      multiple: 1
    }
  },
  {
    title: 'Average moderation time, sec',
    dataIndex: 'avgModerationTime',
    key: 'avgModerationTime',
    sorter: {
      compare: (a: ITestStatisticModel, b: ITestStatisticModel) => (a?.avgModerationTime || 0) - (b?.avgModerationTime || 0),
      multiple: 1
    }
  },
  {
    title: 'Max moderation time, sec',
    dataIndex: 'maxModerationTime',
    key: 'maxModerationTime',
    sorter: {
      compare: (a: ITestStatisticModel, b: ITestStatisticModel) => (a?.maxModerationTime || 0) - (b?.maxModerationTime || 0),
      multiple: 1
    }
  },
  {
    title: 'Total count',
    dataIndex: 'totalCount',
    key: 'totalCount',
    sorter: {
      compare: (a: ITestStatisticModel, b: ITestStatisticModel) => (a?.totalCount || 0) - (b?.totalCount || 0),
      multiple: 1
    }
  },
  {
    title: 'Accept count',
    dataIndex: 'acceptCount',
    key: 'acceptCount',
    sorter: {
      compare: (a: ITestStatisticModel, b: ITestStatisticModel) => (a?.acceptCount || 0) - (b?.acceptCount || 0),
      multiple: 1
    }
  },
  {
    title: 'Reject count',
    dataIndex: 'rejectCount',
    key: 'rejectCount',
    sorter: {
      compare: (a: ITestStatisticModel, b: ITestStatisticModel) => (a?.rejectCount || 0) - (b?.rejectCount || 0),
      multiple: 1
    }
  },
  {
    title: 'Moderation count',
    dataIndex: 'moderationCount',
    key: 'moderationCount',
    sorter: {
      compare: (a: ITestStatisticModel, b: ITestStatisticModel) => (a?.moderationCount || 0) - (b?.moderationCount || 0),
      multiple: 1
    }
  },
  {
    title: 'Changed count',
    dataIndex: 'changedCount',
    key: 'changedCount',
    sorter: {
      compare: (a: ITestStatisticModel, b: ITestStatisticModel) => (a?.changedCount || 0) - (b?.changedCount || 0),
      multiple: 1
    }
  },
  {
    title: 'Old changed count',
    dataIndex: 'oldChangedCount',
    key: 'oldChangedCount',
    sorter: {
      compare: (a: ITestStatisticModel, b: ITestStatisticModel) => (a?.oldChangedCount || 0) - (b?.oldChangedCount || 0),
      multiple: 1
    }
  },
  {
    title: 'Accept percent',
    dataIndex: 'acceptPercent',
    key: 'acceptPercent',
    sorter: {
      compare: (a: ITestStatisticModel, b: ITestStatisticModel) => (a?.acceptPercent || 0) - (b?.acceptPercent || 0),
      multiple: 1
    }
  },
  {
    title: 'Reject percent',
    dataIndex: 'rejectPercent',
    key: 'rejectPercent',
    sorter: {
      compare: (a: ITestStatisticModel, b: ITestStatisticModel) => (a?.rejectPercent || 0) - (b?.rejectPercent || 0),
      multiple: 1
    }
  },
  {
    title: 'Changed percent',
    dataIndex: 'changedPercent',
    key: 'changedPercent',
    sorter: {
      compare: (a: ITestStatisticModel, b: ITestStatisticModel) => (a?.changedPercent || 0) - (b?.changedPercent || 0),
      multiple: 1
    }
  },
  {
    title: 'Total AI processed time, sec',
    dataIndex: 'totalAiProcessedTime',
    key: 'totalAiProcessedTime',
    sorter: {
      compare: (a: ITestStatisticModel, b: ITestStatisticModel) =>
        (a?.totalAiProcessedTime || 0) - (b?.totalAiProcessedTime || 0),
      multiple: 1
    }
  }
];

export const tableLeaderboardTestConfig = [
  {
    title: 'Position',
    dataIndex: 'position',
    key: 'position',
    ellipsis: true
  },
  {
    title: 'Result',
    dataIndex: 'result',
    key: 'result',
    ellipsis: true
  },
  {
    title: 'Player',
    dataIndex: 'player',
    key: 'player',
    ellipsis: true,
    render: (playerModel: IPlayerModel) => (
      <Link to={`${ERoutesPrivate.Players}/${playerModel?.id}`}>
        {nameBuilder(playerModel?.firstName, playerModel?.lastName)}
      </Link>
    )
  },
  {
    title: 'Test result',
    dataIndex: 'playerToTestId',
    key: 'playerToTestId',
    ellipsis: true,
    render: (playerToTestId: string) => <Link to={`${ERoutesPrivate.TestResults}/${playerToTestId}`}>Open result</Link>
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    ellipsis: true
  },
  {
    title: 'Video',
    dataIndex: 'video',
    key: 'video',
    ellipsis: true,
    render: ({ videoId, previewId }: any) => (
      <Row justify="center">
        <VideoPrivate height={50} width={100} videoId={videoId} preview={previewId} />
      </Row>
    )
  }
];
