import React, { ChangeEvent } from 'react';
import { Button, Col, Input, Row } from 'antd';
import debounce from 'lodash.debounce';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { AdminTable } from 'entities/Admin/components/AdminTable';
import { EAdminStatus, IAdminCollectionFilter, tableAdminConfig } from 'entities/Admin/Admin.models';
import { AdminStatusSelector } from 'entities/Admin/components/Selector/AdminStatusSelector';
import { UserRoleSelector } from 'entities/User/components/UserRoleSelector';
import { EUserRoles } from 'entities/User/User.models';
import { AdminPanelStatistic } from 'entities/Admin/components/AdminPanelStatistic';
import { AdminRegCompleteSelector } from 'entities/Admin/components/Selector/AdminRegCompleteSelector';

type AllProps = RouteComponentProps;

interface IComponentState {
  filter: IAdminCollectionFilter;
}

class AdminPageComponent extends React.PureComponent<AllProps, IComponentState> {
  public debounceSearch: any;
  state: IComponentState = {
    filter: {
      pageIndex: 1,
      search: undefined,
      regComplete: undefined,
      status: undefined,
      roles: undefined
    }
  };
  constructor(props: AllProps) {
    super(props);
    this.state.filter = { ...this.state.filter, ...queryToObject<IAdminCollectionFilter>(this.state.filter) };
    this.debounceSearch = debounce(this.autocompleteSearch, 300);
  }

  render() {
    const { filter } = this.state;
    const { roles, status, search, regComplete } = filter;

    return (
      <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10">
        <Row className="mb-10 width-full" justify="space-between" align="middle">
          <Col span={18}>
            <Row justify="space-between" align="middle">
              <Col span={7}>
                <Input.Search
                  placeholder="Search by name or email"
                  defaultValue={search}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => this.debounceSearch(e.target.value)}
                />
              </Col>
              <Col span={5}>
                <AdminStatusSelector onChange={this.onChangeStatus} value={status} placeholder="Search by status" />
              </Col>
              <Col span={6}>
                <UserRoleSelector onChange={this.onChangeRole} value={roles} placeholder="Search by role" />
              </Col>
              <Col span={5}>
                <AdminRegCompleteSelector
                  onChange={this.onChangeRegComplete}
                  value={regComplete}
                  placeholder="Complete registration"
                />
              </Col>
            </Row>
          </Col>
          <Col span={4}>
            <Link to={ERoutesPrivate.AdminCreate}>
              <Button block type="primary" title="Add user">
                Add user
              </Button>
            </Link>
          </Col>
        </Row>
        <AdminPanelStatistic />
        <AdminTable config={tableAdminConfig} filter={filter} />
      </CommonLayout>
    );
  }

  setFilter = (partialFilter: Partial<IAdminCollectionFilter>) => {
    const oldFilter = this.state.filter;
    const filter = { ...oldFilter, ...partialFilter };

    this.props.history.replace({ search: objectToQuery(filter) });
    this.setState({ filter });
  };

  onChangeStatus = (status?: EAdminStatus) => {
    this.setFilter({ status });
  };

  onChangeRole = (roles?: EUserRoles) => {
    this.setFilter({ roles: roles ? [roles] : undefined });
  };

  onChangeRegComplete = (regComplete?: boolean) => {
    this.setFilter({ regComplete });
  };

  autocompleteSearch = (text: string) => {
    const search = text === '' ? undefined : text;
    this.setFilter({ search });
  };
}

export const AdminTablePage = withRouter(AdminPageComponent);
