import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { EWalletStatus } from 'entities/Wallet/Wallet.models';

interface IComponentProps {
  onChange?: (value: EWalletStatus) => void;
  placeholder?: string;
}

type AllProps = SelectProps<any> & IComponentProps;

export class WalletStatusSelector extends React.PureComponent<AllProps> {
  handleChange = (value: EWalletStatus) => {
    const { onChange } = this.props;
    onChange && onChange(value);
  };

  render() {
    const { value, defaultValue, placeholder = 'Select' } = this.props;

    return (
      <Select
        allowClear
        className="width-full"
        value={value}
        placeholder={placeholder}
        defaultActiveFirstOption={false}
        defaultValue={defaultValue}
        showArrow
        onChange={(value: EWalletStatus) => this.handleChange(value)}
      >
        <Select.Option key={EWalletStatus.canceled} value={EWalletStatus.canceled} label={EWalletStatus.canceled}>
          {EWalletStatus.canceled}
        </Select.Option>
        <Select.Option key={EWalletStatus.confirmed} value={EWalletStatus.confirmed} label={EWalletStatus.confirmed}>
          {EWalletStatus.confirmed}
        </Select.Option>
        <Select.Option key={EWalletStatus.new} value={EWalletStatus.new} label={EWalletStatus.new}>
          {EWalletStatus.new}
        </Select.Option>
      </Select>
    );
  }
}
