import { ColumnsType } from 'antd/lib/table';
import { RouteComponentProps, withRouter } from 'react-router';
import { TableCommon } from 'common/components/TableCommon';
import { IBaseFilterModel } from 'common/models/requestModels';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { dateFormatter } from 'common/helpers/date.helper';
import { simpleDateFormat } from 'common/models/dateModels';
import { ITestLeaderboardCollection, ITestLeaderboardCollectionFilter, ITestLeaderboardModel } from 'entities/Test/Test.models';
import { communicationTest, ITestConnectedProps } from 'entities/Test/Test.communication';

interface IComponentProps {
  config: ColumnsType<any>;
}

type AllProps = IComponentProps & ITestConnectedProps & RouteComponentProps;

class TestLeaderboardTableComponent extends TableCommon<
  ITestLeaderboardCollection,
  ITestLeaderboardModel,
  ColumnsType<any>,
  AllProps,
  Partial<ITestLeaderboardCollectionFilter>
> {
  loadCollection = async (params: IBaseFilterModel) => {
    const { getTestLeaderboardCollection, filter } = this.props;
    const { pageIndex, ...filterValue } = filter || {};

    if (filter?.id) {
      await getTestLeaderboardCollection({ ...filterValue, ...params } as any);
    }
  };

  getCollection = () => {
    const { testLeaderboardCollection } = this.props;

    if (testLeaderboardCollection?.data?.data) {
      const data: ITestLeaderboardModel[] = testLeaderboardCollection.data.data.map(item => {
        const { firstName, lastName, id, videoId, previewId, playerToTestDate } = item;

        return {
          ...item,
          player: { firstName, lastName, id },
          video: { previewId, videoId },
          date: dateFormatter(playerToTestDate, simpleDateFormat),
          result: `${item?.value} ${item?.units}`
        };
      });
      return {
        ...testLeaderboardCollection,
        data: {
          ...testLeaderboardCollection.data,
          data
        }
      };
    } else {
      return testLeaderboardCollection;
    }
  };

  addQuery = (pageIndex: number) => {
    const searchPath = queryToObject<Partial<ITestLeaderboardCollectionFilter>>({
      pageIndex: 1
    });
    const search = objectToQuery({ ...searchPath, pageIndex });
    return this.props.history.replace({ search });
  };

  clearCollection = () => {
    return this.props.clearTestLeaderboardCollection();
  };
}

export const TestLeaderboardTable = communicationTest.injector(withRouter(TestLeaderboardTableComponent));
