export class Queue {
  queue: {
    promiseGenerator: () => Promise<void>;
    resolve: (value: void) => void | PromiseLike<void>;
    reject: (value: void) => void | PromiseLike<void>;
  }[] = [];
  pendingPromise = false;
  stop = false;
  workingOnPromise = false;

  enqueue(promiseGenerator: () => Promise<void>) {
    return new Promise((resolve, reject) => {
      this.queue.push({
        promiseGenerator,
        resolve,
        reject
      });
      this.dequeue();
    });
  }

  dequeue() {
    if (this.workingOnPromise) {
      return false;
    }
    if (this.stop) {
      this.queue = [];
      this.stop = false;
      return;
    }
    const item = this.queue.shift();
    if (!item) {
      return false;
    }
    try {
      this.workingOnPromise = true;
      item
        .promiseGenerator()
        .then(item.resolve)
        .catch(item.reject)
        .finally(() => {
          this.workingOnPromise = false;
          this.dequeue();
        });
    } catch (err) {
      this.workingOnPromise = false;
      item.reject(err);
      this.dequeue();
    }
    return true;
  }

  clear() {
    this.queue = [];
  }
}
