import React from 'react';
import { Button, Col, message, Row, Upload } from 'antd';
import { DeleteOutlined, VideoCameraAddOutlined } from '@ant-design/icons';
import { Player } from 'video-react';
import { checkVideo, getBase64 } from 'common/helpers/loader.helper';
import { getHost } from 'common/helpers/axios.helper';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import 'video-react/dist/video-react.css';

interface IComponentProps {
  onChange?: (videoId: string | null) => void;
  value?: string;
  onLoading?: (isLoading: boolean) => void;
}

interface IComponentState {
  loading: boolean;
  list: any[];
}

type AllProps = IComponentProps & IAuthConnectedProps;

class VideoUploadComponent extends React.PureComponent<AllProps> {
  state: IComponentState = {
    loading: false,
    list: []
  };
  constructor(props: AllProps) {
    super(props);
    const { value } = props;

    this.state.list = value ? [value] : [];
  }

  componentDidUpdate(prevProps: Readonly<AllProps>, prevState: Readonly<IComponentState>) {
    const { onLoading } = this.props;
    if (onLoading && prevState.loading !== this.state.loading) {
      onLoading(this.state.loading);
    }
  }

  handleChange = (info: any) => {
    const { onChange } = this.props;
    let fileList = [...info.fileList];

    fileList = fileList.slice(-1);

    fileList = fileList.map(file => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    this.setState({ list: fileList });
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }

    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, () =>
        this.setState({
          loading: false
        })
      );

      if (onChange) {
        onChange(info.file.response.id);
      }
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
      this.setState({ loading: false });
    }
  };

  render() {
    const { authModel, value } = this.props;
    const { loading } = this.state;
    const token = authModel.data?.access?.token;
    const props = getProps(this.props);
    const host = getHost();

    return (
      <>
        <Row>
          <Col>
            <Upload {...props} onChange={this.handleChange} fileList={this.state.list} onRemove={this.onRemove}>
              <Button type="link" size="large">
                <VideoCameraAddOutlined style={{ fontSize: '3em' }} />
              </Button>
            </Upload>
          </Col>
          <Col>
            {!!token && !!value && !loading && (
              <Button type="link" size="large" onClick={this.onRemove}>
                <DeleteOutlined style={{ fontSize: '3em' }} />
              </Button>
            )}
          </Col>
        </Row>
        <Row className="mt-5">
          {!!token && !!value && !loading && (
            <Player fluid={false} height={500}>
              <source src={`${host}/api/videos/${value}/user?token=${token}`} type="video/mp4" />
            </Player>
          )}
        </Row>
      </>
    );
  }
  onRemove = () => {
    const { onChange } = this.props;
    this.setState({ list: [] }, () => onChange && onChange(null));
  };
}

export const VideoUpload = communicationAuth.injector(VideoUploadComponent);

function getProps(props: AllProps) {
  const { authModel } = props;
  const token = authModel.data?.access?.token;

  return {
    name: 'video',
    action: `/api/videos/for-test`,
    headers: {
      authorization: `Bearer ${token}`
    },
    multiple: false,
    showUploadList: {
      showPreviewIcon: false,
      showDownloadIcon: false,
      showRemoveIcon: true
    },
    beforeUpload: checkVideo
  };
}
