import { IBasePathParams } from 'common/models/requestModels';

export interface IFranchiseModel extends Components.Schemas.FranchiseDto {}
export interface IFranchiseCreate extends Components.Schemas.CreateFranchiseDto {}

export interface IFranchiseRequestModel extends Components.Schemas.FranchiseLinkDto {}
export interface IFranchiseRequestModelUpdateStatusParams
  extends Components.Schemas.UpdateFranchiseLinkStatusDto,
    IBasePathParams {}
export interface IFranchiseRequestParamsModel extends IFranchiseRequestModelUpdateStatusParams, IBasePathParams {}

export enum EFranchiseTypes {
  Main = 'main',
  Child = 'child'
}

export enum EFranchiseTypesText {
  Main = 'Main',
  Child = 'Child'
}

export enum EFranchiseStatuses {
  New = 'new',
  Active = 'active',
  Canceled = 'canceled'
}

export enum EFranchiseErrorCode {
  FranchiseNotEmpty = 'error.franchiseNotEmptyException'
}

export enum EFranchiseErrorMessages {
  FranchiseNotEmpty = 'The franchise not empty'
}
