import { AbstractSearchableSelector } from 'common/components/Selector/AbstractSearchableSelector';
import { ITestCollectionFilter, ITestModel } from 'entities/Test/Test.models';
import { testTransport } from 'entities/Test/Test.transport';

interface IComponentProps {}

export class TestSelector extends AbstractSearchableSelector<ITestModel, IComponentProps> {
  getItemLabel = (item: ITestModel): string => {
    return `${item.title} (${item.isVisibleForPlayer ? (item.isVisibleForTrainer ? 'player + coach' : 'player') : 'coach'})`;
  };

  loadData = (value: string | undefined): Promise<{ data: ITestModel[] }> => {
    const filter: ITestCollectionFilter = {};
    if (value) {
      filter.title = value;
    }
    return testTransport.getTestSearchCollection(filter);
  };
}
