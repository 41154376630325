import React from 'react';
import { Button, Col, DatePicker, Row } from 'antd';
import moment from 'moment';
import { RouteComponentProps, withRouter } from 'react-router';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { getUTCEndOfDay, getUTCStartOfDay } from 'common/helpers/date.helper';
import { MentorSelector } from 'entities/User/components/MentorSelector';
import { IUserModel } from 'entities/User/User.models';
import { PlayerSelector } from 'entities/Player/components/PlayerSelector';
import { SubscriptionStatusSelector } from 'entities/Subscription/components/Selector/SubscriptionStatusSelector';
import { SubscriptionTypeSelector } from 'entities/Subscription/components/Selector/SubscriptionTypeSelector';
import { SubscriptionProcessingSelector } from 'entities/Subscription/components/Selector/SubscriptionProcessingSelector';
import {
  ISubscriptionActivityCollectionFilter,
  EPaymentActivitiesTypes,
  EProcessings,
  tableSubscriptionConfig,
  ESubscriptionActivityStatus
} from 'entities/Subscription/Subscription.models';
import { IPlayerModel } from 'entities/Player/Player.models';
import { SubscriptionPanelStatistic } from 'entities/Subscription/components/SubscriptionPanelStatistic';
import { SubscriptionTable } from 'entities/Subscription/components/SubscriptionTable';
import { GiftcardSelector } from 'entities/Giftcard/components/Selector/GiftcardSelector';
import { IGiftcardModel } from 'entities/Giftcard/Giftcard.models';
import { subscriptionTransport } from 'entities/Subscription/Subscription.transport';

type AllProps = RouteComponentProps;

interface IComponentState {
  filter: ISubscriptionActivityCollectionFilter;
}

class PaymentPageComponent extends React.PureComponent<AllProps, IComponentState> {
  state: IComponentState = {
    filter: {
      pageIndex: 1,
      mentor: undefined,
      playerFilter: undefined,
      mentorFilter: undefined,
      statuses: undefined,
      type: undefined,
      player: undefined,
      from: undefined,
      to: undefined,
      processing: undefined,
      giftcardFilter: undefined
    }
  };
  constructor(props: AllProps) {
    super(props);
    this.state.filter = { ...this.state.filter, ...queryToObject<ISubscriptionActivityCollectionFilter>(this.state.filter) };
  }

  render() {
    const { filter } = this.state;
    const { statuses, type, playerFilter, mentorFilter, from, to, processing, giftcardFilter } = filter;

    return (
      <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10">
        <Row className="mb-10 width-full" justify="space-between" align="middle">
          <Col span={24}>
            <Row justify="space-between" align="middle">
              <Col span={4}>
                <PlayerSelector onChange={this.onChangePlayer} value={playerFilter} placeholder="Search by player" />
              </Col>
              <Col span={4}>
                <MentorSelector onChange={this.onChangeMentor} value={mentorFilter} placeholder="Search by mentor" />
              </Col>
              <Col span={3}>
                <SubscriptionStatusSelector onChange={this.onChangeStatus} value={statuses} placeholder="Search by status" />
              </Col>
              <Col span={2}>
                <SubscriptionTypeSelector onChange={this.onChangeType} value={type} placeholder="Search by type" />
              </Col>
              <Col span={3}>
                <GiftcardSelector onChange={this.onChangeGiftcard} value={giftcardFilter} placeholder="Search by giftcard" />
              </Col>
              <Col span={3}>
                <SubscriptionProcessingSelector
                  onChange={this.onChangeProcessing}
                  value={processing}
                  placeholder="Search by processing"
                />
              </Col>
              <Col span={4}>
                <DatePicker.RangePicker
                  onChange={this.onChangeDates}
                  format="DD.MM.YYYY"
                  value={[from && (moment(from) as any), to && (moment(to).utc() as any)]}
                  allowClear={true}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Button type="primary" title="Refresh" onClick={() => subscriptionTransport.stripeRefresh()}>
          Refresh
        </Button>
        <SubscriptionPanelStatistic />
        <SubscriptionTable config={tableSubscriptionConfig} filter={filter} />
      </CommonLayout>
    );
  }

  onChangePlayer = (playerModel?: IPlayerModel) => {
    const playerFilter = playerModel
      ? { id: playerModel.id, firstName: playerModel.firstName, lastName: playerModel.lastName }
      : undefined;

    this.setFilter({ playerFilter });
  };

  onChangeGiftcard = (giftcardModel?: IGiftcardModel) => {
    const giftcardFilter = giftcardModel ? { id: giftcardModel.id, number: giftcardModel.number } : undefined;

    this.setFilter({ giftcardFilter });
  };

  onChangeDates = (values: any) => {
    const [from, to] = values ?? [];
    this.setFilter({
      from: from && getUTCStartOfDay(from),
      to: to && getUTCEndOfDay(to)
    });
  };

  onChangeType = (type?: EPaymentActivitiesTypes) => {
    this.setFilter({ type });
  };

  onChangeStatus = (statuses?: ESubscriptionActivityStatus[]) => {
    this.setFilter({ statuses });
  };

  onChangeProcessing = (processing?: EProcessings) => {
    this.setFilter({ processing });
  };

  setFilter = (partialFilter: Partial<ISubscriptionActivityCollectionFilter>) => {
    const oldFilter = this.state.filter;
    const filter = { ...oldFilter, ...partialFilter };

    this.props.history.replace({ search: objectToQuery(filter) });
    this.setState({ filter });
  };

  onChangeMentor = (mentorModel?: IUserModel) => {
    const mentorFilter = mentorModel
      ? { id: mentorModel.id, firstName: mentorModel.firstName, lastName: mentorModel.lastName }
      : undefined;

    this.setFilter({ mentorFilter });
  };
}

export const PaymentPage = withRouter(PaymentPageComponent);
