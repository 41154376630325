import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { ETestOrientationTypes, ETestOrientationTypesText } from 'entities/Test/Test.models';

type AllProps = SelectProps<any>;

export class OrientationSelector extends React.PureComponent<AllProps> {
  render() {
    const { ...props } = this.props;

    return (
      <Select className="width-full" defaultActiveFirstOption={false} showArrow {...props}>
        <Select.Option
          key={ETestOrientationTypes.Landscape}
          value={ETestOrientationTypes.Landscape}
          label={ETestOrientationTypes.Landscape}
        >
          {ETestOrientationTypesText.Landscape}
        </Select.Option>
        <Select.Option
          key={ETestOrientationTypes.Portrait}
          value={ETestOrientationTypes.Portrait}
          label={ETestOrientationTypes.Portrait}
        >
          {ETestOrientationTypesText.Portrait}
        </Select.Option>
      </Select>
    );
  }
}
