import React from 'react';
import { Col, DatePicker, Row, Tabs } from 'antd';
import moment from 'moment';
import { RouteComponentProps, withRouter } from 'react-router';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { getUTCEndOfDay, getUTCStartOfDay } from 'common/helpers/date.helper';
import { LocationInput } from 'common/components/Input/LocationInput';
import { ELocationType, ILocationModel } from 'common/helpers/location.helper';
import {
  EStatisticTab,
  IStatisticFilter,
  tableStatisticConfig,
  tableStatisticTestConfig
} from 'entities/Statistic/Statistic.models';
import { StatisticTable } from 'entities/Statistic/components/StatisticTable';
import { StatisticTestTable } from 'entities/Statistic/components/StatisticTestTable';

type AllProps = RouteComponentProps;

interface IComponentState {
  filter: IStatisticFilter;
}

class StatisticPageComponent extends React.PureComponent<AllProps, IComponentState> {
  state: IComponentState = {
    filter: {
      pageIndex: 1,
      from: undefined,
      to: undefined,
      location: undefined,
      tab: EStatisticTab.PlayerStatistic
    }
  };
  constructor(props: AllProps) {
    super(props);
    this.state.filter = { ...this.state.filter, ...queryToObject<IStatisticFilter>(this.state.filter) };
  }

  render() {
    const { filter } = this.state;
    const { from, to, location, tab } = filter;

    return (
      <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10">
        <Row className="mb-10 width-full" justify="space-between" align="middle">
          <Col span={12}>
            <Row align="middle" justify="space-between">
              <Col span={11}>
                <LocationInput
                  placeholder="Search by location"
                  onChange={this.onChangeLocation}
                  value={location}
                  types={[ELocationType.Regions]}
                />
              </Col>
              <Col span={11}>
                <DatePicker.RangePicker
                  onChange={this.onChangeDates}
                  format="DD.MM.YYYY"
                  value={[from && (moment(from) as any), to && (moment(to).utc() as any)]}
                  allowClear={true}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Tabs destroyInactiveTabPane onChange={this.onChangeTab} activeKey={tab}>
          <Tabs.TabPane key={EStatisticTab.PlayerStatistic} tab="Players statistic">
            <span className="basic__text_title d-block mb-5" title="Players statistic">
              Players statistic
            </span>
            <Col>
              <StatisticTable config={tableStatisticConfig} filter={filter} />
            </Col>
          </Tabs.TabPane>
          <Tabs.TabPane key={EStatisticTab.TestStatistic} tab="Tests statistic">
            <span className="basic__text_title d-block mb-5" title="Tests statistic">
              Test statistic
            </span>
            <Col>
              <StatisticTestTable config={tableStatisticTestConfig} filter={filter} />
            </Col>
          </Tabs.TabPane>
        </Tabs>
      </CommonLayout>
    );
  }

  onChangeDates = (values: any) => {
    const [from, to] = values ?? [];
    this.setFilter({
      from: from && getUTCStartOfDay(from),
      to: to && getUTCEndOfDay(to)
    });
  };

  onChangeLocation = (location?: ILocationModel) => {
    this.setFilter({ location });
  };

  onChangeTab = (tab?: any) => {
    this.setFilter({ tab });
  };

  setFilter = (partialFilter: Partial<IStatisticFilter>) => {
    const oldFilter = this.state.filter;
    const filter = { ...oldFilter, ...partialFilter };

    this.props.history.replace({ search: objectToQuery(filter) });
    this.setState({ filter });
  };
}

export const StatisticPage = withRouter(StatisticPageComponent);
