import { AnyAction, Reducer } from 'redux';
import { RouterState } from 'react-router-redux';
import { communicationAuth, IAuthStoreProps } from 'entities/Auth/Auth.communication';
import { communicationAdmin } from 'entities/Admin/Admin.communication';
import { communicationTest } from 'entities/Test/Test.communication';
import { communicationPlayerTest, IPlayerTestStoreProps } from 'entities/PlayerTest/PlayerTest.communication';
import { communicationPlayer, IPlayerStoreProps } from 'entities/Player/Player.communication';
import { communicationAcademy } from 'entities/Academy/Academy.communication';
import { communicationSubscription } from 'entities/Subscription/Subscription.communication';
import { communicationGiftcard } from 'entities/Giftcard/Giftcard.communication';
import { communicationNotificationTemplate } from 'entities/NotificationTemplate/NotificationTemplate.communication';
import { communicationNotification } from 'entities/Notification/Notification.communication';
import { communicationTariff } from 'entities/Tariff/Tariff.communication';
import { communicationFranchise } from 'entities/Franchise/Franchise.communication';
import { communicationLanguage } from 'entities/Language/Language.communication';
import { communicationProduct } from 'entities/Product/Product.communication';
import { communicationFeed } from 'entities/Feed/Feed.communication';
import { communicationWallet } from 'entities/Wallet/Wallet.communication';
import { communicationInfluencer } from 'entities/Influencer/Influencer.communication';
import { communicationUI, IUIStoreProps } from 'entities/UI/UI.communication';
import { communicationSkin } from 'entities/Skin/Skin.communication';
import { communicationStatistic } from 'entities/Statistic/Statistic.communication';
import { communicationStory } from 'entities/Story/Story.communication';

type RoutingReducer = Reducer<RouterState, AnyAction>;

export interface IApplicationState {
  routing?: RoutingReducer | null;
  auth: IAuthStoreProps;
  player: IPlayerStoreProps;
  UI: IUIStoreProps;
  playerTest: IPlayerTestStoreProps;
  [key: string]: any;
}

const reducers = {
  ...communicationAuth.reducers,
  ...communicationPlayer.reducers,
  ...communicationAdmin.reducers,
  ...communicationTest.reducers,
  ...communicationPlayerTest.reducers,
  ...communicationAcademy.reducers,
  ...communicationSubscription.reducers,
  ...communicationGiftcard.reducers,
  ...communicationNotificationTemplate.reducers,
  ...communicationTariff.reducers,
  ...communicationNotification.reducers,
  ...communicationFranchise.reducers,
  ...communicationLanguage.reducers,
  ...communicationFeed.reducers,
  ...communicationProduct.reducers,
  ...communicationWallet.reducers,
  ...communicationUI.reducers,
  ...communicationSkin.reducers,
  ...communicationStatistic.reducers,
  ...communicationStory.reducers,
  ...communicationInfluencer.reducers
};

export default reducers;
