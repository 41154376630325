import moment, { Moment } from 'moment';
import { simpleDateFormat, serverDateFormat } from 'common/models/dateModels';

export const dateFormatter = (date: string, dateFormat: string = 'DD MMMM YYYY, HH:mm') => moment(date).format(dateFormat);
export const dateFormatterToUTC = (date: string, dateFormat: string = 'DD MMM YYYY, HH:mm') =>
  moment.utc(date, dateFormat).format();
export const dateDifference = (firstDate: string, secondDate: string) => moment(firstDate).diff(secondDate);

export const getUTCStartOfDayFromString = (date: string): Moment => {
  const pureDate = moment(date)
    .clone()
    .format(simpleDateFormat);

  return moment(`${pureDate}Z`, `${simpleDateFormat}Z`)
    .utc()
    .startOf('day');
};

export const formatDateFromServer = (date: string | undefined | null, format: string = simpleDateFormat) => {
  if (date) {
    return moment(date, serverDateFormat).format(format);
  }
  return moment().format(format);
};

export const getUTCStartOfDay = (day: Moment): string => {
  return day
    .clone()
    .endOf('day')
    .utc()
    .startOf('day')
    .toISOString();
};

export const getUTCEndOfDay = (day: Moment): string => {
  return day
    .clone()
    .endOf('day')
    .utc()
    .endOf('day')
    .toISOString();
};
