import React, { ReactElement } from 'react';
import { Spin } from 'antd';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { EUserRoles } from 'entities/User/User.models';

interface IComponentProps {
  adminRoutes: ReactElement;
  taggerRoutes: ReactElement;
}
type AllProps = IAuthConnectedProps & IComponentProps;

const RoleCheckLayoutComponent: React.FC<AllProps> = props => {
  const { authAdmin, adminRoutes, taggerRoutes } = props;
  const { data: authAdminData, loading: authAdminLoading } = authAdmin;
  const isTagger = authAdminData?.role === EUserRoles.DataTagger;

  return (
    <Spin spinning={authAdminLoading} size="large">
      {isTagger ? taggerRoutes : adminRoutes}
    </Spin>
  );
};

export const RoleCheckLayout = communicationAuth.injector(RoleCheckLayoutComponent);
