import axios, { AxiosStatic } from 'axios';
import { BaseHttpTransport } from '@axmit/transport';
import {
  ISkinCollection,
  ISkinCollectionFilter,
  ISkinModel,
  ISkinCreateParams,
  ISkinUpdateParams
} from 'entities/Skin/Skin.models';

const basePath = '/admin/skins';

class SkinTransport extends BaseHttpTransport<
  AxiosStatic,
  ISkinModel,
  ISkinCollection,
  ISkinCreateParams,
  ISkinUpdateParams,
  ISkinCollectionFilter
> {}

export const skinTransport = new SkinTransport(basePath, axios);
