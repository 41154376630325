import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';

interface IComponentProps {
  onChange?: (value?: boolean) => void;
  disabled?: boolean | void;
}

type AllProps = SelectProps<any> & IComponentProps;

export const AdminRegCompleteSelector = ({ value: propValue, onChange, disabled = false }: AllProps) => (
  <Select
    allowClear
    className="width-full"
    placeholder="Search by complete registration"
    value={propValue?.toString()}
    defaultActiveFirstOption={false}
    showArrow
    onChange={(value: string) => onChange && onChange(value ? value === 'true' : undefined)}
    disabled={disabled}
  >
    <Select.Option value="true" label="Complete">
      Complete
    </Select.Option>
    <Select.Option value="false" label="Incomplete">
      Incomplete
    </Select.Option>
  </Select>
);
