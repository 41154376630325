import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Avatar,
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Popconfirm,
  Row,
  Select,
  Skeleton,
  Switch
} from 'antd';
import { CameraOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router';
import moment, { Moment } from 'moment';
import { Link } from 'react-router-dom';
import { useFormMapper } from '@axmit/antd4-helpers';
import { copyToBuffer } from 'common/helpers/buffer.helper';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize, HOST } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { simpleDateFormat, simpleDateFormatWithTime } from 'common/models/dateModels';
import { ImageUpload } from 'common/components/ImageUpload/ImageUpload';
import { LocationInput } from 'common/components/Input/LocationInput';
import { gendersMap, ISelectMapItem } from 'common/models/selectModels';
import { ELocationType } from 'common/helpers/location.helper';
import { formatDateFromServer, getUTCEndOfDay, getUTCStartOfDay, getUTCStartOfDayFromString } from 'common/helpers/date.helper';
import { Phone } from 'common/components/Phone/Phone';
import { phoneRule } from 'common/helpers/rules.helper';
import { getImageLink } from 'common/helpers/loader.helper';
import { communicationPlayer, IPlayerConnectedProps } from 'entities/Player/Player.communication';
import { EPlayerAgeGroupToText, EPlayerStatuses, IPlayerUpdateParams, PLAYER_TEST_LABELS } from 'entities/Player/Player.models';
import { EImageSize } from 'entities/Image/Image.models';
import { EPaymentActivitiesStatus, ESubscriptionStatus } from 'entities/Subscription/Subscription.models';
import { PlayerVideosList } from 'entities/Player/components/PlayerVideos/PlayerVideosList';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';
import { LanguageSelect } from 'entities/Language/components/Selectors/LanguageSelect';
import { playerTransport } from 'entities/Player/Player.transport';
import { AcademySelector } from 'entities/Academy/components/Selector/AcademySelector';
import { EAcademyStatus, IAcademyModel } from 'entities/Academy/Academy.models';
import { MentorSelector } from 'entities/User/components/MentorSelector';
import { IUserModel } from 'entities/User/User.models';

type AllProps = IPlayerConnectedProps & IUIConnectedProps;

const avatarSize = 88;
const { RangePicker } = DatePicker;

const map2Options = ({ title, value }: ISelectMapItem<string, string>) => (
  <Select.Option key={value} value={value} title={title}>
    {title}
  </Select.Option>
);

const PlayerPageComponent: React.FC<AllProps> = props => {
  const {
    playerModel,
    getPlayerModel,
    updatePlayerModel,
    changeMentorPlayerModel,
    changeAcademyPlayerModel,
    clearPlayerModel,
    playerTestStatisticModel,
    getPlayerTestStatisticModel,
    clearPlayerTestStatisticModel,
    openUIWalletModal,
    deletePlayerModel,
    updateStatusPlayerModel
  } = props;
  const { data, loading, params, errors } = playerModel;
  const { data: statisticData, loading: statisticLoading } = playerTestStatisticModel;
  const [mentorAvatarSrc, setMentorAvatarSrc] = useState<string>('');
  const { id } = useParams();
  const history = useHistory();
  const { coinsBalance } = data?.wallet || {};
  const academy = data?.academyLink?.academy;
  const status = data?.status;
  const buttonStatusText = `${status === EPlayerStatuses.Active ? 'Ban' : 'Unban'} player`;
  const mentorRequest = data?.mentorRequest;
  const isJuniCoachModule = data?.juniCoachModule;
  const subscription = mentorRequest?.subscription;
  const lastActivity = subscription?.lastActivity;
  const playerSkills = data?.playerSkills;
  const tariff = subscription?.tariff;
  const tariffAmount = subscription?.tariffAmount;
  const [playerAcademy, setPlayerAcademy] = useState<IAcademyModel | undefined | null>(academy);
  const [mentor, setMentor] = useState<IUserModel | undefined>(mentorRequest?.mentor);
  const playerName = data?.firstName && data?.lastName ? `${data?.firstName} ${data?.lastName}` : 'Unknown name';
  const mentorName =
    mentorRequest?.mentor?.firstName && mentorRequest?.mentor?.lastName
      ? `${mentorRequest?.mentor?.firstName} ${mentorRequest?.mentor?.lastName}`
      : 'Unknown name';
  const redirectUrl = `${history?.location?.pathname}${history?.location?.search}`;
  const disabled = useMemo(() => academy?.id === playerAcademy?.id, [playerAcademy, academy]);
  const isSubscriptionActive = useMemo(() => subscription && subscription.status === ESubscriptionStatus.Active, [subscription]);

  const isDelayedCancellation = useMemo(() => subscription && subscription.delayedCancellation, [subscription]);

  const isCheckingLastPayment = useMemo(() => {
    if (subscription && subscription.lastActivity) {
      return (
        subscription.lastActivity.status === EPaymentActivitiesStatus.New || subscription.lastActivity.isApplied === undefined
      );
    }

    return false;
  }, [subscription]);

  const subscriptionStatus = useMemo(() => {
    let statusText = 'No subscription';

    switch (true) {
      case isSubscriptionActive:
        statusText = 'Active';
        break;
      case isCheckingLastPayment:
        statusText = 'Checking last payment';
        break;
      case subscription?.status === ESubscriptionStatus.Canceled:
        statusText = 'Canceled';
        break;
      case subscription?.status === ESubscriptionStatus.New && !subscription?.prepaidTo:
        statusText = 'New';
        break;
      case subscription?.status === ESubscriptionStatus.Expired:
        statusText = 'Expired';
        break;
    }
    return `${statusText}${isDelayedCancellation ? '(in delayed cancellation)' : ''}`;
  }, [isSubscriptionActive, subscription, isCheckingLastPayment, isDelayedCancellation]);

  const paidTill = useMemo(() => formatDateFromServer(subscription?.prepaidTo, simpleDateFormat), [subscription]);

  const [form] = Form.useForm();

  const memoizedValue = useMemo(
    () => ({
      ...data,
      image: data?.image?.id,
      birthday: data?.birthday ? moment(data.birthday) : undefined
    }),
    [data]
  );

  useEffect(() => {
    if (id) {
      getPlayerModel(id);
      getPlayerTestStatisticModel({ id });
    }
    return () => {
      clearPlayerModel();
      clearPlayerTestStatisticModel();
    };
  }, []); // eslint-disable-line
  useEffect(() => {
    setPlayerAcademy(academy);
  }, [academy]);

  useEffect(() => {
    if (mentorRequest?.mentor?.image?.id) {
      getImageLink(mentorRequest.mentor.image.id, EImageSize.X96).then(url => setMentorAvatarSrc(url));
    } else {
      setMentorAvatarSrc('');
    }
  }, [data, mentorRequest]);

  const onChangeStatisticDate = (dates: [Moment | null, Moment | null] | null) => {
    const [from, to] = dates || [];
    getPlayerTestStatisticModel({
      id,
      from: from ? getUTCStartOfDay(from) : undefined,
      to: to ? getUTCEndOfDay(to) : undefined
    });
  };
  const onChangeStatus = () => {
    const statusValue = status === EPlayerStatuses.Active ? EPlayerStatuses.Banned : EPlayerStatuses.Active;
    if (id) {
      updateStatusPlayerModel({ id, status: statusValue });
    }
  };

  const deletePlayer = () => {
    if (id) {
      deletePlayerModel(id);
    }
  };

  const { fields } = useFormMapper(
    [
      'firstName',
      'lastName',
      'address',
      'gender',
      'birthday',
      // 'ageGroup',
      'image',
      'phone',
      'leadingLeg',
      'receiveNotifications',
      'isPaid',
      'externalId',
      'email',
      'lang'
    ],
    memoizedValue,
    params,
    errors
  );

  const changePlayerAcademy = () => {
    if (id) {
      changeAcademyPlayerModel({ id, academy: playerAcademy?.id });
    }
  };

  const editPlayer = (values: any) => {
    const { birthday, phone, ...body } = values;

    if (id) {
      const params: IPlayerUpdateParams = {
        id: id,
        phone: phone || null,
        ...body
      };

      if (birthday) {
        params.birthday = getUTCStartOfDayFromString(birthday);
      }

      updatePlayerModel(params);
    }
  };

  const onChangeMentor = useCallback(
    (e?: any) => {
      e?.stopPropagation();
      if (mentor && id) {
        changeMentorPlayerModel({ id, mentor: mentor?.id });
      }
    },
    [mentor, id, changeMentorPlayerModel]
  );
  const onCopy = useCallback(
    async (e?: any) => {
      e?.stopPropagation();
      if (id) {
        let link = `${HOST}/player/${id}`;
        if (data?.mentorRequest?.status !== 'accepted' && data?.juniCoachModule) {
          const { token } = await playerTransport.createShareToken(id);
          link = `${HOST}/player/${id}?token=${token}`;
        }
        try {
          await copyToBuffer(link);
          message.success('Link has been copied');
        } catch (e) {
          console.log(e);
        }
      }
    },
    [id, data]
  );

  const visibleStatus = useMemo(() => {
    let statusText = '-';
    const { juniStatPublicity, juniStatVisibility } = data || {};

    switch (true) {
      case juniStatPublicity === false && juniStatVisibility === 'private':
        statusText = 'Никто (Профиль игрока полностью скрыт и не выдается в публичных списках игроков)';
        break;
      case juniStatPublicity && juniStatVisibility === 'private':
        statusText = 'Все в анонимизированном виде (Данные игрока и контакты представителя не видит никто)';
        break;
      case juniStatPublicity && juniStatVisibility === 'protected':
        statusText = 'В которые отправлены заявки (Данные игрока и контакты представителя видят только выбранные академии)';
        break;
      case juniStatPublicity && juniStatVisibility === 'public':
        statusText = 'Все академии (Данные игрока и контакты представителя видят все академии)';
        break;
    }
    return statusText;
  }, [data]);

  return (
    <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10 pb-10">
      <Col span={12}>
        <Breadcrumb className="fs-sm font-weight-5 font-family__default mb-8">
          <Breadcrumb.Item href={ERoutesPrivate.Players}>Players</Breadcrumb.Item>
          <Breadcrumb.Item>
            {!loading ? playerName : <Skeleton.Input active style={{ width: '200px' }} size="small" />}
          </Breadcrumb.Item>
        </Breadcrumb>
      </Col>
      <Row justify="space-between" className="mb-5" gutter={[16, 16]}>
        <Col className="mb-5" span={11}>
          <Row gutter={[16, 8]}>
            <Col span={24}>
              <span className="basic__text_title d-block" title="Player">
                Player
              </span>
            </Col>
            <Col>
              <span className="basic__text_label mr-5" title="Status">
                Status:
              </span>
              <span className="basic__text_default" title={data?.status}>
                {data?.status}
              </span>
            </Col>
            <Col>
              <span className="basic__text_label mr-5" title="Registration date">
                Registration date:
              </span>
              <span className="basic__text_default" title="Registration date">
                {data?.createdAt ? moment(data.createdAt).format(simpleDateFormatWithTime) : '-'}
              </span>
            </Col>
            <Col>
              <span className="basic__text_label mr-5" title="Age group">
                Age group:
              </span>
              <span className="basic__text_default" title="Age group">
                {data?.ageGroup && EPlayerAgeGroupToText[data.ageGroup]}
              </span>
            </Col>
            <Col>
              <span className="basic__text_label mr-5" title="Phone number">
                Phone number:
              </span>
              <span className="basic__text_default" title="Phone number">
                {data?.phone || 'Unknown'}
              </span>
            </Col>
            <Col>
              <span className="basic__text_label mr-5" title="Phone model">
                Phone model:
              </span>
              <span className="basic__text_default" title="Phone model">
                {data?.devices?.join(', ') || 'Unknown'}
              </span>
            </Col>
            <Col>
              <span className="basic__text_label mr-5" title="Subscription status">
                Subscription status:
              </span>
              <span className="basic__text_default" title={subscriptionStatus}>
                {subscriptionStatus}
              </span>
            </Col>
            <Col span={24}>
              <span className="basic__text_label mr-5" title="Coins balance">
                Coins balance: {coinsBalance}
              </span>
            </Col>
            <Col>
              <span className="basic__text_title mr-5" title="Visibility">
                Visibility:
              </span>
              <span className="basic__text_default" title={data?.visibility}>
                {data?.visibility}
              </span>
            </Col>
            <Col span={24}>
              <Row className="mb-5 width-full" justify="space-between">
                <Col span={12}>
                  <span className="basic__text_title" title="JuniStat">
                    Mentor
                  </span>
                  <Col>
                    <span className="basic__text_label mr-5" title="Visibility">
                      Visibility:
                    </span>
                    <span className="basic__text_default">{visibleStatus}</span>
                  </Col>
                </Col>
                {data?.juniCoachPublicity !== undefined && (
                  <Col span={12}>
                    <span className="basic__text_title" title="JuniCoach">
                      Academy
                    </span>
                    <Col>
                      <span className="basic__text_label mr-5" title="Visibility">
                        Player hidden:
                      </span>
                      <span className="basic__text_default">{`${!data?.juniCoachPublicity}`}</span>
                    </Col>
                  </Col>
                )}
              </Row>
            </Col>
            {!!data && (
              <Col className="mb-3">
                <Button
                  block
                  type="primary"
                  title="Create transaction"
                  onClick={() => openUIWalletModal({ redirectUrl, player: data })}
                >
                  Create transaction
                </Button>
              </Col>
            )}
            {subscription?.prepaidTo && (
              <Col>
                <span className="basic__text_label mr-5" title="Paid till">
                  Paid till:
                </span>
                <span className="basic__text_default" title={paidTill}>
                  {paidTill}
                </span>
              </Col>
            )}
            {academy && (
              <Col className="width-full mb-3">
                <span className="basic__text_label mr-5">Academy:</span>
                <Link to={`${ERoutesPrivate.Academy}/${academy.id}`} target="_blank">
                  <span className="basic__text_default">{academy.name}</span>
                </Link>
              </Col>
            )}
            <Col span={24} className="mt-3">
              <Row>
                <Col span={11} className="mr-5">
                  <AcademySelector
                    onChange={setPlayerAcademy}
                    value={playerAcademy}
                    placeholder="Select academy"
                    filter={{ status: EAcademyStatus.Active }}
                  />
                </Col>
                <Col span={11}>
                  <Button
                    block
                    type="primary"
                    title="Change academy"
                    onClick={changePlayerAcademy}
                    disabled={disabled || loading}
                  >
                    Change academy
                  </Button>
                </Col>
              </Row>
            </Col>
            <Form form={form} className="mb-5" onFinish={editPlayer} fields={fields}>
              <Col span={24}>
                <Form.Item name="image" label="Avatar">
                  <ImageUpload />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name="firstName" label="First name">
                  <Input />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name="lastName" label="Last name">
                  <Input />
                </Form.Item>
              </Col>

              <Col>
                <Form.Item
                  rules={[
                    {
                      min: 2,
                      message: 'External Id must be longer than or equal 2 characters'
                    },
                    {
                      max: 128,
                      message: 'External Id must be shorter than or equal 128 characters'
                    }
                  ]}
                  name="externalId"
                  label="External Id"
                >
                  <Input name="externalId" type="text" placeholder="External Id" disabled={loading} />
                </Form.Item>
              </Col>

              <Col>
                <Form.Item name="birthday" label="Birthday">
                  <DatePicker />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name="address" label="Address">
                  <LocationInput types={[ELocationType.Cities]} />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item rules={[phoneRule]} name="phone" label="Phone">
                  <Phone size="middle" disabled={loading} />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name="email" label="Email">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name="gender" label="Gender">
                  <Select>{gendersMap.map(map2Options)}</Select>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name="leadingLeg" label="Leading leg">
                  <Select>
                    {['right', 'left'].map(leg => (
                      <Select.Option key={leg} value={leg} title={leg}>
                        {leg}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name="lang" label="Lang">
                  <LanguageSelect />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item valuePropName="checked" name="receiveNotifications" label="Receive notifications">
                  <Switch />
                </Form.Item>
              </Col>
              {isJuniCoachModule && academy && (
                <Col>
                  <Form.Item name="isPaid" label="Is paid" valuePropName="checked">
                    <Checkbox>Is paid</Checkbox>
                  </Form.Item>
                </Col>
              )}
              <Col span={24}>
                <Form.Item className="mb-0">
                  <Popconfirm title="Are you sure?" onConfirm={() => form.submit()} okText="Yes" cancelText="No">
                    <Button className="mr-8" htmlType="submit" type="primary" disabled={loading} loading={loading} title="Save">
                      Save
                    </Button>
                  </Popconfirm>
                  <Link to={ERoutesPrivate.Players}>
                    <Button disabled={loading} loading={loading} title="Cancel">
                      Cancel
                    </Button>
                  </Link>
                  {!!id && (
                    <Button className="ml-4" type="primary" title="Share" onClick={onCopy}>
                      Share
                    </Button>
                  )}
                </Form.Item>
              </Col>
              <Col span={24} className="mt-3">
                <Popconfirm title="Are you sure?" onConfirm={onChangeStatus} okText="Yes" cancelText="No">
                  <Button className="mr-8" type="primary" disabled={loading} loading={loading} title={buttonStatusText}>
                    {buttonStatusText}
                  </Button>
                </Popconfirm>
                <Popconfirm title="Are you sure?" onConfirm={deletePlayer} okText="Yes" cancelText="No">
                  <Button className="mr-8" danger disabled={loading} loading={loading} title="Delete player">
                    Delete player
                  </Button>
                </Popconfirm>
              </Col>
            </Form>
          </Row>
        </Col>
        <Col span={11}>
          <span className="basic__text_title mb-5 d-block" title="Mentor">
            Mentor
          </span>
          {mentorRequest ? (
            <>
              <Row>
                <Col className="mr-3">
                  <Avatar size={avatarSize} icon={<CameraOutlined />} src={mentorAvatarSrc} />
                </Col>
                <Col>
                  <Row align="middle" className="mb-5">
                    <span className="basic__text_label mr-5" title="Name">
                      Name:
                    </span>
                    <span className="basic__text_default" title={mentorName}>
                      {mentorName}
                    </span>
                  </Row>
                  <Row align="middle" className="mb-5">
                    <span className="basic__text_label mr-5" title="Email">
                      Email:
                    </span>
                    <span className="basic__text_default" title={mentorRequest?.mentor?.email}>
                      {mentorRequest?.mentor?.email}
                    </span>
                  </Row>
                </Col>
              </Row>
              <Row align="middle" className="mb-5">
                <span className="basic__text_label mr-5" title="Representation type">
                  Representation type:
                </span>
                <span className="basic__text_default" title={mentorRequest?.mentor?.representationType}>
                  {mentorRequest?.mentor?.representationType}
                </span>
              </Row>
              <Row align="middle" className="mb-5">
                <span className="basic__text_label mr-5" title="Phone">
                  Phone:
                </span>
                <span className="basic__text_default" title={mentorRequest?.mentor?.phone}>
                  {mentorRequest?.mentor?.phone}
                </span>
              </Row>
              <Row align="middle" className="mb-5">
                <span className="basic__text_label mr-5" title="Status">
                  Status:
                </span>
                <span className="basic__text_default" title={mentorRequest?.mentor?.status}>
                  {mentorRequest?.mentor?.status}
                </span>
              </Row>
            </>
          ) : (
            <span className="basic__text_label" title="The player has no mentor">
              The player has no mentor
            </span>
          )}
          <span className="basic__text_title mb-5 d-block" title="Mentor">
            Change mentor
          </span>
          <Row className="mb-5" align="middle" justify="space-between">
            <Col span={11}>
              <MentorSelector value={mentor} onChange={setMentor} />
            </Col>
            <Col span={11}>
              <Button block type="primary" title="Change" onClick={onChangeMentor} disabled={loading || !mentor}>
                Change
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="mb-5" align="middle">
        <span className="basic__text_title mr-5 d-block" title="Player rating">
          Player rating:
        </span>
        <span className="basic__text_title">{data?.rating || 0}</span>
      </Row>
      <Col className="mb-5">
        <Row className="mb-3" align="middle">
          <span className="basic__text_title mr-5 d-block" title="Player statistic">
            Player tests statistic:
          </span>
        </Row>
        <RangePicker className="mb-3" allowEmpty={[true, true]} format={simpleDateFormat} onChange={onChangeStatisticDate} />
        <Row className="mb-3">
          <span className="basic__text_label mr-5" title="Total tests count">
            Total tests count:
          </span>
          <span className="basic__text_default">
            {statisticLoading ? <Skeleton.Input active style={{ width: '50px' }} size="small" /> : statisticData?.testTotalCount}
          </span>
        </Row>
        <Row className="mb-3">
          <span className="basic__text_label mr-5" title="Accepted tests count">
            Accepted tests count:
          </span>
          <span className="basic__text_default">
            {statisticLoading ? <Skeleton.Input active style={{ width: '50px' }} size="small" /> : statisticData?.testAcceptCount}
          </span>
        </Row>
        <Row className="mb-3">
          <span className="basic__text_label mr-5" title="Failed tests count">
            Failed tests count:
          </span>
          <span className="basic__text_default">
            {statisticLoading ? <Skeleton.Input active style={{ width: '50px' }} size="small" /> : statisticData?.testRejectCount}
          </span>
        </Row>
      </Col>
      <Row justify="space-between">
        <Col span={11}>
          <span className="basic__text_title mb-5 d-block" title="Player skills">
            Player skills
          </span>
          {playerSkills?.length ? (
            playerSkills?.map(playerSkill => (
              <Row align="middle" className="mb-5" key={playerSkill?.id}>
                <span className="basic__text_label mr-5" title={playerSkill?.skill?.name}>
                  {PLAYER_TEST_LABELS[playerSkill?.skill?.name as string] ?? playerSkill?.skill?.name}
                </span>
                <span className="basic__text_default">{playerSkill?.value}</span>
              </Row>
            ))
          ) : (
            <span className="basic__text_label" title="The player has no skills">
              The player has no skills
            </span>
          )}
        </Col>
        <Col span={11}>
          <span className="basic__text_title mb-5 d-block" title="Subscription">
            Subscription
          </span>
          {subscription ? (
            <>
              <Row align="middle" className="mb-5">
                <span className="basic__text_label mr-5" title="Status">
                  Status:
                </span>
                <span className="basic__text_default" title={subscription?.status}>
                  {subscription?.status}
                </span>
              </Row>
              <Row align="middle" className="mb-5">
                <span className="basic__text_label mr-5" title="Id">
                  Id:
                </span>
                <span className="basic__text_default" title={subscription?.id}>
                  {subscription?.id}
                </span>
              </Row>
              <Row align="middle" className="mb-5">
                <span className="basic__text_label mr-5" title="Has delayed cancellation">
                  Has delayed cancellation:
                </span>
                <span className="basic__text_default" title="Has delayed cancellation">
                  {subscription?.delayedCancellation ? 'Yes' : 'No'}
                </span>
              </Row>
              <Row align="middle" className="mb-5">
                <span className="basic__text_label mr-5" title="Prepaid to">
                  Prepaid to:
                </span>
                <span className="basic__text_default" title="Prepaid to">
                  {subscription?.prepaidTo ? moment(subscription?.prepaidTo).format(simpleDateFormat) : 'Unknown'}
                </span>
              </Row>
            </>
          ) : (
            <span className="basic__text_label" title="The player has no subscription">
              The player has no subscription
            </span>
          )}
        </Col>
      </Row>
      <Row gutter={[16, 0]} className="mb-5 width-full" justify="space-between">
        <Col span={12}>
          <span className="basic__text_title mb-5 d-block" title="Subscription">
            Last payment
          </span>
          {lastActivity ? (
            <>
              <Row align="middle" className="mb-5">
                <span className="basic__text_label mr-5" title="Status">
                  Status:
                </span>
                <span className="basic__text_default" title={lastActivity?.status}>
                  {lastActivity?.status}
                </span>
              </Row>
              <Row align="middle" className="mb-5">
                <span className="basic__text_label mr-5" title="Id">
                  Id:
                </span>
                <span className="basic__text_default" title={lastActivity?.id}>
                  {lastActivity?.id}
                </span>
              </Row>
              <Row align="middle" className="mb-5">
                <span className="basic__text_label mr-5" title="Type">
                  Type:
                </span>
                <span className="basic__text_default" title={lastActivity?.type}>
                  {lastActivity?.type}
                </span>
              </Row>
              <Row align="middle" className="mb-5">
                <span className="basic__text_label mr-5" title="Amount">
                  Amount:
                </span>
                <span className="basic__text_default" title={`${lastActivity?.amountValue} ${lastActivity?.amountCurrency}`}>
                  {lastActivity?.amountValue ? `${lastActivity?.amountValue} ${lastActivity?.amountCurrency}` : 'Not paid'}
                </span>
              </Row>
              <Row align="middle" className="mb-5">
                <span className="basic__text_label mr-5" title="Payed at">
                  Payed at:
                </span>
                <span className="basic__text_default" title="Payed at">
                  {lastActivity?.payedAt ? moment(lastActivity?.payedAt).format(simpleDateFormat) : 'Unknown'}
                </span>
              </Row>
            </>
          ) : (
            <span className="basic__text_label" title="There is no last payment">
              There is no last payment
            </span>
          )}
        </Col>
        <Col span={12}>
          <span className="basic__text_title mb-5 d-block" title="Subscription">
            Tariff
          </span>
          {tariff ? (
            <>
              <Row align="middle" className="mb-5">
                <span className="basic__text_label mr-5" title="Amount">
                  Amount:
                </span>
                <span className="basic__text_default" title={`${tariffAmount?.amount} ${tariffAmount?.currency}`}>
                  {`${tariffAmount?.amount} ${tariffAmount?.currency}`}
                </span>
              </Row>
              <Row align="middle" className="mb-5">
                <span className="basic__text_label mr-5" title="Id">
                  Id:
                </span>
                <span className="basic__text_default" title={tariff?.id}>
                  {tariff?.id}
                </span>
              </Row>
              <Row align="middle" className="mb-5">
                <span className="basic__text_label mr-5" title="Type">
                  Type:
                </span>
                <span className="basic__text_default" title={tariff?.type}>
                  {tariff?.type}
                </span>
              </Row>
              <Row align="middle" className="mb-5">
                <span className="basic__text_label mr-5" title="Localization tag">
                  Localization tag:
                </span>
                <span className="basic__text_default" title={tariff?.localizationTag}>
                  {tariff?.localizationTag}
                </span>
              </Row>
            </>
          ) : (
            <span className="basic__text_label" title="There is no last payment">
              There is no last payment
            </span>
          )}
        </Col>
      </Row>
      <Col span={12}>
        <Row>
          <span className="basic__text_title mb-5 d-block" title="Subscription">
            Videos
          </span>
        </Row>
        <Row>
          <PlayerVideosList elementId="basicLayout" playerId={id} />
        </Row>
      </Col>
    </CommonLayout>
  );
};

export const PlayerPage = communicationUI.injector(communicationPlayer.injector(PlayerPageComponent));
