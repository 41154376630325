import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { EAdminRole, EAdminRoleText } from 'entities/Admin/Admin.models';

interface IComponentProps {
  onChange?: (value?: EAdminRole) => void;
  disabled?: boolean;
}

type AllProps = SelectProps<any> & IComponentProps;

export class AdminRoleSelector extends React.PureComponent<AllProps> {
  render() {
    const { value, onChange, disabled = false, allowClear } = this.props;

    return (
      <Select
        allowClear={allowClear}
        className="width-full"
        placeholder="Select role"
        value={value}
        defaultActiveFirstOption={false}
        showArrow
        onChange={(value: EAdminRole) => onChange && onChange(value)}
        disabled={disabled}
      >
        <Select.Option key={EAdminRole.Admin} value={EAdminRole.Admin} label={EAdminRoleText.Admin}>
          {EAdminRoleText.Admin}
        </Select.Option>
        <Select.Option key={EAdminRole.Tagger} value={EAdminRole.Tagger} label={EAdminRoleText.Tagger}>
          {EAdminRoleText.Tagger}
        </Select.Option>
        <Select.Option key={EAdminRole.Trainer} value={EAdminRole.Trainer} label={EAdminRoleText.Trainer}>
          {EAdminRoleText.Trainer}
        </Select.Option>
        <Select.Option key={EAdminRole.AcademyWorker} value={EAdminRole.AcademyWorker} label={EAdminRoleText.AcademyWorker}>
          {EAdminRoleText.AcademyWorker}
        </Select.Option>
      </Select>
    );
  }
}
