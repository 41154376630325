import React from 'react';
import { configureAxiosJWTInterseptors } from 'axios-patch-jwt';
import 'jsreap';
import axios from 'axios';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { ConnectedRouter } from 'connected-react-router';
import { IAsyncStorage, WebStorageDecorator } from 'universal-storage';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { errorMapper } from 'fe-error-helper';
import { envConfig } from 'common/config/env.config';
import createStore from 'app/store/createStore';
import 'app/assets/sass/index.scss';
import 'antd/dist/antd.less';
import { App } from 'app/App';

// Sentry initialization
Sentry.init({
  dsn: envConfig.REACT_APP_SENTRY_DSN,
  environment: envConfig.NODE_ENV
});

// Axios initialization

const storage: IAsyncStorage = new WebStorageDecorator(localStorage);

export const axiosConfig = {
  storage,
  axios,
  refreshTokenEndpoint: '/auth'
};

configureAxiosJWTInterseptors(axiosConfig);

axios.defaults.baseURL = `/api`;
axios.interceptors.request.use(config => ({
  ...config,
  headers: { 'Source-Service': 'admin', ...config.headers }
}));
axios.interceptors.response.use(
  response => response.data,
  error => errorMapper(error)
);
// Render Setup

const MOUNT_NODE = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const initialState = (window as any).___INITIAL_STATE__;
const history = createBrowserHistory();
export const store = createStore(initialState, history);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  MOUNT_NODE
);
