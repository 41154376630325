import { AbstractSearchableSelector } from 'common/components/Selector/AbstractSearchableSelector';
import { academyTransport } from 'entities/Academy/Academy.transport';
import { IAcademyModel, IAcademyCollectionFilter } from 'entities/Academy/Academy.models';

interface IComponentProps {
  filter?: IAcademyCollectionFilter;
}

export class AcademySelector extends AbstractSearchableSelector<IAcademyModel, IComponentProps> {
  getItemLabel = (item: IAcademyModel): string => {
    return item.name || item.id;
  };

  loadData = (value?: string): Promise<{ data: IAcademyModel[] }> => {
    const { filter } = this.props;
    const filterData: IAcademyCollectionFilter = { ...filter };
    if (value) {
      filterData.name = value;
    }
    return academyTransport.getAcademySearch(filterData);
  };
}
