import window from 'global/window';

export function queryToObject<State extends {}>(keysOfProps: State): {} {
  const location = window.location;

  if (!location) {
    return {};
  }

  const acc: { [index: string]: any } = {};
  // @ts-ignore
  for (const [key, value] of new window.URL(location).searchParams) {
    const clearedKey = key.replace('[]', '');
    const isValArray = key !== clearedKey;
    const keyOfPropsArray = Object.keys(keysOfProps);

    if (keyOfPropsArray.includes(clearedKey)) {
      let val = value;
      try {
        val = JSON.parse(value);
        // eslint-disable-next-line no-empty
      } catch (e) {}

      if (isValArray) {
        if (!acc[clearedKey]) {
          acc[clearedKey] = [val];
        }
        const exist = acc[clearedKey].find((el: any) => (el.id ? el.id === val.id : el === val));
        if (!exist) {
          acc[clearedKey].push(val);
        }
      } else {
        acc[clearedKey] = val;
      }
    }
  }
  return acc;
}

export function objectToQuery(obj: { [index: string]: any } = {}): string {
  return obj && typeof obj === 'object'
    ? `?${Object.keys(obj)
        .filter((k: any) => obj[k] !== undefined)
        .map(k =>
          Array.isArray(obj[k])
            ? obj[k]
                .filter((item: any) => item !== undefined)
                .map(
                  (item: any) =>
                    `${encodeURIComponent(k)}[]=${
                      typeof item === 'object' ? encodeURIComponent(JSON.stringify(item)) : encodeURIComponent(item)
                    }`
                )
                .join('&')
            : `${encodeURIComponent(k)}=${
                typeof obj[k] === 'object' ? encodeURIComponent(JSON.stringify(obj[k])) : encodeURIComponent(obj[k])
              }`
        )
        .join('&')}`
    : !obj
    ? ''
    : obj;
}

export function clearFromUndefinedFields(obj?: { [key: string]: any }) {
  return Object.keys(obj || {}).reduce((acc: { [key: string]: any }, key) => {
    if (obj && obj[key] !== undefined) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
}
