import { AbstractSearchableSelector } from 'common/components/Selector/AbstractSearchableSelector';
import { IGiftcardCollectionFilter, IGiftcardModel } from 'entities/Giftcard/Giftcard.models';
import { giftcardTransport } from 'entities/Giftcard/Giftcard.transport';

interface IComponentProps {}

export class GiftcardSelector extends AbstractSearchableSelector<IGiftcardModel, IComponentProps> {
  getItemLabel = (item: IGiftcardModel): string => {
    return item.number;
  };

  loadData = (value?: string): Promise<{ data: IGiftcardModel[] }> => {
    const filter: IGiftcardCollectionFilter = {};
    if (value) {
      filter.number = value;
    }
    return giftcardTransport.getCollection(filter);
  };
}
