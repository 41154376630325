import React, { useEffect } from 'react';
import { Breadcrumb, Col } from 'antd';
import { useParams } from 'react-router';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import {
  communicationNotificationTemplate,
  INotificationTemplateConnectedProps
} from 'entities/NotificationTemplate/NotificationTemplate.communication';
import { NotificationTemplateForm } from 'entities/NotificationTemplate/components/NotificationTemplateForm';

type AllProps = INotificationTemplateConnectedProps;

const NotificationTemplatesPageComponent: React.FC<AllProps> = props => {
  const {
    notificationTemplateModel,
    getNotificationTemplateModel,
    clearNotificationTemplateModel,
    updateNotificationTemplateModel
  } = props;
  const { data, loading } = notificationTemplateModel;
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      getNotificationTemplateModel(id);
    }
    return () => {
      clearNotificationTemplateModel();
    };
  }, [id, getNotificationTemplateModel, clearNotificationTemplateModel]);

  return (
    <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10 pb-10">
      <Col span={12}>
        <Breadcrumb className="fs-sm font-weight-5 font-family__default mb-8">
          <Breadcrumb.Item href={ERoutesPrivate.NotificationTemplates}>Notification template</Breadcrumb.Item>
          <Breadcrumb.Item>№ {data?.id}</Breadcrumb.Item>
        </Breadcrumb>
        {!loading && (
          <NotificationTemplateForm model={notificationTemplateModel} id={id} onSubmit={updateNotificationTemplateModel} />
        )}
      </Col>
    </CommonLayout>
  );
};

export const NotificationTemplatePage = communicationNotificationTemplate.injector(NotificationTemplatesPageComponent);
