import React from 'react';
import List from 'antd/es/list';
import Table from 'antd/es/table';
import InfiniteScroll from 'react-infinite-scroller';
import { StoreBranch } from '@axmit/redux-communications';
import { IBaseFilterModel } from '@axmit/client-models';
import { LoadingSpin } from 'common/components/LoadingSpin';
import { ItemsNotFound } from 'common/components/Text/ItemsNotFound';
import { clearFromUndefinedFields } from 'common/helpers/filters.helper';
import { Queue } from 'common/helpers/queue.helper';

interface IComponentProps<Filter = undefined> {
  elementId: string;
  bordered?: boolean;
  filter?: Filter;
  pageSize?: number;
  isReverse?: boolean;
  threshold?: number;
  isTable?: boolean;
  columns?: object[];
  className?: string;
  grid?: object;
  hasPlaceholder?: boolean;
}

interface IComponentState {
  pageIndex: number;
}

abstract class InfiniteListComponent<
  Collection extends { data: Model[]; meta: { count?: number } },
  Model extends object,
  IProps,
  Filter extends any = undefined,
  Params = any
> extends React.PureComponent<IComponentProps<Filter> & IProps, IComponentState> {
  mounted = true;
  queue = new Queue();

  constructor(props: IComponentProps<Filter> & IProps) {
    super(props);
    this.state = { pageIndex: 0 };
  }

  componentDidMount(): void {
    this.addLoadRequestToQueue();
  }

  componentWillUnmount(): void {
    this.mounted = false;
    this.queue.clear();
    this.clearCollection();
  }

  componentDidUpdate(prevProps: Readonly<IComponentProps<Filter> & IProps>) {
    const prevFilter = clearFromUndefinedFields(prevProps.filter);
    const nextFilter = clearFromUndefinedFields(this.props.filter);

    if (JSON.stringify(prevFilter) !== JSON.stringify(nextFilter)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.mounted && this.setState({ pageIndex: 0 }, this.addLoadRequestToQueue);
    }
  }

  render() {
    const {
      elementId,
      isReverse,
      threshold = 250,
      isTable = false,
      columns,
      grid,
      bordered = true,
      className = '',
      hasPlaceholder
    } = this.props;
    const collection = this.getCollection();
    const { data: collectionData, loading } = collection;
    const items = collectionData?.data || [];
    const count = collectionData?.meta?.count || 0;
    const hasMore = !loading && items.length < count;

    return (
      <InfiniteScroll
        className="width-full"
        pageStart={1}
        loadMore={this.addLoadRequestToQueue}
        hasMore={hasMore}
        useWindow={false}
        isReverse={isReverse}
        threshold={threshold}
        getScrollParent={() => document.getElementById(elementId)}
      >
        {loading && isReverse && <LoadingSpin />}
        {isTable && columns ? (
          <Table dataSource={items} columns={columns} pagination={false} bordered={bordered} loading={loading} />
        ) : (
          (collectionData || hasPlaceholder || !loading) && (
            <List
              split={bordered}
              itemLayout="vertical"
              className={className}
              dataSource={items}
              grid={grid}
              renderItem={this.renderListItem}
              locale={{ emptyText: loading && !hasPlaceholder ? <div /> : this.renderNoData() || <ItemsNotFound /> }}
            />
          )
        )}
        {loading && !isReverse && !isTable && <LoadingSpin />}
      </InfiniteScroll>
    );
  }

  addLoadRequestToQueue = () => {
    const { pageIndex } = this.state;
    if (!pageIndex) {
      this.queue.clear();
    }
    this.queue.enqueue(() => this.loadData(pageIndex));
  };

  loadData = async (pageIndex: number) => {
    const collection = this.getCollection();
    const { pageSize = 20 } = this.props;

    const { data: collectionData } = collection;
    const count = collectionData?.meta?.count || 0;
    const hasMore = !count || count > pageSize * pageIndex;

    if (hasMore) {
      const params: IBaseFilterModel = {
        limit: pageSize,
        offset: pageSize * pageIndex
      };

      if (params.offset === 0) {
        await this.clearCollection();
      }
      await this.loadCollection(params);
      this.mounted && this.setState(state => ({ pageIndex: state.pageIndex + 1 }));
    }
  };

  renderNoData: () => JSX.Element | void = () => undefined;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  clearCollection: () => void = () => {};
  abstract getCollection: () => StoreBranch<Collection, Params>;
  abstract loadCollection: (params: IBaseFilterModel) => Promise<void>;
  abstract renderListItem: (model: Model) => JSX.Element;
}

export const InfiniteList = InfiniteListComponent;
