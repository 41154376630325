import axios, { AxiosStatic } from 'axios';
import { BaseHttpTransport } from '@axmit/transport';
import {
  INotificationCollection,
  INotificationCollectionFilter,
  INotificationCreate,
  INotificationModel,
  INotificationQueueTaskCount,
  INotificationSmsCreate,
  INotificationEmailCreate,
  IMailCreate
} from 'entities/Notification/Notification.models';

const basePath = '/notifications';

class NotificationTransport extends BaseHttpTransport<
  AxiosStatic,
  INotificationModel,
  INotificationCollection,
  INotificationCreate,
  null,
  INotificationCollectionFilter
> {
  public getNotificationsQueueTaskCount = (): Promise<INotificationQueueTaskCount> => axios.get(`${basePath}/queue-task/count`);
  public sendingSms = (body: INotificationSmsCreate) => axios.post(`${basePath}/sms`, body);
  public sendingEmail = (body: INotificationEmailCreate) => axios.post(`${basePath}/email`, body);
  public sendCustomMail = (body: IMailCreate) => axios.post(`${basePath}/mailing`, body);
  public getMailingQueue = () => axios.get(`${basePath}/mailing`);
  public skipMailingQueue = () => axios.delete(`${basePath}/mailing`);
}

export const notificationTransport = new NotificationTransport(basePath, axios);
