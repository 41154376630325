import axios, { AxiosStatic } from 'axios';
import { BaseHttpTransport } from '@axmit/transport';
import {
  ILanguageModel,
  ILanguageCollection,
  ICreateLanguageParams,
  IUpdateLanguageParams,
  ILanguageCollectionFilter
} from 'entities/Language/Language.models';

const basePath = '/languages';

class LanguageTransport extends BaseHttpTransport<
  AxiosStatic,
  ILanguageModel,
  ILanguageCollection,
  ICreateLanguageParams,
  IUpdateLanguageParams,
  ILanguageCollectionFilter
> {}

export const languageTransport = new LanguageTransport(basePath, axios);
