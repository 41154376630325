export interface ISelectMapItem<T, V> {
  title: T;
  value: V;
}

export const gendersMap: ISelectMapItem<string, string>[] = [
  {
    title: 'Male',
    value: 'male'
  },
  {
    title: 'Female',
    value: 'female'
  }
];
