import React from 'react';
import { Breadcrumb, Col } from 'antd';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { communicationNotification, INotificationConnectedProps } from 'entities/Notification/Notification.communication';
import { NotificationEmailSendForm } from 'entities/Notification/components/NotificationEmailSendForm';

type AllProps = INotificationConnectedProps;

const NotificationEmailPageComponent: React.FC<AllProps> = props => {
  const { notificationEmailModel, createNotificationEmailModel } = props;
  const { loading } = notificationEmailModel;
  return (
    <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10 pb-10">
      <Col span={12}>
        <Breadcrumb className="fs-sm font-weight-5 font-family__default mb-8">
          <Breadcrumb.Item href={ERoutesPrivate.NotificationTemplates}>Notification</Breadcrumb.Item>
          <Breadcrumb.Item>Sending email</Breadcrumb.Item>
        </Breadcrumb>
        {!loading && <NotificationEmailSendForm model={notificationEmailModel} onSubmit={createNotificationEmailModel} />}
      </Col>
    </CommonLayout>
  );
};

export const NotificationEmailPage = communicationNotification.injector(NotificationEmailPageComponent);
