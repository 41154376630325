import { ColumnsType } from 'antd/lib/table';
import { RouteComponentProps, withRouter } from 'react-router';
import { TableCommon } from 'common/components/TableCommon';
import { IBaseFilterModel } from 'common/models/requestModels';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { ERoutesPrivate } from 'common/models/routesModel';
import { IStoryCollection, IStoryCollectionFilter, IStoryModel } from 'entities/Story/Story.models';
import { communicationStory, IStoryConnectedProps } from 'entities/Story/Story.communication';

interface IComponentProps {
  config: ColumnsType<any>;
}

type AllProps = IComponentProps & IStoryConnectedProps & RouteComponentProps;

class StoryTableComponent extends TableCommon<IStoryCollection, IStoryModel, ColumnsType<any>, AllProps, IStoryCollectionFilter> {
  loadCollection = (params: IBaseFilterModel) => {
    const { getStoryCollection, filter } = this.props;
    const filterParams: IStoryCollectionFilter = { ...params };

    if (filter?.langs) {
      filterParams.langs = filter?.langs;
    }
    if (filter?.statuses) {
      filterParams.statuses = filter?.statuses;
    }
    if (filter?.ageGroups) {
      filterParams.ageGroups = filter?.ageGroups;
    }

    getStoryCollection(filterParams);
  };

  getCollection = () => {
    const { storyCollection } = this.props;

    return storyCollection;
  };

  addQuery = (pageIndex: number) => {
    const searchPath = queryToObject<IStoryCollectionFilter>({
      pageIndex: 1,
      langs: undefined,
      statuses: undefined,
      ageGroups: undefined
    });
    const search = objectToQuery({ ...searchPath, pageIndex });
    return this.props.history.replace({ search });
  };

  onRowClick = (item: IStoryModel) => {
    const { history } = this.props;
    history.push(`${ERoutesPrivate.Story}/${item.id}`);
  };

  clearCollection = () => {
    return this.props.clearStoryCollection();
  };
}

export const StoryTable = communicationStory.injector(withRouter(StoryTableComponent));
