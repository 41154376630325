import React from 'react';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { Link } from 'react-router-dom';
import { StoreBranch } from '@axmit/redux-communications';
import { useFormMapper } from '@axmit/antd4-helpers';
import { ERoutesPrivate } from 'common/models/routesModel';
import { ImageUpload } from 'common/components/ImageUpload/ImageUpload';
import { INotificationTemplateCreateParams } from 'entities/NotificationTemplate/NotificationTemplate.models';
import { INotificationModel } from 'entities/Notification/Notification.models';
import { PlayerSelector } from 'entities/Player/components/PlayerSelector';
import { AcademySelector } from 'entities/Academy/components/Selector/AcademySelector';
import { MentorSelector } from 'entities/User/components/MentorSelector';
import { IPlayerModel } from 'entities/Player/Player.models';
import { IAdminModel } from 'entities/Admin/Admin.models';
import { IAcademyModel } from 'entities/Academy/Academy.models';
import { LanguageSelect } from 'entities/Language/components/Selectors/LanguageSelect';
import { EEntityType } from 'entities/User/User.models';
import { EntityTypeSelector } from 'entities/User/components/EntityTypeSelector';

enum ENotificationType {
  Default = 'Default',
  Marketing = 'Marketing'
}

enum EMarketingNotificationLinkTypes {
  Video = 'video',
  Url = 'url'
}

enum EMarketingApps {
  Junistat = 'junistat',
  Junicoach = 'junicoach'
}

enum EMarketingPageInAppTypes {
  Promo = 'Promo'
}

interface IComponentProps {
  model: StoreBranch<INotificationModel>;
  onSubmit: (data: INotificationTemplateCreateParams) => void;
}

type TListItem = IPlayerModel & IAdminModel & IAcademyModel;

type IFormValues = INotificationTemplateCreateParams & {
  appType: EMarketingApps;
  notificationLinkType: EMarketingNotificationLinkTypes;
  appPage: EMarketingPageInAppTypes;
};

const normalizeList = (list: TListItem[] = []): string[] => list.map((item: TListItem) => item.id);

const NotificationSendFormComponent: React.FC<IComponentProps> = props => {
  const { model, onSubmit } = props;
  const { errors, loading } = model;

  const { fields } = useFormMapper(
    [
      'image',
      'title',
      'description',
      'appType',
      'notificationLinkType',
      'link',
      'appPage',
      'lang',
      'receiverType',
      'mentors',
      'players',
      'academies'
    ],
    null,
    null,
    errors
  );

  const [notificationType, setNotificationType] = React.useState(ENotificationType.Default);

  const handleSubmit = (results: IFormValues) => {
    const { appType, notificationLinkType, link, appPage, players, mentors, academies, ...rest } = results;

    const body: INotificationTemplateCreateParams = {
      ...rest,
      players: normalizeList(players as any),
      mentors: normalizeList(mentors as any),
      academies: normalizeList(academies as any),
      isHide: false
    };

    if (notificationType === ENotificationType.Marketing) {
      body.isHide = true;
      body.link = `${appType}://${appPage}?url=${link}&type=${notificationLinkType}`;
    }

    onSubmit({
      ...body
    });
  };

  return (
    <Form className="mt-5" onFinish={handleSubmit} fields={fields}>
      <Row gutter={[8, 8]}>
        <Col span={5}>
          <Select className="width-full" value={notificationType} onSelect={setNotificationType}>
            <Select.Option value={ENotificationType.Default} key={ENotificationType.Default}>
              {ENotificationType.Default}
            </Select.Option>
            <Select.Option value={ENotificationType.Marketing} key={ENotificationType.Marketing}>
              {ENotificationType.Marketing}
            </Select.Option>
          </Select>
        </Col>
        {notificationType === ENotificationType.Default ? (
          <>
            <Col span={24}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Image is required'
                  }
                ]}
                name="image"
                label="Image"
              >
                <ImageUpload />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Title is required'
                  },
                  {
                    min: 2,
                    message: 'Title must be longer than or equal 2 characters'
                  },
                  {
                    max: 1024,
                    message: 'Title must be shorter than or equal 1024 characters'
                  }
                ]}
                name="title"
                label="Title"
              >
                <Input type="text" placeholder="Title" disabled={loading} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Description is required'
                  },
                  {
                    min: 2,
                    message: 'Description must be longer than or equal 2 characters'
                  },
                  {
                    max: 1024,
                    message: 'Description must be shorter than or equal 1024 characters'
                  }
                ]}
                name="description"
                label="Description"
              >
                <Input.TextArea placeholder="Description" disabled={loading} />
              </Form.Item>
            </Col>
          </>
        ) : (
          <>
            <Col span={24}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'App is required'
                  }
                ]}
                name="appType"
                label="App"
              >
                <Select className="width-full">
                  <Select.Option value={EMarketingApps.Junistat} key={EMarketingApps.Junistat}>
                    {EMarketingApps.Junistat}
                  </Select.Option>
                  <Select.Option value={EMarketingApps.Junicoach} key={EMarketingApps.Junicoach}>
                    {EMarketingApps.Junicoach}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Notification link type is required'
                  }
                ]}
                name="notificationLinkType"
                label="Notification link type"
              >
                <Select className="width-full">
                  <Select.Option value={EMarketingNotificationLinkTypes.Url} key={EMarketingNotificationLinkTypes.Url}>
                    {EMarketingNotificationLinkTypes.Url}
                  </Select.Option>
                  <Select.Option value={EMarketingNotificationLinkTypes.Video} key={EMarketingNotificationLinkTypes.Video}>
                    {EMarketingNotificationLinkTypes.Video}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Link is required'
                  },
                  {
                    min: 2,
                    message: 'Link must be longer than or equal 2 characters'
                  }
                ]}
                name="link"
                label="Link"
              >
                <Input type="text" placeholder="Link" disabled={loading} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Page in app is required'
                  }
                ]}
                name="appPage"
                label="Page in app"
              >
                <Select className="width-full">
                  <Select.Option value={EMarketingPageInAppTypes.Promo} key={EMarketingPageInAppTypes.Promo}>
                    {EMarketingPageInAppTypes.Promo}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </>
        )}
        <Col span={12}>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Language is required'
              }
            ]}
            label="Notification language"
            name="lang"
          >
            <LanguageSelect />
          </Form.Item>
        </Col>
        <Col md={12} xs={12}>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Receiver type is required'
              }
            ]}
            name="receiverType"
            label="Receiver type"
          >
            <EntityTypeSelector placeholder="Select receiver type" />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item shouldUpdate={(prevValues, values) => prevValues.receiverType !== values.receiverType}>
            {({ getFieldValue }) => {
              switch (getFieldValue('receiverType')) {
                case EEntityType.User: {
                  return (
                    <Form.Item name="mentors" label="Mentors">
                      <MentorSelector mode="multiple" />
                    </Form.Item>
                  );
                }
                case EEntityType.Player: {
                  return (
                    <Form.Item name="players" label="Players">
                      <PlayerSelector mode="multiple" />
                    </Form.Item>
                  );
                }
                case EEntityType.Academy: {
                  return (
                    <Form.Item name="academies" label="Academies">
                      <AcademySelector mode="multiple" />
                    </Form.Item>
                  );
                }
                default: {
                  return null;
                }
              }
            }}
          </Form.Item>
        </Col>
      </Row>

      <Row justify="space-between" align="middle">
        <Col span={16}>
          <Form.Item className="mb-0">
            <Button className="mr-8" htmlType="submit" type="primary" disabled={loading} loading={loading} title="Save">
              Send
            </Button>
            <Link to={ERoutesPrivate.NotificationTemplates}>
              <Button disabled={loading} loading={loading} title="Cancel">
                Cancel
              </Button>
            </Link>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export const NotificationSendForm = NotificationSendFormComponent;
