import {
  EActionsTypes,
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  StoreBranch,
  getStartType
} from '@axmit/redux-communications';
import { message } from 'antd';
import { put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { ERoutesPrivate } from 'common/models/routesModel';
import { IMailCreate, INotificationModel, INotificationQueueTaskCount } from 'entities/Notification/Notification.models';
import { notificationTransport } from 'entities/Notification/Notification.transport';

const namespace = 'notification';

export interface INotificationConnectedProps {
  notificationQueueCount: StoreBranch<INotificationQueueTaskCount>;
  notificationMail: StoreBranch<void>;
  notificationModel: StoreBranch<INotificationModel>;
  notificationSmsModel: StoreBranch<void>;
  notificationEmailModel: StoreBranch<void>;

  sendNotificationMail(params: IMailCreate): void;
  clearNotificationMail(): void;

  addNotificationModel(): void;
  clearNotificationModel(): void;
  getNotificationQueueCount(): void;

  createNotificationSmsModel(): void;
  createNotificationEmailModel(): void;
}

const NotificationQueueCountAPIProviders = [
  new APIProvider(EActionsTypes.get, notificationTransport.getNotificationsQueueTaskCount)
];

const NotificationSmsAPIProviders = [
  new APIProvider('create', notificationTransport.sendingSms, {
    onSuccess: function*() {
      message.success('Sms notification has been sent');
      yield put(push(ERoutesPrivate.NotificationTemplates));
    }
  })
];

const NotificationEmailAPIProviders = [
  new APIProvider('create', notificationTransport.sendingEmail, {
    onSuccess: function*() {
      message.success('Email notification has been sent');
      yield put(push(ERoutesPrivate.NotificationTemplates));
    }
  })
];

const NotificationModelAPIProviders = [
  new APIProvider(EActionsTypes.add, notificationTransport.add, {
    onSuccess: function*() {
      message.success('Notification has been sent');
      yield put(push(ERoutesPrivate.NotificationTemplates));
    }
  })
];
const NotificationMailAPIProviders = [
  new APIProvider('send', notificationTransport.sendCustomMail, {
    onSuccess: function*() {
      message.success('Mails has been sent');
      yield put({ type: getStartType(namespace, 'mail', EActionsTypes.clear) });
    }
  })
];

const branches = [
  new Branch('model', NotificationModelAPIProviders),
  new Branch('smsModel', NotificationSmsAPIProviders),
  new Branch('emailModel', NotificationEmailAPIProviders),
  new Branch('queueCount', NotificationQueueCountAPIProviders),
  new Branch('mail', NotificationMailAPIProviders)
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationNotification = buildCommunication<INotificationConnectedProps>(strategy);
