import { EActionsTypes, APIProvider, BaseStrategy, Branch, buildCommunication, StoreBranch } from '@axmit/redux-communications';
import { message } from 'antd';
import { put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { ERoutesPrivate } from 'common/models/routesModel';
import { feedTransport } from 'entities/Feed/Feed.transport';
import {
  EFeedSuccessMessage,
  IFeedCollection,
  IFeedCollectionFilter,
  IFeedCreateParams,
  IFeedModel,
  IFeedParamsModel,
  IFeedUpdateParams
} from 'entities/Feed/Feed.models';

const namespace = 'feed';

export interface IFeedConnectedProps {
  feedModel: StoreBranch<IFeedModel, IFeedParamsModel>;
  feedCollection: StoreBranch<IFeedCollection, IFeedCollectionFilter>;

  addFeedModel(params: IFeedCreateParams): void;
  getFeedModel(id: string): void;
  updateFeedModel(params: IFeedUpdateParams): void;
  deleteFeedModel(id: string): void;
  getFeedCollection(filter?: IFeedCollectionFilter): void;

  clearFeedModel(): void;
  clearFeedCollection(): void;
}

const FeedModelAPIProviders = [
  new APIProvider(EActionsTypes.add, feedTransport.add, {
    onSuccess: function*() {
      message.success(EFeedSuccessMessage.Add);
      yield put(push(ERoutesPrivate.Feed));
    }
  }),
  new APIProvider(EActionsTypes.get, feedTransport.get),
  new APIProvider(EActionsTypes.update, feedTransport.update, {
    onSuccess: function*() {
      message.success(EFeedSuccessMessage.Edit);
      yield put(push(ERoutesPrivate.Feed));
    }
  }),
  new APIProvider(EActionsTypes.delete, feedTransport.delete, {
    onSuccess: function*() {
      message.success(EFeedSuccessMessage.Delete);
      yield put(push(ERoutesPrivate.Feed));
    }
  })
];

const FeedCollectionAPIProviders = [new APIProvider(EActionsTypes.get, feedTransport.getCollection)];

const branches = [new Branch('model', FeedModelAPIProviders), new Branch('collection', FeedCollectionAPIProviders)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationFeed = buildCommunication<IFeedConnectedProps>(strategy);
