import axios, { AxiosStatic } from 'axios';
import { BaseHttpTransport } from '@axmit/transport';
import { IUpdateParams } from '@axmit/transport/src/index';
import { objectToQuery } from 'common/helpers/filters.helper';
import {
  IPlayerCollection,
  IPlayerCollectionFilter,
  IPlayerCreateParams,
  IPlayerModel,
  IPlayerUpdateParams,
  IPlayerVideoCollectionFilter,
  IPlayerVideoCollection,
  IPlayerTestStatisticModel,
  IPlayerTestStatisticFilter,
  IPlayerChangeAcademyParams,
  IPlayerChangeStatusParams,
  IPlayerChangeMentorParams
} from 'entities/Player/Player.models';

const basePath = '/players';

class PlayerTransport extends BaseHttpTransport<
  AxiosStatic,
  IPlayerModel,
  IPlayerCollection,
  IPlayerCreateParams,
  IPlayerUpdateParams,
  IPlayerCollectionFilter
> {
  public changePlayerAcademy = ({ id, ...params }: IPlayerChangeAcademyParams) => axios.put(`${basePath}/${id}/academy`, params);
  public updateStatus = ({ id, ...params }: IPlayerChangeStatusParams) => axios.put(`${basePath}/${id}/set-status`, params);
  public changeMentor = ({ id, ...params }: IPlayerChangeMentorParams) => axios.put(`${basePath}/${id}/set-mentor`, params);
  public update = ({ id, ...rest }: Partial<IPlayerModel> & IUpdateParams): Promise<IPlayerModel> => {
    const preparedData =
      this.mapper && this.mapper.mapTo && typeof this.mapper.mapTo === 'function' ? this.mapper.mapTo(rest) : rest;
    return this.axios.put(`${this.path}/${id}/by-admin`, preparedData).then((r: any) => {
      return this.mapper && this.mapper.mapFrom && typeof this.mapper.mapFrom === 'function' ? this.mapper.mapFrom(r) : r;
    });
  };

  public getPlayerVideosCollection = ({ playerId, ...params }: IPlayerVideoCollectionFilter): Promise<IPlayerVideoCollection> =>
    axios.get(`${basePath}/${playerId}/player-videos${objectToQuery(params)}`);

  public getPlayerTestStatisticModel = ({ id, ...params }: IPlayerTestStatisticFilter): Promise<IPlayerTestStatisticModel> =>
    axios.get(`${basePath}/${id}/statistic${objectToQuery(params)}`);

  public getPlayerSearch = (params: IPlayerCollectionFilter): Promise<IPlayerCollection> =>
    axios.get(`players/search${objectToQuery(params)}`);
  public createShareToken = (id: string): Promise<{ token?: string }> => axios.post(`/trainers${basePath}/${id}/magic-token`);

  public createShareTokenCSV = (id: string): Promise<{ token?: string }> =>
    axios.post(`/trainers${basePath}/${id}/magic-token/csv`);

  public createInviteMentorsToken = (academyId?: string): Promise<{ token?: string }> =>
    axios.post(`/trainers${basePath}/invite-mentors-token`, academyId ? { academyId } : undefined);
}

export const playerTransport = new PlayerTransport(basePath, axios);
