import React, { useMemo } from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import { useFormMapper } from '@axmit/antd4-helpers';
import { Link } from 'react-router-dom';
import { StoreBranch } from '@axmit/redux-communications';
import { nameBuilder } from 'common/helpers/name.helper';
import { dateFormatter } from 'common/helpers/date.helper';
import { VideoUpload } from 'common/components/Video/VideoUpload';
import { ERoutesPrivate } from 'common/models/routesModel';
import { VideoPrivate } from 'common/components/Video/VideoPrivate';
import { useFilesLoading } from 'common/components/UseFilesLoading';
import { IFeedModel, IFeedCreateParams, IFeedUpdateParams, IFeedParamsModel } from 'entities/Feed/Feed.models';
import { FeedStatusSelector } from 'entities/Feed/components/Selectors/FeedStatusSelector';

interface IComponentProps {
  createFeed?: (data: IFeedCreateParams) => void;
  updateFeed?: (data: IFeedUpdateParams) => void;
  deleteFeed?: (id: string) => void;
  feedModel: StoreBranch<IFeedModel, IFeedParamsModel>;
  feedId?: string;
}

const FeedFormComponent: React.FC<IComponentProps> = props => {
  const { feedModel, updateFeed, feedId, createFeed } = props;
  const { errors, loading, data, params } = feedModel;
  const [filesLoading, onChangeFileLoading] = useFilesLoading(['video']);
  const isUpdateForm = !!feedId;
  const submitButtonText = isUpdateForm ? 'Save' : ' Add';
  const { authorPlayer, authorUser, likes, createdAt, playerToTestId, type } = data || {};
  const createdAtValue = createdAt && dateFormatter(createdAt);
  const { id: playerId, firstName: playerFirstName, lastName: playerLastName } = authorPlayer || {};
  const { id: userId, firstName: userFirstName, lastName: userLastName } = authorUser || {};
  const playerName = nameBuilder(playerFirstName, playerLastName);
  const userName = nameBuilder(userFirstName, userLastName);
  const { id: videoId, image } = data?.video || {};
  const memoizedValue = useMemo(
    () => ({
      ...data,
      image: data?.image?.id,
      video: data?.video?.id
    }),
    [data]
  );
  const { fields } = useFormMapper(['title', 'playerToTest', 'video', 'status'], memoizedValue, params, errors);

  const addFeed = (values: any) => {
    const { title, image, playerToTest, video } = values;
    const newData: IFeedCreateParams = {
      type: 'news',
      image,
      playerToTest,
      video
    };
    if (title) {
      newData.title = title;
    }

    createFeed && createFeed(newData);
  };
  const editFeed = (values: any) => {
    if (feedId && updateFeed) {
      const { status, title } = values;
      const newData: IFeedUpdateParams = {
        id: feedId,
        status
      };
      if (title) {
        newData.title = title;
      }
      updateFeed(newData);
    }
  };

  return (
    <Form className="mt-5" onFinish={isUpdateForm ? editFeed : addFeed} fields={fields}>
      <>
        <Row gutter={[28, 10]}>
          <>
            <Col span={6}>
              <Form.Item name="title" label="Title">
                <Input name="title" type="text" placeholder="Title" disabled={loading} />
              </Form.Item>
              {isUpdateForm && (
                <Form.Item name="status" label="Status">
                  <FeedStatusSelector />
                </Form.Item>
              )}
              {isUpdateForm && (
                <>
                  <span className="basic__text_label" title="Type">
                    Type:
                  </span>
                  <br />
                  <span className="basic__text_label">{type}</span>
                  <br />
                </>
              )}
            </Col>

            {isUpdateForm && (
              <>
                <Col span={6}>
                  {playerId && (
                    <>
                      <span className="basic__text_label" title="Player">
                        Player:
                      </span>
                      <br />
                      <Link to={`${ERoutesPrivate.Players}/${playerId}`} title="Player">
                        {playerName}
                      </Link>
                      <br />
                    </>
                  )}
                  <br />
                  {userId && (
                    <>
                      <span className="basic__text_label" title="User">
                        User:
                      </span>
                      <br />
                      <Link to={`${ERoutesPrivate.UserList}/${userId}`} title="User">
                        {userName}
                      </Link>
                      <br />
                    </>
                  )}

                  <span className="basic__text_label" title="Likes">
                    Likes:
                  </span>
                  <br />
                  <span className="basic__text_label">{likes}</span>
                </Col>

                <Col span={6}>
                  <div className="mb-6">
                    <span className="basic__text_label" title="Player to test">
                      Player to test:
                    </span>
                    <br />
                    <Link to={`${ERoutesPrivate.TestResults}/${playerToTestId}`}>{playerToTestId}</Link>
                  </div>
                  <span className="basic__text_label" title="Created at">
                    Created at:
                  </span>
                  <br />
                  <span className="basic__text_label">{createdAtValue}</span>
                </Col>
              </>
            )}
            <Col span={6}>
              <Form.Item>
                <Button
                  className="mr-8"
                  htmlType="submit"
                  type="primary"
                  disabled={loading || filesLoading}
                  loading={loading || filesLoading}
                  title={submitButtonText}
                >
                  {submitButtonText}
                </Button>
                <Link to={ERoutesPrivate.Feed}>
                  <Button disabled={loading} loading={loading} title="Cancel">
                    Cancel
                  </Button>
                </Link>
              </Form.Item>
            </Col>
          </>
        </Row>
        {isUpdateForm ? (
          <Col span={12}>
            <VideoPrivate height={100} width={250} videoId={videoId} preview={image?.id} />
          </Col>
        ) : (
          <Col span={12}>
            <Form.Item name="video" label="Add video">
              <VideoUpload onLoading={(isLoading: boolean) => onChangeFileLoading('video', isLoading)} />
            </Form.Item>
          </Col>
        )}
      </>
    </Form>
  );
};

export const FeedForm = FeedFormComponent;
