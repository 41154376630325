import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { EPlayerTestStatus, EPlayerTestStatusText } from 'entities/PlayerTest/PlayerTest.models';

interface IComponentProps {
  onChange?: (value: EPlayerTestStatus) => void;
  placeholder?: string;
}

type AllProps = SelectProps<any> & IComponentProps;

export class PlayerTestStatusSelector extends React.PureComponent<AllProps> {
  handleChange = (value: EPlayerTestStatus) => {
    const { onChange } = this.props;
    onChange && onChange(value);
  };

  render() {
    const { value, defaultValue, placeholder = 'Select' } = this.props;

    return (
      <Select
        allowClear
        className="width-full"
        value={value}
        placeholder={placeholder}
        defaultActiveFirstOption={false}
        defaultValue={defaultValue}
        showArrow
        onChange={(value: EPlayerTestStatus) => this.handleChange(value)}
      >
        <Select.Option key={EPlayerTestStatus.New} value={EPlayerTestStatus.New} label={EPlayerTestStatusText.New}>
          {EPlayerTestStatusText.New}
        </Select.Option>
        <Select.Option key={EPlayerTestStatus.Accept} value={EPlayerTestStatus.Accept} label={EPlayerTestStatusText.Accept}>
          {EPlayerTestStatusText.Accept}
        </Select.Option>
        <Select.Option
          key={EPlayerTestStatus.AIModeration}
          value={EPlayerTestStatus.AIModeration}
          label={EPlayerTestStatusText.AIModeration}
        >
          {EPlayerTestStatusText.AIModeration}
        </Select.Option>
        <Select.Option
          key={EPlayerTestStatus.Moderation}
          value={EPlayerTestStatus.Moderation}
          label={EPlayerTestStatusText.Moderation}
        >
          {EPlayerTestStatusText.Moderation}
        </Select.Option>
        <Select.Option key={EPlayerTestStatus.Reject} value={EPlayerTestStatus.Reject} label={EPlayerTestStatusText.Reject}>
          {EPlayerTestStatusText.Reject}
        </Select.Option>
      </Select>
    );
  }
}
