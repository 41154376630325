import React from 'react';
import { Breadcrumb, Col, Skeleton } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { ERoutesPrivate } from 'common/models/routesModel';
import { communicationSkin, ISkinConnectedProps } from 'entities/Skin/Skin.communication';
import { SkinForm } from 'entities/Skin/components/Form/SkinForm';

interface IParams {
  id?: string;
}

type AllProps = ISkinConnectedProps & RouteComponentProps<IParams>;

class SkinPageComponent extends React.PureComponent<AllProps> {
  componentDidMount() {
    const { getSkinModel, match } = this.props;
    const { params } = match;
    const { id } = params;
    if (id) {
      getSkinModel(id);
    }
  }

  componentWillUnmount() {
    const { clearSkinModel } = this.props;

    clearSkinModel();
  }

  render() {
    const { skinModel, match, addSkinModel, updateSkinModel } = this.props;
    const { params } = match;
    const { id } = params;
    const { data: skinModelData, loading } = skinModel;
    const skinName = skinModelData?.name || 'Unknown name';
    const isEdit = !!id;

    return (
      <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10 pb-10">
        <Col span={12}>
          <Breadcrumb className="fs-sm font-weight-5 font-family__default mb-8">
            <Breadcrumb.Item href={ERoutesPrivate.Skins}>Skins list</Breadcrumb.Item>
            <Breadcrumb.Item>
              {isEdit ? !loading ? skinName : <Skeleton.Input active style={{ width: '200px' }} size="small" /> : 'Add skin'}
            </Breadcrumb.Item>
          </Breadcrumb>
          {!loading || !isEdit ? (
            <SkinForm skinModel={skinModel} createSkin={addSkinModel} updateSkin={updateSkinModel} skinId={id} />
          ) : null}
        </Col>
      </CommonLayout>
    );
  }
}

export const SkinPage = communicationSkin.injector(withRouter(SkinPageComponent));
