import { IAcademyModel } from 'entities/Academy/Academy.models';
import { EFranchiseStatuses, EFranchiseTypes } from 'entities/Franchise/Franchise.models';

export const isAcademyFranchiseActive = (academy: IAcademyModel | null) => {
  return academy?.franchiseRequest?.status === EFranchiseStatuses.Active;
};

// main in franchise
export const isAcademyFranchisor = (academy?: IAcademyModel | null) => {
  return academy?.franchiseRequest?.type === EFranchiseTypes.Main && isAcademyFranchiseActive(academy);
};

// child in franchise
export const isAcademyFranchisee = (academy?: IAcademyModel | null) => {
  return academy?.franchiseRequest?.type === EFranchiseTypes.Child && isAcademyFranchiseActive(academy);
};
