import React from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import { useUpdateFormValidation } from '@axmit/antd4-helpers';
import { Link } from 'react-router-dom';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { ERoutesPublic } from 'common/models/routesModel';
import { IPasswordForgotParams } from 'entities/Auth/Auth.models';
import { IAuthConnectedProps, communicationAuth } from 'entities/Auth/Auth.communication';

type AllProps = IAuthConnectedProps;

const RestoreFormComponent: React.FC<AllProps> = props => {
  const { forgotAuthPasswordRestore, authModel } = props;
  const { loading, errors, params } = authModel;

  const { fields } = useUpdateFormValidation(['email'], null, errors, params);

  const onFinish = (values: any) => {
    const { email } = values;
    const body: IPasswordForgotParams = {
      email
    };
    forgotAuthPasswordRestore(body);
  };

  return (
    <CommonLayout typeLayout={ELayoutSize.Small} classStyle="basic__layout_m-t">
      <Row justify="center" className="mb-4">
        <h1 className="basic__text_title fs-md" title="Log in">
          Restore password
        </h1>
      </Row>
      <Form onFinish={onFinish} fields={fields}>
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Email is required'
            }
          ]}
          name="email"
          label="Email"
        >
          <Input name="email" type="email" placeholder="Email" disabled={loading} />
        </Form.Item>

        <Form.Item>
          <Row justify="space-between" align="middle" gutter={3}>
            <Col>
              <Button htmlType="submit" block type="primary" title="Restore" disabled={loading} loading={loading}>
                Restore password
              </Button>
            </Col>
            <Col>
              <Link to={ERoutesPublic.Login}>Login</Link>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </CommonLayout>
  );
};

export const RestorePage = communicationAuth.injector(RestoreFormComponent);
