import { ColumnsType } from 'antd/lib/table';
import { RouteComponentProps, withRouter } from 'react-router';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { ISorter, TableCommon } from 'common/components/TableCommon';
import { IBaseFilterModel } from 'common/models/requestModels';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { ERoutesPrivate } from 'common/models/routesModel';
import { simpleDateFormatWithTime } from 'common/models/dateModels';
import { IPlayerCollection, IPlayerCollectionFilter, IPlayerModel, EPlayerAgeGroupToText } from 'entities/Player/Player.models';
import { communicationPlayer, IPlayerConnectedProps } from 'entities/Player/Player.communication';

interface IComponentProps {
  config: ColumnsType<any>;
}

type AllProps = IComponentProps & IPlayerConnectedProps & RouteComponentProps;

class PlayerTableComponent extends TableCommon<
  IPlayerCollection,
  IPlayerModel,
  ColumnsType<any>,
  AllProps,
  IPlayerCollectionFilter
> {
  loadCollection = (params: IBaseFilterModel, sorter?: ISorter) => {
    const { getPlayerCollection, filter } = this.props;
    const filterParams: IPlayerCollectionFilter = { ...params };

    if (filter?.hideArchived !== undefined) {
      filterParams.hideArchived = filter?.hideArchived;
    }
    if (filter?.name) {
      filterParams.name = filter?.name;
    }
    if (filter?.academyFilter?.id) {
      filterParams.academy = filter?.academyFilter?.id;
    }
    if (filter?.private !== undefined) {
      filterParams.private = filter?.private;
    }
    if (filter?.phone) {
      filterParams.phone = filter?.phone;
    }
    if (filter?.academyFilter?.referralCode) {
      filterParams.referralCode = filter.academyFilter.referralCode;
    }
    if (filter?.regComplete !== undefined) {
      filterParams.regComplete = filter.regComplete;
    }
    if (filter?.mentorFilter?.id) {
      filterParams.mentor = filter.mentorFilter?.id;
    }
    if (filter?.trainerFilter?.id) {
      filterParams.trainer = filter.trainerFilter?.id;
    }
    if (filter?.statuses) {
      filterParams.statuses = filter?.statuses;
    }
    if (filter?.ageGroups) {
      filterParams.ageGroups = filter?.ageGroups;
    }
    if (filter?.dateRegFrom) {
      filterParams.dateRegFrom = filter.dateRegFrom;
    }
    if (filter?.dateRegTo) {
      filterParams.dateRegTo = filter.dateRegTo;
    }
    if (filter?.academy) {
      filterParams.academy = filter.academy;
    }
    if (filter?.email) {
      filterParams.email = filter.email;
    }
    if (filter?.location) {
      filterParams.country = filter.location?.country;
    }

    if (sorter && sorter?.field && sorter?.order) {
      switch (sorter?.field) {
        case 'ageGroupLabel': {
          filterParams.orderField = 'ageGroup';
          break;
        }
        case 'name': {
          filterParams.orderField = 'name';
          break;
        }
        case 'rating': {
          filterParams.orderField = 'rating';
          break;
        }
        case 'registrationDate': {
          filterParams.orderField = 'createdAt';
          break;
        }
      }
      filterParams.orderDirection = sorter.order === 'ascend' ? 'ASC' : 'DESC';
    }

    getPlayerCollection(filterParams);
  };

  getCollection = () => {
    const { playerCollection } = this.props;
    if (playerCollection?.data?.data) {
      const data: IPlayerModel[] = playerCollection.data.data.map(item => {
        return {
          ...item,
          name: (
            // eslint-disable-next-line react/react-in-jsx-scope
            <Link
              to={`${ERoutesPrivate.Players}/${item.id}`}
              target="_blank"
              onClick={e => {
                e.preventDefault();
              }}
            >
              {`${item.lastName || 'Unknown'} ${item.firstName || 'name'}`}
            </Link>
          ),
          fullAddress: `${item?.address?.country || ''} ${item?.address?.region || ''} ${item?.address?.city || ''}`,
          registrationDate: moment(item.createdAt).format(simpleDateFormatWithTime),
          ageGroupLabel: item?.ageGroup ? EPlayerAgeGroupToText[item.ageGroup] : '-',
          share: item
        };
      });
      return {
        ...playerCollection,
        data: {
          ...playerCollection.data,
          data
        }
      };
    } else {
      return playerCollection;
    }
  };

  addQuery = (pageIndex: number) => {
    const searchPath = queryToObject<IPlayerCollectionFilter>({
      pageIndex: 1,
      name: undefined,
      phone: undefined,
      private: undefined,
      mentorFilter: undefined,
      trainerFilter: undefined,
      academyFilter: undefined,
      statuses: undefined,
      ageGroups: undefined,
      dateRegFrom: undefined,
      regComplete: undefined,
      hideArchived: undefined,
      location: undefined,
      email: undefined,
      dateRegTo: undefined
    });
    const search = objectToQuery({ ...searchPath, pageIndex });
    return this.props.history.replace({ search });
  };

  onRowClick = (item: IPlayerModel) => {
    const { history } = this.props;
    history.push(`${ERoutesPrivate.Players}/${item.id}`);
  };

  clearCollection = () => {
    return this.props.clearPlayerCollection();
  };
}

export const PlayerTable = communicationPlayer.injector(withRouter(PlayerTableComponent));
