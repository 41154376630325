import { ColumnsType } from 'antd/lib/table';
import { RouteComponentProps, withRouter } from 'react-router';
import { ISorter, TableCommon } from 'common/components/TableCommon';
import { IBaseFilterModel } from 'common/models/requestModels';
import { dateFormatter } from 'common/helpers/date.helper';
import { simpleDateFormat } from 'common/models/dateModels';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { communicationWallet, IWalletConnectedProps } from 'entities/Wallet/Wallet.communication';
import {
  IWalletTransactionCollection,
  IWalletTransactionCollectionFilter,
  IWalletTransactionModel
} from 'entities/Wallet/Wallet.models';

interface IComponentProps {
  config: ColumnsType<any>;
}

type AllProps = IComponentProps & IWalletConnectedProps & RouteComponentProps;

class WalletTransactionTableComponent extends TableCommon<
  IWalletTransactionCollection,
  IWalletTransactionModel,
  ColumnsType<any>,
  AllProps,
  IWalletTransactionCollectionFilter
> {
  loadCollection = (params: IBaseFilterModel, sorter?: ISorter) => {
    const { getWalletTransactionCollection, filter } = this.props;
    const filterParams: IWalletTransactionCollectionFilter = { ...params };

    if (filter?.id) {
      filterParams.id = filter.id;
    }
    if (filter?.types) {
      filterParams.types = filter?.types;
    }
    if (filter?.statuses) {
      filterParams.statuses = filter?.statuses;
    }
    if (filter?.description) {
      filterParams.description = filter.description;
    }
    if (filter?.from) {
      filterParams.from = filter.from;
    }
    if (filter?.to) {
      filterParams.to = filter.to;
    }
    if (filter?.senderValue) {
      filterParams.sender = filter.senderValue?.id;
    }
    if (filter?.targetValue) {
      filterParams.target = filter.targetValue?.id;
    }

    if (sorter?.field === 'createdAt' || sorter?.field === 'status') {
      if (sorter?.order) {
        const orderDirection = sorter.order === 'ascend' ? 'ASC' : 'DESC';
        filterParams.orderField = sorter?.field;
        filterParams.orderDirection = orderDirection;
      }
    } else {
      if (filter?.orderField && filter?.orderDirection) {
        filterParams.orderField = filter?.orderField;
        filterParams.orderDirection = filter?.orderDirection;
      }
    }

    getWalletTransactionCollection(filterParams);
  };

  getCollection = () => {
    const { walletTransactionCollection } = this.props;
    if (walletTransactionCollection?.data?.data) {
      const data: IWalletTransactionModel[] = walletTransactionCollection.data.data.map(item => {
        const { createdAt } = item || {};
        return {
          ...item,
          senderData: { type: item?.senderType, value: item?.sender },
          targetData: { type: item?.targetType, value: item?.target },
          createdAt: dateFormatter(createdAt, simpleDateFormat)
        };
      });
      return {
        ...walletTransactionCollection,
        data: {
          ...walletTransactionCollection.data,
          data
        }
      };
    } else {
      return walletTransactionCollection;
    }
  };

  addQuery = (pageIndex: number, sorterValue?: ISorter) => {
    const searchPath = queryToObject<IWalletTransactionCollectionFilter>({
      pageIndex: 1,
      description: undefined,
      externalId: undefined,
      product: undefined,
      statuses: undefined,
      types: undefined,
      from: undefined,
      to: undefined,
      orderField: undefined,
      orderDirection: undefined,
      direction: undefined,
      senderValue: undefined,
      senderEntity: undefined,
      targetValue: undefined,
      targetEntity: undefined
    });
    let search;

    if (sorterValue?.field === 'createdAt' || sorterValue?.field === 'status') {
      if (sorterValue?.order) {
        const orderDirection = sorterValue.order === 'ascend' ? 'ASC' : 'DESC';
        search = objectToQuery({ ...searchPath, pageIndex, orderField: sorterValue?.field, orderDirection });
      } else {
        search = objectToQuery({ ...searchPath, pageIndex, orderField: undefined, orderDirection: undefined });
      }
    } else {
      search = objectToQuery({ orderField: undefined, orderDirection: undefined, ...searchPath, pageIndex });
    }

    return this.props.history.replace({ search });
  };

  clearCollection = () => {
    return this.props.clearWalletTransactionCollection();
  };
}

export const WalletTransactionTable = communicationWallet.injector(withRouter(WalletTransactionTableComponent));
