import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, Form, Input, InputNumber, Popconfirm, Row, Image, Typography, Switch, Skeleton, Modal } from 'antd';
import { StepBackwardOutlined, StepForwardOutlined } from '@ant-design/icons';
import { useFormMapper } from '@axmit/antd4-helpers';
import { Link } from 'react-router-dom';
import { StoreBranch } from '@axmit/redux-communications';
import { getCreds, ICreds } from 'axios-patch-jwt';
import { Player, ControlBar, PlaybackRateMenuButton, Shortcut } from 'video-react';
import moment from 'moment';
import { useForm } from 'antd/es/form/Form';
import { ERoutesPrivate } from 'common/models/routesModel';
import 'video-react/dist/video-react.css';
import { getHost } from 'common/helpers/axios.helper';
import { simpleDateFormatWithTime } from 'common/models/dateModels';
import { getPlayerName, nameBuilder } from 'common/helpers/name.helper';
import {
  EPlayerTestRejectReasonText,
  EPlayerTestRenderStatus,
  EPlayerTestStatus,
  ERejectCodes,
  IPlayerTestModel,
  IPlayerTestResultModel,
  IPlayerTestUpdateParams,
  IPlayerTestVideoPoint,
  IPlayerTestVideoBoxPoint,
  TPlayerTestParamsModel,
  IPlayerTestUpdateMove
} from 'entities/PlayerTest/PlayerTest.models';
import { PlayerTestStatusSelector } from 'entities/PlayerTest/components/Selector/PlayerTestStatusSelector';
import { PlayerTestRejectSelector } from 'entities/PlayerTest/components/Selector/PlayerTestRejectSelector';
import { communicationAdmin, IAdminConnectedProps } from 'entities/Admin/Admin.communication';
import { PlayerTestInvestigationStatus } from 'entities/PlayerTest/components/Selector/PlayerTestInvestigationStatus';
import { communicationFeed, IFeedConnectedProps } from 'entities/Feed/Feed.communication';
import { EFeedType, IFeedCreateParams } from 'entities/Feed/Feed.models';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';
import { TestMarkersEdit } from 'entities/PlayerTest/components/TestMarkersEdit';
import { communicationPlayer, IPlayerConnectedProps } from 'entities/Player/Player.communication';
import { EPaymentActivitiesStatus, ESubscriptionStatus } from 'entities/Subscription/Subscription.models';
import { TestSelector } from 'entities/Test/components/Selector/TestSelector';
import { ITestModel } from 'entities/Test/Test.models';

interface IComponentProps {
  updatePlayerTest?: (data: IPlayerTestUpdateParams) => void;
  nextPlayerTest?: () => void;
  renderPlayerTest?: (id: string) => void;
  movePlayerTestModel?: (params: IPlayerTestUpdateMove) => Promise<IPlayerTestModel>;
  playerTestModel: StoreBranch<IPlayerTestModel, TPlayerTestParamsModel>;
  playerTestId?: string;
}

type AllProps = IComponentProps & IAdminConnectedProps & IUIConnectedProps & IFeedConnectedProps & IPlayerConnectedProps;

const VideoHeightDefault = 550;
const ScaleSize = 0.2;

const PlayerTestFormComponent: React.FC<AllProps> = props => {
  const {
    playerTestModel,
    updatePlayerTest,
    playerTestId,
    getAdminModel,
    adminModel,
    renderPlayerTest,
    addFeedModel,
    nextPlayerTest,
    movePlayerTestModel,
    feedModel,
    playerTestStatisticModel,
    getPlayerTestStatisticModel,
    getPlayerModel,
    clearPlayerModel,
    clearPlayerTestStatisticModel,
    playerModel,
    UIPlayerTestQuery
  } = props;

  const [videoHeight, setVideoHeight] = useState(VideoHeightDefault);
  const videoWidth = useMemo(() => videoHeight * 1.77777818, [videoHeight]);

  const { loading: feedLoading } = feedModel;
  const { errors, loading, data, params } = playerTestModel;
  const [form] = useForm();
  const { data: uploadedUser } = adminModel;
  const submitButtonText = 'Save';
  const {
    id,
    renderVideo,
    renderStatus,
    logFile,
    uploadById,
    test,
    rejectCode,
    rejectReason,
    developerMessages,
    player,
    videoTime,
    createdAt,
    video,
    feedId,
    processingTime,
    nonResettableTryParseCount,
    testResults,
    tryParseCount,
    warnings,
    status,
    investigationStatus,
    team,
    screenPoints,
    screenBoxPoints,
    appVersion,
    phoneModel,
    moderationTime,
    tagger,
    renderAllVideo,
    beautyVideo,
    logOutputFile,
    logInfoImage,
    isForceTest,
    fieldMetadata,
    isSkipCalculation
  } = data || {};
  const renderVideoId = renderVideo?.id;
  const { data: statisticData, loading: statisticLoading } = playerTestStatisticModel;
  const { data: playerData, loading: playerLoading } = playerModel;
  const [markers, setMarkers] = useState<IPlayerTestVideoPoint[]>(screenPoints?.length ? screenPoints : []);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [boxes, setBoxes] = useState<IPlayerTestVideoBoxPoint[]>(screenBoxPoints?.length ? screenBoxPoints : []);

  // const markersData = useMemo(() => {
  //   if (zoomLevel === 0) {
  //     return markers;
  //   }
  //   return markers.map(({ xPos, yPos }) => {
  //     return {
  //       xPos: xPos + VideoHeightDefault * ScaleSize * zoomLevel,
  //       yPos: yPos + VideoHeightDefault * ScaleSize * zoomLevel
  //     };
  //   });
  // }, [markers, zoomLevel]);

  const isRenderInProgress = renderStatus === EPlayerTestRenderStatus.InProgress;
  const logFileId = logFile?.id;
  const { id: testId, title } = test || {};
  const [testValue, setTestValue] = useState<Partial<ITestModel> | undefined>({ id: testId, title });
  const isAccepted = status === EPlayerTestStatus.Accept;
  const videoId = video?.id;
  const lang = player?.lang;
  const playerName = getPlayerName(player);
  const uploadedByName = nameBuilder(uploadedUser?.firstName, uploadedUser?.lastName, uploadedUser?.email);
  const uploadedByAcademy = uploadedUser?.trainerRequest?.academy;
  const playerId = player?.id;
  const [token, setToken] = useState('');
  const host = getHost();
  const formData: any = useMemo(() => ({ ...data }), [data]);
  const fieldMetadataArray: any[] = useMemo(() => fieldMetadata && Object.entries(fieldMetadata), [fieldMetadata]);
  const formItemBuilder = (baseResultArray?: IPlayerTestResultModel[]) => {
    const formItems = [];
    const fieldsName = [
      'status',
      'rejectCode',
      'rejectDetails',
      'investigationStatus',
      'isForceTest',
      'isSkipCalculation',
      'weightsName'
    ];
    baseResultArray?.sort((a: IPlayerTestResultModel, b: IPlayerTestResultModel) => {
      if (a.baseResult.name < b.baseResult.name) {
        return -1;
      }
      if (a.baseResult.name > b.baseResult.name) {
        return 1;
      }
      return 0;
    });

    if (baseResultArray) {
      for (let i = 0; i < baseResultArray.length; i++) {
        const { baseResult, id, value, rawValue } = baseResultArray[i];
        const label = `${baseResult?.name}(${baseResult?.units || '-'})`;
        formData[id] = value === undefined || value === null ? rawValue : value;
        fieldsName.push(id);

        formItems[i] = (
          <React.Fragment key={id}>
            <Col span={12}>
              <label title={label}>{label}</label>
              <InputNumber step={0.01} value={rawValue === null ? undefined : rawValue} type="number" disabled={true} />
            </Col>
            <Col span={12}>
              <label title={label}>{label}</label>
              <Form.Item name={id}>
                <InputNumber step={0.01} name={id} type="number" disabled={loading} />
              </Form.Item>
            </Col>
          </React.Fragment>
        );
      }
    }
    return { formData, formItems, fieldsName };
  };

  useEffect(() => {
    getCreds().then((creds: ICreds) => {
      setToken(creds?.access?.token || '');
    });
  }, []);

  useEffect(() => {
    if (screenPoints?.length) {
      setMarkers(screenPoints);
    }
  }, [screenPoints]);
  useEffect(() => {
    if (!isVisible) {
      setTestValue({ id: testId, title });
    }
  }, [isVisible]);

  useEffect(() => {
    if (uploadById) {
      getAdminModel(uploadById);
    }
  }, [uploadById, getAdminModel]);

  useEffect(() => {
    if (playerId) {
      getPlayerTestStatisticModel({ id: playerId });
      getPlayerModel(playerId);
    }
    return () => {
      clearPlayerModel();
      clearPlayerTestStatisticModel();
    };
  }, [playerId, getPlayerTestStatisticModel, getPlayerModel]);

  const formParams = useMemo(() => {
    return (params as IPlayerTestUpdateParams)?.playerTestResults?.reduce((acc, item) => {
      return {
        ...acc,
        [item.result]: item.value
      };
    }, {});
  }, [params]);

  const formErrors = useMemo(() => {
    // @ts-ignore
    if (errors?.validation && Object.keys(errors?.validation).length) {
      // @ts-ignore
      const validationFields = { ...errors?.validation };
      const formParamsFieldsMap = Object.keys(formParams as { [key: string]: any });

      return {
        // @ts-ignore
        ...errors,
        validation: Object.keys(validationFields).reduce((acc, validationField) => {
          const [, fieldName] = validationField.split('.');

          return {
            ...acc,
            [formParamsFieldsMap[fieldName]]: validationFields[validationField]
          };
        }, {})
      };
    }

    return errors;
  }, [errors, formParams]);

  const { fields } = useFormMapper(
    formItemBuilder(testResults).fieldsName,
    formItemBuilder(testResults).formData,
    formParams,
    formErrors
  );

  const addFeed = () => {
    const newData: IFeedCreateParams = {
      type: EFeedType.Passing,
      playerToTest: playerTestId
    };

    addFeedModel(newData);
  };

  const testDate = useMemo(() => moment(createdAt).format(simpleDateFormatWithTime), [createdAt]);

  const onZoomIn = useCallback(() => {
    setVideoHeight(videoHeight + VideoHeightDefault * ScaleSize);
  }, [videoHeight]);

  const onZoomOut = useCallback(() => {
    setVideoHeight(videoHeight - VideoHeightDefault * ScaleSize);
  }, [videoHeight]);

  const onZoomReset = useCallback(() => {
    setVideoHeight(VideoHeightDefault);
  }, []);

  const developerMessagesArray = developerMessages?.length ? (
    developerMessages?.map((item, key) => (
      <div key={key} className="fs-sm font-family__default">
        {item}
      </div>
    ))
  ) : (
    <span className="fs-sm font-family__default">No Messages</span>
  );
  const warningsArray = warnings?.length ? (
    warnings?.map((item, index) => (
      <div key={index} className="fs-sm font-family__default">
        {item}
      </div>
    ))
  ) : (
    <span className="fs-sm font-family__default">No Warnings</span>
  );
  const updatePlayerTestMove = async () => {
    if (id && testValue?.id && movePlayerTestModel) {
      await movePlayerTestModel({ id, test: testValue.id });
      setIsVisible(false);
    }
  };
  const editPlayerTest = (isNext?: boolean) => {
    const values = form?.getFieldsValue();
    const {
      status,
      rejectCode: rejectCodeValue,
      rejectDetails,
      developerMessages: developerMessagesValue,
      investigationStatus: investigationStatusValue,
      isForceTest: isForceTestValue,
      weightsName,
      isSkipCalculation: isSkipCalculationValue
    } = values;
    if (playerTestId && updatePlayerTest) {
      const playerTestResults: any =
        testResults?.map(item => ({
          result: item.id,
          value: values[item.id]
        })) || [];

      const newData: IPlayerTestUpdateParams = {
        id: playerTestId,
        playerTestResults,
        status,
        weightsName,
        screenPoints: markers,
        screenBoxPoints: boxes
      };

      if (investigationStatusValue && investigationStatusValue !== investigationStatus) {
        newData.investigationStatus = investigationStatusValue;
      }

      if (isForceTest !== undefined) {
        newData.isForceTest = isForceTestValue;
      }
      if (isSkipCalculation !== undefined) {
        newData.isSkipCalculation = isSkipCalculationValue;
      }
      if (developerMessagesValue) {
        newData.developerMessage = developerMessagesValue;
      }
      if (values?.status === EPlayerTestStatus.Reject) {
        newData.rejectCode = rejectCodeValue;
        newData.rejectDetails = rejectDetails ? rejectDetails : null;
      }
      if (isNext && UIPlayerTestQuery?.data) {
        newData.query = UIPlayerTestQuery?.data as any;
      }
      updatePlayerTest(newData);
    }
  };

  const [showReason, setShowReason] = useState(false);

  useEffect(() => {
    const status = fields.find(item => item.name === 'status')?.value;
    setShowReason([EPlayerTestStatus.Reject].includes(status));
  }, [fields]);

  const onFieldsChanged = (changedValue: any, allFields: any) => {
    if (allFields?.status) {
      setShowReason(allFields?.status === EPlayerTestStatus.Reject);
    }
  };

  const playerRef = useRef<typeof Player>();
  const playerRenderRef = useRef<typeof Player>();
  const playerAllRef = useRef<typeof Player>();
  const playerBeautyRef = useRef<typeof Player>();

  const onRewindFrame = useCallback(
    (isInverse, ref: any) => () => {
      if (isInverse) {
        ref?.current?.replay(0.03);
      } else {
        ref?.current?.forward(0.03);
      }
    },
    []
  );
  const subscription = playerData?.mentorRequest?.subscription;

  const isSubscriptionActive = useMemo(() => subscription && subscription.status === ESubscriptionStatus.Active, [subscription]);
  const isCheckingLastPayment = useMemo(() => {
    if (subscription && subscription.lastActivity) {
      return (
        subscription.lastActivity.status === EPaymentActivitiesStatus.New || subscription.lastActivity.isApplied === undefined
      );
    }

    return false;
  }, [subscription]);
  const isDelayedCancellation = useMemo(() => subscription && subscription.delayedCancellation, [subscription]);

  const subscriptionStatus = useMemo(() => {
    let statusText = 'No subscription';
    switch (true) {
      case isSubscriptionActive:
        statusText = 'Active';
        break;
      case isCheckingLastPayment:
        statusText = 'Checking last payment';
        break;
      case subscription?.status === ESubscriptionStatus.Canceled:
        statusText = 'Canceled';
        break;
      case subscription?.status === ESubscriptionStatus.New && !subscription?.prepaidTo:
        statusText = 'New';
        break;
      case subscription?.status === ESubscriptionStatus.Expired:
        statusText = 'Expired';
        break;
    }
    return `${statusText}${isDelayedCancellation ? '(in delayed cancellation)' : ''}`;
  }, [isSubscriptionActive, subscription, isCheckingLastPayment, isDelayedCancellation]);

  return (
    <Form className="mt-5" fields={fields} onValuesChange={onFieldsChanged} form={form}>
      <Row className="mb-5" align="middle">
        <span className="basic__text_title" title={title}>
          {title}
        </span>
      </Row>
      <Row gutter={[28, 10]}>
        <Col span={12}>
          <Row gutter={[28, 10]} className="mb-5">
            <Col span={12}>
              <span className="fs-sm font-weight-5 font-family__default">Default values</span>
            </Col>
            <Col span={12}>
              <span className="fs-sm font-weight-5 font-family__default">Editable values</span>
            </Col>
            {formItemBuilder(testResults).formItems}
          </Row>
        </Col>
        <Col span={12}>
          <Row gutter={[28, 10]}>
            <Col span={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Status is required'
                  }
                ]}
                name="status"
                label="Status"
              >
                <PlayerTestStatusSelector />
              </Form.Item>

              {showReason && (
                <>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Reject reason is required'
                      }
                    ]}
                    label="Reject reason"
                    name="rejectCode"
                  >
                    <PlayerTestRejectSelector />
                  </Form.Item>
                  <Form.Item label="Reject details" name="rejectDetails">
                    <Input.TextArea maxLength={1024} rows={3} />
                  </Form.Item>
                </>
              )}
            </Col>
            <Col span={12}>
              {playerId && (
                <>
                  <div className="mb-4">
                    <span className="fs-sm font-weight-5 font-family__default">Player:</span>
                    <br />
                    <Link to={`${ERoutesPrivate.Players}/${playerId}`} target="_blank">
                      <span className="fs-sm font-family__default">{`${playerName} (${player?.ageGroup || ''})`}</span>
                    </Link>
                  </div>

                  <div className="mb-4">
                    <span className="fs-sm font-weight-5 font-family__default">Lang:</span>
                    <br />
                    <span className="fs-sm font-family__default">{lang}</span>
                  </div>
                  <>
                    <Row className="mb-3">
                      <span className="basic__text_label mr-3" title="Coins balance:">
                        Coins balance:
                      </span>
                      <span className="basic__text_default">
                        {playerLoading ? (
                          <Skeleton.Input active style={{ width: '50px' }} size="small" />
                        ) : (
                          playerData?.wallet?.coinsBalance || '-'
                        )}
                      </span>
                    </Row>
                    <Row className="mb-3">
                      <span className="basic__text_label mr-3" title="Subscription status:">
                        Subscription status:
                      </span>
                      <span className="basic__text_default">
                        {playerLoading ? <Skeleton.Input active style={{ width: '50px' }} size="small" /> : subscriptionStatus}
                      </span>
                    </Row>
                    <Row className="mb-3">
                      <span className="basic__text_label mr-3" title="Total tests count">
                        Total tests count:
                      </span>
                      <span className="basic__text_default">
                        {statisticLoading ? (
                          <Skeleton.Input active style={{ width: '50px' }} size="small" />
                        ) : (
                          statisticData?.testTotalCount
                        )}
                      </span>
                    </Row>
                    <Row className="mb-3">
                      <span className="basic__text_label mr-3" title="Accepted tests count">
                        Accepted tests count:
                      </span>
                      <span className="basic__text_default">
                        {statisticLoading ? (
                          <Skeleton.Input active style={{ width: '50px' }} size="small" />
                        ) : (
                          statisticData?.testAcceptCount
                        )}
                      </span>
                    </Row>
                    <Row className="mb-3">
                      <span className="basic__text_label mr-3" title="Failed tests count">
                        Failed tests count:
                      </span>
                      <span className="basic__text_default">
                        {statisticLoading ? (
                          <Skeleton.Input active style={{ width: '50px' }} size="small" />
                        ) : (
                          statisticData?.testRejectCount
                        )}
                      </span>
                    </Row>
                  </>
                </>
              )}
              <Form.Item valuePropName="checked" name="isForceTest" label="Is force test">
                <Switch checkedChildren="Yes" unCheckedChildren="No" />
              </Form.Item>
              <Form.Item label="Is skip calculation">
                {!logFileId && <span className="fs-sm">No log file</span>}
                <Form.Item valuePropName="checked" name="isSkipCalculation">
                  <Switch checkedChildren="Yes" unCheckedChildren="No" disabled={!logFileId} />
                </Form.Item>
              </Form.Item>
              <Col>
                <Form.Item name="weightsName" label="Weights name">
                  <Input name="weightsName" type="text" placeholder="Weights name" />
                </Form.Item>
              </Col>
              {uploadById && uploadedUser && (
                <div className="mb-4">
                  <span className="fs-sm font-weight-5 font-family__default">Uploaded by:</span>
                  <br />
                  <Row>
                    <Link to={`${ERoutesPrivate.UserList}/${uploadById}`} target="_blank">
                      <span className="fs-sm font-family__default">{uploadedByName}</span>
                    </Link>
                    {uploadedByAcademy?.id && (
                      <div className="ml-2">
                        <Link to={`${ERoutesPrivate.Academy}/${uploadedByAcademy.id}`} target="_blank">
                          <span className="fs-sm font-family__default">({uploadedByAcademy.name})</span>
                        </Link>
                      </div>
                    )}
                  </Row>
                </div>
              )}
              <Button
                className="mr-2 mb-2"
                onClick={() => editPlayerTest()}
                type="primary"
                disabled={loading}
                loading={loading}
                title={submitButtonText}
              >
                {submitButtonText}
              </Button>
              <Button
                className="mr-2 mb-2"
                onClick={() => editPlayerTest(true)}
                type="primary"
                disabled={loading}
                loading={loading}
                title="Save and next"
              >
                Save and next
              </Button>
              <Button
                className="mr-2 mb-2"
                onClick={() => nextPlayerTest && nextPlayerTest()}
                type="primary"
                disabled={loading}
                loading={loading}
                title="Next"
              >
                Next
              </Button>
              <Popconfirm
                title="Are you sure?"
                onConfirm={() => addFeed()}
                okText="Yes"
                cancelText="No"
                disabled={!!feedId || !isAccepted || feedLoading || loading}
              >
                <Button
                  className="mr-2 mb-2"
                  type="primary"
                  disabled={!!feedId || !isAccepted || feedLoading || loading}
                  loading={feedLoading || loading}
                  title="Convert to Feed"
                >
                  Convert to Feed
                </Button>
              </Popconfirm>
              {testId && (
                <div className="mr-2 mb-2">
                  <Link to={`${ERoutesPrivate.TestLeaderboard}/${testId}`} className="fs-sm">
                    <Button>View Leaderboard</Button>
                  </Link>
                </div>
              )}
              <Link to={ERoutesPrivate.TestResults}>
                <Button disabled={loading} loading={loading} title="Cancel">
                  Cancel
                </Button>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
      {isVisible && (
        <Modal visible={isVisible} footer={null} onCancel={() => setIsVisible(false)}>
          <span className="basic__text_label" title="Test value:">
            Test value
          </span>
          <TestSelector value={testValue} placeholder="Search by test" onChange={setTestValue} />

          <Button className="mt-4" onClick={updatePlayerTestMove} type="primary" title={submitButtonText}>
            {submitButtonText}
          </Button>
        </Modal>
      )}
      <Row className="mt-5 mb-5" justify="space-between" wrap>
        <Col span={24} className="mb-5">
          {token && (
            <TestMarkersEdit
              testId={playerTestId}
              videoHeight={videoHeight}
              videoWidth={videoWidth}
              markers={markers}
              setMarkers={setMarkers}
              onZoomIn={onZoomIn}
              onZoomOut={onZoomOut}
              onZoomReset={onZoomReset}
              boxes={boxes}
              setBoxes={setBoxes}
            >
              <Player ref={playerRef} fluid={false} height={videoHeight} preload={true}>
                <Shortcut
                  shortcuts={[
                    {
                      keyCode: 38,
                      handle: () => {
                        console.log('test');
                      }
                    },
                    {
                      keyCode: 37,
                      handle: onRewindFrame(true, playerRef)
                    },
                    {
                      keyCode: 39,
                      handle: onRewindFrame(false, playerRef)
                    }
                  ]}
                />
                {videoId && <source src={`${host}/api/videos/${videoId}/user?token=${token}`} type="video/mp4" />}
                <ControlBar>
                  <PlaybackRateMenuButton rates={[5, 3, 2, 1.5, 1, 0.5, 0.1]} />
                  <Row gutter={[8, 8]} justify="center" align="middle">
                    <Col>
                      <StepBackwardOutlined onClick={onRewindFrame(true, playerRef)} className="fs-xs cursor-pointer" />
                    </Col>
                    <Col>
                      <StepForwardOutlined onClick={onRewindFrame(false, playerRef)} className="fs-xs cursor-pointer" />
                    </Col>
                  </Row>
                </ControlBar>
              </Player>
            </TestMarkersEdit>
          )}
          <Typography.Text type="secondary">You can use the arrows to advance frame by frame</Typography.Text>
          <Row className="mt-5" justify="space-between">
            <a
              className="fs-sm font-family__default color-blue nt"
              href={`${host}/api/videos/${videoId}/user?token=${token}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Download video
            </a>
            <div className="mr-5">
              {logFileId ? (
                <Typography.Link
                  className="fs-sm font-family__default"
                  href={`${host}/api/files/${logFileId}?token=${token}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download log file
                </Typography.Link>
              ) : (
                <span className="fs-sm font-family__default">No log file</span>
              )}
            </div>
            <div className="mr-5">
              {logOutputFile?.id ? (
                <Typography.Link
                  className="fs-sm font-family__default"
                  href={`${host}/api/files/${logOutputFile.id}?token=${token}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download log output file
                </Typography.Link>
              ) : (
                <span className="fs-sm font-family__default">No log output file</span>
              )}
            </div>
          </Row>
        </Col>
        <Col span={12}>
          {token && renderVideoId && (
            <Row>
              <Col xs={24}>Render video:</Col>
              <Col xs={24}>
                <Player ref={playerRenderRef} fluid={false} height={350}>
                  <Shortcut
                    shortcuts={[
                      {
                        keyCode: 38,
                        handle: () => {
                          console.log('test');
                        }
                      },
                      {
                        keyCode: 37,
                        handle: onRewindFrame(true, playerRenderRef)
                      },
                      {
                        keyCode: 39,
                        handle: onRewindFrame(false, playerRenderRef)
                      }
                    ]}
                  />
                  <source src={`${host}/api/videos/${renderVideoId}/user?token=${token}`} type="video/mp4" />
                  <ControlBar>
                    <PlaybackRateMenuButton rates={[5, 3, 2, 1.5, 1, 0.5, 0.1]} />
                    <Row gutter={[8, 8]} justify="center" align="middle">
                      <Col>
                        <StepBackwardOutlined onClick={onRewindFrame(true, playerRenderRef)} className="fs-xs cursor-pointer" />
                      </Col>
                      <Col>
                        <StepForwardOutlined onClick={onRewindFrame(false, playerRenderRef)} className="fs-xs cursor-pointer" />
                      </Col>
                    </Row>
                  </ControlBar>
                </Player>
              </Col>
            </Row>
          )}
          {token && renderAllVideo && (
            <Row>
              <Col xs={24}>Render all video:</Col>
              <Col xs={24}>
                <Player ref={playerAllRef} fluid={false} height={350}>
                  <Shortcut
                    shortcuts={[
                      {
                        keyCode: 38,
                        handle: () => {
                          console.log('test');
                        }
                      },
                      {
                        keyCode: 37,
                        handle: onRewindFrame(true, playerAllRef)
                      },
                      {
                        keyCode: 39,
                        handle: onRewindFrame(false, playerAllRef)
                      }
                    ]}
                  />
                  <ControlBar>
                    <PlaybackRateMenuButton rates={[5, 3, 2, 1.5, 1, 0.5, 0.1]} />
                    <Row gutter={[8, 8]} justify="center" align="middle">
                      <Col>
                        <StepBackwardOutlined onClick={onRewindFrame(true, playerAllRef)} className="fs-xs cursor-pointer" />
                      </Col>
                      <Col>
                        <StepForwardOutlined onClick={onRewindFrame(false, playerAllRef)} className="fs-xs cursor-pointer" />
                      </Col>
                    </Row>
                  </ControlBar>
                  <source src={`${host}/api/videos/${renderAllVideo.id}/user?token=${token}`} type="video/mp4" />
                </Player>
              </Col>
            </Row>
          )}
          {token && beautyVideo && (
            <Row>
              <Col xs={24}>Beauty video:</Col>
              <Col xs={24}>
                <Player ref={playerBeautyRef} fluid={false} height={350}>
                  <ControlBar>
                    <PlaybackRateMenuButton rates={[5, 3, 2, 1.5, 1, 0.5, 0.1]} />
                    <Row gutter={[8, 8]} justify="center" align="middle">
                      <Col>
                        <StepBackwardOutlined onClick={onRewindFrame(true, playerBeautyRef)} className="fs-xs cursor-pointer" />
                      </Col>
                      <Col>
                        <StepForwardOutlined onClick={onRewindFrame(false, playerBeautyRef)} className="fs-xs cursor-pointer" />
                      </Col>
                    </Row>
                  </ControlBar>
                  <Shortcut
                    shortcuts={[
                      {
                        keyCode: 38,
                        handle: () => {
                          console.log('test');
                        }
                      },
                      {
                        keyCode: 37,
                        handle: onRewindFrame(true, playerBeautyRef)
                      },
                      {
                        keyCode: 39,
                        handle: onRewindFrame(false, playerBeautyRef)
                      }
                    ]}
                  />
                  <source src={`${host}/api/videos/${beautyVideo.id}/user?token=${token}`} type="video/mp4" />
                </Player>
              </Col>
            </Row>
          )}
          {token && logInfoImage && (
            <Row>
              <Col xs={24}>Log image video:</Col>
              <Col xs={24}>
                <Image width={200} src={`${host}/api/images/${logInfoImage.id}?token=${token}`} />
              </Col>
            </Row>
          )}
          <Row className="mt-5" justify="space-between">
            {renderVideoId ? (
              <a
                className="fs-sm font-family__default"
                href={`${host}/api/videos/${renderVideoId}/user?token=${token}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Download render video
              </a>
            ) : (
              <span className="fs-sm font-family__default">No render video</span>
            )}
            <span className="fs-sm font-family__default">Render status: {renderStatus}</span>

            <Button
              onClick={() => renderPlayerTest && playerTestId && renderPlayerTest(playerTestId)}
              loading={loading}
              disabled={isRenderInProgress || loading}
            >
              Render
            </Button>
          </Row>
        </Col>
      </Row>

      <Row className="mt-5 mb-5">
        {feedId ? (
          <Row align="middle">
            <span className="basic__text_label mr-3" title="Feed link">
              Feed link:
            </span>
            <Link to={`${ERoutesPrivate.Feed}/${feedId}`}>{feedId}</Link>
          </Row>
        ) : (
          <span className="basic__text_label" title="No feed link">
            No feed link
          </span>
        )}
      </Row>

      <Row gutter={[28, 10]}>
        <Col span={12}>
          <Form.Item label="Developer messages" name="developerMessages">
            <Input.TextArea maxLength={1024} rows={3} />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Investigation status is required'
              }
            ]}
            label="Investigation status"
            name="investigationStatus"
          >
            <PlayerTestInvestigationStatus />
          </Form.Item>
        </Col>
        <Col span={12}>
          <div className="mb-5">
            <span className="fs-sm font-weight-5 mr-5 font-family__default">Investigation status:</span>
            <span className="fs-sm font-family__default">{investigationStatus}</span>
          </div>
          <div className="mb-5">
            <div className="fs-sm font-weight-5 mr-5 font-family__default">Developer investigation messages:</div>
            {developerMessagesArray}
          </div>
          <div className="mb-5">
            <div className="fs-sm font-weight-5 mr-5 font-family__default">Warnings:</div>
            {warningsArray}
          </div>
        </Col>
      </Row>
      <Row gutter={[28, 10]}>
        <Col span={12}>
          <Row className="mb-5">
            <span className="fs-sm font-weight-5 mr-5 font-family__default">Date:</span>
            <span className="fs-sm font-family__default">{testDate}</span>
          </Row>
          {team && (
            <Row className="mb-5">
              <span className="fs-sm font-weight-5 mr-5 font-family__default">Team:</span>
              <span title={team?.id} className="fs-sm font-family__default">
                {team?.name}
              </span>
            </Row>
          )}
          <Row className="mb-5">
            <span className="fs-sm font-weight-5 mr-5 font-family__default">Video length:</span>
            <span className="fs-sm font-family__default">{videoTime ?? 0} sec.</span>
          </Row>
          <Row className="mb-5">
            <span className="fs-sm font-weight-5 mr-5 font-family__default">Processing time:</span>
            <span className="fs-sm font-family__default">{processingTime ?? 0} sec.</span>
          </Row>
          {status && [EPlayerTestStatus.Accept, EPlayerTestStatus.Reject].includes(status as EPlayerTestStatus) && (
            <Row className="mb-5">
              <Col>
                <span className="fs-sm font-weight-5 mr-5 font-family__default">Moderation time:</span>
                <span className="fs-sm font-family__default">{moderationTime ?? 0} sec.</span>
              </Col>
              {tagger && (
                <Col className="ml-2">
                  <Link to={`${ERoutesPrivate.UserList}/${tagger.id}`} target="_blank">
                    (<span className="fs-sm font-family__default">{nameBuilder(tagger.firstName, tagger.lastName)}</span>)
                  </Link>
                </Col>
              )}
            </Row>
          )}
          <Row className="mb-5">
            <span className="fs-sm font-weight-5 mr-5 font-family__default">App version:</span>
            <span className="fs-sm font-family__default">{appVersion || 'Unknown'}</span>
          </Row>
          <Row className="mb-5">
            <span className="fs-sm font-weight-5 mr-5 font-family__default">Phone model:</span>
            <span className="fs-sm font-family__default">{phoneModel || 'Unknown'}</span>
          </Row>
        </Col>
        <Col span={12}>
          {rejectCode && (
            <Row className="mb-5">
              <span className="fs-sm font-weight-5 mr-5 font-family__default">Code:</span>
              <span className="fs-sm font-family__default">{EPlayerTestRejectReasonText[ERejectCodes[rejectCode]]}</span>
            </Row>
          )}
          {rejectReason && (
            <Row className="mb-5">
              <span className="fs-sm font-weight-5 mr-5 font-family__default">Reject reason:</span>
              <span className="fs-sm font-family__default">{rejectReason}</span>
            </Row>
          )}
          {tryParseCount !== null && tryParseCount !== undefined && (
            <Row className="mb-5">
              <span className="fs-sm font-weight-5 mr-5 font-family__default">Attempts to send to the AI:</span>
              <span className="fs-sm font-family__default">{tryParseCount}</span>
            </Row>
          )}
          {nonResettableTryParseCount !== null && nonResettableTryParseCount !== undefined && (
            <Row className="mb-5">
              <span className="fs-sm font-weight-5 mr-5 font-family__default">Total attempts to send to the AI:</span>
              <span className="fs-sm font-family__default">{nonResettableTryParseCount}</span>
            </Row>
          )}
          {status &&
            [EPlayerTestStatus.Moderation, EPlayerTestStatus.AIModeration, EPlayerTestStatus.New].includes(
              status as EPlayerTestStatus
            ) && (
              <Button
                onClick={() => setIsVisible(!isVisible)}
                type="primary"
                disabled={loading}
                loading={loading}
                title={submitButtonText}
              >
                Edit test
              </Button>
            )}
        </Col>
        <Col span={12}>
          <div className="fs-sm font-weight-5 font-family__default mb-5">Conditions values:</div>
          {fieldMetadataArray?.length
            ? fieldMetadataArray?.map((itemValue, index) => (
                <Row key={index} className="mb-5">
                  <span className="fs-xs font-weight-4 mr-5 font-family__default">{`${itemValue[0] || ''}:`}</span>
                  <span className="fs-xs font-family__default">
                    {itemValue[1] === undefined || itemValue[1] === null ? '-' : itemValue[1]}
                  </span>
                </Row>
              ))
            : 'No data'}
        </Col>
      </Row>
    </Form>
  );
};

export const PlayerTestForm = communicationUI.injector(
  communicationFeed.injector(communicationPlayer.injector(communicationAdmin.injector(PlayerTestFormComponent)))
);
