import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { EPlayerTestInvestigationStatus } from 'entities/PlayerTest/PlayerTest.models';

interface IComponentProps {
  onChange?: (value: EPlayerTestInvestigationStatus) => void;
  placeholder?: string;
}

type AllProps = SelectProps<any> & IComponentProps;

export class PlayerTestInvestigationStatus extends React.PureComponent<AllProps> {
  handleChange = (value: EPlayerTestInvestigationStatus) => {
    const { onChange } = this.props;
    onChange && onChange(value);
  };

  render() {
    const { value, defaultValue, placeholder = 'Select' } = this.props;

    return (
      <Select
        allowClear
        className="width-full"
        value={value}
        placeholder={placeholder}
        defaultActiveFirstOption={false}
        defaultValue={defaultValue}
        showArrow
        onChange={(val: EPlayerTestInvestigationStatus) => this.handleChange(val)}
      >
        <Select.Option
          key={EPlayerTestInvestigationStatus.None}
          value={EPlayerTestInvestigationStatus.None}
          label={EPlayerTestInvestigationStatus.None}
        >
          {EPlayerTestInvestigationStatus.None}
        </Select.Option>
        <Select.Option
          key={EPlayerTestInvestigationStatus.Needed}
          value={EPlayerTestInvestigationStatus.Needed}
          label={EPlayerTestInvestigationStatus.Needed}
        >
          {EPlayerTestInvestigationStatus.Needed}
        </Select.Option>
        <Select.Option
          key={EPlayerTestInvestigationStatus.Done}
          value={EPlayerTestInvestigationStatus.Done}
          label={EPlayerTestInvestigationStatus.Done}
        >
          {EPlayerTestInvestigationStatus.Done}
        </Select.Option>
        <Select.Option
          key={EPlayerTestInvestigationStatus.Resolved}
          value={EPlayerTestInvestigationStatus.Resolved}
          label={EPlayerTestInvestigationStatus.Resolved}
        >
          {EPlayerTestInvestigationStatus.Resolved}
        </Select.Option>
      </Select>
    );
  }
}
