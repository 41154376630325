import axios, { AxiosStatic } from 'axios';
import { BaseHttpTransport } from '@axmit/transport';
import { IFranchiseModel, IFranchiseCreate, IFranchiseRequestModelUpdateStatusParams } from 'entities/Franchise/Franchise.models';

const basePath = '/franchises';

class FranchiseTransport extends BaseHttpTransport<AxiosStatic, IFranchiseModel, void, IFranchiseCreate, void, void> {
  public getFranchiseRequest = (id: string): Promise<void> => axios.get(`franchise-requests/${id}`);

  public updateStatusFranchiseRequest = ({ id, ...rest }: IFranchiseRequestModelUpdateStatusParams): Promise<void> =>
    axios.put(`franchise-requests/${id}`, rest);
}

export const franchiseTransport = new FranchiseTransport(basePath, axios);
