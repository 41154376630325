import React, { useMemo, useState } from 'react';
import { Button, Col, Form, Input, InputNumber, Row } from 'antd';
import { useFormMapper } from '@axmit/antd4-helpers';
import { Link } from 'react-router-dom';
import { StoreBranch } from '@axmit/redux-communications';
import { ERoutesPrivate } from 'common/models/routesModel';
import { ImageUpload } from 'common/components/Image/ImageUpload';
import {
  IProductCreateData,
  IProductModel,
  TProductParamsModel,
  IProductUpdateData,
  EProductMarket
} from 'entities/Product/Product.models';
import { ProductTargetGroupSelector } from 'entities/Product/components/Selectors/ProductTargetGroupSelector';
import { ProductStatusSelector } from 'entities/Product/components/Selectors/ProductStatusSelector';
import { ProductTypeSelector } from 'entities/Product/components/Selectors/ProductTypeSelector';
import { ProductMarketSelector } from 'entities/Product/components/Selectors/ProductMarketSelector';
import { EAcademyStatus } from 'entities/Academy/Academy.models';
import { EPlayerTestStatus } from 'entities/PlayerTest/PlayerTest.models';

interface IComponentProps {
  createProduct?: (data: IProductCreateData) => void;
  updateProduct?: (data: IProductUpdateData) => void;
  deleteProduct?: (id: string) => void;
  productModel: StoreBranch<IProductModel, TProductParamsModel>;
  productId?: string;
}

const ProductFormComponent: React.FC<IComponentProps> = props => {
  const { productModel, updateProduct, productId, createProduct } = props;
  const { errors, loading, data, params } = productModel;
  const isUpdateForm = !!productId;
  const submitButtonText = isUpdateForm ? 'Save' : ' Add';
  const memoizedValue = useMemo(
    () => ({
      ...data,
      image: data?.image?.id
    }),
    [data]
  );
  const { fields } = useFormMapper(
    [
      'description',
      'amount',
      'image',
      'status',
      'targetGroups',
      'type',
      'market',
      'externalId',
      'localizationTag',
      'yookassaAmount'
    ],
    memoizedValue,
    params,
    errors
  );

  const addProduct = (values: any) => {
    const {
      amount,
      description,
      image,
      status,
      targetGroups,
      type,
      market,
      externalId,
      localizationTag,
      yookassaAmount
    } = values;

    const newData: IProductCreateData = {
      image,
      amount,
      description,
      status,
      targetGroups,
      type,
      market,
      externalId,
      localizationTag
    };
    if (yookassaAmount !== undefined) {
      newData.yookassaAmount = yookassaAmount;
    }

    createProduct && createProduct(newData);
  };
  const editProduct = (values: any) => {
    const { amount, description, image, status, targetGroups, localizationTag, yookassaAmount } = values;

    if (productId && updateProduct) {
      const newData: IProductUpdateData = {
        image,
        amount,
        description,
        status,
        targetGroups,
        id: productId,
        localizationTag
      };
      if (yookassaAmount !== undefined) {
        newData.yookassaAmount = yookassaAmount;
      }
      updateProduct(newData);
    }
  };
  const [showYookassaAmount, setShowYookassaAmount] = useState<boolean>(false);

  const onFieldsChanged = (changedValue: any) => {
    if (changedValue?.market) {
      setShowYookassaAmount(changedValue?.market === EProductMarket.Yookassa);
    }
  };

  return (
    <Form className="mt-5" onFinish={isUpdateForm ? editProduct : addProduct} fields={fields} onValuesChange={onFieldsChanged}>
      <>
        <Row className="mb-5" align="middle">
          <span className="basic__text_title" title="Information">
            Information
          </span>
        </Row>
        <Row gutter={[28, 10]}>
          {!isUpdateForm && (
            <>
              <Col span={24}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'External Id is required'
                    },
                    {
                      min: 2,
                      message: 'External Id must be longer than or equal 2 characters'
                    },
                    {
                      max: 128,
                      message: 'External Id must be shorter than or equal 128 characters'
                    }
                  ]}
                  name="externalId"
                  label="External Id"
                >
                  <Input name="externalId" type="text" placeholder="External Id" disabled={loading} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Type is required'
                    }
                  ]}
                  name="type"
                  label="Type"
                >
                  <ProductTypeSelector />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Market is required'
                    }
                  ]}
                  name="market"
                  label="Market"
                >
                  <ProductMarketSelector />
                </Form.Item>
              </Col>
            </>
          )}
          <Col span={24}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Amount is required'
                },
                { type: 'number', min: 0 }
              ]}
              name="amount"
              label="Amount"
            >
              <InputNumber min={0} name="amount" placeholder="Amount" disabled={loading} />
            </Form.Item>
          </Col>
          {showYookassaAmount && (
            <Col span={24}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Yookassa amount is required'
                  },
                  { type: 'number', min: 0 }
                ]}
                name="yookassaAmount"
                label="Yookassa amount"
              >
                <InputNumber min={0} name="yookassaAmount" placeholder="Yookassa amount" disabled={loading} />
              </Form.Item>
            </Col>
          )}

          <Col span={24}>
            <Form.Item
              rules={[
                {
                  min: 2,
                  message: 'Description must be longer than or equal 2 characters'
                },
                {
                  max: 1024,
                  message: 'Description must be shorter than or equal 1024 characters'
                }
              ]}
              name="description"
              label="Description"
            >
              <Input.TextArea placeholder="Description" disabled={loading} autoSize={{ minRows: 10, maxRows: 15 }} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="image" label="Image">
              <ImageUpload />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="targetGroups" label="Target group">
              <ProductTargetGroupSelector />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="status" label="Status">
              <ProductStatusSelector />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              rules={[
                {
                  min: 2,
                  message: 'Localization Tag must be longer than or equal 2 characters'
                },
                {
                  max: 32,
                  message: 'Localization Tag must be shorter than or equal 32 characters'
                }
              ]}
              name="localizationTag"
              label="Localization Tag"
            >
              <Input name="localizationTag" type="text" placeholder="Localization Tag" disabled={loading} />
            </Form.Item>
          </Col>
        </Row>
      </>

      <Row justify="space-between" align="middle">
        <Col span={16}>
          <Form.Item className="mb-0">
            <Button
              className="mr-8"
              htmlType="submit"
              type="primary"
              disabled={loading}
              loading={loading}
              title={submitButtonText}
            >
              {submitButtonText}
            </Button>
            <Link to={ERoutesPrivate.Product}>
              <Button disabled={loading} loading={loading} title="Cancel">
                Cancel
              </Button>
            </Link>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export const ProductForm = ProductFormComponent;
