import axios, { AxiosStatic } from 'axios';
import { BaseHttpTransport } from '@axmit/transport';
import {
  IFeedCollection,
  IFeedCollectionFilter,
  IFeedCreateParams,
  IFeedModel,
  IFeedUpdateParams
} from 'entities/Feed/Feed.models';

const basePath = '/feeds';

class FeedTransport extends BaseHttpTransport<
  AxiosStatic,
  IFeedModel,
  IFeedCollection,
  IFeedCreateParams,
  IFeedUpdateParams,
  IFeedCollectionFilter
> {}

export const feedTransport = new FeedTransport(basePath, axios);
